import React from "react";

import BaseModal, {
  Props as BaseModalProps,
} from "app/designSystem/components/Modal/BaseModal/BaseModal";

export default function TransactionalModal({
  isCloseable = false,
  avoidCloseOnOverlayClick = false,
  isOpen = false,
  title = "Title",
  ...props
}: TransactionalModalProps) {
  return (
    <BaseModal
      isCloseable={isCloseable}
      avoidCloseOnOverlayClick={avoidCloseOnOverlayClick}
      isOpen={isOpen}
      title={title}
      {...props}
    />
  );
}

export type TransactionalModalProps = BaseModalProps;
