import React from "react";
import { Tooltip as ChakraTooltip } from "@chakra-ui/react";

export type TooltipProps = {
  /**
   * The label of the tooltip
   */
  label?: React.ReactNode;
  /**
   * The accessible, human friendly label to use for screen readers.
   * If passed, tooltip will show the content `label` but expose only `aria-label` to assistive technologies
   */
  "aria-label"?: string;

  /**
   * If `true`, the tooltip will wrap its children in a `<span/>` with `tabIndex=0`.
   * This is useful to show Tooltip on a disabled `<Button/>`
   * @default false
   */
  shouldWrapChildren?: boolean;

  /**
   * Delay (in ms) before showing the tooltip
   * @default 300ms
   */
  openDelay?: number;

  /**
   * Delay (in ms) before hiding the tooltip
   * @default 0ms
   */
  closeDelay?: number;

  /**
   * Callback to run when the tooltip shows
   */
  onOpen?(): void;

  /**
   * Callback to run when the tooltip hides
   */
  onClose?(): void;

  /**
   * If `true`, the tooltip will be shown (in controlled mode).
   * Note: This does not trigger onOpen and onClose.
   * @default false
   */
  isOpen?: boolean;

  /**
   * If `true`, the tooltip will be initially shown
   * @default false
   */
  defaultIsOpen?: boolean;

  /**
   * If `true`, the tooltip will not be shown at all.
   * @default false
   */
  isDisabled?: boolean;

  /**
   * The main and cross-axis offset to displace popper element from its reference element.
   */
  offset?: [number, number];

  /**
   * The placement of the tooltip relative to its reference.
   */
  placement?:
    | "auto-start"
    | "auto"
    | "auto-end"
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "left-start"
    | "left"
    | "left-end";
};

export const Tooltip = ({
  children,
  "aria-label": ariaLabel,
  isDisabled,
  isOpen,
  defaultIsOpen,
  label,
  offset,
  onClose,
  onOpen,
  closeDelay,
  openDelay = 300,
  placement = "bottom",
  shouldWrapChildren,
}: React.PropsWithChildren<TooltipProps>) => {
  return (
    <ChakraTooltip
      aria-label={ariaLabel}
      closeDelay={closeDelay}
      isDisabled={isDisabled}
      defaultIsOpen={defaultIsOpen}
      isOpen={isOpen}
      label={label}
      offset={offset}
      onClose={onClose}
      onOpen={onOpen}
      openDelay={openDelay}
      placement={placement}
      shouldWrapChildren={shouldWrapChildren}
    >
      {children}
    </ChakraTooltip>
  );
};
