import { ModalHeaderProps, StackProps, TextProps } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

export const modalHeaderStyle: Partial<ModalHeaderProps> = {
  borderTopRadius: "md",
  pt: "16px",
  pb: "24px",
  px: "32px",
};

export const mainVerticalStackStyle: Partial<StackProps> = {
  align: "stretch",
  flex: 1,
  spacing: "8px",
};

export const mainHorizontalStackStyle: Partial<StackProps> = {
  justify: "space-between",
  align: "flex-start",
  width: "full",
};

export const rightHorizontalStackStyle: Partial<StackProps> = {
  spacing: 6,
};

export const titleStyle: Partial<TextProps> = {
  textStyle: "heading3",
  textColor: "black",
  m: 0,
  overflowWrap: "anywhere",
};

export const backIconStyle: Partial<React.ComponentProps<typeof ChakraIcon>> = {
  cursor: "pointer",
  mt: "8px",
};

export const secondaryInfoStyle: Partial<TextProps> = {
  fontStyle: "italic",
  textStyle: "body1",
  textColor: "grey",
  m: 0,
  overflowWrap: "anywhere",
};

export const subtitleStyle: Partial<TextProps> = {
  textColor: "grey-dark",
  textStyle: "body1",
  m: 0,
};
