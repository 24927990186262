import React from "react";

import PageLayoutWrapper from "app/components/PageLayout/PageLayoutWrapper";
import { useApi } from "app/hooks/useCurrentIndex";
import ProspectWarningMessage from "components/ProspectWarningMessage";
import useCurrentUser from "app/hooks/useCurrentUser";

export default function PageLayout(
  props: React.ComponentProps<typeof PageLayoutWrapper>
) {
  const {
    showIndexSelector,
    indexBaseUrl,
    allowAllIndexes,
    disableIndexSelector,
    persistQueryParams,
    children,
  } = props;

  const [index, { fetching }] = useApi();

  const { email: userEmail } = useCurrentUser();

  return (
    <PageLayoutWrapper
      showIndexSelector={showIndexSelector}
      indexBaseUrl={indexBaseUrl}
      allowAllIndexes={allowAllIndexes}
      disableIndexSelector={disableIndexSelector}
      persistQueryParams={persistQueryParams}
      index={index}
      isIndexFetching={fetching}
    >
      <>
        {userEmail && <ProspectWarningMessage />}
        {children}
      </>
    </PageLayoutWrapper>
  );
}
