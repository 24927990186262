import {
  Box,
  Button as ChakraButton,
  Flex,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";

import { Button } from "app/designSystem/components";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import dayjs from "utils/dayjs";

const MaintainanceBanner: FC<Props> = ({
  onClose,
  startTime,
  endTime,
  isActive,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const durationHours = dayjs(endTime).diff(startTime, "hours");
  const duration = `${durationHours} ${durationHours === 1 ? "hour" : "hours"}`;

  const content = isActive ? (
    <>
      👋 <b>Attention:</b> Constructor is undergoing scheduled maintenance,{" "}
      ending at <b>{dayjs(endTime).format("h:mm A")} (your time)</b>. Limited
      searchandizing capabilities during this time.{" "}
    </>
  ) : (
    <>
      👋 <b>Attention:</b> Constructor will undergo scheduled maintenance on{" "}
      <b>
        {dayjs(startTime).format("MMMM D")}, starting at{" "}
        {dayjs(startTime).format("h:mm A")} (your time)
      </b>{" "}
      for up to {duration}. Limited searchandizing capabilities during this
      time.{" "}
    </>
  );

  if (!isVisible) {
    return null;
  }

  return (
    <Flex
      bgColor="yellow-very-light"
      width="100%"
      padding="14px 32px"
      alignItems="center"
      justifyContent="space-between"
      role="banner"
    >
      <Text as="span" textStyle="body2" color="black">
        {content}
        <Popover
          trigger="hover"
          placement="bottom-end"
          arrowShadowColor="transparent"
        >
          <PopoverTrigger>
            <ChakraButton
              display="inline-block"
              fontWeight="normal"
              boxSize="auto"
              role="button"
              textDecoration="underline"
              variant="unstyled"
              verticalAlign="baseline"
              borderWidth={0}
            >
              See details here
            </ChakraButton>
          </PopoverTrigger>
          <PopoverContent shadow="md">
            <PopoverArrow bgColor="grey-dark" />
            <Box
              position="relative"
              bgColor="grey-dark"
              color="white"
              padding="16px"
              textAlign="left"
              role="tooltip"
              borderRadius="md"
            >
              <Text
                as="span"
                display="inline-block"
                marginBottom="30px"
                textStyle="body2"
              >
                During maintenance, you will not be able to create, edit or
                delete searchandizing rules, including:
              </Text>
              <Box as="ul" padding="0" margin="0" marginBottom="30px">
                {[
                  "Search rules",
                  "Autosuggest rules",
                  "Browse rules",
                  "Global rules",
                ].map((text) => (
                  <Text
                    key={text}
                    as="li"
                    textStyle="body2"
                    display="inline-flex"
                    alignItems="center"
                    width="100%"
                  >
                    <ChakraIcon
                      icon="close-circle-24"
                      color="white"
                      marginRight="5px"
                    />
                    {text}
                  </Text>
                ))}
              </Box>
              <Text
                as="span"
                display="inline-block"
                marginBottom="30px"
                textStyle="body2"
              >
                We apologize for any inconvenience and appreciate your patience.
              </Text>
              <PopoverCloseButton
                as="span"
                display="inline"
                position="relative"
                width="auto"
                height="auto"
                background="transparent"
                outline="none"
                border="none"
                margin="0"
                padding="0"
              >
                <Button variant="secondary">Thanks</Button>
              </PopoverCloseButton>
            </Box>
          </PopoverContent>
        </Popover>
        .
      </Text>
      <ChakraButton
        display="inline-flex"
        h="auto"
        onClick={() => {
          onClose?.();
          setIsVisible(false);
        }}
        title="Close warning"
        type="button"
        variant="unstyled"
      >
        <ChakraIcon
          boxSize="16px"
          icon="close-24"
          color="grey-dark"
          cursor="pointer"
        />
      </ChakraButton>
    </Flex>
  );
};

type Props = {
  onClose?: () => void;
  startTime: Date;
  endTime: Date;
  isActive?: boolean;
};

export default MaintainanceBanner;
