// Scrolls window to display element top or bottom side if this side is not
// visible in the current viewport.
export default function revealElement(
  element: HTMLElement,
  { side = "top", offset = 0 }: Options
) {
  if (side === "top") {
    throw new Error("Revealing element top is not currently supported");
  }

  const { top, height } = element.getBoundingClientRect();

  if (top + height + offset > window.innerHeight) {
    const oldScrollBehavior = document.body.style.scrollBehavior;
    document.body.style.scrollBehavior = "smooth";

    document.body.scrollTo({
      top:
        // window bottom should match element bottom + offset after scroll
        document.body.scrollTop + top + height + offset - window.innerHeight,
    });

    window.setTimeout(
      () => (document.body.style.scrollBehavior = oldScrollBehavior),
      128
    );
  }
}

type Options = {
  side?: "top" | "bottom";
  offset?: number;
};
