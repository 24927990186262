import React, { ReactNode } from "react";
import { Router } from "react-router-dom";

import { scrollToHash } from "utils/scrollToHash";

import history from "components/browserHistory";

export default function RouterProvider({ children }: Props) {
  let currentPathName = history.location.pathname;
  history.listen((location) => {
    if (location.pathname !== currentPathName) {
      currentPathName = location.pathname;
      window.scrollTo({ top: 0 });
    }

    setTimeout(() => {
      if (location.hash) {
        scrollToHash(location);
      }
    }, 128);
  });

  return <Router history={history}>{children}</Router>;
}

type Props = {
  children: ReactNode;
};
