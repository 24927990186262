const disabledRadioStyle = {
  cursor: "not-allowed",
  bg: "white-disabled",
  borderColor: "grey-disabled",
  color: "grey-disabled",
  icon: { color: "grey-disabled" },
  _before: {
    bg: "grey-disabled",
  },
};

const getNewVariant = (color: string, hoverColor: string) => {
  return {
    control: {
      borderColor: "grey-light-green",
      _hover: {
        borderColor: "grey-dark",
      },
      _checked: {
        bg: "inherit",
        borderColor: "inherit",
        color: color,
        _before: {
          bg: color,
          width: "8px",
          height: "8px",
        },
        _hover: {
          bg: "inherit",
          color: hoverColor,
          borderColor: hoverColor,
          _before: {
            bg: hoverColor,
          },
        },
      },
    },
  };
};

export default {
  parts: ["label"],
  baseStyle: {
    label: {
      cursor: "pointer",
      _disabled: { cursor: "not-allowed" },
    },
    control: {
      bg: "white",
      cursor: "pointer",
      border: "2px solid",
      transition: "color 0.2s ease-in, border-color 0.2s ease-in",
      _disabled: disabledRadioStyle,
      _hover: { _disabled: disabledRadioStyle },
      _checked: { _disabled: disabledRadioStyle },
    },
  },
  sizes: {
    md: {
      control: {
        w: "16px",
        h: "16px",
      },
    },
  },
  variants: {
    primary: getNewVariant("green", "green-dark"),
    secondary: getNewVariant("blue", "blue-dark"),
  },
  defaultProps: {
    size: "md",
    variant: "primary",
  },
};
