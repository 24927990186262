import React from "react";
import { Box } from "@chakra-ui/react";

import Link from "components/Modules/Link";
import Graphics from "components/Modules/Graphics";

export default function BrandLogo({
  isSidebarOpen,
}: {
  isSidebarOpen: boolean;
}) {
  return (
    <Box
      w={{ sm: "94px", md: isSidebarOpen ? "94px" : "28px" }}
      overflow="hidden"
      transition="width 300ms ease-in-out"
    >
      <Link to="/dashboard">
        <Graphics.Brand.LogoHorizontalNew
          display="block"
          htmlHeight="22px"
          htmlWidth="100px"
        />
      </Link>
    </Box>
  );
}
