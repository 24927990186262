import React, { ReactNode } from "react";
import { QueryParamProvider } from "use-query-params";

import Route from "components/Modules/Route";

export default function QueryParamsProvider({ children }: Props) {
  return (
    <QueryParamProvider ReactRouterRoute={Route}>{children}</QueryParamProvider>
  );
}

type Props = {
  children: ReactNode;
};
