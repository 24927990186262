import React, { ReactNode } from "react";
import {
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
  RadioGroup,
  useRadio,
  Flex,
  Box,
} from "@chakra-ui/react";

type RadioProps = Omit<
  ChakraRadioProps,
  "size" | "isChecked" | "isDisabled" | "isReadOnly" | "isRequired" | "variant"
> & {
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  children?: ReactNode;
  variant?: (string & {}) | "primary" | "secondary";
  helperText?: ReactNode;
};

/**
 * This component will give a warning "React does not recognize the `isChecked` prop on a DOM element".
 * This is a known issue and is fixed in version 1.7.3 of chakra-ui
 *
 */

export function Radio({
  children,
  checked = false,
  disabled = false,
  readOnly = false,
  required = false,
  helperText,
  ...args
}: RadioProps) {
  return (
    <Flex direction="column">
      <ChakraRadio
        isRequired={required}
        isChecked={checked}
        isReadOnly={readOnly}
        isDisabled={disabled}
        {...args}
      >
        {children}
      </ChakraRadio>
      {!!helperText && (
        <Box marginLeft="25px" marginTop="8px" textStyle="body3">
          {helperText}
        </Box>
      )}
    </Flex>
  );
}

export { RadioGroup, useRadio };
