import { useMutation } from "@tanstack/react-query";

import { update } from "modules/currentUser";
import { CurrentUser } from "modules/currentUser/types";

export default function useUpdateCurrentUser() {
  return useMutation<CurrentUser, Error, Parameters<typeof update>[0]>((data) =>
    update(data)
  );
}
