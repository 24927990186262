import {
  FlexProps,
  FormControlProps,
  FormLabelProps,
  InputProps,
  InputElementProps,
  TextProps,
} from "@chakra-ui/react";

export const getFormControlStyle = (hasError: boolean): FormControlProps => ({
  textAlign: "left",
  marginBottom: hasError ? "16px" : undefined,
});

export const labelContainer: FlexProps = {
  alignItems: "center",
};

export const formLabel: FormLabelProps = {
  textStyle: "body1",
  color: "grey-dark",
  fontWeight: "bold",
  mb: "0",
  mr: "0",
};

export const leftAddon: InputElementProps = {
  m: "0",
  p: "0",
};

export const icon = {
  mt: "8px",
  ml: "16px",
  width: "16px",
  height: "16px",
};

export const rightIcon = {
  mt: "8px",
  mr: "16px",
  width: "16px",
  height: "16px",
};

export const getInputStyle = (
  hasIcon: boolean,
  hasRightIcon: boolean
): InputProps => {
  return {
    border: "2px solid",
    focusBorderColor: "green",
    errorBorderColor: "danger",
    borderColor: "grey-light-blue",
    bgColor: "white",
    color: "grey-dark",
    h: "44px",
    _hover: {
      boxShadow: "none",
      borderColor: "grey",
    },
    _invalid: {
      boxShadow: "none",
      borderColor: "danger",
    },
    _focus: {
      boxShadow: "none",
      borderColor: "green",
    },
    _disabled: {
      borderColor: "grey-disabled",
      bgColor: "grey-light-disabled",
    },
    _placeholder: {
      color: "grey",
    },
    sx: {
      paddingY: "14px",
      paddingLeft: hasIcon ? "40px" : "16px",
      paddingRight: hasRightIcon ? "40px" : "16px",
    },
  };
};

export const text: TextProps = {
  textStyle: "body3",
  minHeight: "16px",
  m: "0",
  lineHeight: "16px",
};
