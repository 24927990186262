import React from "react";
import { Global, css, ClassNames } from "@emotion/react";

import ReactModal from "react-modal";

// TODO: Setting the `body` element as the "app element" is incorrect -
// react-modal will set this element as `aria-hidden="true"` when the modal is
// open, meaning that the whole app is technically inaccessible to a screen
// reader (and to `testing-library` queries).
// A proper solution implies:
// * Setting an id or some unique identifier to the main "App" element.
// * Using a selector that matches that element here.
// * Export a custom render function to wrap all `testing-library` renders in a
//   container that matches that selector as well.
ReactModal.setAppElement("body");

ReactModal.defaultStyles.content = {
  ...ReactModal.defaultStyles.content,
  background: "transparent",
  border: "none",
  top: 0,
  bottom: 0,
  padding: 0,
};

ReactModal.defaultStyles.overlay = {
  ...ReactModal.defaultStyles.overlay,
  backgroundColor: "rgba(0, 0, 0, 0.55)",
  zIndex: 10,
  paddingTop: "16px",
  paddingBottom: "16px",
  display: "flex",
  overflow: "auto",
};

const Gate = React.forwardRef(
  (
    {
      isOpen,
      children,
      className,
      ...props
    }: Props & React.ComponentProps<typeof ReactModal>,
    ref: React.Ref<ReactModal>
  ) => (
    <>
      <Global
        styles={css`
          .ReactModal__Body--open {
            overflow: hidden;
          }
        `}
      />

      <ClassNames>
        {({ css }) => {
          const baseClassName = css`
            margin: auto;
            display: flex;
            align-items: center;
            opacity: 0;
            transform: translateY(-15%);
            transition: opacity 150ms linear, transform 0.15s ease-out;
          `;

          const afterOpenClassName = css`
            opacity: 1;
            transform: translateY(0);
          `;

          const beforeCloseClassName = css`
            opacity: 0;
            transform: translateY(-15%);
          `;

          return (
            <ReactModal
              closeTimeoutMS={150}
              isOpen={isOpen}
              className={{
                base: className || baseClassName,
                afterOpen: afterOpenClassName,
                beforeClose: beforeCloseClassName,
              }}
              ref={ref}
              {...props}
              overlayClassName={css`
                position: fixed;
                inset: 0px;
                background-color: rgba(0, 0, 0, 0.55);
                // need to override modals opened before
                z-index: 1400;
                padding-top: 16px;
                padding-bottom: 16px;
                display: flex;
                overflow: auto;
              `}
            >
              {isOpen && children}
            </ReactModal>
          );
        }}
      </ClassNames>
    </>
  )
);

Gate.displayName = "Gate";

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  onRequestClose?: () => void;
};

export default Gate;
