import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/Modules/Icon";

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(356deg);
  }
`;

/**
 * @deprecated Use Chakra's `Spinner` component instead.
 */
const Spinner = function ({ className }) {
  return (
    <Icon
      icon="spinner"
      aria-label="Loading"
      className={className}
      title="Loading..."
      css={css`
        animation: ${rotation} 2s infinite linear;
      `}
    />
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
};

export default Spinner;

const SpinnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { SpinnerContainer };
export { SpinnerOverlay };
export { SpinnerBackground };
export { WithSpinner };

const SpinnerOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const SpinnerBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  opacity: 0.5;
`;

const SpinnerWrapper = styled.div`
  text-align: center;
  padding: 32px;
`;

function WithSpinner({
  displaySpinner,
  children,
  overlay,
  wrapperProps = {},
  ...props
}) {
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: 100%;
      `}
      {...props}
    >
      {displaySpinner &&
        (React.isValidElement(overlay) ? (
          overlay
        ) : overlay ? (
          <SpinnerOverlay>
            <SpinnerBackground />
            <Spinner />
          </SpinnerOverlay>
        ) : (
          <SpinnerWrapper {...wrapperProps}>
            <Spinner />
          </SpinnerWrapper>
        ))}

      {children}
    </div>
  );
}

WithSpinner.defaultProps = {
  overlay: true,
};

WithSpinner.propTypes = {
  overlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  displaySpinner: PropTypes.bool,
  children: PropTypes.node,
  wrapperProps: PropTypes.object,
};
