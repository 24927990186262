export default {
  parts: ["toast", "action"],
  baseStyle: {
    toast: {
      display: "inline-flex",
      alignItems: "center",
      padding: "12px 16px",
      color: "white",
      borderRadius: "4px",
      boxShadow: "feedback",
      apply: "textStyles.body2",
      minWidth: "304px",
      textAlign: "left",
    },
    action: {
      apply: "textStyles.subheadline",
      color: "white",
      p: 0,
      background: "none",
      border: "none",
      cursor: "pointer",
    },
  },
  variants: {
    success: {
      toast: {
        backgroundColor: "green",
      },
    },
    info: {
      toast: {
        backgroundColor: "blue",
      },
    },
    error: {
      toast: {
        backgroundColor: "danger",
      },
    },
  },
  defaultProps: {
    variant: "success",
  },
};
