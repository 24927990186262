import React from "react";
import { Spinner as ChakraSpinner, Flex } from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./Spinner.styles";

export const Spinner = ({
  size = "md",
  color = "success",
  emptyColor,
  label,
  speed = "1s",
  thickness,
}: SpinnerProps) => {
  return (
    <Flex {...styles.SpinnerContainer(size)}>
      <ChakraSpinner
        size={size}
        emptyColor={emptyColor}
        label={label}
        speed={speed}
        thickness={thickness}
        color={color}
        {...styles.Spinner(size)}
      />
    </Flex>
  );
};

type SpinnerProps = {
  /**
   * The size of the `spinner`.
   */
  size?: "sm" | "md" | "lg";
  /**
   * Set the color of the `spinner`.
   */
  color?: string;
  /**
   * Set the color of the empty area in the `spinner`.
   */
  emptyColor?: string;
  /**
   * For accessibility, set a fallback loading text that is visible to screen readers.
   */
  label?: string;
  /**
   * Set the time taken for 1 rotation.
   */
  speed?: string;
  /**
   * Set the thickness of `spinner`.
   */
  thickness?: string;
};
