import React from "react";
import { Text } from "@chakra-ui/react";

import ThemeProvider from "app/providers/ThemeProvider";

import CrabsTable from "./Subscription/CrabsTable";
import { CompanyInfo, SubscriptionInfo } from "./Subscription/types";

export default function Subscription({ subscription, companyInfo }: Props) {
  return (
    <ThemeProvider>
      <Text color="black" textStyle="heading2" m="4px 0">
        Subscription
      </Text>

      {subscription && subscription.displayOnPricingPage && (
        <Text fontSize="14px" fontWeight="medium" lineHeight="5" m="0 0 24px">
          Plan billed monthly at ${subscription.price}
        </Text>
      )}

      <CrabsTable companyInfo={companyInfo} />
    </ThemeProvider>
  );
}

type Props = {
  subscription: SubscriptionInfo;
  companyInfo: CompanyInfo;
};
