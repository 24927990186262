import {
  UserData,
  User,
  NextPageCursor,
  NextPageCursorData,
} from "modules/authentication/actions/shared/types";
import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

export type GetCompanyUsersParams = {
  companyId: string;
  nextPageCursor?: string;
};

/**
 * @description Get all users assigned to the specified company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-company-users
 * @returns {GetCompanyUsersResponse}
 */
export default async function getUsersByCompany({
  companyId,
  nextPageCursor = "",
}: GetCompanyUsersParams): Promise<GetCompanyUsersResponse> {
  const queryParams = nextPageCursor
    ? { next_page_cursor: nextPageCursor }
    : {};

  const result = await AuthApi.get<GetCompanyUsersResponseData>(
    ["v1", "companies", companyId, "users"],
    queryParams
  );

  return GetCompanyUsersResponse.fromGetCompanyUsersResponseData(result);
}

getUsersByCompany.key = "modules/authentication/actions/users/getRoleUsers";

export type GetCompanyUsersResponseData = {
  users: UserData[];
} & NextPageCursorData;

export type GetCompanyUsersResponse = {
  users: User[];
} & NextPageCursor;

export const GetCompanyUsersResponse = {
  fromGetCompanyUsersResponseData: (
    data: GetCompanyUsersResponseData
  ): GetCompanyUsersResponse => {
    const getCompanyUsersResponse: GetCompanyUsersResponse = {
      users: data.users.map((user) => ({
        createdAt: user.created_at
          ? convertFromApiFormat(user.created_at)
          : null,
        email: user.email,
        firstName: user.first_name,
        id: user.id,
        lastLoginAt: user.last_login_at
          ? convertFromApiFormat(user.last_login_at)
          : null,
        lastName: user.last_name,
        signInCount: user.sign_in_count,
      })),
      nextPageCursor: data.next_page_cursor,
    };
    return getCompanyUsersResponse;
  },
};
