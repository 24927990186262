import * as Sentry from "@sentry/react";
// @refactoring Forbid usage of `ramda` (https://constructor.slab.com/posts/deprecating-ramda-and-adopting-remeda-wlks8rn7)
// eslint-disable-next-line local-rules/no-ramda
import { omit } from "ramda";

import { AppError } from "modules/appError";

import ApiErrors from "./ApiErrors";

function reportError(error: unknown, errorInfo?: { [key: string]: unknown }) {
  /* eslint-disable-next-line no-console */
  console.error(error);

  if (error instanceof AppError && !error.notify) {
    // Don't report errors that are not meant to be reported to Sentry.
    return;
  }

  Sentry.withScope((scope) => {
    // Sets additional data
    if (errorInfo) {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    }

    // if payload is a special data that should be uploaded with error
    if (typeof error === "object" && error !== null && "payload" in error) {
      const payload = (error as { payload: { [key: string]: unknown } })
        .payload;
      const sanitizedPayload = omit(["displayErrorMessage"], payload);
      Object.keys(sanitizedPayload).forEach((key) => {
        scope.setExtra(key, sanitizedPayload[key]);
      });
    }

    if (error instanceof AppError) {
      Object.keys(error.metadata).forEach((key) => {
        scope.setExtra(key, error.metadata[key]);
      });

      return Sentry.captureException(error);
    }

    if (error instanceof ApiErrors) {
      return Sentry.captureException(error, {
        extra: {
          message: error.message,
          errors: error.errors,
          status: error.status,
          source: error.source,
        },
      });
    }

    if (error instanceof Error) {
      return Sentry.captureException(error);
    }

    if (typeof error === "string") {
      return Sentry.captureMessage(error);
    }

    return Sentry.captureException(error);
  });
}

export default reportError;
