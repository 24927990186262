import React from "react";
import styled from "@emotion/styled";
import { Button, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import {
  CHAKRA_ICONS_16_TYPE,
  CHAKRA_ICONS_24_TYPE,
} from "app/designSystem/components/ChakraIcon/iconTypes";

import { Spinner } from "app/designSystem/components/Spinner";

const accentColors: Record<
  string,
  {
    borderColor: { hover: string; focus: string };
    backgroundColor: string;
    svgColor: string;
  }
> = {
  magenta: {
    borderColor: {
      hover: "var(--cio-colors-magenta-light)",
      focus: "var(--cio-colors-magenta)",
    },
    backgroundColor: "var(--cio-colors-magenta-light)",
    svgColor: "magenta-light",
  },
};

export type Props = {
  /**
   * Button size
   */
  size?: "lg" | "sm";
  /**
   * Button UI variant
   */
  variant?: "primary" | "ghost" | "danger" | "secondary" | "link";
  /**
   * Used for specifying a left icon for the button.
   * This property is only applicable to `ghost`, `secondary` and `link` variant
   * buttons.
   */
  icon?: CHAKRA_ICONS_16_TYPE | CHAKRA_ICONS_24_TYPE;
  /**
   * Space between icon and label.
   */
  iconSpacing?: ChakraButtonProps["iconSpacing"];
  /**
   * If `true`, the button will have a big "plus" icon with appropriate padding.
   */
  withPlusIcon?: boolean;
  /**
   * Whether the button is disabled.
   */
  disabled?: boolean;
  /**
   * Whether the button is in its loading state.
   */
  isLoading?: boolean;
  /**
   * Label to show in the button when it's loading.
   */
  loadingText?: string;
  /**
   * If `true`, the button will take the full width of its container.
   */
  isFullWidth?: boolean;
  /**
   * Determines where to put the spinner in the loading state.
   */
  spinnerPlacement?: "end" | "start";
  /**
   * Called when the user activates the button.
   */
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  /**
   * HTML element id.
   */
  id?: string;
  /**
   * Button name within a form
   */
  name?: string;
  /**
   * Indicates whether the button can be focused, and in which order it will
   * appear in sequential keyboard navigation.
   */
  tabIndex?: number;
  /**
   * Accessible label for the element.
   */
  ariaLabel?: string;
  /**
   * Id of the element that labels this button.
   */
  ariaLabelledBy?: string;
  /**
   * Button type.
   */
  type?: "button" | "submit" | "reset";
  /**
   * Button width.
   */
  width?: ChakraButtonProps["width"];
  /**
   * Button color scheme.
   */
  colorScheme?: "magenta" | "blue";
  /**
   * CSS filter.
   */
  filter?: string;
  /**
   * Id of the form this button is associated with (only necessary if its not
   * the button's ancestor.)
   */
  form?: string;
  /**
   * Id of the element to get a reference to it during tests.
   *
   * **USE ONLY AS A LAST RESORT**. Buttons usually have semantic texts or
   * labels that indicate what they do / what they're for (otherwise, how would
   * real users know?). Make sure you've read the caveats and suggestions in
   * https://testing-library.com/docs/queries/bytestid/
   */
  dataTestId?: string;
  children: React.ReactNode;
};

// We use React's `forwardRef` instead of Chakra so that Storybook will pick up
// props and their descriptions automatically. If we run into a situation where
// we need to use the `as` prop with this component (which would require us to
// use Chakra's version of `forwardRef`) consider the options available here
// https://chakra-ui.com/community/recipes/as-prop.
// If none work, then we might need to duplicate prop definitions in storybook.
// We export `RegularButton` as a named export so that Storybook will be able to
// properly extract prop information for the UI controls.
export const RegularButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      size = "sm",
      variant = "primary",
      disabled = false,
      withPlusIcon = false,
      isLoading = false,
      isFullWidth = false,
      loadingText,
      spinnerPlacement,
      ariaLabel,
      onClick,
      id,
      name,
      type = "button",
      ariaLabelledBy,
      dataTestId,
      tabIndex,
      icon,
      iconSpacing,
      width,
      colorScheme,
      filter,
      form,
      ...rest
    },
    ref
  ) => {
    const getLeftIcon = () => {
      // If the button is one of ghost, link or secondary variant and icon prop exists,
      // then return the icon
      if (
        (variant === "ghost" ||
          variant === "link" ||
          variant === "secondary") &&
        icon
      ) {
        return <ChakraIcon icon={icon} />;
      }

      // Special case for primary and secondary button variants with a plus icon
      if (
        withPlusIcon &&
        !isLoading &&
        size === "lg" &&
        (variant === "primary" || variant === "secondary")
      ) {
        return <ChakraIcon icon="add-circle-24" boxSize="icon-md" />;
      }
    };
    return (
      <ButtonElement
        type={type}
        name={name}
        id={id}
        ref={ref}
        variant={variant}
        size={size}
        isLoading={isLoading}
        disabled={disabled}
        isFullWidth={isFullWidth}
        loadingText={loadingText}
        spinnerPlacement={spinnerPlacement}
        onClick={onClick}
        leftIcon={getLeftIcon()}
        withPlusIcon={withPlusIcon && !icon}
        iconSpacing={iconSpacing}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        data-testid={dataTestId}
        tabIndex={tabIndex}
        width={width}
        colorScheme={colorScheme}
        form={form}
        sx={
          colorScheme && accentColors[colorScheme]
            ? { svg: { color: accentColors[colorScheme].svgColor } }
            : undefined
        }
        filter={filter}
        spinner={<Spinner size="sm" color="white" />}
        {...rest}
      >
        {children}
      </ButtonElement>
    );
  }
);

RegularButton.displayName = "Button";

export const ButtonElement = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "isFullWidth" && prop !== "withPlusIcon",
})`
  padding-top: ${({ leftIcon, variant, size }) => {
    if (
      leftIcon &&
      (variant === "primary" || variant === "secondary" || variant === "ghost")
    ) {
      return size === "sm" ? "4px" : "8px";
    }
  }};
  padding-bottom: ${({ leftIcon, variant, size }) => {
    if (
      leftIcon &&
      (variant === "primary" || variant === "secondary" || variant === "ghost")
    ) {
      return size === "sm" ? "4px" : "8px";
    }
  }};
  padding-inline-start: ${({ leftIcon, variant, size, withPlusIcon }) => {
    if (withPlusIcon && (variant === "primary" || variant === "secondary")) {
      return size === "sm" ? "14px" : "10px";
    }
    if (leftIcon && (variant === "primary" || variant === "secondary")) {
      return size === "sm" ? "14px" : "16px";
    }
  }};
  padding-inline-end: ${({ leftIcon, variant, size }) => {
    if (leftIcon && (variant === "primary" || variant === "secondary")) {
      return size === "sm" ? "14px" : "18px";
    }
  }};
  background-color: ${({ leftIcon, variant }) => {
    if (leftIcon && variant === "secondary") {
      return "var(--cio-colors-white)";
    }
  }};
  color: ${({ leftIcon, variant }) => {
    if (leftIcon && variant === "secondary") {
      return "var(--cio-colors-grey-dark)";
    }
  }};
  &:hover,
  &:focus,
  &:active {
    color: ${({ leftIcon, variant }) => {
      if (leftIcon && variant === "secondary") {
        return "var(--cio-colors-white)";
      }
    }};
    svg {
      color: ${({ leftIcon, withPlusIcon, variant, disabled }) => {
        if (leftIcon && !withPlusIcon && variant === "secondary" && !disabled) {
          return "var(--cio-colors-white)";
        }
      }};
    }
  }
  background-color: ${({ isLoading, variant }) => {
    if (isLoading) {
      if (variant === "primary") {
        return "var(--cio-colors-green-dark)";
      }
      if (variant === "ghost") {
        return "var(--cio-colors-grey-light-blue)";
      }
      if (variant === "danger") {
        return "var(--cio-colors-danger-dark)";
      }
      if (variant === "secondary") {
        return "var(--cio-colors-blue-dark)";
      }
    }
  }};
  color: ${({ isLoading, variant }) => {
    if (isLoading && variant === "ghost") {
      return "var(--cio-colors-white)";
    }
  }};
  ${({ isFullWidth, width }) =>
    width ? `width: ${width}` : isFullWidth ? "width: 100%" : "width: auto"};
  ${({ variant, leftIcon, size }) => {
    if (variant === "ghost" && size === "sm" && leftIcon) {
      return `
        svg {
          margin-inline-end: -3px;
        }
      `;
    }
  }}
  ${({ variant, leftIcon, size }) => {
    if (variant === "ghost" && size === "lg" && leftIcon) {
      return `
        padding-inline-start: 14px;
        padding-inline-end: 18px;
      `;
    }
  }}
  ${({ variant, isFullWidth }) => {
    if (isFullWidth) {
      if (variant === "ghost") {
        return;
      }

      return `
        b {
          margin: 0 auto;
        }
      `;
    }
  }}
  ${({ variant, size, leftIcon }) => {
    if (variant === "secondary" && size === "lg" && leftIcon) {
      return `
          border-color: var(--cio-colors-grey-light-blue);

          &:hover {
            background-color: var(--cio-colors-blue);
            border-color: var(--cio-colors-blue);
          }

          &:active, &:focus {
            background-color: var(--cio-colors-blue);
            border-color: var(--cio-colors-blue-dark);
          }
      `;
    }
  }}

  ${({ colorScheme }) => {
    if (colorScheme && accentColors[colorScheme]) {
      return `
          border-color: var(--cio-colors-grey-light-blue);

          &:hover {
            background-color: ${accentColors[colorScheme].backgroundColor};
            border-color: ${accentColors[colorScheme].borderColor.hover};
          }

          &:active, &:focus {
            background-color: ${accentColors[colorScheme].backgroundColor};
            border-color: ${accentColors[colorScheme].borderColor.focus};
          }
      `;
    }
  }}
`;

export default RegularButton;
