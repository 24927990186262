import React, { useEffect } from "react";

import * as routes from "utils/routes";
import { UserLoggedOutError, AppError } from "modules/appError";
import Link from "components/Modules/Link";
import reportError from "services/ReportError";
import ApiErrors from "services/ApiErrors";
import { RequestError } from "services/request";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Alert } from "./Alert";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { AlertFailureProps } from "./Alert.types";

export function AlertFailure({
  error,
  className,
  noClose = true,
}: AlertFailureProps) {
  useEffect(() => {
    if (error instanceof ApiErrors && error.status !== 422) {
      reportError(error, error.info);
    } else if (error instanceof Error) {
      reportError(error);
    }
  }, [error]);

  if (error instanceof AppError && error.isExpected()) {
    return (
      <Alert noClose={noClose} type="danger" className={className}>
        {error.message}
      </Alert>
    );
  }

  if (error instanceof UserLoggedOutError) {
    return (
      <Alert noClose={noClose} type="danger" className={className}>
        You are not currently logged in - please{" "}
        <Link to={routes.signIn().toString()} useHistory={false}>
          sign in again.
        </Link>
      </Alert>
    );
  }

  if (error instanceof RequestError && !!error?.payload?.displayErrorMessage) {
    return (
      <Alert noClose={noClose} type="danger" className={className}>
        Unexpected error
        {` "${error.payload.displayErrorMessage}"`}: our team is already
        notified about this problem; reach out to us at{" "}
        <a href="mailto:support@constructor.io">support@constructor.io</a> if
        you need help.
      </Alert>
    );
  }

  return (
    <Alert noClose={noClose} type="danger" className={className}>
      Unexpected error{error && ` "${error.message || error.toString()}"`}: our
      team is already notified about this problem; reach out to us at{" "}
      <a href="mailto:support@constructor.io">support@constructor.io</a> if you
      need help.
    </Alert>
  );
}
