import React from "react";
import styled from "@emotion/styled";
import { Text } from "@chakra-ui/react";

export default function TrialBadge() {
  return (
    <TrialBadgeBackground>
      <Text
        textStyle="caption"
        fontWeight="bold"
        textTransform="uppercase"
        color="white"
        m="0"
      >
        Trial
      </Text>
    </TrialBadgeBackground>
  );
}

const TrialBadgeBackground = styled.span`
  display: flex;
  width: 50px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--neutral-grey);
`;
