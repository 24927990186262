export const shadows = {
  base: "0px 2px 1px 0px rgba(80, 92, 117, 0.05)",
  modal: "0px 4px 16px 0px rgba(0, 93, 186, 0.04)",
  feedback: "0px 3px 8px 0px rgba(0, 0, 0, 0.1)",
  list: "0px 0 8px 0px rgba(0, 0, 0, 0.1)",
  card: "0px 2px 1px 0px rgba(80, 92, 117, 0.05)",
  "card-edit":
    "0px 8.44615px 16.8923px rgba(96, 128, 153, 0.1), 0px 1.68923px 16.8923px rgba(96, 128, 153, 0.04), 0px 0.844615px 3.37846px rgba(96, 128, 153, 0.06)",
  menu: "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 93, 186, 0.1)",
  right: "8px 0 14px 0px rgba(225, 229, 241, 0.35)",
  popover: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
  highlight:
    "0px 1px 2px 0px #181C2514, 0px 2px 6px 0px #181C250A, 0px 0px 0px 1px #181C2514, 0px 0px 0px 4px #181C2521",
};
