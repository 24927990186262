// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { ColorSchemes } from "./Badge.types";

const paddingLeftSize = 8;
const marginBetweenIcon = 4;
const iconSize = 10;

export const Badge = (colorScheme: ColorSchemes, showOverflowText: boolean) => {
  return {
    Badge: {
      borderRadius: "12px",
      maxWidth: "119px",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: `${paddingLeftSize}px`,
      paddingRight: `${paddingLeftSize + iconSize + marginBetweenIcon}px`,
      height: "24px",
      backgroundColor:
        colorScheme === "green" ? "green-light-2" : "danger-light",
      color: colorScheme === "green" ? "green-dark" : "danger",
    },
    Text: {
      textStyle: "body-caption",
      margin: 0,
      textOverflow: "ellipsis",
      overflow: showOverflowText ? "initial" : "hidden",
    },
  };
};

export const BetaBadge = {
  Badge: {
    borderRadius: "12px",
    maxWidth: "119px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    p: "0 8px",
    bgColor: "green-dark",
    color: "white",
    h: "20px",
  },
  Text: {
    textStyle: "body-caption",
    m: 0,
    fontSize: "11px",
  },
};

export const NewBadge = {
  Badge: {
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgColor: "grey-dark",
    color: "white",
    maxWidth: "50px",
    p: "0 8px",
    h: "20px",
    marginLeft: "8px",
  },
  Text: {
    m: 0,
    textStyle: "body-caption",
    fontSize: "8px",
  },
};

export const TrialBadge = {
  Badge: {
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    bgColor: "#FFE4DE",
    color: "#FF592E",
    maxWidth: "63px",
    p: "0 8px",
    h: "20px",
    marginLeft: "8px",
  },
  Text: {
    m: 0,
    textStyle: "body-caption",
    fontSize: "8px",
    fontWeight: 600,
  },
};
