import React from "react";
import {
  Table as ChakraTable,
  Thead as ChakraThead,
  Tbody as ChakraTbody,
  Tr as ChakraTr,
  Th as ChakraTh,
  Td as ChakraTd,
  Text,
} from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./DataTable.styles";
import {
  ThProps,
  TrProps,
  TbodyProps,
  TheadProps,
  TdProps,
  // @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
  // eslint-disable-next-line local-rules/enforce-fractal-pattern,
} from "./DataTable.types";

export const Table = ChakraTable;

export const Thead = ({
  children,
  position,
  zIndex,
}: React.PropsWithChildren<TheadProps>) => (
  <ChakraThead {...styles.THeadStyles({ position, zIndex })}>
    {children}
  </ChakraThead>
);

export const Tbody = ({
  children,
  position,
  height,
  width,
}: React.PropsWithChildren<TbodyProps>) => (
  <ChakraTbody
    {...styles.TBodyStyles({
      position,
      height,
      width,
    })}
  >
    {children}
  </ChakraTbody>
);

export const Tr = ({
  isSelected = false,
  onClick,
  children,
  position,
  top,
  left,
  height,
  width,
  transform,
  cursor,
}: React.PropsWithChildren<TrProps>) => (
  <ChakraTr
    {...styles.TrStyles({
      isSelected,
      position,
      top,
      left,
      height,
      width,
      transform,
      cursor,
    })}
    onClick={onClick}
  >
    {children}
  </ChakraTr>
);

export const Th = ({
  isNumeric,
  children,
  onClick,
  width,
  left,
  bgColor,
  borderColor,
  borderBottom,
  borderBottomColor,
  borderRight,
  borderRightColor,
  position,
  top,
  zIndex,
  padding,
}: React.PropsWithChildren<ThProps>) => (
  <ChakraTh
    {...styles.ThStyles({ width, padding: String(padding) })}
    isNumeric={isNumeric}
    textTransform="none"
    onClick={onClick}
    left={left}
    bgColor={bgColor}
    position={position}
    borderColor={borderColor}
    borderBottom={borderBottom}
    borderBottomColor={borderBottomColor}
    borderRight={borderRight}
    borderRightColor={borderRightColor}
    top={top}
    zIndex={zIndex}
  >
    {children}
  </ChakraTh>
);

export const Td = ({
  isNumeric,
  children,
  width,
  height,
  ...props
}: React.PropsWithChildren<TdProps>) => (
  <ChakraTd
    isNumeric={isNumeric}
    {...styles.TdStyles({ width, height })}
    {...props}
  >
    {children}
  </ChakraTd>
);

export const TableHeaderText = ({ children }: React.PropsWithChildren<{}>) => (
  <Text {...styles.TableHeaderTextStyles} textTransform="none">
    {children}
  </Text>
);
