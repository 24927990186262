import React, {
  FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Box, List, Text, Button, ListItem } from "@chakra-ui/react";
import { FixedSizeList } from "react-window";

import Spinner from "components/Modules/Spinner";
import { Select } from "app/designSystem/components/Select";
import { SearchBar } from "components/Modules/Widgets/SearchBar";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { ChakraIcon } from "../ChakraIcon/ChakraIcon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as types from "./MultiSelect.types";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./MultiSelect.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as helpers from "./MultiSelect.helpers";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import OptionListItem from "./OptionListItem";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import MaskedSelectBox from "./MaskedSelectBox";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import EmptyMaskedSelectBox from "./EmptyMaskedSelectBox";

/*
 * consider making this component to be a dumb component in the future (no useState, or useEffect)
 * existing useState & useEffect used by the default onSearch & filterItems behavior
 */

export const MultiSelect: FC<types.MultiSelectProps> = ({
  name,
  data = [],
  label,
  placeholder,
  wordSeparator,
  isLoading = false,
  actionBtnLabel = "Add",
  containerMinWidth = "auto",
  containerHeight = styles.CONTAINER_HEIGHT,
  onAdd,
  onEdit,
  onSearch,
  onSelectItems,
  isInvalid,
  onBlur,
  ariaLabel,
  hideSelectedItems = false,
  isSubmitButtonDisabled,
  selectAllText,
  hideAddButton = false,
  isDisabled,
}) => {
  const [isInitialData, setIsInitialData] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [items, setItems] = useState(data);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const selectedItems = items.filter(
    (item: types.MultiSelectItem) => item.selected
  );

  const filteredItems = useMemo(
    () => helpers.filterItems(items, searchKeyword),
    [items, searchKeyword]
  );

  const dataItems = filteredItems || items;
  const isEmptyItems = !items.length || !filteredItems.length;

  const joinSeparator = wordSeparator
    ? ` ${wordSeparator.toUpperCase()} `
    : ", ";

  const maskedSelectBoxItems = selectedItems.map((item) => ({
    id: item.id,
    label: item.labelTagOverride || item.label,
  }));

  const selectedItemLabels = maskedSelectBoxItems
    .map((item) => item.label)
    .join(joinSeparator);

  const searchBarRef = useRef<HTMLInputElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);

  const onSelectHandler = (id: types.MultiSelectItemId, index?: number) => {
    setActiveIndex(index ?? -1);

    setIsInitialData(false);
    setItems((prev) => helpers.toggleSelectedItems(id, prev));

    onSelectItems?.([id]);
  };

  const onSearchHandler = (keyword: string) => {
    if (onSearch) {
      onSearch(keyword);
      return;
    }

    setSearchKeyword(keyword);
  };

  const onChangeSelectOpen = (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);

    if (isOpen) {
      // We only do it when the dropdown gets opened, to avoid all the items
      // showing up briefly as the dropdown closes.
      setSearchKeyword("");
    }
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  useEffect(() => {
    if (isDropdownOpen) {
      // Chakra sets focus to the dropdown body after opening the dropdown, so we use "requestAnimationFrame" to override this behavior and set focus after chakra finishes setting theirs.
      requestAnimationFrame(() => {
        searchBarRef.current?.focus();
      });
    }
  }, [isDropdownOpen]);

  const isShowingEditLink = !!(onEdit && selectedItems.length && isInitialData);
  const isAddButtonDisabled =
    typeof isSubmitButtonDisabled === "boolean"
      ? isSubmitButtonDisabled
      : isInitialData || !selectedItems.length;

  const moveFocusUp = (index: number) => {
    if (index === 0) {
      searchBarRef?.current?.focus();
    } else {
      setActiveIndex(activeIndex <= 0 ? dataItems.length - 1 : activeIndex - 1);
    }
  };

  const moveFocusDown = () => {
    if (activeIndex + 1 === dataItems.length && !isAddButtonDisabled) {
      submitButtonRef?.current?.focus();
      setActiveIndex(-1);
    } else {
      setActiveIndex(
        activeIndex + 1 === dataItems.length ? 0 : activeIndex + 1
      );
    }
  };

  const handleItemOnKeyDownEvent = (
    eventKey: React.KeyboardEvent["key"],
    id: types.MultiSelectItemId,
    index: number,
    isShiftKeyPressed: boolean
  ) => {
    switch (eventKey) {
      case "Up":
      case "ArrowUp":
        moveFocusUp(index);
        return;
      case "Tab":
        if (isShiftKeyPressed) {
          moveFocusUp(index);
        } else {
          moveFocusDown();
        }
        return;
      case "Down":
      case "ArrowDown":
        moveFocusDown();
        return;
      case "Enter":
      case " ": // Space
        onSelectHandler(id, index);
        return;
    }
  };

  const onSelectAllHandler = useCallback(
    (isAllSelected: boolean) => {
      setActiveIndex(-1);

      setIsInitialData(false);

      const filteredItemsIds = filteredItems.map((item) => item.id);
      setItems((prev) =>
        helpers.selectAll(filteredItemsIds, prev, isAllSelected)
      );

      onSelectItems?.(
        filteredItems
          .filter((item) => item.selected !== isAllSelected)
          .map((item) => item.id)
      );
    },
    [filteredItems, onSelectItems]
  );

  const isAllSelected = filteredItems.every((item) => item.selected);

  let maskedSelectBox: JSX.Element | null = null;
  if (hideSelectedItems) {
    const emptyMaskedSelectBoxProps =
      typeof placeholder === "string"
        ? {
            placeholder,
          }
        : {};

    maskedSelectBox = <EmptyMaskedSelectBox {...emptyMaskedSelectBoxProps} />;
  } else if (selectedItems.length !== 0) {
    maskedSelectBox = (
      <MaskedSelectBox
        isShowingEditLink={isShowingEditLink}
        onRemoveHandler={onSelectHandler}
        selectedItems={maskedSelectBoxItems}
        joinSeparator={joinSeparator}
        wordSeparator={wordSeparator}
      />
    );
  }

  return (
    <Select
      isOpen={isDropdownOpen}
      name={name}
      label={label}
      placeholder={placeholder}
      isMultiSelect
      isShowingEditLink={isShowingEditLink}
      onClickEditLink={onEdit}
      value={selectedItemLabels}
      onChangeOpen={onChangeSelectOpen}
      maskedSelectBox={maskedSelectBox}
      isInvalid={isInvalid}
      onBlur={onBlur}
      ariaLabel={ariaLabel}
      isDisabled={isDisabled}
    >
      <List w="100%" borderBottomRadius={0} minWidth={containerMinWidth}>
        <ListItem p="8px" bgColor="white" _before={{ height: 0 }}>
          <Box
            flexGrow={1}
            onKeyDown={(e) => {
              if (e.key === "ArrowDown" || e.key === "Tab") {
                e.preventDefault();
                e.stopPropagation();
                setActiveIndex(0);
              }
            }}
            onFocus={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setActiveIndex(-1);
            }}
          >
            <SearchBar
              ref={searchBarRef}
              size="sm"
              isAlwaysExpanded
              query={searchKeyword}
              onChange={onSearchHandler}
            />
          </Box>
        </ListItem>
      </List>

      {isLoading && (
        <List
          {...styles.listItemEmptyContainer(containerHeight)}
          minWidth={containerMinWidth}
        >
          <Box {...styles.containerLoading(containerHeight)}>
            <Spinner />
          </Box>
        </List>
      )}

      {!isLoading && isEmptyItems && (
        <List
          {...styles.listItemEmptyContainer(containerHeight)}
          minWidth={containerMinWidth}
        >
          <Box bgColor="white" flexGrow={1}>
            <Text {...styles.containerEmptyStateText}>No items found.</Text>
          </Box>
        </List>
      )}

      {!isLoading && !isEmptyItems && (
        <FixedSizeList
          height={containerHeight}
          style={{
            backgroundColor: "white",
            minWidth: containerMinWidth,
          }}
          innerElementType={({ children }) => (
            <List
              height="auto"
              w="100%"
              minWidth={containerMinWidth}
              borderRadius="0"
            >
              {selectAllText && !!filteredItems.length && (
                <ListItem
                  onClick={() => onSelectAllHandler(!isAllSelected)}
                  role="option"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    flexGrow={1}
                    maxW="80%"
                    alignItems="center"
                  >
                    <ChakraIcon {...styles.checkboxIcon(isAllSelected)} />
                    <Text {...styles.listItemText}>
                      {`${selectAllText} (${filteredItems.length})`}
                    </Text>
                  </Box>
                </ListItem>
              )}
              {children}
            </List>
          )}
          itemSize={44}
          width="100%"
          itemData={{
            items: dataItems,
            onSelectHandler,
            activeIndex,
            handleItemOnKeyDownEvent,
          }}
          itemCount={dataItems.length}
          itemKey={(index, { items }) => items[index].id}
        >
          {({ data, index, style }) =>
            selectAllText ? (
              <OptionListItem
                data={data}
                index={index}
                style={{
                  ...style,
                  top: Number(style.top) + Number(style.height),
                }}
              />
            ) : (
              <OptionListItem data={data} index={index} style={style} />
            )
          }
        </FixedSizeList>
      )}

      {!hideAddButton && (
        <Button
          ref={submitButtonRef}
          className="multi-select-action-button"
          disabled={isAddButtonDisabled}
          minW={containerMinWidth}
          onClick={onAdd}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              setIsDropdownOpen(false);
              onAdd?.();
              return;
            }

            if (e.key === "ArrowUp" || (e.key === "Tab" && e.shiftKey)) {
              e.preventDefault();
              e.stopPropagation();
              setActiveIndex(dataItems.length - 1);
              return;
            }

            if (e.key === "ArrowDown" || e.key === "Tab") {
              e.preventDefault();
              e.stopPropagation();
              searchBarRef?.current?.focus();
              setActiveIndex(-1);
            }
          }}
          {...styles.buttonAction}
        >
          {actionBtnLabel}
        </Button>
      )}
    </Select>
  );
};
