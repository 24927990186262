import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";

const quizzesItemPlaceholder = require("./quizzes-item-placeholder.png");
export const QuizzesItemPlaceholder = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={quizzesItemPlaceholder}
      htmlWidth={311}
      htmlHeight={240}
      alt=""
      {...props}
    />
  );
};

const hugeLogo = require("./huge-logo.svg");
export const HugeLogo = () => {
  return (
    <Image
      src={hugeLogo}
      position="absolute"
      left="50%"
      transform="translateX(-50%)"
      alt="Constructor logo"
    />
  );
};
