import { convertFromApiFormat } from "utils/datesApi";

export type Index = {
  id: number;
  name: string;
  indexKey: string;
  itemImageBaseUrl: string | null;
  serviceUrl: string;
  defaultSearchSection: string;
  autocompleteClientHost: string;
  sections: Section[];
  active: boolean;
  environment: IndexEnvironment;
  infrastructurePool: string;
};

export const Index = {
  fromData(data: IndexData): Index {
    return {
      id: data.id,
      indexKey: data.index_key,
      name: data.name,
      itemImageBaseUrl: data.item_image_base_url,
      serviceUrl: data.service_url,
      defaultSearchSection: data.default_search_section,
      autocompleteClientHost: data.autocomplete_client_host,
      sections: data.sections.map((section) => ({
        name: section.name,
        type: section.type,
        acPartId: section.ac_part_id,
        acId: section.ac_id,
        lastUpdated:
          section.last_updated === null
            ? null
            : convertFromApiFormat(section.last_updated),
        lastIndexBuildTime:
          section.last_index_build_time === null
            ? null
            : convertFromApiFormat(section.last_index_build_time),
      })),
      active: data.active,
      environment: data.environment,
      infrastructurePool: data.infrastructure_pool,
    };
  },
};

export type Section = {
  name: string;
  type: string;
  acPartId: number;
  acId: number;
  lastUpdated: Date | null;
  lastIndexBuildTime: Date | null;
};

export const Section = {
  getSectionAutocompleteId(
    index: Index,
    sectionName: Section["name"]
  ): Section["acId"] | undefined {
    const { sections } = index;
    return sections.filter((section) => section.name === sectionName)[0]?.acId;
  },
  isProducts(section: string): boolean {
    return section.toLowerCase() === "products";
  },
};

export type IndexData = {
  id: number;
  name: string;
  index_key: string;
  item_image_base_url: string | null;
  service_url: string;
  default_search_section: string;
  autocomplete_client_host: string;
  sections: SectionData[];
  active: boolean;
  environment: IndexEnvironment;
  infrastructure_pool: string;
};

export enum IndexEnvironment {
  Development = "development",
  Qa = "qa",
  Production = "production",
}

export type SectionData = {
  name: string;
  type: string;
  ac_part_id: number;
  ac_id: number;
  last_updated: string | null;
  last_index_build_time: string | null;
};

export type CompanyKeyData = {
  key: string;
  environment: string;
  language: string | null;
  name: string;
  default_search_section: string;
};

export type CompanyKey = {
  key: string;
  environment: string;
  language: string | null;
  name: string;
  defaultSearchSection: string;
};
