import React from "react";
import { Box, Button, HStack, useMultiStyleConfig } from "@chakra-ui/react";
import assertNever from "assert-never";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

/**
 * Renders a toast message for the user.
 * You will typically use the `useToast` hook instead of using this component
 * directly.
 */
const Toast: React.FC<Props> = ({
  children,
  variant = "success",
  actionLabel,
  onAction,
}) => {
  const styles = useMultiStyleConfig("Toast", { variant });

  let icon;

  switch (variant) {
    case "success":
      icon = <ChakraIcon icon="success-white-16" />;
      break;
    case "info":
      icon = <ChakraIcon icon="info-white-16" />;
      break;
    case "error":
      icon = <ChakraIcon icon="danger-white-16" />;
      break;
    default:
      assertNever(variant);
  }

  return (
    <Box __css={styles.toast}>
      <HStack justifyContent="space-between" w="full">
        <HStack spacing="8px">
          {icon}
          <Box overflowWrap="anywhere">{children}</Box>
        </HStack>
        {!!actionLabel && (
          <Button __css={styles.action} onClick={onAction}>
            {actionLabel.slice(0, 12)}
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default Toast;

type Props = {
  /**
   * Message for the toast.
   */
  children: React.ReactNode;
  /**
   * Which variant of the toast to show.
   */
  variant?: "success" | "info" | "error";
  /**
   * If provided, the toast will have a button to trigger an action with this
   * label. 12 characters at most, anything longer will be truncated.
   */
  actionLabel?: string;
  /**
   * Expected when `actionLabel` is present - the action to trigger upon
   * clicking on the button.
   */
  onAction?: () => void;
};
