import React, { useEffect, useState } from "react";
import styled, { StyledComponent } from "@emotion/styled";
import { css } from "@emotion/react";

import * as routes from "utils/routes";
import { UserLoggedOutError, AppError } from "modules/appError";
import theme from "components/theme";
import Icon from "components/Modules/Icon";
import Link from "components/Modules/Link";
import reportError from "services/ReportError";
import ApiErrors, { ApiError } from "services/ApiErrors";
import { RequestError } from "services/request";

/**
 * @deprecated Use common component `Alert` instead.
 */
export default function Alert(props: Props) {
  const [display, setDisplay] = useState(true);

  if (!display) {
    return null;
  }

  return (
    <Container type={props.type} className={props.className}>
      {props.icon ? (
        props.icon
      ) : (
        <Icon
          css={css`
            margin-top: 2px;
          `}
          icon={TYPES[props.type].icon}
        />
      )}

      {"children" in props ? (
        <Content aria-label="Alert message">{props.children}</Content>
      ) : (
        <Content
          aria-label="Alert message"
          dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
        />
      )}

      {props.noClose ? null : (
        <Button
          type="button"
          aria-label="Close"
          onClick={() => {
            if (props.onClose) {
              props.onClose();
              return;
            }

            setDisplay(false);
          }}
        >
          <CloseIcon icon="x" />
        </Button>
      )}
    </Container>
  );
}

type Props = {
  noClose?: boolean;
  type: keyof typeof TYPES;
  onClose?: () => void;
  icon?: React.ReactNode;
  className?: string;
} & ChildrenOrHTML;

type ChildrenOrHTML =
  | {
      children: React.ReactNode;
    }
  | {
      dangerouslySetInnerHTML: { __html: string };
    };

/**
 * @deprecated Alert has been replaced with `/Chakra/Alert/Alert.tsx`
 */
export function Support({ children, className }: SupportProps) {
  return (
    <Alert type="warning" className={className} noClose>
      {children}

      <div
        css={css`
          margin-top: 8px;
        `}
      >
        <Contact />
      </div>
    </Alert>
  );
}

type SupportProps = {
  children: React.ReactNode;
  className?: string;
};

/**
 * @deprecated Alert has been replaced with `/Chakra/Alert/Alert.tsx`
 */
export function Contact() {
  return (
    <>
      Please contact <a href={`mailto:${Email}`}>{Email}</a> to receive help.
    </>
  );
}

/**
 * @deprecated Alert has been replaced with `/Chakra/Alert/Alert.tsx`
 */
export function Failure({ error, className }: FailureProps) {
  useEffect(() => {
    if (error instanceof ApiErrors && error.status !== 422) {
      reportError(error, error.info);
    } else if (error instanceof Error) {
      reportError(error);
    }
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error instanceof UserLoggedOutError) {
    return (
      <Alert noClose type="danger" className={className}>
        You are not currently logged in - please{" "}
        <Link to={routes.signIn().toString()} useHistory={false}>
          sign in again.
        </Link>
      </Alert>
    );
  }

  if (error instanceof AppError && !error.notify) {
    return (
      <Alert noClose type="danger" className={className}>
        {error.message}
      </Alert>
    );
  }

  if (error instanceof RequestError && !!error?.payload?.displayErrorMessage) {
    return (
      <Alert noClose type="danger" className={className}>
        Unexpected error
        {` "${error.payload.displayErrorMessage}"`}: our team is already
        notified about this problem; reach out to us at{" "}
        <a href="mailto:support@constructor.io">support@constructor.io</a> if
        you need help.
      </Alert>
    );
  }

  return (
    <Alert noClose type="danger" className={className}>
      Unexpected error{error && ` "${error.message || error.toString()}"`}: our
      team is already notified about this problem; reach out to us at{" "}
      <a href="mailto:support@constructor.io">support@constructor.io</a> if you
      need help.
    </Alert>
  );
}

type FailureProps = {
  error?: Error | ApiError;
  className?: string;
  noClose?: boolean;
};

export const Email = "support@constructor.io";

const TYPES = {
  success: {
    color: theme.colors.success,
    icon: "alert-success",
  },
  warning: {
    color: theme.colors.warning,
    icon: "alert-warning",
  },
  danger: {
    color: theme.colors.danger,
    icon: "alert-danger",
  },
} as const;

const Container: StyledComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  { type: keyof typeof TYPES },
  // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
> = styled.div<{ type: keyof typeof TYPES }>`
  background-color: ${theme.colors.backgroundWhite};
  box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 16px;
  padding-left: 13px;
  display: flex;
  justify-content: space-between;
  border-left: 3px solid ${({ type }) => TYPES[type].color};
  clear: both;
  margin: 10px 0;
  overflow: hidden;

  .alert & {
    margin: 0;
  }

  & + ${() => Container} {
    margin-top: 8px;
  }
`;

const Content = styled.span`
  flex: 1;
  margin-left: 10px;
  margin-right: 10px;
`;

const Button = styled.button`
  align-self: baseline;
  background-position: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const CloseIcon = styled(Icon)`
  vertical-align: middle;
`;
