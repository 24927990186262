import {
  UserData,
  User,
  NextPageCursor,
  NextPageCursorData,
} from "modules/authentication/actions/shared/types";
import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

export type GetRoleUsersParams = {
  companyId: string;
  roleId: string;
  nextPageCursor?: string;
};

/**
 * @description Get all users assigned to a specific role within a company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-assigned-company-users-to-role
 * @returns {GetUsersByCompanyAndRoleResponse}
 */
export default async function getUsersByCompanyAndRole({
  companyId,
  roleId,
  nextPageCursor = "",
}: GetRoleUsersParams): Promise<GetUsersByCompanyAndRoleResponse> {
  const queryParams = nextPageCursor
    ? { next_page_cursor: nextPageCursor }
    : {};

  const result = await AuthApi.get<GetRoleUsersResponseData>(
    ["v1", "companies", companyId, "roles", roleId, "users"],
    queryParams
  );

  return GetUsersByCompanyAndRoleResponse.fromGetRoleUsersResponseData(result);
}

getUsersByCompanyAndRole.key =
  "modules/authentication/actions/companies/getUsersByCompanyAndRole";

export type GetRoleUsersResponseData = {
  users: UserData[];
} & NextPageCursorData;

export type GetUsersByCompanyAndRoleResponse = {
  users: User[];
} & NextPageCursor;

export const GetUsersByCompanyAndRoleResponse = {
  fromGetRoleUsersResponseData: (
    data: GetRoleUsersResponseData
  ): GetUsersByCompanyAndRoleResponse => {
    const getRoleUsersResponse: GetUsersByCompanyAndRoleResponse = {
      users: data.users.map((user) => ({
        createdAt: user.created_at
          ? convertFromApiFormat(user.created_at)
          : null,
        email: user.email,
        lastLoginAt: user.last_login_at
          ? convertFromApiFormat(user.last_login_at)
          : null,
        firstName: user.first_name,
        id: user.id,
        lastName: user.last_name,
        signInCount: user.sign_in_count,
      })),
      nextPageCursor: data.next_page_cursor,
    };

    return getRoleUsersResponse;
  },
};
