import React from "react";
import { css } from "@emotion/react";

import { Failure } from "components/Modules/Alert";

const ErrorMessage =
  "Unexpected error: our team is already notified about this problem; reach " +
  "out to us at support@constructor.io if you need help.";

export { ErrorMessage };

const displayError = (error?: Error) => (
  <Failure
    error={error}
    css={css`
      height: min-content;
      margin: 16px;
    `}
  />
);

class ErrorBoundary extends React.Component<Props, State> {
  static defaultProps: Omit<Props, "children"> = {
    displayError: displayError,
  };

  state: State = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      return this.displayError(error);
    }

    const { children } = this.props;

    if (typeof children === "function") {
      try {
        return children();
      } catch (rawError: unknown) {
        return this.displayError(rawError as Error);
      }
    }

    return children;
  }

  displayError(error: Error) {
    const { displayError } = this.props;
    return displayError(error);
  }
}

type Props = {
  displayError: (error: Error) => React.ReactNode;
  children: React.ReactNode | (() => React.ReactNode);
};

type State = {
  error: Error | null;
};

export default ErrorBoundary;
