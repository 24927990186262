import URI from "utils/urijs";

/**
 * Reloads the current page removing from the URL any reference to the current
 * company.
 * This is useful when we need to force a reload after the user switched
 * companies.
 */
export default function reloadWithoutCompanyContext() {
  location.href = URI()
    .removeSearch("company_id")
    .removeSearch("index_key")
    .removeSearch("key")
    .toString();
}
