import React from "react";

import { Configuration } from "app/App";

import IndexFilter from "components/Modules/IndexFilter";

export default function WithHistory(
  props: React.ComponentProps<typeof IndexFilter>
) {
  return (
    <Configuration>
      <IndexFilter {...props} />
    </Configuration>
  );
}
