import RegularButton from "app/designSystem/components/Button/RegularButton/RegularButton";

export const buttonStyle: Partial<React.ComponentProps<typeof RegularButton>> =
  {
    size: "lg",
    variant: "ghost",
  };

export const buildConfirmButtonStyle = (
  variant: "confirm" | "unsaved"
): Partial<React.ComponentProps<typeof RegularButton>> => ({
  ...buttonStyle,
  variant: variant === "confirm" ? "danger" : "primary",
});
