import React, { useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import ReactRailsUJS from "react_ujs";

import { Configuration } from "app/App";

import PageLayout from "./PageLayoutWrapperRails/PageLayout";

export default function PageLayoutWrapperRails(
  props: React.ComponentProps<typeof Configuration> &
    React.ComponentProps<typeof PageLayout>
) {
  const {
    showIndexSelector,
    indexBaseUrl,
    allowAllIndexes,
    disableIndexSelector,
    persistQueryParams,
    html,
    ...rest
  } = props;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ReactRailsUJS.mountComponents(ref.current);
    // Javascript inside is not executed when setting innerHTML. This overcomes the issue.
    Array.from(ref.current.getElementsByTagName("script")).forEach(
      (oldScript) => {
        const newScript = document.createElement("script");
        Array.from(oldScript.attributes).forEach((attr) => {
          newScript.setAttribute(attr.name, attr.value);
        });
        const scriptText = document.createTextNode(oldScript.innerHTML);
        newScript.appendChild(scriptText);
        oldScript.parentNode!.replaceChild(newScript, oldScript);
      }
    );
  }, []);

  return (
    <Configuration {...rest}>
      <PageLayout
        showIndexSelector={showIndexSelector}
        indexBaseUrl={indexBaseUrl}
        allowAllIndexes={allowAllIndexes}
        disableIndexSelector={disableIndexSelector}
        persistQueryParams={persistQueryParams}
      >
        <Box ref={ref} dangerouslySetInnerHTML={{ __html: html }} />
      </PageLayout>
    </Configuration>
  );
}
