import { HTMLChakraProps } from "@chakra-ui/react";
import assertNever from "assert-never";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { SelectOperatorProps } from "./SelectOperator.types";

export function getStyles({
  isDisabled,
  operator,
  colorScheme = "green",
  textVariant = "default",
  isAllowChange = false,
}: SelectOperatorProps) {
  let color = "white";
  let backgroundColor = "grey-dark";

  if (operator === "if" || operator === "jump") {
    switch (colorScheme) {
      case "green":
        backgroundColor = "green";
        break;
      case "grey":
        backgroundColor = "grey-light-green";
        color = "grey-dark";
        break;
      default:
        assertNever(colorScheme);
    }
  }

  if (isDisabled) {
    backgroundColor = "grey-disabled";
    color = "grey";
  }

  return {
    button: {
      borderRadius: 5,
      bg: backgroundColor,
      p: isAllowChange ? "0 0 0 2px" : "0",
      w: "78px",
      h: "44px",
      minW: "78px",
      minH: "44px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textStyle: "subheadline",
      color,
      fontSize: "12px",
      _active: {
        bg: backgroundColor,
      },
      _hover: {
        bg: backgroundColor,
      },
      _disabled: {
        bg: backgroundColor,
      },
      sx: {
        ".chakra-button__icon": {
          marginInlineStart: 0,
        },
      },
    },
    menuList: {
      minW: "78px",
      p: 0,
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      overflow: "hidden",
      flexDirection: "column" as const,
    },
    menuItemWrapper: ({ selected }: { selected: boolean }) => {
      const style: HTMLChakraProps<"button"> = {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        p: 0,
      };

      if (selected) {
        style.backgroundColor = "grey-verylight-green";
      } else {
        style._active = {
          backgroundColor: "grey-pale",
        };

        style._focus = {
          backgroundColor: "grey-pale",
        };

        style._hover = {
          backgroundColor: "grey-pale",
        };
      }

      return style;
    },
    menuItem: {
      textStyle: textVariant === "default" ? "subheadline" : "body1",
      color: "grey-dark",
      display: "flex",
      justifyContent: textVariant === "default" ? "center" : "flex-start",
      p: textVariant === "default" ? "0" : "12px 20px",
      alignItems: "center",
      h: "44px",
    },
    divider: {
      w: "calc(100% - 20px)",
      m: 0,
      alignSelf: "center",
    },
  };
}
