import React, { lazy } from "react";
import { Redirect } from "react-router";

import RawRoute from "components/Modules/Route";

import Route from "./shared/MainRoute";
import { getIsPodLevelMerchandizingEnabled } from "./shared/utils";

const Home = lazy(() => import("./recommendations/index"));
const NewPod = lazy(() => import("./recommendations/new/pod"));
const RecommendationRulesNew = lazy(
  () => import("./recommendations/rules/new")
);
const RecommendationRulesEdit = lazy(
  () => import("./recommendations/rules/edit/[ruleId]")
);
const EditPod = lazy(() => import("./recommendations/edit/[podId]"));
const Searchandizing = lazy(
  () => import("components/Recommendations/Searchandizing")
);

export default function Recommendations() {
  const isPodLevelMerchandisingEnabled = getIsPodLevelMerchandizingEnabled();

  return (
    <>
      <Route
        exact
        path={[
          "/dashboard/recommendations",
          "/dashboard/recommendations/interact/pod/:podId",
        ]}
        component={Home}
      />

      <Route
        blank
        exact
        path="/dashboard/recommendations/new/pod"
        component={NewPod}
      />

      <Route
        blank
        exact
        path="/dashboard/recommendations/edit/pod/:podId"
        component={EditPod}
      />

      {isPodLevelMerchandisingEnabled ? (
        <RawRoute
          exact
          path="/dashboard/recommendations/searchandizing/:strategyId"
        >
          <Redirect to="/dashboard/recommendations?tab=rules" />
        </RawRoute>
      ) : (
        <Route
          blank
          exact
          path="/dashboard/recommendations/searchandizing/:strategyId"
          component={Searchandizing}
        />
      )}

      <Route
        blank
        exact
        path="/dashboard/recommendations/rules/new"
        component={RecommendationRulesNew}
      />

      <Route
        blank
        exact
        path="/dashboard/recommendations/rules/edit/:ruleId"
        component={RecommendationRulesEdit}
      />
    </>
  );
}
