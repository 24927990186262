const baseStyle = {
  content: {
    display: "flex",
    gap: "8px",
    padding: "16px",
    border: "none",
    borderRadius: "4px",
    wordBreak: "normal",
    overflowWrap: "anywhere",
    whiteSpace: "normal",
  },
  header: {
    apply: "textStyles.buttonLabel",
    color: "grey-dark",
    textAlign: "left",
    p: 0,
  },
  body: {
    apply: "textStyles.body3",
    lineHeight: "16px",
    textAlign: "left",
    color: "grey-dark",
    p: 0,
  },
  footer: {
    textAlign: "left",
    color: "grey-dark",
    p: 0,
  },
};

/**
 * Popover has 3 variants, default, designSystem and educational
 * default - used for the Chakra popovers, and should be deprecated
 * designSystem - used for new design system popovers
 * educational - used for design system educational popovers
 */
const variants = {
  default: {
    content: {
      boxShadow: "none",
      backgroundColor: "white",
      padding: "0px",
    },
  },
  designSystem: {
    content: {
      boxShadow: "feedback",
      backgroundColor: "white",
    },
  },
  educational: {
    header: {
      color: "white",
    },
    content: {
      boxShadow: "none",
      backgroundColor: "grey-dark",
    },
    body: {
      apply: "textStyles.body2",
      color: "white",
      lineHeight: "20px",
    },
  },
};

export default {
  variants,
  baseStyle,
  defaultProps: {
    variant: "default",
  },
};
