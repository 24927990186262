import { createContext, useCallback, useState } from "react";

import { ContextType } from "modules/rootContext/types";

export const defaultRootContextValue: ContextType = {
  userId: 0,
  userFirstName: "",
  userEmail: "",
  currentCompany: { id: 0, name: "", status: null, active: true },
  companies: [{ id: 0, name: "", status: null, active: true }],
  hasSso: false,
  canUpdateCompany: false,
  isIaResponsivePageLayoutActive: false,
  setIsIaResponsePageLayoutActive: () => {},
};

type UseRootContext = Omit<ContextType, "setIsIaResponsePageLayoutActive">;

export default createContext<ContextType>(defaultRootContextValue);

export const useRootContext = (args: UseRootContext): ContextType => {
  const [rootContext, setRootContext] = useState(args);

  const setIsIaResponsePageLayoutActive = useCallback(
    (isIaResponsivePageLayoutActive: boolean) => {
      setRootContext((context) => ({
        ...context,
        isIaResponsivePageLayoutActive,
      }));
    },
    []
  );

  return { ...rootContext, setIsIaResponsePageLayoutActive };
};
