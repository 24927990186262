import {
  Role,
  RoleData,
  NextPageCursor,
  NextPageCursorData,
} from "modules/authentication/actions/shared/types";
import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

export type GetUserRolesByCompanyParams = {
  userId: string;
  companyId: string;
  nextPageCursor?: string;
};

/**
 * @description Get roles assigned to user for a specific company
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-assigned-company-roles-to-user
 * @returns {GetUserRolesByCompanyResponse}
 */
export default async function getUserRolesByCompany({
  userId,
  companyId,
  nextPageCursor = "",
}: GetUserRolesByCompanyParams): Promise<GetUserRolesByCompanyResponse> {
  const queryParams = nextPageCursor
    ? { next_page_cursor: nextPageCursor }
    : {};

  const result = await AuthApi.get<GetUserRolesByCompanyResponseData>(
    ["v1", "companies", companyId, "users", userId, "roles"],
    queryParams
  );

  return GetUserRolesByCompanyResponse.fromGetUserRolesByCompanyResponseData(
    result
  );
}

getUserRolesByCompany.key =
  "modules/authentication/actions/users/getUserRolesByCompany";

export type GetUserRolesByCompanyResponseData = {
  roles: RoleData[];
} & NextPageCursorData;

export type GetUserRolesByCompanyResponse = {
  roles: Role[];
} & NextPageCursor;

export const GetUserRolesByCompanyResponse = {
  fromGetUserRolesByCompanyResponseData: (
    data: GetUserRolesByCompanyResponseData
  ): GetUserRolesByCompanyResponse => {
    const getUserRolesByCompanyResponse: GetUserRolesByCompanyResponse = {
      roles: data.roles.map((role) => {
        return {
          createdAt: role.created_at
            ? convertFromApiFormat(role.created_at)
            : null,
          defaultAdminRole: role.default_admin_role,
          defaultBasicRole: role.default_basic_role,
          description: role.description,
          displayName: role.display_name,
          id: role.id,
          modifiedAt: role.modified_at
            ? convertFromApiFormat(role.modified_at)
            : null,
          modifiedBy: role.modified_by,
          permissions: role.permissions,
        };
      }),
      nextPageCursor: data.next_page_cursor,
    };

    return getUserRolesByCompanyResponse;
  },
};
