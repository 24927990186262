import { ResultData } from "types/search";

import { ItemVariation, ItemVariationData } from "./variations/types";
import * as variations from "./variations/types";

export type Item = {
  id: string;
  name: string;
  metadata: Metadata | object;
  suggestedScore?: number;
  deactivated?: boolean;
  facets?: Record<string, string[] | [number]>;
  groupIds?: string[];
  imageUrl?: string;
  url?: string;
  description?: string;
  keywords?: string[];
  variations?: ItemVariation[];
};

export type ItemData = {
  id: string;
  name: string;
  metadata: object;
  suggested_score?: number;
  deactivated?: boolean;
  facets?: Record<string, string[] | [number]>;
  group_ids?: string[];
  image_url?: string;
  url?: string;
  description?: string;
  keywords?: string[];
  variations: ItemVariationData[];
};

export const ItemData = {
  toItem(data: ItemData): Item {
    return {
      id: data.id,
      name: data.name,
      deactivated: data.deactivated,
      description: data.description,
      facets: data.facets,
      groupIds: data.group_ids,
      imageUrl: data.image_url,
      metadata: data.metadata,
      suggestedScore: data.suggested_score,
      url: data.url,
      keywords: data.keywords,
      variations: data.variations?.map(
        variations.ItemVariationData.toItemVariation
      ),
    };
  },
  fromItem(data: Item): ItemData {
    return {
      id: data.id,
      name: data.name,
      deactivated: data.deactivated,
      description: data.description,
      facets: data.facets,
      group_ids: data.groupIds,
      image_url: data.imageUrl,
      metadata: data.metadata,
      suggested_score: data.suggestedScore,
      url: data.url,
      keywords: data.keywords,
      variations: data.variations
        ? data.variations.map(variations.ItemVariationData.fromItemVariation)
        : [],
    };
  },
};

export type ListResponse = {
  items: Item[];
  totalCount: number;
};

export type ListResponseData = {
  items: ItemData[];
  total_count: number;
};

type Metadata = {
  brandName?: string;
  moreColors?: string;
  listPrice?: string;
};

export * from "./variations/types";

export function searchResultDataToItem(data: ResultData): Item {
  return {
    id: data.data.id,
    name: data.value,
    metadata: data.data,
    imageUrl: data.data?.image_url,
    suggestedScore: 0,
    variations: [],
  };
}

export function idToItem(id: string): Item {
  return {
    id: id,
    name: id,
    metadata: {},
    suggestedScore: 0,
    variations: [],
  };
}

// Maps each item's ID to its corresponding Item object
export type ItemDictionaryList = ItemDictionary[];

export type ItemDictionary = {
  [itemId: string]: Item;
};
