import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Icon from "components/Modules/Icon";

const NavbarButton = styled.button`
  background: none;
  border: none;
  padding: 0;
`;

const NavbarWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

function Navbar({
  showNextButton,
  showPreviousButton,
  onNextClick,
  onPreviousClick,
}) {
  return (
    <NavbarWrapper>
      {showPreviousButton && (
        <NavbarButton
          onClick={() => onPreviousClick()}
          aria-label="Previous Month"
        >
          <Icon icon="left" />
        </NavbarButton>
      )}
      {showNextButton && (
        <NavbarButton onClick={() => onNextClick()} aria-label="Next Month">
          <Icon icon="right" />
        </NavbarButton>
      )}
    </NavbarWrapper>
  );
}

Navbar.propTypes = {
  showNextButton: PropTypes.bool,
  showPreviousButton: PropTypes.bool,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
};

Navbar.defaultProps = {
  showNextButton: false,
  showPreviousButton: false,
  onNextClick: () => {},
  onPreviousClick: () => {},
};

export default Navbar;
