import React, { createContext } from "react";

import { TokenValidateResponse } from "modules/authentication/actions/tokens/validate";
import useTokenValidate from "app/queries/authentication/useTokenValidate";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";
import RequestBox from "components/Modules/RequestBox";

export type AuthTokenContextType = {
  token?: TokenValidateResponse | null;
};

export const AuthTokenContext = createContext<AuthTokenContextType>({});

export default function AuthTokenContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const isAuthServiceEnabled = isFlagEnabled(
    FeatureFlags.UseAuthenticationService
  );

  const {
    data: token,
    isFetching,
    error,
  } = useTokenValidate([], {
    enabled: isAuthServiceEnabled,
  });

  return isAuthServiceEnabled ? (
    <RequestBox error={error} fetching={isFetching} overlay>
      {token ? (
        <AuthTokenContext.Provider value={{ token }}>
          {children}
        </AuthTokenContext.Provider>
      ) : null}
    </RequestBox>
  ) : (
    <>{children}</>
  );
}
