import React from "react";

import * as UI from "./shared/UI";
import * as utils from "./shared/utils";

const Action = React.forwardRef(function ButtonComponent(
  { children, ...props },
  ref
) {
  // Suppress loading attribute error; `Action` does not accept `loading`
  // property and it's passed wrongly by default props. Remove this line once
  // copying of `defaultProps` is fixed.
  delete props.loading;

  return (
    <UI.ButtonElement {...props} ref={ref}>
      {children}
    </UI.ButtonElement>
  );
});

Action.propTypes = utils.propTypes;
// TODO: We're mutating utils.defaultProps in the following two lines. There are
// some places where we depend on this mutation and otherwise buttons will look
// weird (e.g. the "Delete" button for a collection expression). Leaving as it
// is for now, but we'll want to change it when we have time.
// Once we do that, let's remove the `loading` prop from `Action`'s default
// props - otherwise it will get passed to UI.ButtonElement which is a plain
// `button`, and it's invalid to pass a boolean value to that attribute.
Action.defaultProps = utils.defaultProps;
Action.defaultProps.kind = "text";
Action.displayName = "Action";

export default Action;
