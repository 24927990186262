import { History } from "history";

import { isHistoryApiSupported } from "utils/routes";

export default function redirect(history: History, url: string) {
  const currentUrl = history.location.pathname + history.location.search;

  if (
    isHistoryApiSupported(history.location.pathname, { currentUrl }) &&
    isHistoryApiSupported(url, { currentUrl })
  ) {
    history.push(url);
    return;
  }

  window.location.href = url;
}
