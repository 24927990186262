import React from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  VStack,
} from "@chakra-ui/react";

import * as amplitude from "utils/amplitude/events";
import { PageGroup } from "app/components/shared/shared/types";
import { getSearch } from "app/components/shared/shared/utils";
import usePageLayout from "app/components/shared/usePageLayout";

import MenuItem from "./MenuGroup/MenuItem";
import SubItem from "./shared/SubItem";
export { default as MenuItem } from "./MenuGroup/MenuItem";

export default function MenuGroup({
  isSidebarExpanded,
  pageGroup,
  onExpand,
  isExpanded,
}: Props) {
  const { currentPathData, currentPath } = usePageLayout();

  return (
    <AccordionItem>
      <VStack spacing="4px" alignItems="flex-start">
        <MenuItem
          icon={pageGroup.icon}
          label={pageGroup.label}
          isSidebarExpanded={isSidebarExpanded}
          onClick={onExpand}
          isExpanded={isExpanded}
          isActive={pageGroup.index === currentPathData.index}
          as={AccordionButton}
        />
        <AccordionPanel p={0}>
          <VStack spacing="4px">
            {pageGroup.items.map(
              ({
                label: title,
                href,
                isExternal: external,
                activePaths,
                isEnabled,
                isHistorySupported,
                isSubscribed,
                hasVisited,
                analyticsLabel,
              }) => {
                if (!isEnabled) {
                  return null;
                }

                const onSubItemClick = () => {
                  amplitude.sendAmplitudeEvent({
                    eventName:
                      amplitude.AmplitudeEvents.IaSidebarMainNavigation,
                    eventProperties: {
                      label: `${pageGroup.analyticsLabel}:${analyticsLabel}`,
                    },
                  });
                };

                return (
                  <SubItem
                    label={title}
                    href={!external ? href + getSearch([]) : href}
                    isExternal={external}
                    key={title}
                    isActive={activePaths.some((path) =>
                      currentPath.startsWith(path)
                    )}
                    isHistorySupported={isHistorySupported}
                    hasVisited={hasVisited}
                    isSubscribed={isSubscribed}
                    onClick={onSubItemClick}
                  />
                );
              }
            )}
          </VStack>
        </AccordionPanel>
      </VStack>
    </AccordionItem>
  );
}

type Props = {
  isSidebarExpanded: boolean;
  pageGroup: PageGroup;
  onExpand: () => void;
  isExpanded: boolean;
};
