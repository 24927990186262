export default {
  baseStyle: {
    backgroundColor: "white",
    borderColor: "grey-light-green",
    borderWidth: "2px",
    borderRadius: "full",
    borderStyle: "solid",
    py: "4px",
    paddingLeft: "14px",
    paddingRight: "10px",
    color: "grey-dark",
    apply: "textStyles.body2",
    cursor: "pointer",
    _hover: {
      backgroundColor: "grey-light-green",
    },
    _active: {
      backgroundColor: "grey-light-green",
    },
    _expanded: {
      backgroundColor: "grey-light-green",
    },
    _disabled: {
      borderColor: "grey-disabled",
      color: "grey-dark-disabled",
      cursor: "default",
      // This color is intentionally custom for now, confirmed with the design
      // team here https://www.figma.com/file/HEaAvkLHrzbjogthAkiInb?node-id=9200:94410&mode=design#561540506
      backgroundColor: "#EAEBED",
    },
  },
};
