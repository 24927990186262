import React, { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import { Button, ColorProps } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import { CHAKRA_ICONS_16_TYPE } from "app/designSystem/components/ChakraIcon/iconTypes";

export type Props = {
  size?: "lg" | "md" | "sm";
  leftIcon?: CHAKRA_ICONS_16_TYPE;
  rightIcon?: CHAKRA_ICONS_16_TYPE;
  to?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  href?: string;
  disabled?: boolean;
  isExternal?: boolean;
  type?: "button" | "submit" | "reset";
  ariaLabel?: string;
  ariaLabelledBy?: string;
  dataTestId?: string;
  tabIndex?: number;
  id?: string;
  name?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  color?: ColorProps["color"];
};

const LinkButton = ({
  children,
  color,
  size = "md",
  disabled = false,
  leftIcon,
  rightIcon,
  to,
  onClick,
  href,
  isExternal,
  id,
  name,
  type = "button",
  ariaLabel,
  ariaLabelledBy,
  dataTestId,
  tabIndex,
  buttonRef,
}: PropsWithChildren<Props>) => {
  return (
    <ButtonElement
      as={to ? RouterLink : "a"}
      href={href}
      target={isExternal ? "_blank" : undefined}
      onClick={(event) => {
        if (disabled) {
          event.stopPropagation();
          event.preventDefault();
        } else if (onClick) {
          onClick(event);
        }
      }}
      color={color}
      to={to}
      variant="link"
      ref={buttonRef}
      size={size}
      disabled={disabled}
      leftIcon={leftIcon && <ChakraIcon icon={leftIcon} />}
      rightIcon={
        rightIcon && !leftIcon ? <ChakraIcon icon={rightIcon} /> : undefined
      }
      data-testid={dataTestId}
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      tabIndex={tabIndex}
      type={type}
      name={name}
      id={id}
    >
      <b>{children}</b>
    </ButtonElement>
  );
};

export const ButtonElement = styled(Button)`
  height: "auto";
  min-height: "auto";
  font-size: ${({ size }) => {
    if (size === "sm") {
      return "13px";
    }
    if (size === "lg") {
      return "15px";
    }
    return "14px";
  }};
  span {
    ${({ leftIcon }) => leftIcon && "margin-right: 4px"}
    ${({ rightIcon }) => rightIcon && "margin-left: 4px"}
  }
  b {
    font-weight: 400 !important;
  }
  &:active b {
    background-color: var(--cio-colors-grey-verylight-green);
  }
  &:focus b {
    background-color: var(--cio-colors-grey-verylight-green);
  }
`;

export default LinkButton;
