import Api from "services/Api";

import { Index, IndexData } from "./types";

export async function get(indexKey: string): Promise<Index> {
  const index = await Api.get<Index>([
    "api",
    "autocomplete_companies",
    indexKey,
  ]);
  return index;
}

export async function list(): Promise<Index[]> {
  const indexes = await Api.get<IndexData[]>(["api", "autocomplete_companies"]);

  return indexes.map((index) => Index.fromData(index));
}

export async function getIndexesByKey(): Promise<{
  [key: string]: string;
}> {
  const indexesList = await list();
  return indexesList.reduce(
    (prev, index) => ({ ...prev, [index.indexKey]: index.name }),
    {}
  );
}

export const getIndexesByKeyCacheKey = "modules/indexes/getIndexesByKey";

export const listKey = "modules/indexes/list";

export async function update(
  indexKey: string,
  index: Partial<Index>
): Promise<void> {
  return Api.patch(
    ["api", "autocomplete_companies", indexKey],
    {},
    toData(index)
  );
}

function toData(
  data: Partial<Pick<Index, "name" | "itemImageBaseUrl">>
): Partial<Pick<IndexData, "name" | "item_image_base_url">> {
  return {
    name: data.name,
    item_image_base_url: data.itemImageBaseUrl,
  };
}
