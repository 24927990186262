import {
  AccordionProps,
  BoxProps,
  GridProps,
  TextProps,
} from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Variant } from "./Accordion.types";

export const accordionStyle: Partial<AccordionProps> = {
  w: "full",
};

export const accordionHeaderStyle: Partial<BoxProps> = {
  flex: 1,
  textAlign: "left",
  alignItems: "center",
};

export const accordionHeaderTitleStyle: Partial<TextProps> = {
  color: "grey-dark",
  textStyle: "caption",
  textTransform: "uppercase",
  fontWeight: "bold",
  letterSpacing: "1px",
  m: 0,
};

export const accordionHeaderSubtitleStyle: Partial<TextProps> = {
  color: "grey-dark",
  textStyle: "body3",
  m: 0,
};

export const accordionSeparatorStyle = (size: "sm" | "md") => {
  return {
    borderBottom: "1px solid",
    borderColor: "grey-light-green",
    mx: size === "md" ? "24px" : "16px",
  };
};

export const accordionPanelStyle = (size: "sm" | "md", variant: Variant) => {
  const defaultStyle = {
    p: size === "md" ? "24px" : "16px",
    bgColor: "white",
  };

  const floatingVariantStyle = {
    backgroundColor: "transparent",
    padding: 0,
  };

  switch (variant) {
    case "default":
      return defaultStyle;
    case "floating":
      return { ...defaultStyle, ...floatingVariantStyle };
  }
};

export const accordionButtonStyle = (size: "sm" | "md", variant: Variant) => {
  const defaultStyle = {
    p: size === "md" ? "24px" : "16px",
    border: "2px solid",
    borderColor: "transparent",
    bgColor: "white",
  };

  const floatingVariantStyle = {
    cursor: "pointer",
    padding: "12px",
    backgroundColor: "transparent",
  };

  switch (variant) {
    case "default":
      return defaultStyle;
    case "floating":
      return { ...defaultStyle, ...floatingVariantStyle };
  }
};

export const accordionGridStyle: Partial<GridProps> = {
  w: "full",
  gap: 1.5,
};

export const accordionButtonFocusWithin = {
  default: {
    boxShadow: "none",
    borderColor: "blue-light",
  },
  floating: {
    borderColor: "transparent",
  },
};

export const accordionButtonHover = {
  default: {},
  floating: {
    backgroundColor: "transparent",
  },
};

export const accordionItem = {
  default: {},
  floating: {
    padding: 0,
    borderRadius: "8px",
    backgroundColor: "white",
    boxShadow: "var(--light-shadow)",
    transition: "box-shadow 0.3s ease-in-out",
  },
};

export const accordionItemHover = {
  default: {},
  floating: {
    boxShadow: "var(--heavy-shadow)",
  },
};
