import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

export type GetUserParams = {
  userId: string;
};

/**
 * @description Get data related to user.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Users/operation/v1-auth-users-get-user
 * @returns {GetUserResponse}
 */
export default async function getUser({
  userId,
}: GetUserParams): Promise<GetUserResponse> {
  const result = await AuthApi.get<GetUserResponseData>([
    "v1",
    "users",
    userId,
  ]);

  return GetUserResponse.fromGetUserResponseData(result);
}

getUser.key = "modules/authentication/actions/users/get";

export type GetUserResponseData = {
  created_at: string;
  email: string;
  first_name: string;
  id: string;
  last_logins_at_in_company: {
    company_id: string;
    last_login_at: string;
  }[];
  last_name: string;
  sign_in_count: number;
};

export type GetUserResponse = {
  createdAt: Date | null;
  email: string;
  firstName: string;
  id: string;
  lastLoginsAtInCompany: {
    companyId: string;
    lastLoginAt: Date | null;
  }[];
  lastName: string;
  signInCount: number;
};

export const GetUserResponse = {
  fromGetUserResponseData: (data: GetUserResponseData): GetUserResponse => {
    const getUserResponse: GetUserResponse = {
      createdAt: data.created_at ? convertFromApiFormat(data.created_at) : null,
      email: data.email,
      firstName: data.first_name,
      id: data.id,
      lastLoginsAtInCompany: data.last_logins_at_in_company.map((item) => ({
        companyId: item.company_id,
        lastLoginAt: item.last_login_at
          ? convertFromApiFormat(item.last_login_at)
          : null,
      })),
      lastName: data.last_name,
      signInCount: data.sign_in_count,
    };

    return getUserResponse;
  },
};
