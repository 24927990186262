import React, { useState } from "react";
import { Flex } from "@chakra-ui/react";

import useDates from "app/hooks/useDates";
import usePrevious from "app/hooks/usePrevious";

import { Filter, Main } from "components/Modules/Page";
import DateRangeFilter from "components/Modules/DateRangeFilter";
import IndexFilter from "components/Modules/IndexFilter";

import AnalyticsMenu from "app/components/Navigation/AnalyticsMenu";
import IntegrationMenu from "app/components/Navigation/IntegrationMenu";
import AccountsMenu from "app/components/Navigation/AccountsMenu";
import { Menu } from "app/components/Navigation";
import PageLayout from "app/components/PageLayout";
import { Spinner } from "app/designSystem/components/Spinner";
import { ActionGroupProps } from "app/components/shared/actionGroupProps";
import usePageLayout from "app/components/shared/usePageLayout";

import AuthTokenContextProvider from "app/providers/AuthTokenContext";

import { PageLayoutWrapperContext } from "./PageLayoutWrapper/PageLayoutWrapperContext";

type Props = ActionGroupProps & {
  className?: string;
  children: React.ReactNode;
};

export default function PageLayoutWrapper({
  children,
  allowAllIndexes,
  dateRangeFilter,
  disableIndexSelector,
  index,
  isIndexFetching,
  indexBaseUrl,
  persistQueryParams = [],
  showDateRangePicker,
  showIndexSelector,
  className,
  breadcrumbs,
}: Props) {
  const {
    analytics,
    integration,
    workspace,
    currentPathData,
    accounts,
    currentPath: path,
    isRenderingNewLayout,
  } = usePageLayout();

  const [{ startDate, endDate }, setDates] = useDates({
    keepQueryParameters: persistQueryParams,
  });

  const [isLayoutUpdating, setIsLayoutUpdating] = useState(false);

  const previousIndexName = usePrevious(index && index.name);
  let menu = null;

  switch (true) {
    case analytics.index === currentPathData.index:
      menu = <AnalyticsMenu />;
      break;
    case integration.index === currentPathData.index:
      menu = <IntegrationMenu />;
      break;
    case workspace.index === currentPathData.index || path === "/dashboard":
      menu = <Menu />;
      break;
    case accounts.index === currentPathData.index:
      menu = <AccountsMenu />;
      break;
    default:
      menu = null;
  }

  if (isRenderingNewLayout) {
    return (
      <PageLayoutWrapperContext.Provider
        value={{ isLayoutUpdating, setIsLayoutUpdating }}
      >
        <AuthTokenContextProvider>
          <PageLayout
            allowAllIndexes={allowAllIndexes}
            dateRangeFilter={dateRangeFilter}
            disableIndexSelector={disableIndexSelector}
            indexBaseUrl={indexBaseUrl}
            persistQueryParams={persistQueryParams}
            showDateRangePicker={showDateRangePicker}
            showIndexSelector={showIndexSelector}
            breadcrumbs={breadcrumbs}
          >
            {children}
          </PageLayout>
        </AuthTokenContextProvider>
        {isLayoutUpdating && (
          <Flex
            w="100%"
            h="100vh"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="0"
            left="0"
            bg="white"
            zIndex="10"
          >
            <Spinner size="lg" color="success" />
          </Flex>
        )}
      </PageLayoutWrapperContext.Provider>
    );
  }

  return (
    <AuthTokenContextProvider>
      <PageLayoutWrapperContext.Provider
        value={{ isLayoutUpdating, setIsLayoutUpdating }}
      >
        <Main
          menu={menu}
          filter={
            <Filter>
              {showDateRangePicker &&
                (dateRangeFilter ?? (
                  <DateRangeFilter
                    startDate={startDate.format("YYYY-MM-DD")}
                    endDate={endDate.format("YYYY-MM-DD")}
                    onChange={setDates}
                  />
                ))}
              {showIndexSelector && (
                <IndexFilter
                  currentIndexName={
                    isIndexFetching ? previousIndexName : index?.name
                  }
                  allowAll={allowAllIndexes}
                  keepQueryParameters={[
                    "start_date",
                    "end_date",
                    ...persistQueryParams,
                  ]}
                  baseUrl={indexBaseUrl}
                  disabled={disableIndexSelector}
                />
              )}
            </Filter>
          }
          className={className}
        >
          {children}
        </Main>
        {isLayoutUpdating && (
          <Flex
            w="100%"
            h="100vh"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            top="0"
            left="0"
            bg="white"
            zIndex="10"
          >
            <Spinner size="lg" color="success" />
          </Flex>
        )}
      </PageLayoutWrapperContext.Provider>
    </AuthTokenContextProvider>
  );
}
