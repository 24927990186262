// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag_replacement 'application.js' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "url-polyfill";
import "whatwg-fetch";

import "./enhancements/expandableTable";

import "utils/sentry";

import {
  listenAndAlertUserOnCompanyChange,
  notifyOtherTabsOfLoadedCompany,
} from "services/companyChangeNotifier";

document.addEventListener("DOMContentLoaded", () => {
  const currentCompanyIdTag = document.querySelector(
    "meta[name=current_company_id]"
  );

  if (currentCompanyIdTag) {
    const companyId = currentCompanyIdTag.content;
    listenAndAlertUserOnCompanyChange(companyId);
    notifyOtherTabsOfLoadedCompany(companyId);
  }
});

// Support component names relative to this directory:
var componentRequireContext = require.context(
  "../",
  true,
  /(app\/App|app\/rails\/[^/]+?)(?<!\.test\.(j|t)sx?)$/
);

var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
