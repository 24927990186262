/**
 * Internal menu component to be reused in multiple dropdown and select
 * components.
 */

import React from "react";
import {
  Box,
  forwardRef,
  LayoutProps,
  VStack,
  useStyleConfig,
} from "@chakra-ui/react";

/**
 * Container for the menu options.
 */
export const Menu = forwardRef<ListMenuProps, typeof VStack>(
  ({ children, ...props }, ref) => {
    const styles = useStyleConfig("internal.Menu");

    return (
      <VStack ref={ref} __css={styles} alignItems="stretch" {...props}>
        {children}
      </VStack>
    );
  }
);

type ListMenuProps = Pick<LayoutProps, "width" | "w" | "maxWidth" | "maxW">;

/**
 * Individual menu items.
 */
export const MenuItem = forwardRef(
  ({ children, selected = false, ...props }, ref) => {
    const styles = useStyleConfig("internal.MenuItem");

    return (
      <Box
        ref={ref}
        aria-selected={selected}
        as="button"
        __css={styles}
        {...props}
      >
        {/*
          We use an internal box for the padding so that the ::before
          pseudo-element that creates the separation line is correctly
          positioned at the edge of the outer box.
        */}
        <Box
          __css={{
            px: "20px",
            py: "12px",
          }}
          maxWidth="full"
        >
          {children}
        </Box>
      </Box>
    );
  }
);
