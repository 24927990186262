import React from "react";
import styled from "@emotion/styled";

import ErrorBoundary from "components/Modules/ErrorBoundary";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Header, { SubTitle } from "./Header";

/**
 * @deprecated Use the design system `Modal` component instead.
 */
export default function Modal({
  title,
  subTitle,
  description,
  children,
  onClose,
  ...props
}: ModalProps & Omit<React.ComponentProps<typeof ModalElement>, "title">) {
  return (
    <ModalElement {...props}>
      <ErrorBoundary>
        {title ? (
          <Header
            title={title}
            subTitle={
              typeof subTitle === "string" ? (
                <SubTitle>{subTitle}</SubTitle>
              ) : (
                subTitle
              )
            }
            description={description}
            onClose={onClose}
          />
        ) : null}

        {children}
      </ErrorBoundary>
    </ModalElement>
  );
}

type ModalProps = {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
};

export const ModalElement = styled.div`
  border-radius: 6px;
  background-color: white;
  overflow: hidden;
  width: 600px;
  margin: auto;

  & > :last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;
