// Styling for Select
export const selectStyle = (
  hasIcon: boolean,
  hasInfoTextOrEditLink: boolean,
  isShowingOptions: boolean,
  isRounded: boolean,
  hasPlaceholder: boolean
) => {
  return {
    select: {
      bg: "white",
      border: "2px solid",
      borderColor: isShowingOptions ? "green" : "grey-light-blue",
      borderRadius: isRounded ? "120px" : "md",
      focusBorderColor: "green",
      errorBorderColor: "danger",
      h: "44px",
      cursor: "pointer",
      color: "grey",
      sx: {
        paddingLeft: hasIcon ? "40px" : "16px",
        paddingRight: hasInfoTextOrEditLink ? "200px" : "36px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: hasPlaceholder ? "grey" : "grey-dark",
      },
      _hover: {
        borderColor: isShowingOptions ? "green" : "grey",
      },
      _invalid: {
        boxShadow: "none",
        borderColor: "danger",
      },
      _disabled: {
        borderColor: "grey-disabled",
        bgColor: "grey-light-disabled",
        cursor: "not-allowed",
      },
    },
  };
};

export const selectStackStyle = {
  spacing: "8px",
  align: "stretch",
};

// Styling for label component
export const selectLabelStyle = {
  container: {
    alignItems: "center",
  },
  label: {
    textStyle: "body1",
    fontSize: "15px",
    color: "grey-dark",
    fontWeight: "bold",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
    m: 0,
  },
};

export const selectHelperStyle = {
  formHelper: {
    color: "grey",
    textStyle: "body3",
    fontSize: "13px",
    m: 0,
  },
  errorMessage: {
    color: "danger",
    textStyle: "body3",
    fontSize: "13px",
    m: 0,
  },
};

export const selectInfoStyle = (hasLabel?: boolean, hasTooltip?: boolean) => {
  return {
    box: {
      p: "12px 16px",
      alignItems: "center",
      h: "44px",
      w: "100%",
      top: hasLabel || hasTooltip ? "28px" : "0px",
      pos: "absolute",
      display: "flex",
      pointerEvents: "none",
    } as const,
    icon: {
      mr: "8px",
    },
    spacer: {
      w: "100%",
    },
    dropdownIcon: {
      ml: "8px",
    },
    infoText: {
      color: "grey",
      textStyle: "body3-italic",
      m: 0,
      maxWidth: "149px",
      // Italic font causes last letter to overlap with the block's boundaries
      paddingRight: "1px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      flexShrink: 0,
    },
  };
};

export const selectEditTextStyle = (
  hasLabel?: boolean,
  hasTooltip?: boolean
) => {
  return {
    box: {
      top: hasLabel || hasTooltip ? "28px" : "0px",
      right: "40px",
      pos: "absolute",
      cursor: "pointer",
      h: "44px",
      display: "flex",
      alignItems: "center",
    } as const,
    editLink: {
      color: "black",
      textStyle: "body3",
      m: 0,
    },
  };
};
