import React from "react";
import PropTypes from "prop-types";
import { ClassNames } from "@emotion/react";
import DayPicker from "react-day-picker";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";

import theme from "components/theme";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import DateInput from "components/Searchandizing/shared/Edit/DateRangePickerModal/DatetimeSelector/DateInput";

function DatePicker({ ...props }) {
  return (
    <ClassNames>
      {({ css }) => (
        <DayPicker
          classNames={{
            // react-day-picker default classes
            container: "DayPicker",
            wrapper: "DayPicker-wrapper",
            interactionDisabled: "DayPicker--interactionDisabled",
            month: "DayPicker-Month",

            navBar: "DayPicker-NavBar",
            navButtonPrev: "DayPicker-NavButton DayPicker-NavButton--prev",
            navButtonNext: "DayPicker-NavButton DayPicker-NavButton--next",
            navButtonInteractionDisabled:
              "DayPicker-NavButton--interactionDisabled",

            weekdays: "DayPicker-Weekdays",
            weekdaysRow: "DayPicker-WeekdaysRow",
            weekday: "DayPicker-Weekday",
            body: "DayPicker-Body",
            week: "DayPicker-Week",
            weekNumber: "DayPicker-WeekNumber",
            footer: "DayPicker-Footer",
            todayButton: "DayPicker-TodayButton",

            today: "today",
            selected: "selected",

            // Overrides
            months: css`
              label: months;
              display: flex;
              justify-content: space-between;
            `,
            day: css`
              label: day;
              display: table-cell;
              border: 2px solid ${theme.colors.backgroundGrayDark};
              font-size: 12px;
              width: 40px;
              height: 40px;
              text-align: center;
              vertical-align: middle;
              cursor: pointer;
              :hover {
                background-color: var(--primary-green);
                color: var(--background-white);
              }
            `,
            caption: css`
              label: caption;
              display: table-caption;
              text-transform: uppercase;
              text-align: center;
              color: ${theme.colors.textGrayBlue};
              font-size: 12px;
              letter-spacing: 1px;
            `,
            disabled: css`
              label: disabled;
              color: rgba(80, 92, 117, 0.38);
              cursor: default;
            `,
            outside: css`
              label: outside;
              color: rgba(80, 92, 117, 0.38);
              background-color: transparent;
              cursor: default;
            `,
          }}
          showOutsideDays
          {...props}
        />
      )}
    </ClassNames>
  );
}

export default DatePicker;

function DatePickerInput({
  dayPickerProps,
  clearable,
  isError,
  onClear,
  inputId,
  disabled,
  ...props
}) {
  const DateInputWithRef = React.forwardRef((props, ref) => {
    return (
      <DateInput
        {...props}
        ref={ref}
        disabled={disabled}
        onClear={onClear}
        isError={isError}
        clearable={clearable}
        id={inputId}
      />
    );
  });

  DateInputWithRef.displayName = "DateInputWithRef";

  return (
    <ClassNames>
      {({ css }) => (
        <DayPickerInput
          component={DateInputWithRef}
          dayPickerProps={{
            showOutsideDays: true,
            classNames: {
              // react-day-picker default classes
              container: "DayPicker",
              wrapper: "DayPicker-wrapper",
              interactionDisabled: "DayPicker--interactionDisabled",
              month: "DayPicker-Month",

              navBar: "DayPicker-NavBar",
              navButtonPrev: "DayPicker-NavButton DayPicker-NavButton--prev",
              navButtonNext: "DayPicker-NavButton DayPicker-NavButton--next",
              navButtonInteractionDisabled:
                "DayPicker-NavButton--interactionDisabled",

              weekdays: "DayPicker-Weekdays",
              weekdaysRow: "DayPicker-WeekdaysRow",
              weekday: "DayPicker-Weekday",
              body: "DayPicker-Body",
              week: "DayPicker-Week",
              weekNumber: "DayPicker-WeekNumber",
              footer: "DayPicker-Footer",
              todayButton: "DayPicker-TodayButton",

              today: "today",
              selected: "selected",

              // Overrides
              months: css`
                label: months;
                display: flex;
                justify-content: space-between;
              `,
              day: css`
                label: day;
                display: table-cell;
                border: 2px solid ${theme.colors.backgroundGrayDark};
                font-size: 12px;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 12px;
                padding-right: 12px;
                :hover {
                  background-color: var(--primary-green);
                  color: var(--background-white);
                }
              `,
              caption: css`
                label: caption;
                display: table-caption;
                text-transform: uppercase;
                text-align: center;
                color: ${theme.colors.textGrayBlue};
                font-size: 12px;
                letter-spacing: 1px;
              `,
              disabled: css`
                label: disabled;
                color: rgba(80, 92, 117, 0.38);
                cursor: default;
              `,
              outside: css`
                label: outside;
                color: rgba(80, 92, 117, 0.38);
                background-color: transparent;
                cursor: default;
              `,
            },
            ...dayPickerProps,
          }}
          {...props}
        />
      )}
    </ClassNames>
  );
}

DatePickerInput.propTypes = {
  dayPickerProps: PropTypes.any,
  clearable: PropTypes.bool,
  isError: PropTypes.bool,
  onClear: PropTypes.func,
  inputId: PropTypes.string,
  disabled: PropTypes.bool,
};

export { DatePickerInput };
