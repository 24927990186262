export const SpinnerContainer = (size: "sm" | "md" | "lg") => {
  let boxSize;
  switch (size) {
    case "sm":
      boxSize = "16px";
      break;
    case "md":
      boxSize = "20px";
      break;
    case "lg":
      boxSize = "44px";
      break;
  }

  return {
    h: boxSize,
    w: boxSize,
    justifyContent: "center",
    alignItems: "center",
  };
};

export const Spinner = (size: "sm" | "md" | "lg") => {
  let boxSize;
  switch (size) {
    case "sm":
      boxSize = "12.8px";
      break;
    case "md":
      boxSize = "16px";
      break;
    case "lg":
      boxSize = "40px";
      break;
  }

  return {
    h: boxSize,
    w: boxSize,
  };
};
