import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

import {
  UserData,
  User,
  NextPageCursor,
  NextPageCursorData,
} from "../shared/types";

export type GetUsersByRoleParams = {
  companyId: string;
  roleId: string;
  nextPageCursor?: string;
};

/**
 * @description Get all users assigned to a specific role within a company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-assigned-company-users-to-role
 * @returns {GetUsersByRoleResponse}
 */
export default async function getUsersByRole({
  companyId,
  roleId,
  nextPageCursor = "",
}: GetUsersByRoleParams): Promise<GetUsersByRoleResponse> {
  const queryParams = nextPageCursor
    ? { next_page_cursor: nextPageCursor }
    : {};

  const result = await AuthApi.get<GetUsersByRoleResponseData>(
    ["v1", "companies", companyId, "roles", roleId, "users"],
    queryParams
  );

  return GetUsersByRoleResponse.fromGetUsersByRoleResponseData(result);
}

getUsersByRole.key = "modules/authentication/actions/users/getUsersByRole";

export type GetUsersByRoleResponseData = {
  users: Omit<UserData, "last_login_at">[];
} & NextPageCursorData;

export type GetUsersByRoleResponse = {
  users: Omit<User, "lastLoginAt">[];
} & NextPageCursor;

export const GetUsersByRoleResponse = {
  fromGetUsersByRoleResponseData: (
    data: GetUsersByRoleResponseData
  ): GetUsersByRoleResponse => {
    const getUsersByRoleResponse: GetUsersByRoleResponse = {
      users: data.users.map((user) => ({
        createdAt: user.created_at
          ? convertFromApiFormat(user.created_at)
          : null,
        email: user.email,
        firstName: user.first_name,
        id: user.id,
        lastName: user.last_name,
        signInCount: user.sign_in_count,
      })),
      nextPageCursor: data.next_page_cursor,
    };
    return getUsersByRoleResponse;
  },
};
