import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";

const quizzes = require("./quizzes.png");
export const Quizzes = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={quizzes}
      htmlWidth={40}
      htmlHeight={40}
      alt="Quizzes"
      {...props}
    />
  );
};

const recommendations = require("./recommendations.png");
export const Recommendations = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendations}
      htmlWidth={40}
      htmlHeight={40}
      alt="Recommendations"
      {...props}
    />
  );
};

const collections = require("./collections.png");
export const Collections = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={collections}
      htmlWidth={40}
      htmlHeight={40}
      alt="Collections"
      {...props}
    />
  );
};

const recommendationAnimation = require("./recommendation-animation.gif");
export const RecommendationAnimation = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendationAnimation}
      htmlWidth={600}
      htmlHeight={600}
      alt="Product detail page showing frequently bought together item recommendations."
      {...props}
    />
  );
};

const collectionsAnimation = require("./collections-animation.gif");
export const CollectionsAnimation = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={collectionsAnimation}
      htmlWidth={600}
      htmlHeight={600}
      alt="Material and brand conditions to create a spring collection."
      {...props}
    />
  );
};

const quizzesAnimation = require("./quizzes-animation.gif");
export const QuizzesAnimation = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={quizzesAnimation}
      htmlWidth={503}
      htmlHeight={576}
      alt="Workout questionnaire with recommended products."
      {...props}
    />
  );
};

const collections01 = require("./Collections 01.png");
export const Collections01 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={collections01}
      htmlWidth={100}
      htmlHeight={100}
      alt="Collections 01"
      {...props}
    />
  );
};

const collections02 = require("./Collections 02.png");
export const Collections02 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={collections02}
      htmlWidth={100}
      htmlHeight={100}
      alt="Collections 02"
      {...props}
    />
  );
};

const collections03 = require("./Collections 03.png");
export const Collections03 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={collections03}
      htmlWidth={100}
      htmlHeight={100}
      alt="Collections 03"
      {...props}
    />
  );
};

const recommendations01 = require("./Recommendations 01.png");
export const Recommendations01 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendations01}
      htmlWidth={100}
      htmlHeight={100}
      alt="Recommendations 01"
      {...props}
    />
  );
};

const recommendations02 = require("./Recommendations 02.png");
export const Recommendations02 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendations02}
      htmlWidth={100}
      htmlHeight={100}
      alt="Recommendations 02"
      {...props}
    />
  );
};

const recommendations03 = require("./Recommendations 03.png");
export const Recommendations03 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendations03}
      htmlWidth={100}
      htmlHeight={100}
      alt="Recommendations 03"
      {...props}
    />
  );
};

const quizzes01 = require("./Quizzes 01.png");
export const Quizzes01 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={quizzes01}
      htmlWidth={100}
      htmlHeight={100}
      alt="Quizzes 01"
      {...props}
    />
  );
};

const quizzes02 = require("./Quizzes 02.png");
export const Quizzes02 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={quizzes02}
      htmlWidth={100}
      htmlHeight={100}
      alt="Quizzes 02"
      {...props}
    />
  );
};

const quizzes03 = require("./Quizzes 03.png");
export const Quizzes03 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={quizzes03}
      htmlWidth={100}
      htmlHeight={100}
      alt="Quizzes 03"
      {...props}
    />
  );
};
