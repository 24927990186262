import React, { ReactNode } from "react";
import { IntlProvider } from "react-intl";

import { i18nConfig } from "i18n";

export default function InternationalizeProvider({ children }: Props) {
  return (
    <IntlProvider
      locale="en"
      defaultLocale={i18nConfig.defaultLocale}
      messages={i18nConfig.messages.get("en")}
    >
      {children}
    </IntlProvider>
  );
}

type Props = {
  children: ReactNode;
};
