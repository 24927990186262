import { ApiClass } from "./Api";

export const authServiceUrl =
  document.querySelector<HTMLMetaElement>('meta[name="auth_service_url"]')
    ?.content ?? "";

const ApiInstance = new ApiClass({ baseUrl: authServiceUrl, getFetchOptions });

export { ApiInstance as default };

type FetchOptions = {
  signal?: AbortSignal | null;
};

function getFetchOptions(
  options: FetchOptions = {}
): Pick<RequestInit, "credentials" | "headers" | "signal"> {
  const headers: Record<string, string> = {};
  const { signal } = options;

  // Add Constructor token
  const cnstrcToken = document.querySelector<HTMLMetaElement>(
    '[name="cnstrc_token"]'
  );

  if (cnstrcToken) {
    headers["Authorization"] = `Bearer ${cnstrcToken.content}`;
  }

  return {
    credentials: "same-origin",
    headers,
    signal,
  };
}
