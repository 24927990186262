import React, { PropsWithChildren, useState } from "react";
import { Alert as ChakraAlert, AlertDescription } from "@chakra-ui/react";

import classNames from "classnames";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./Alert.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { AlertType } from "./Alert.types";

export const Alert = ({
  type = "success",
  noClose = false,
  onClose,
  className,
  children,
  style,
  icon,
}: PropsWithChildren<AlertType>) => {
  const [display, setDisplay] = useState<boolean>(true);

  if (!display) {
    return null;
  }

  return (
    <ChakraAlert
      {...styles.Alert(type)}
      className={classNames(className, "sentry-unmask")}
      variant="left-accent"
      style={style}
    >
      {icon ? (
        icon
      ) : (
        <ChakraIcon
          icon={styles.alertVariant[type].icon}
          {...styles.AlertIcon(type)}
        />
      )}

      {children && (
        <AlertDescription {...styles.AlertText}>{children}</AlertDescription>
      )}

      {!noClose && (
        <ChakraIcon
          aria-label="Close"
          icon="close-16"
          {...styles.AlertCloseIcon}
          onClick={() => {
            if (onClose) {
              onClose();
            }
            setDisplay(false);
          }}
        />
      )}
    </ChakraAlert>
  );
};
