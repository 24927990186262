import styled from "@emotion/styled";

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  font-family: var(--font-header);
  font-size: 20px;
  color: var(--neutral-grey);
`;

export { Title };

// Styleguide name: DisplayH1
/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const H1 = styled.h1`
  margin: 0;
  font-size: 28px;
  font-weight: 500;
  font-family: var(--font-header);
  color: var(--black);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
const H2 = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-header);
  color: var(--neutral-black);
`;

export { H2 }; // Styleguide name: DisplayH2Primary

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
const H3 = styled.h3`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-header);
  color: var(--neutral-black);

  /* reset old ".dashboard" styles */
  .dashboard & {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
    font-family: var(--font-header);
    color: var(--neutral-black);
  }
`;

export { H3 }; // Styleguide name: DisplayH3

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
const SectionName = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font-text);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--neutral-lightgrey);
  text-transform: uppercase;
  margin-bottom: 8px;
`;

export { SectionName };

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
const TextBody2CopyPrimary = styled.div`
  font-size: 14px;
  color: var(--neutral-black);
`;

export { TextBody2CopyPrimary };

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextCaptionDisabled = styled.div`
  font-size: 12px;
  color: var(--neutral-lightgrey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextBody2CopySecondary = styled.div`
  font-size: 14px;
  color: var(--neutral-grey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextBody2CopyPlaceholder = styled.div`
  font-size: 14px;
  color: var(--neutral-lightgrey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextSubheadlinesSecondary = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--neutral-grey);
  text-transform: uppercase;
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const Comment = styled.span`
  font-size: 14px;
  color: var(--neutral-lightgrey);
  text-transform: none;
  font-weight: normal;
  font-style: italic;
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const Subheader = styled.h4`
  font-size: 14px;
  color: var(--neutral-grey);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const SubheadlinesPrimary = styled.span`
  font-family: var(--font-text);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--neutral-black);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const SubheadlinesSecondary = styled.span`
  font-family: var(--font-text);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--neutral-grey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const Body2Copyplaceholder = styled.div`
  font-family: var(--font-text);
  font-size: 14px;
  color: var(--neutral-lightgrey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const Body2CopySecondary = styled.span`
  font-family: var(--font-text);
  font-size: 14px;
  line-height: 14px;
  color: var(--neutral-grey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const Body2CopyDisabled = styled.span`
  font-family: var(--font-text);
  font-size: 14px;
  color: var(--neutral-disabledgrey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextBody2DatesDisabled = styled.div`
  font-size: 14px;
  font-style: italic;
  color: var(--neutral-lightgrey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const ButtonsLightEnabled = styled.div`
  font-family: var(--font-text);
  font-size: 12px;
  font-weight: bold;
  color: var(--neutral-grey);
  letter-spacing: 1px;
  text-transform: uppercase;
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const A = styled.a`
  color: var(--neutral-black);
  text-decoration: none;

  :hover {
    color: var(--neutral-black);
    text-decoration: underline;
  }
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const P = styled.p`
  & {
    margin: 0;
  }

  & + & {
    margin-top: 1em;
  }
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextStyle2 = styled.div`
  font-size: 15px;
  line-height: 1.33;
  color: var(--neutral-grey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextLabelsPlaceholder = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--neutral-lightgrey);
  text-transform: uppercase;
`;

// Alias
export const LabelsPlaceholder = TextLabelsPlaceholder;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextLabelsSecondary = styled.div`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: var(--neutral-grey);
  text-transform: uppercase;
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const DisplayNumbersSmSecondary = styled.div`
  font-family: var(--font-header);
  font-size: 16px;
  color: var(--neutral-grey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextBody1DescriptionPrimary = styled.div`
  font-size: 15px;
  color: var(--neutral-black);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const DisplayNumbersMedium = styled.div`
  font-family: var(--font-header);
  font-size: 30px;
  font-weight: 300;
  color: var(--black);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const TextSectionsLinksSecondary = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: var(--neutral-grey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const Body1Secondary = styled.div`
  font-family: var(--font-text);
  font-size: 15px;
  line-height: 23px;
  color: var(--neutral-lightgrey);
`;

/**
 * @deprecated Use Chakra's `textStyle` prop with the `textStyles` defined in
 * the theme.
 */
export const Danger = styled.div`
  font-family: var(--font-text);
  font-size: 14px;
  color: var(--accent-danger);
`;
