if (typeof $ !== 'undefined') {
  $(() => {
    const tables = $('[data-expandable-table]');

    tables.each((i, elem) => {
      const $elem = $(elem);
      const numberOfVisibleRows = $elem.data('expandable-table') || 10;
      const colspan = $elem.find('tbody tr:first td').length;
      const $dynamicRows = $elem.find(`tbody tr:nth-child(n+${numberOfVisibleRows + 1})`);

      if ($dynamicRows.length === 0) {
        return;
      }

      var $toggler = $(`
        <tr class="expandable-table__control">
          <td colspan="${colspan}">
            <i class="fa fa-caret-down"></i> <span>more</span>
          </td>
        </tr>`
      );

      $dynamicRows.addClass('hidden');
      $elem.find('tbody').append($toggler);

      $toggler.on('click', () => {
        $dynamicRows.toggleClass('hidden');
        $toggler.find('i').toggleClass('fa-caret-down fa-caret-up');
        $toggler.find('span').text((i, oldText) => oldText === 'more' ? 'less' : 'more');
      });
    });
  });
}
