import { LocationDescriptor } from "history";
import React, { lazy, useEffect } from "react";
import { Redirect } from "react-router";

import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import Route from "./shared/MainRoute";

const Index = lazy(() => import("components/Index"));
const Start = lazy(() => import("./start/index"));
const Upload = lazy(() => import("./upload/index"));
const Interact = lazy(() => import("components/Interact/Interact"));
const Indexes = lazy(() => import("app/pages/indexes"));
const Items = lazy(() => import("app/pages/indexes/items"));
const RedirectRules = lazy(() => import("components/RedirectRules/List"));
const OneWaySynonymsNew = lazy(() => import("app/pages/synonyms/one"));
const TwoWaySynonymsNew = lazy(() => import("app/pages/synonyms/two"));
const OneWaySynonyms = lazy(() => import("components/OneWaySynonyms/Page"));
const RetailMediaCampaigns = lazy(
  () => import("app/pages/retail_media/campaigns")
);
const RetailMediaCreateCampaign = lazy(
  () => import("app/pages/retail_media/campaigns/create")
);
const RetailMediaEditCampaign = lazy(
  () => import("app/pages/retail_media/campaigns/edit")
);
const AccountProfile = lazy(() => import("app/pages/accounts/profile"));
const AccountUserManagement = lazy(
  () => import("app/pages/accounts/user_management")
);
const SynonymGroups = lazy(() => import("components/SynonymGroups/Page"));
const Collections = lazy(() => import("app/pages/collections"));
const CollectionsEdit = lazy(() => import("app/pages/collections/edit"));
const Facets = lazy(() => import("app/pages/facets"));
const Searchabilities = lazy(() => import("components/Searchabilities"));

const AttributeEnrichment = lazy(
  () => import("app/pages/attribute_enrichment")
);

const RefinedFiltersCollectionsList = lazy(
  () => import("components/FacetSearchandizing/RefinedFilters/CollectionsList")
);

const RefinedFiltersCategoriesList = lazy(
  () => import("components/FacetSearchandizing/RefinedFilters/CategoriesList")
);

const RefinedFiltersEditCollection = lazy(
  () => import("components/FacetSearchandizing/RefinedFilters/EditCollection")
);
const RefinedFiltersEditCategory = lazy(
  () => import("components/FacetSearchandizing/RefinedFilters/EditCategory")
);

export default function Main() {
  return (
    <>
      <Route exact path="/dashboard" component={Index} />
      <Route blank exact path="/dashboard/start" component={Start} />
      <Route blank exact path="/dashboard/upload" component={Upload} />
      <Route blank exact path="/dashboard/interact" component={Interact} />
      <Route exact path="/dashboard/indexes" component={Indexes} />
      <Route exact path="/dashboard/indexes/items" component={Items} />

      <FacetRoutes />

      <Route
        blank
        exact
        path="/dashboard/redirect-rules"
        component={RedirectRules}
      />

      <SynonymRoutes />

      <RetailMediaRoutes />

      <AccountProfileRoutes />

      <CollectionRoutes />
      <Route
        blank
        path="/dashboard/searchabilities"
        component={Searchabilities}
      />

      <Route
        exact
        path="/dashboard/attribute_enrichment"
        component={AttributeEnrichment}
      />
    </>
  );
}

function FacetRoutes() {
  return (
    <>
      <Route
        exact
        path="/dashboard/modify-facets/browse"
        component={RefinedFiltersCategoriesList}
      />

      <Route
        exact
        path="/dashboard/modify-facets/collections"
        component={RefinedFiltersCollectionsList}
      />

      <Route
        blank
        path="/dashboard/modify-facets/browse/group_id/:groupId"
        component={RefinedFiltersEditCategory}
      />

      <Route
        blank
        path="/dashboard/modify-facets/browse/collection_id/:collectionId"
        component={RefinedFiltersEditCollection}
      />

      <Route blank path="/dashboard/facets" component={Facets} />
    </>
  );
}

function CollectionRoutes() {
  return (
    <>
      <Route exact path="/dashboard/collections" component={Collections} />

      <Route
        blank
        exact
        path="/dashboard/collections/new"
        component={CollectionsEdit}
      />

      <Route
        blank
        exact
        path="/dashboard/collections/edit/:id"
        component={CollectionsEdit}
      />
    </>
  );
}

/**
 * To be used for redirects to rails rendered pages
 * Runs location.reload after mounting the client redirect
 */
const RedirectWithReload = <S,>({ to }: { to: LocationDescriptor<S> }) => {
  useEffect(() => {
    globalThis.location.reload();
  }, []);

  return <Redirect to={to} push />;
};

function RetailMediaRoutes() {
  return (
    <>
      <Route
        exact
        path="/dashboard/retail-media/campaigns"
        component={RetailMediaCampaigns}
      />
      <Route
        exact
        path="/dashboard/retail-media/campaigns/create"
        component={RetailMediaCreateCampaign}
      />
      <Route
        exact
        path="/dashboard/retail-media/campaigns/edit/:campaignId"
        component={RetailMediaEditCampaign}
      />
    </>
  );
}

function AccountProfileRoutes() {
  return (
    <>
      <Route
        exact
        path="/dashboard/accounts_v2/profile"
        component={AccountProfile}
      />
      <Route
        path="/dashboard/accounts_v2/user_management"
        component={AccountUserManagement}
      />
    </>
  );
}

function SynonymRoutes() {
  const isReactSynonymsPageEnabled = isFlagEnabled(
    FeatureFlags.ReactSynonymsPage
  );

  if (isReactSynonymsPageEnabled) {
    return (
      <>
        <Route path="/dashboard/synonyms/one-way">
          <Redirect to="/dashboard/synonyms/one" />
        </Route>

        <Route path="/dashboard/synonyms/two-way">
          <Redirect to="/dashboard/synonyms/two" />
        </Route>

        <Route
          exact
          path="/dashboard/synonyms/one"
          component={OneWaySynonymsNew}
        />

        <Route
          exact
          path="/dashboard/synonyms/two"
          component={TwoWaySynonymsNew}
        />
      </>
    );
  }

  return (
    <>
      <Route path="/dashboard/synonyms/one">
        <RedirectWithReload to="/dashboard/synonyms/one-way" />
      </Route>

      <Route path="/dashboard/synonyms/two">
        <RedirectWithReload to="/dashboard/synonyms/two-way" />
      </Route>

      <Route
        blank
        exact
        path="/dashboard/synonyms/one-way"
        component={OneWaySynonyms}
      />

      <Route
        blank
        exact
        path="/dashboard/synonyms/two-way"
        component={SynonymGroups}
      />
    </>
  );
}
