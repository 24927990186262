import React, { lazy } from "react";

import Route from "./shared/MainRoute";

const RefinedQueriesList = lazy(
  () => import("components/Searchandizing/RefinedQueries/List")
);

const RefinedQueriesEdit = lazy(
  () => import("components/Searchandizing/RefinedQueries/Edit")
);

const RefinedFiltersList = lazy(
  () => import("components/Searchandizing/RefinedFilters/List")
);

const RefinedFiltersEdit = lazy(
  () => import("components/Searchandizing/RefinedFilters/Edit")
);

const RefinedCollectionsEdit = lazy(
  () => import("components/Searchandizing/RefinedCollections/Edit")
);

const CampaignsNew = lazy(() => import("./searchandizing/campaigns/new"));
const Campaigns = lazy(() => import("./searchandizing/campaigns"));

const CampaignsEdit = lazy(
  () => import("./searchandizing/campaigns/edit/[campaignId]")
);

const BrowseSearchandizing = lazy(
  () => import("./searchandizing/pages/[filterName]/[filterValue]")
);

const QuerySearchandizing = lazy(
  () => import("./searchandizing/queries/[query]")
);

export default function SearchandizingRoutes() {
  return (
    <>
      <Route
        exact
        path="/dashboard/searchandizing/dynamic"
        component={RefinedQueriesList}
      />

      <Route
        blank
        exact
        path="/dashboard/searchandizing/dynamic/:query"
        component={RefinedQueriesEdit}
      />

      <Route
        exact
        path="/dashboard/searchandizing/global/dynamic"
        component={RefinedQueriesEdit}
      />

      <Route
        exact
        path="/dashboard/searchandizing/browse"
        component={RefinedFiltersList}
      />

      <Route
        blank
        exact
        path="/dashboard/searchandizing/browse/:filterName/:filterValue"
        component={RefinedFiltersEdit}
      />

      <Route
        exact
        path="/dashboard/searchandizing/global/browse"
        component={RefinedFiltersEdit}
      />

      <Route
        blank
        exact
        path="/dashboard/searchandizing/collections/:collectionId"
        component={RefinedCollectionsEdit}
      />

      <Route
        exact
        path="/dashboard/searchandizing/campaigns"
        component={Campaigns}
      />

      <Route
        blank
        exact
        path="/dashboard/searchandizing/campaigns/new"
        component={CampaignsNew}
      />

      <Route
        blank
        exact
        path="/dashboard/searchandizing/campaigns/edit/:campaignId"
        component={CampaignsEdit}
      />

      <Route
        blank
        exact
        path="/dashboard/searchandizing/pages/:filterName/:filterValue"
        component={BrowseSearchandizing}
      />

      <Route
        blank
        exact
        path="/dashboard/searchandizing/queries/:query"
        component={QuerySearchandizing}
      />
    </>
  );
}
