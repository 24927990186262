import React from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
// @refactoring Forbid usage of `ramda` (https://constructor.slab.com/posts/deprecating-ramda-and-adopting-remeda-wlks8rn7)
// eslint-disable-next-line local-rules/no-ramda
import { omit } from "ramda";

import URI from "utils/urijs";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import { Links as LinksComponent, Link } from "./shared/MenuUI";

export default function IntegrationMenu() {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isFtpPasswordPageEnabled = isFlagEnabled(FeatureFlags.FtpPassword);

  return (
    <LinksComponent>
      <Link
        to={buildUrl(params, Links.CatalogUploads)}
        isActive={
          location.pathname.startsWith(Links.CatalogUploads) ||
          location.pathname.startsWith(Links.Ingestions)
        }
      >
        Catalog Uploads
      </Link>

      <Link
        to={buildUrl(params, Links.Performance)}
        isActive={location.pathname.startsWith(Links.Performance)}
      >
        Performance
      </Link>

      <Link
        to={buildUrl(params, Links.ApiToken)}
        isActive={location.pathname.startsWith(Links.ApiToken)}
      >
        API Integration
      </Link>

      {isFtpPasswordPageEnabled && (
        <Link
          to={buildUrl(params, Links.FtpCredentials)}
          isActive={location.pathname.startsWith(Links.FtpCredentials)}
        >
          FTP Credentials
        </Link>
      )}
    </LinksComponent>
  );
}

enum Links {
  // TODO: replace old `ingestions` with `CatalogUploads`
  CatalogUploads = "/dashboard/integration/catalog_uploads",
  Ingestions = "/dashboard/integration/ingestions",
  Performance = "/dashboard/integration/performance",
  ApiToken = "/dashboard/integration/api_tokens",
  FtpCredentials = "/dashboard/integration/ftp_credentials",
}

/**
 * Builds a link URL.
 *
 * It also validates query string params according to the target URL.
 */
function buildUrl(params: qs.ParsedQs, link: Links): string {
  let validParams = {};

  if (link === Links.Ingestions) {
    validParams = omit(["index_key"], params);
  }

  if (link === Links.Performance) {
    validParams = omit(["autocomplete_company_id"], params);
  }

  return URI(link).search(validParams).toString();
}
