import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import "focus-visible/dist/focus-visible";
import { enableMapSet } from "immer";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/react";

import * as IndexKeyId from "utils/IndexKeyId";
import * as IndexKeyValue from "utils/IndexKeyValue";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import { Cache as CurrentIndexCache } from "app/hooks/useCurrentIndex";
import useHighchartsReflow from "app/hooks/useHighchartsReflow";

import GlobalStyle from "components/Modules/GlobalStyle";
import NewVersionNotification from "components/NewVersionNotification";
import DelightedSurvey from "components/DelightedSurvey";
import MaintainanceBannerWrapper from "components/shared/MaintainanceBannerWrapper";
import SessionWatcher from "components/SessionWatcher";

import ReactQueryProvider from "app/providers/ReactQuery";

import MainRoutes from "app/pages/main";
import SearchandizingRoutes from "app/pages/searchandizing";
import RecommendationRoutes from "app/pages/recommendations";
import QuizzesRoutes from "app/pages/quizzes";
import AnalyticsRoutes from "app/pages/analytics";
import IntegrationRoutes from "app/pages/integration";

import "utils/logrocket";
import "utils/amplitude";

import ThemeProvider from "./providers/ThemeProvider";
import ToastProvider from "./providers/ToastProvider";
import RootContext, { useRootContext } from "./providers/RootContext";
import InternationalizeProvider from "./providers/InternationalizeProvider";
import RouterProvider from "./providers/RouterProvider";
import QueryParamsProvider from "./providers/QueryParamsProvider";
import LoadingStateConfigProvider, {
  DEFAULT_SPINNER_DELAY,
} from "./providers/LoadingStateConfigProvider";

function Routes() {
  useHighchartsReflow();

  return (
    <>
      <MainRoutes />
      <SearchandizingRoutes />
      <RecommendationRoutes />
      <QuizzesRoutes />
      <AnalyticsRoutes />
      <IntegrationRoutes />
    </>
  );
}

// @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function Configuration(props: any) {
  useEffect(() => {
    const saveIndexKey = () =>
      (isFlagEnabled(FeatureFlags.EnableAcidDeprecate)
        ? IndexKeyValue
        : IndexKeyId
      ).dumpToCookies();
    window.addEventListener("focus", saveIndexKey);
    return () => window.removeEventListener("focus", saveIndexKey);
  });

  const { children, ...rootContextProps } = props;
  const rootContext = useRootContext(rootContextProps);

  return (
    <RootContext.Provider value={rootContext}>
      <ThemeProvider>
        <ReactQueryProvider>
          <ReactQueryDevtools />
          <CurrentIndexCache>
            <InternationalizeProvider>
              <RouterProvider>
                <LoadingStateConfigProvider
                  minimumSpinnerDelay={DEFAULT_SPINNER_DELAY}
                >
                  <QueryParamsProvider>{children}</QueryParamsProvider>
                </LoadingStateConfigProvider>
              </RouterProvider>
            </InternationalizeProvider>
          </CurrentIndexCache>
        </ReactQueryProvider>
      </ThemeProvider>
    </RootContext.Provider>
  );
}

// @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function App(props: any) {
  enableMapSet();

  return (
    <Configuration {...props}>
      <ToastProvider>
        <DelightedSurvey />
        <GlobalStyle />
        <NewVersionNotification />
        <MaintainanceBannerWrapper
          startTime={new Date(Date.UTC(2024, 3, 23, 9, 0))}
          endTime={new Date(Date.UTC(2024, 3, 23, 10, 0))}
        />
        <SessionWatcher>
          <Switch>
            <Routes />
          </Switch>
        </SessionWatcher>
      </ToastProvider>
    </Configuration>
  );
}

export default Sentry.withErrorBoundary(App, {});
