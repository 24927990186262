import React from "react";
import { motion } from "framer-motion";
import { Box, useTheme } from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  width?: number;
  height?: number;
} & React.ComponentProps<typeof Box>;

const Hamburger = ({ isOpen, width = 24, height = 24, ...boxProps }: Props) => {
  const theme = useTheme();
  const variant = isOpen ? "opened" : "closed";
  const unitHeight = 4;
  const unitWidth = (unitHeight * width) / height;

  const top = {
    closed: { d: "M 2 6.5 L 20 6.5" },
    opened: { d: "M 3 20.5 L 17 6.5" },
  };
  const bottom = {
    closed: { d: "M 2 20.5 L 20 20.5" },
    opened: { d: "M 3 6.5 L 17 20.5" },
  };
  const center = {
    closed: { x2: unitWidth, opacity: 1 },
    opened: { x2: 0, opacity: 0 },
  };
  const pathProps: React.ComponentProps<typeof motion.path> = {
    stroke: theme.colors["grey-dark"],
    strokeWidth: 2,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    strokeLinecap: "round",
  };

  return (
    <Box {...boxProps}>
      <motion.svg
        viewBox={`0 0 ${width} ${height}`}
        overflow="visible"
        width={width}
        height={height}
      >
        <motion.path variants={top} {...pathProps} />
        <motion.path variants={center} d="M 2 13.5 L 20 13.5" {...pathProps} />
        <motion.path variants={bottom} {...pathProps} />
      </motion.svg>
    </Box>
  );
};

export default Hamburger;
