import React from "react";

import moment from "modules/moment";

export default function Date({ date }: Props) {
  return (
    <span title={moment(date).format("MMM Do, YYYY hh:mm A \\G\\M\\TZ")}>
      {moment(date).format("MMM DD, YYYY hh:mm A")}
    </span>
  );
}

type Props = {
  date: Date;
};
