import React from "react";

function AuthToken() {
  return (
    <input
      type="hidden"
      name="authenticity_token"
      value={
        document.querySelector<HTMLMetaElement>("meta[name=csrf-token]")
          ?.content
      }
    />
  );
}

export default AuthToken;
