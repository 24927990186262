import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";
import {
  CommonPageGroupArgs,
  PageGroup,
} from "app/components/shared/shared/types";
import { getIsHistorySupported } from "app/components/shared/shared/utils";
import { Return as VisitedStateReturn } from "app/components/SubscriptionGuard/useVisitedTabs";
import useFeatureToggle from "app/hooks/useFeatureToggle";

export default function ({
  path,
  visitedTabs,
}: CommonPageGroupArgs & Pick<VisitedStateReturn, "visitedTabs">): PageGroup {
  const isReactSynonymsPageEnabled = useFeatureToggle(
    FeatureFlags.ReactSynonymsPage
  );

  const isRetailMediaPageEnabled = useFeatureToggle(
    FeatureFlags.RetailMediaPage
  );

  return {
    items: [
      {
        label: "Searchandizing",
        href: "/dashboard/searchandizing/dynamic",
        activePaths: [
          "/dashboard/searchandizing/dynamic",
          "/dashboard/searchandizing/browse",
          "/dashboard/searchandizing/campaigns",
        ],
        isEnabled: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/searchandizing/dynamic",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Workspace" }, { label: "Searchandizing" }],
        analyticsLabel: "Searchandising",
      },
      {
        label: "Collections",
        href: "/dashboard/collections",
        activePaths: ["/dashboard/collections"],
        isEnabled:
          isFlagEnabled(FeatureFlags.Collections) ||
          isFlagEnabled(FeatureFlags.EnableNavbarUpsell),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/collections",
          path
        ),
        hasVisited: !!visitedTabs.collections,
        isSubscribed: isFlagEnabled(FeatureFlags.Collections),
        breadcrumbs: [{ label: "Workspace" }, { label: "Collections" }],
        analyticsLabel: "Collections",
      },
      {
        label: "Recommendations",
        href: "/dashboard/recommendations",
        activePaths: ["/dashboard/recommendations"],
        isEnabled:
          isFlagEnabled(FeatureFlags.Recommendations) ||
          isFlagEnabled(FeatureFlags.EnableNavbarUpsell),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/recommendations",
          path
        ),
        hasVisited: !!visitedTabs.recommendations,
        isSubscribed: isFlagEnabled(FeatureFlags.Recommendations),
        breadcrumbs: [{ label: "Workspace" }, { label: "Recommendations" }],
        analyticsLabel: "Recommendations",
      },
      {
        label: "Facets",
        href: "/dashboard/modify-facets/browse",
        activePaths: ["/dashboard/modify-facets/"],
        isEnabled: isFlagEnabled(FeatureFlags.FacetsSearchandizing),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/modify-facets/browse",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Workspace" }, { label: "Facets" }],
        analyticsLabel: "Facets",
      },
      {
        label: "Indexes",
        href: "/dashboard/indexes",
        activePaths: ["/dashboard/indexes"],
        isEnabled: true,
        isHistorySupported: getIsHistorySupported("/dashboard/indexes", path),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Workspace" }, { label: "Indexes" }],
        analyticsLabel: "Indexes",
      },
      {
        label: "Synonyms",
        href: isReactSynonymsPageEnabled
          ? "/dashboard/synonyms/one"
          : "/dashboard/synonyms/one-way",
        activePaths: [
          "/dashboard/synonyms/one-way",
          "/dashboard/synonyms/two-way",
          "/dashboard/synonyms/one",
          "/dashboard/synonyms/two",
        ],
        isEnabled: true,
        isHistorySupported: getIsHistorySupported(
          isReactSynonymsPageEnabled
            ? "/dashboard/synonyms/one"
            : "/dashboard/synonyms/one-way",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Workspace" }, { label: "Synonyms" }],
        analyticsLabel: "Synonyms",
      },
      {
        label: "Retail Media",
        href: "/dashboard/retail-media/campaigns",
        activePaths: ["/dashboard/retail-media/campaigns"],
        isEnabled: isRetailMediaPageEnabled,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/retail-media/campaigns",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Workspace" }, { label: "Retail Media" }],
        analyticsLabel: "Retail Media",
      },
      {
        label: "Redirects",
        href: "/dashboard/redirect-rules",
        activePaths: ["/dashboard/redirect-rules"],
        isEnabled: true,
        isHistorySupported: getIsHistorySupported(
          "/dashboard/redirect-rules",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [{ label: "Workspace" }, { label: "Redirects" }],
        analyticsLabel: "Redirects",
      },
      {
        label: "Attribute Enrichment",
        href: "/dashboard/attribute_enrichment",
        activePaths: ["/dashboard/attribute_enrichment"],
        isEnabled: isFlagEnabled(FeatureFlags.AttributeEnrichment),
        isHistorySupported: getIsHistorySupported(
          "/dashboard/attribute_enrichment",
          path
        ),
        hasVisited: true,
        isSubscribed: true,
        breadcrumbs: [
          { label: "Workspace" },
          { label: "Attribute Enrichment" },
        ],
        analyticsLabel: "Attribute_Enrichment",
      },
      {
        label: "Quizzes",
        href: "/dashboard/quizzes",
        activePaths: ["/dashboard/quizzes"],
        isEnabled:
          isFlagEnabled(FeatureFlags.Quizzes) ||
          isFlagEnabled(FeatureFlags.EnableNavbarUpsell),
        isHistorySupported: getIsHistorySupported("/dashboard/quizzes", path),
        hasVisited: !!visitedTabs.quizzes,
        isSubscribed: isFlagEnabled(FeatureFlags.Quizzes),
        breadcrumbs: [{ label: "Workspace" }, { label: "Quizzes" }],
        analyticsLabel: "Quizzes",
      },
    ].sort((a, b) =>
      b.isSubscribed === a.isSubscribed ? 0 : b.isSubscribed ? 1 : -1
    ),
    index: 1,
    label: "Workspace",
    icon: "grid-hollow-16",
    analyticsLabel: "Workspace",
  };
}
