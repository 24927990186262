const baseStyle = {
  p: "4px 8px",
  display: "flex",
  boxShadow: "feedback",
  borderRadius: "4px",
  bgColor: "grey-dark",
  color: "white",
  apply: "textStyles.caption",
  fontSize: "12px",
  fontWeight: "regular",
  wordBreak: "normal",
  overflowWrap: "anywhere",
  whiteSpace: "normal",
};

export default {
  baseStyle,
  variants: {
    white: {
      p: "16px",
      bgColor: "white",
      boxShadow: "feedback",
      borderRadius: "4px",
      color: "grey-dark",
    },
  },
};
