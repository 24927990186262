import React from "react";
import {
  Popover as ChakraPopover,
  PopoverTrigger as ChakraPopoverTrigger,
  PopoverContent as ChakraPopoverContent,
  PopoverArrow as ChakraPopoverArrow,
  PopoverHeader as ChakraPopoverHeader,
  PopoverBody as ChakraPopoverBody,
  PopoverFooter as ChakraPopoverFooter,
  Portal,
} from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Button } from "../Button";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { PopoverProps } from "../Popover";

export type PopoverEducationalProps = Omit<
  PopoverProps,
  "popoverLinkHref" | "popoverLinkText" | "popoverLinkDownload"
> & {
  /**
   * If `true`, `popover` will have a dismiss button at the footer.
   */
  popoverButtonText?: string;

  /**
   * Callback when button in `popover` footer is clicked.
   */
  onPopoverButtonClick?(): void;

  /**
   * Whether content in `popover` is rendered in portal
   */
  renderInPortal?: boolean;
};

export const PopoverEducational = ({
  children,
  trigger = "hover",
  placement = "top-start",
  openDelay = 300,
  closeDelay = 300,
  defaultIsOpen = false,
  closeOnBlur = true,
  closeOnEsc = true,
  popoverBody,
  popoverHeader,
  popoverButtonText,
  onPopoverButtonClick,
  width = "186px",
  renderInPortal = false,
  ...rest
}: React.PropsWithChildren<PopoverEducationalProps>) => {
  const ContentWrapper = renderInPortal ? Portal : React.Fragment;

  return (
    <ChakraPopover
      trigger={trigger}
      placement={placement}
      openDelay={openDelay}
      defaultIsOpen={defaultIsOpen}
      closeOnBlur={closeOnBlur}
      closeOnEsc={closeOnEsc}
      closeDelay={closeDelay}
      variant="educational"
      {...rest}
    >
      <ChakraPopoverTrigger>{children}</ChakraPopoverTrigger>
      <ContentWrapper>
        <ChakraPopoverContent width={width}>
          <ChakraPopoverArrow bg="grey-dark" />
          {popoverHeader && (
            <ChakraPopoverHeader>{popoverHeader}</ChakraPopoverHeader>
          )}
          <ChakraPopoverBody>{popoverBody}</ChakraPopoverBody>
          {popoverButtonText && (
            <ChakraPopoverFooter>
              <Button variant="secondary" onClick={onPopoverButtonClick}>
                {popoverButtonText}
              </Button>
            </ChakraPopoverFooter>
          )}
        </ChakraPopoverContent>
      </ContentWrapper>
    </ChakraPopover>
  );
};
