import React, { forwardRef, PropsWithChildren } from "react";
import { Box, BoxProps } from "@chakra-ui/react";

const Container = forwardRef<HTMLDivElement, Props>(
  function ForwardedRefContainer({ children, maxHeight = "none" }, ref) {
    return (
      <Box
        as="div"
        ref={ref}
        maxHeight={maxHeight}
        background="white"
        borderRadius="4px"
        zIndex={1}
        overflow="auto"
        overflowX="hidden"
        boxShadow="0px 4px 16px 0px rgba(0, 36, 73, 0.1),
  0px 4px 8px 0px rgba(0, 0, 0, 0.1)"
        _focus={{
          outline: "none",
        }}
      >
        {children}
      </Box>
    );
  }
);

type Props = PropsWithChildren<{
  maxHeight?: BoxProps["maxHeight"];
}>;

export default Container;
