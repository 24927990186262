import styled from "@emotion/styled";

import theme from "components/theme";

const Textarea = styled.textarea`
  background-color: white;
  border-radius: 6px;
  border: 2px solid ${theme.colors.borderGrayLight};
  box-shadow: none;
  color: ${theme.colors.inputColor};
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  outline: none;
  padding: 6px 12px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;

  :focus {
    border: 2px solid ${theme.colors.success};
  }
`;

export default Textarea;
