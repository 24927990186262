import { FlexProps, FormControlProps, FormLabelProps } from "@chakra-ui/react";

export const getFormControlStyle = (resize: string): FormControlProps => ({
  textAlign: "left",
  width: resize !== "none" ? "fit-content" : "full",
});

export const labelContainer: FlexProps = {
  alignItems: "center",
};

export const formLabel: FormLabelProps = {
  textStyle: "body1",
  color: "grey-dark",
  fontWeight: "bold",
  mb: "0",
  mr: "0",
};

export const textAreaStyle = {
  border: "2px solid",
  focusBorderColor: "green",
  errorBorderColor: "danger",
  borderColor: "grey-light-blue",
  bgColor: "white",
  color: "grey-dark",
  h: "44px",
  paddingY: "14px",
  paddingLeft: "16px",
  paddingRight: "16px",
  flexGrow: 1,
  _hover: {
    boxShadow: "none",
    borderColor: "grey",
  },
  _focus: {
    boxShadow: "none",
    borderColor: "green",
  },
  _invalid: {
    boxShadow: "none",
    borderColor: "danger",
  },
  _disabled: {
    borderColor: "grey-disabled",
    bgColor: "grey-light-disabled",
    cursor: "not-allowed",
  },
  _placeholder: {
    color: "grey",
  },
};

export const helperText = {
  container: {
    display: "flex",
    w: "full",
    justifyContent: "space-between",
  },
  error: {
    textStyle: "body3",
    fontSize: "13px",
    minHeight: "16px",
    lineHeight: "16px",
    color: "danger",
    m: "0",
  },
  helper: {
    textStyle: "body3",
    fontSize: "13px",
    minHeight: "16px",
    lineHeight: "16px",
    color: "grey",
    m: "0",
  },
};

export const maxLength = (isInvalid?: boolean) => {
  return {
    textStyle: "body3",
    fontSize: "13px",
    minHeight: "16px",
    lineHeight: "16px",
    color: isInvalid ? "danger" : "grey",
    m: "0",
  };
};
