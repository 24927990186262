import * as actions from "modules/authentication/actions";

import getQueryHook from "app/hooks/getQueryHook";

const useGetCompaniesByUser = getQueryHook(
  actions.getCompaniesByUser.key,
  () => actions.getCompaniesByUser,
  {
    refetchOnMount: true,
  }
);

export default useGetCompaniesByUser;
