import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { AlertFailure } from "app/designSystem/components";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Container from "./Container";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Loader from "./Loader";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Spinner, { WithSpinner } from "./Spinner";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Box from "./Box";

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || "space-around"};
  min-height: ${({ minHeight }) => (minHeight || 0) + "px"};
  ${({ height }) => height !== undefined && `height: ${height}`};
`;

export { FlexContainer };

const BorderContainer = FlexContainer.withComponent(Container);
export { BorderContainer };

const BoxContainer = FlexContainer.withComponent(Box);
export { BoxContainer };

function SpinnerLoader({
  alertProps,
  children,
  component,
  container,
  containerProps,
  props,
  overlay,
  ...loaderProps
}) {
  return (
    <Loader {...loaderProps}>
      {({ error, loading, fetching, data, ...childProps }) => {
        if (error) {
          return <AlertFailure error={error} {...alertProps} />;
        }

        let content = null;

        if (data) {
          const Component = component;
          if (Component) {
            content = <Component data={data} {...childProps} {...props} />;
          } else {
            content = children(data, { ...childProps, ...props });
          }
        }

        const inProgress = loading || fetching || !data;

        if (overlay && data) {
          return (
            <WithSpinner displaySpinner={inProgress}>{content}</WithSpinner>
          );
        }

        if (inProgress) {
          if (!container) {
            return null;
          }

          const RenderedContainer = container;
          return (
            <RenderedContainer {...containerProps}>
              <Spinner />
            </RenderedContainer>
          );
        }

        return content;
      }}
    </Loader>
  );
}

SpinnerLoader.defaultProps = {
  containerProps: {
    minHeight: 400,
  },
};

SpinnerLoader.propTypes = {
  alertProps: PropTypes.object,
  children: PropTypes.func,
  component: PropTypes.func,
  container: PropTypes.any,
  containerProps: PropTypes.object,
  props: PropTypes.object,
  minHeight: PropTypes.number,
  overlay: PropTypes.bool,
};

SpinnerLoader.defaultProps = {
  container: BorderContainer,
  loader: "fetch",
};

export default SpinnerLoader;

function SpinnerFlexLoader(props) {
  return <SpinnerLoader {...props} container={FlexContainer} />;
}

SpinnerFlexLoader.defaultProps = {
  containerProps: {
    minHeight: 400,
  },
};

export { SpinnerFlexLoader };
