import React from "react";

import * as Modal from "components/Modules/Modal";

import Body from "./Form/Body";

export default function Form({
  onCancel,
  ...props
}: React.ComponentProps<typeof Body>) {
  return (
    <Modal.ModalElement style={{ overflow: "visible" }}>
      <Modal.Header title="Select Index" onClose={onCancel} />
      <Body onCancel={onCancel} {...props} />
    </Modal.ModalElement>
  );
}

export { Body };
