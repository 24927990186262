import { HStack } from "@chakra-ui/react";
import React from "react";

import { ActionGroupProps } from "app/components/shared/actionGroupProps";

import ActionGroup from "./shared/ActionGroup";
import Hamburger from "./Header/Hamburger";
import BrandLogo from "./Header/BrandLogo";
import Breadcrumbs from "./Header/Breadcrumbs";

type Props = {
  onToggle: () => void;
  isOpen: boolean;
  isSidebarOpen: boolean;
  currentPath: string;
} & ActionGroupProps;

export default function Header({
  breadcrumbs = [],
  onToggle,
  isOpen,
  isSidebarOpen,
  currentPath,
  ...actionGroupProps
}: Props) {
  return (
    <HStack
      paddingInline="24px 32px"
      bg="white"
      alignItems="stretch"
      borderBottom="1px solid"
      borderColor="overlay-white"
      boxShadow="modal"
      spacing={0}
      gridArea="header"
      transition="gap 300ms ease-in-out"
      h="60px"
      gap={{
        md: isSidebarOpen ? "144px" : "48px",
        lg: isSidebarOpen ? "152px" : "max(calc((100vw - 1200px) / 2), 56px)",
        xl: isSidebarOpen
          ? "max(calc((100vw - 1172px) / 2), 152px)"
          : "calc((100vw - 1200px) / 2)",
      }}
    >
      <HStack
        spacing={0}
        gap={{ sm: "8px", md: "0" }}
        transition="gap 300ms ease-in-out"
      >
        <Hamburger
          w={{ sm: "24px", md: "0" }}
          transition="width 300ms ease-in-out"
          overflow="hidden"
          cursor="pointer"
          isOpen={isOpen}
          onClick={onToggle}
          aria-label="Main menu"
        />
        <BrandLogo isSidebarOpen={isSidebarOpen} />
      </HStack>
      <HStack flexGrow={1}>
        <HStack
          gap={0}
          spacing={0}
          flexGrow={1}
          color="grey-dark"
          display={{ sm: "none", md: "flex" }}
        >
          {breadcrumbs.map(({ label, href }, index) => (
            <Breadcrumbs
              key={label}
              label={label}
              href={href}
              index={index}
              currentPath={currentPath}
            />
          ))}
        </HStack>
        <HStack
          gap="8px"
          alignItems="stretch"
          display={{ sm: "none", md: "flex" }}
          height="100%"
        >
          <ActionGroup {...actionGroupProps} />
        </HStack>
      </HStack>
    </HStack>
  );
}
