/* cSpell:disable */

import moment from "moment/moment";
import "moment-timezone/moment-timezone";

moment.tz.load({
  version: "2018g",
  zones: [
    "Africa/Abidjan||g.8 0",
    "Africa/Accra||.Q 0 -k",
    "Africa/Nairobi||-2r.g -30 -2u -2J",
    "Africa/Algiers||-c.c -9.l 0 -10 -10 -20",
    "Africa/Lagos||-d.A -10",
    "Africa/Bissau||12.k 10 0",
    "Africa/Maputo||-2a.k -20",
    "Africa/Cairo||-25.9 -20 -30",
    "Africa/Casablanca||u.k 0 -10",
    "Africa/Ceuta||l.g 0 -10 -10 -20",
    "Africa/El_Aaiun||Q.M 10 0 -10",
    "Africa/Johannesburg||-1Q -1u -20 -30",
    "Africa/Juba||-26.s -20 -30 -30",
    "Africa/Khartoum||-2a.8 -20 -30 -30",
    "Africa/Monrovia||H.8 H.8 I.u 0",
    "Africa/Ndjamena||-10.c -10 -20",
    "Africa/Sao_Tome||-q.U A.J 0 -10",
    "Africa/Tripoli||-Q.I -10 -20 -20",
    "Africa/Tunis||-E.I -9.l -10 -20",
    "Africa/Windhoek||-18.o -1u -20 -30 -20 -10",
    "America/Adak||-cd.m bK.C b0 a0 a0 b0 a0 a0 a0 90",
    "America/Anchorage||-e0.o 9X.A a0 90 90 a0 90 90 90 80",
    "America/Port_of_Spain||46.4 40",
    "America/Araguaina||3c.M 30 20",
    "America/Argentina/Buenos_Aires||3R.M 4g.M 40 30 20",
    "America/Argentina/Catamarca||4n.8 4g.M 40 30 20",
    "America/Argentina/Cordoba||4g.M 4g.M 40 30 20",
    "America/Argentina/Jujuy||4l.c 4g.M 40 30 20",
    "America/Argentina/La_Rioja||4r.o 4g.M 40 30 20",
    "America/Argentina/Mendoza||4z.g 4g.M 40 30 20",
    "America/Argentina/Rio_Gallegos||4A.Q 4g.M 40 30 20",
    "America/Argentina/Salta||4l.E 4g.M 40 30 20",
    "America/Argentina/San_Juan||4y.4 4g.M 40 30 20",
    "America/Argentina/San_Luis||4p.o 4g.M 40 30 20",
    "America/Argentina/Tucuman||4k.Q 4g.M 40 30 20",
    "America/Argentina/Ushuaia||4x.c 4g.M 40 30 20",
    "America/Curacao||4z.L 4u 40",
    "America/Asuncion||3O.E 3O.E 40 30",
    "America/Atikokan||66.s 60 50 50 50 50",
    "America/Bahia_Banderas||71 70 60 80 60 50",
    "America/Bahia||2y.4 30 20",
    "America/Barbados||3W.t 3W.t 40 30",
    "America/Belem||3d.U 30 20",
    "America/Belize||5Q.M 60 5u 50",
    "America/Blanc-Sablon||3M.s 40 30 30 30",
    "America/Boa_Vista||42.E 40 30",
    "America/Bogota||4U.g 4U.g 50 40",
    "America/Boise||7I.N 80 70 70 60 60 60",
    "America/Cambridge_Bay||0 70 60 60 50 60 60 50 50",
    "America/Campo_Grande||3C.s 40 30",
    "America/Cancun||5L.4 60 50 40 50",
    "America/Caracas||4r.I 4r.E 4u 40",
    "America/Cayenne||3t.k 40 30",
    "America/Panama||5i.8 5j.A 50",
    "America/Chicago||5O.A 60 50 50 50 50",
    "America/Chihuahua||74.k 70 60 50 60",
    "America/Costa_Rica||5A.d 5A.d 60 50",
    "America/Creston||7K.4 70 80",
    "America/Cuiaba||3I.k 40 30",
    "America/Danmarkshavn||1e.E 30 20 0",
    "America/Dawson_Creek||80.U 80 70 70 70 70",
    "America/Dawson||9h.E 90 80 80 80 70 80 70",
    "America/Denver||6X.U 70 60 60 60",
    "America/Detroit||5w.b 60 50 40 40 40",
    "America/Edmonton||7x.Q 70 60 60 60",
    "America/Eirunepe||4D.s 50 40",
    "America/El_Salvador||5U.M 60 50",
    "America/Tijuana||7M.4 70 80 70 70 70",
    "America/Fort_Nelson||8a.L 80 70 70 70 70",
    "America/Fort_Wayne||5I.C 60 50 50 50 50 40",
    "America/Fortaleza||2y 30 20",
    "America/Glace_Bay||3X.M 40 30 30 30",
    "America/Godthab||3q.U 30 20",
    "America/Goose_Bay||41.E 3u.Q 2u.Q 3u 2u 2u 2u 40 30 20",
    "America/Grand_Turk||4I.w 57.a 50 40 40",
    "America/Guatemala||62.4 60 50",
    "America/Guayaquil||5j.k 5e 50 40",
    "America/Guyana||3Q.E 3J 30 40",
    "America/Halifax||4e.o 40 30 30 30",
    "America/Havana||5t.s 5t.A 50 40",
    "America/Hermosillo||7n.Q 70 60 80 60",
    "America/Indiana/Knox||5K.u 60 50 50 50 50",
    "America/Indiana/Marengo||5J.n 60 50 50 50 50 40",
    "America/Indiana/Petersburg||5N.7 60 50 50 50 50 40",
    "America/Indiana/Tell_City||5L.3 60 50 50 50 50 40",
    "America/Indiana/Vevay||5E.g 60 50 50 50 50 40",
    "America/Indiana/Vincennes||5O.7 60 50 50 50 50 40",
    "America/Indiana/Winamac||5K.p 60 50 50 50 50 40",
    "America/Inuvik||0 80 60 70 60",
    "America/Iqaluit||0 40 40 50 30 40 60 50",
    "America/Jamaica||57.a 57.a 50 40",
    "America/Juneau||-f2.j 8V.F 80 70 70 70 80 90 90 80",
    "America/Kentucky/Louisville||5H.2 60 50 50 50 50 40",
    "America/Kentucky/Monticello||5D.o 60 50 50 50 50 40",
    "America/La_Paz||4w.A 4w.A 3w.A 40",
    "America/Lima||58.c 58.A 50 40",
    "America/Los_Angeles||7Q.W 80 70 70 70",
    "America/Maceio||2m.Q 30 20",
    "America/Managua||5J.8 5J.c 60 50 50",
    "America/Manaus||40.4 40 30",
    "America/Martinique||44.k 44.k 40 30",
    "America/Matamoros||6E 60 50",
    "America/Mazatlan||75.E 70 60 80 60",
    "America/Menominee||5O.r 60 50 50 50 50",
    "America/Merida||5W.s 60 50 50",
    "America/Metlakatla||-fd.G 8K.i 80 70 70 70 90 80",
    "America/Mexico_City||6A.A 70 60 50 50",
    "America/Miquelon||3I.E 40 30 20",
    "America/Moncton||4j.8 50 40 30 30 30",
    "America/Monterrey||6F.g 60 50",
    "America/Montevideo||3I.P 3I.P 40 30 3u 2u 20 1u",
    "America/Toronto||5h.w 50 40 40 40",
    "America/Nassau||59.u 50 40",
    "America/New_York||4U.2 50 40 40 40",
    "America/Nipigon||5R.4 50 40 40 40",
    "America/Nome||-cW.m b1.C b0 a0 a0 b0 a0 90 90 80",
    "America/Noronha||29.E 20 10",
    "America/North_Dakota/Beulah||6L.7 70 60 60 60 60 50",
    "America/North_Dakota/Center||6J.c 70 60 60 60 60 50",
    "America/North_Dakota/New_Salem||6J.D 70 60 60 60 60 50",
    "America/Ojinaga||6V.E 70 60 50 60",
    "America/Pangnirtung||0 40 30 30 20 30 40 50 60 50",
    "America/Paramaribo||3E.E 3E.Q 3E.A 3u 30",
    "America/Phoenix||7s.i 70 60 60",
    "America/Port-au-Prince||4N.k 4N 50 40",
    "America/Rio_Branco||4v.c 50 40",
    "America/Porto_Velho||4f.A 40 30",
    "America/Puerto_Rico||4o.p 40 30 30",
    "America/Punta_Arenas||4H.E 4G.K 50 40 30",
    "America/Rainy_River||6i.g 60 50 50 50",
    "America/Rankin_Inlet||0 60 40 50 50",
    "America/Recife||2j.A 30 20",
    "America/Regina||6W.A 70 60 60 60 60",
    "America/Resolute||0 60 40 50 50",
    "America/Santarem||3C.M 40 30",
    "America/Santiago||4G.K 4G.K 50 40 30",
    "America/Santo_Domingo||4D.A 4E 50 40 4u 40",
    "America/Sao_Paulo||36.s 30 20",
    "America/Scoresbysund||1r.Q 20 10 0",
    "America/Sitka||-eW.L 91.d 80 70 70 70 90 90 80",
    "America/St_Johns||3u.Q 3u.Q 2u.Q 3u 2u 2u 2u 1u",
    "America/Swift_Current||7b.k 70 60 60 60 60",
    "America/Tegucigalpa||5M.Q 60 50",
    "America/Thule||4z.8 40 30",
    "America/Thunder_Bay||5V 60 50 40 40 40",
    "America/Vancouver||8c.s 80 70 70 70",
    "America/Whitehorse||90.c 90 80 80 80 70 80 70",
    "America/Winnipeg||6s.A 60 50 50 50",
    "America/Yakutat||-eF.5 9i.T 90 80 80 80 90 80",
    "America/Yellowknife||0 70 60 60 50 60",
    "Antarctica/Casey||0 -80 -b0",
    "Antarctica/Davis||0 -70 -50",
    "Antarctica/DumontDUrville||0 -a0",
    "Antarctica/Macquarie||0 -a0 -b0 -b0",
    "Antarctica/Mawson||0 -60 -50",
    "Pacific/Auckland||-bD.4 -bu -cu -c0 -d0",
    "Antarctica/Palmer||0 30 40 20",
    "Antarctica/Rothera||0 30",
    "Antarctica/Syowa||0 -30",
    "Antarctica/Troll||0 0 -20",
    "Antarctica/Vostok||0 -60",
    "Europe/Oslo||-H -10 -20",
    "Asia/Riyadh||-36.Q -30",
    "Asia/Almaty||-57.M -50 -60 -70",
    "Asia/Amman||-2n.I -20 -30",
    "Asia/Anadyr||-bN.U -c0 -d0 -e0 -b0",
    "Asia/Aqtau||-3l.4 -40 -50 -60",
    "Asia/Aqtobe||-3M.E -40 -50 -60",
    "Asia/Ashgabat||-3R.w -40 -50 -60",
    "Asia/Atyrau||-3r.I -30 -50 -60 -40",
    "Asia/Baghdad||-2V.E -2V.A -30 -40",
    "Asia/Qatar||-3q.8 -40 -30",
    "Asia/Baku||-3j.o -30 -40 -50",
    "Asia/Bangkok||-6G.4 -6G.4 -70",
    "Asia/Barnaul||-5z -60 -70 -80",
    "Asia/Beirut||-2m -20 -30",
    "Asia/Bishkek||-4W.o -50 -60 -70",
    "Asia/Brunei||-7D.E -7u -80",
    "Asia/Kolkata||-5R.s -5R.k -5l.a -5u -6u",
    "Asia/Chita||-7x.Q -80 -90 -a0",
    "Asia/Choibalsan||-7C -70 -80 -a0 -90",
    "Asia/Shanghai||-85.H -80 -90",
    "Asia/Colombo||-5j.o -5j.w -5u -60 -6u",
    "Asia/Dhaka||-61.E -5R.k -6u -5u -60 -70",
    "Asia/Damascus||-2p.c -20 -30",
    "Asia/Dili||-8m.k -80 -90",
    "Asia/Dubai||-3F.c -40",
    "Asia/Dushanbe||-4z.c -50 -60 -70",
    "Asia/Famagusta||-2f.M -20 -30 -30",
    "Asia/Gaza||-2h.Q -20 -30 -20 -30",
    "Asia/Hebron||-2k.n -20 -30 -20 -30",
    "Asia/Ho_Chi_Minh||-76.E -76.u -70 -80 -90",
    "Asia/Hong_Kong||-7A.G -80 -90 -90",
    "Asia/Hovd||-66.A -60 -70 -80",
    "Asia/Irkutsk||-6V.5 -6V.5 -70 -80 -90",
    "Europe/Istanbul||-1T.Q -1U.U -20 -30 -40 -30",
    "Asia/Jakarta||-77.c -77.c -7k -7u -90 -80 -70",
    "Asia/Jayapura||-9m.M -90 -9u -90",
    "Asia/Jerusalem||-2k.S -2k.E -20 -30 -40",
    "Asia/Kabul||-4A.M -40 -4u",
    "Asia/Kamchatka||-ay.A -b0 -c0 -d0",
    "Asia/Karachi||-4s.c -5u -6u -50 -50 -60",
    "Asia/Urumqi||-5O.k -60",
    "Asia/Kathmandu||-5F.g -5u -5J",
    "Asia/Khandyga||-92.d -80 -90 -a0 -b0",
    "Asia/Krasnoyarsk||-6b.q -60 -70 -80",
    "Asia/Kuala_Lumpur||-6K.K -6T.p -70 -7k -7u -90 -80",
    "Asia/Kuching||-7l.k -7u -80 -8k -90",
    "Asia/Macau||-7y.a -80 -90 -a0 -90",
    "Asia/Magadan||-a3.c -a0 -b0 -c0",
    "Asia/Makassar||-7V.A -7V.A -80 -90 -80",
    "Asia/Manila||fU -84 -80 -90 -90",
    "Asia/Nicosia||-2d.s -20 -30",
    "Asia/Novokuznetsk||-5M.M -60 -70 -80",
    "Asia/Novosibirsk||-5v.E -60 -70 -80",
    "Asia/Omsk||-4R.u -50 -60 -70",
    "Asia/Oral||-3p.o -30 -50 -60 -40",
    "Asia/Pontianak||-7h.k -7h.k -7u -90 -80 -80 -70",
    "Asia/Pyongyang||-8n -8u -90 -90",
    "Asia/Qyzylorda||-4l.Q -40 -50 -60",
    "Asia/Rangoon||-6o.L -6o.L -6u -90",
    "Asia/Sakhalin||-9u.M -90 -b0 -c0 -a0",
    "Asia/Samarkand||-4r.R -40 -50 -60",
    "Asia/Seoul||-8r.Q -8u -90 -90 -9u -a0",
    "Asia/Singapore||-6T.p -6T.p -70 -7k -7u -90 -80",
    "Asia/Srednekolymsk||-ae.Q -a0 -b0 -c0",
    "Asia/Taipei||-86 -80 -90 -90",
    "Asia/Tashkent||-4B.b -50 -60 -70",
    "Asia/Tbilisi||-2X.b -2X.b -30 -40 -50",
    "Asia/Tehran||-3p.I -3p.I -3u -40 -50 -4u",
    "Asia/Thimphu||-5W.A -5u -60",
    "Asia/Tokyo||-9i.X -90 -a0",
    "Asia/Tomsk||-5D.P -60 -70 -80",
    "Asia/Ulaanbaatar||-77.w -70 -80 -90",
    "Asia/Ust-Nera||-9w.S -80 -90 -c0 -b0 -a0",
    "Asia/Vladivostok||-8L.v -90 -a0 -b0",
    "Asia/Yakutsk||-8C.W -80 -90 -a0",
    "Asia/Yekaterinburg||-42.x -3J.5 -40 -50 -60",
    "Asia/Yerevan||-2W -30 -40 -50",
    "Atlantic/Azores||1G.E 1S.w 20 10 0 0",
    "Atlantic/Bermuda||4j.i 40 30",
    "Atlantic/Canary||11.A 10 0 -10",
    "Atlantic/Cape_Verde||1y.4 20 10",
    "Atlantic/Faroe||r.4 0 -10",
    "Atlantic/Madeira||17.A 17.A 10 0 -10 0 -10",
    "Atlantic/Reykjavik||1s 10 0 0",
    "Atlantic/South_Georgia||2q.8 20",
    "Atlantic/Stanley||3P.o 3P.o 40 30 20",
    "Australia/Sydney||-a4.Q -a0 -b0",
    "Australia/Adelaide||-9e.k -90 -9u -au",
    "Australia/Brisbane||-ac.8 -a0 -b0",
    "Australia/Broken_Hill||-9p.M -a0 -90 -9u -au",
    "Australia/Currie||-9z.s -a0 -b0",
    "Australia/Darwin||-8H.k -90 -9u -au",
    "Australia/Eucla||-8z.s -8J -9J",
    "Australia/Hobart||-9N.g -a0 -b0",
    "Australia/Lord_Howe||-aA.k -a0 -au -bu -b0",
    "Australia/Lindeman||-9T.U -a0 -b0",
    "Australia/Melbourne||-9D.Q -a0 -b0",
    "Australia/Perth||-7H.o -80 -90",
    "CET||-10 -20",
    "Pacific/Easter||7h.s 7h.s 70 60 50",
    "CST6CDT||60 50 50 50",
    "EET||-20 -30",
    "Europe/Dublin||p p.l -y.D 0 -10 -10",
    "EST||50",
    "EST5EDT||50 40 40 40",
    "Etc/GMT-0||0",
    "Etc/GMT-1||-10",
    "Etc/GMT-10||-a0",
    "Etc/GMT-11||-b0",
    "Etc/GMT-12||-c0",
    "Etc/GMT-13||-d0",
    "Etc/GMT-14||-e0",
    "Etc/GMT-2||-20",
    "Etc/GMT-3||-30",
    "Etc/GMT-4||-40",
    "Etc/GMT-5||-50",
    "Etc/GMT-6||-60",
    "Etc/GMT-7||-70",
    "Etc/GMT-8||-80",
    "Etc/GMT-9||-90",
    "Etc/GMT+1||10",
    "Etc/GMT+10||a0",
    "Etc/GMT+11||b0",
    "Etc/GMT+12||c0",
    "Etc/GMT+2||20",
    "Etc/GMT+3||30",
    "Etc/GMT+4||40",
    "Etc/GMT+5||50",
    "Etc/GMT+6||60",
    "Etc/GMT+7||70",
    "Etc/GMT+8||80",
    "Etc/GMT+9||90",
    "Etc/UCT||0",
    "Etc/UTC||0",
    "Europe/Amsterdam||-j.w -j.w -1j.w -1k -k -20 -10",
    "Europe/Andorra||-6.4 0 -10 -20",
    "Europe/Astrakhan||-3c.c -30 -40 -50",
    "Europe/Athens||-1y.Q -1y.Q -20 -30 -20 -10",
    "Europe/London||1.f 0 -10 -20",
    "Europe/Belgrade||-1m -10 -20",
    "Europe/Berlin||-R.s -10 -20 -30",
    "Europe/Prague||-V.I -V.I -10 -20 0",
    "Europe/Brussels||-h.u -h.u 0 -10 -20 -10",
    "Europe/Bucharest||-1I.o -1I.o -20 -30",
    "Europe/Budapest||-1g.k -10 -20",
    "Europe/Zurich||-y.8 -t.K -10 -20",
    "Europe/Chisinau||-1T.k -1T -1I.o -20 -30 -20 -10 -30 -40",
    "Europe/Copenhagen||-O.k -O.k -10 -20",
    "Europe/Gibraltar||l.o 0 -10 -20 -10 -20",
    "Europe/Helsinki||-1D.N -1D.N -20 -30",
    "Europe/Kaliningrad||-1m -10 -20 -20 -30 -30 -40 -30 -20 -30",
    "Europe/Kiev||-22.4 -22.4 -20 -30 -20 -10 -40 -30",
    "Europe/Kirov||-3i.M -30 -40 -50",
    "Europe/Lisbon||A.J 0 -10 -20 -10 -20",
    "Europe/Luxembourg||-o.A -10 -20 0 -10 -20 -10",
    "Europe/Madrid||e.I 0 -10 -20 -10 -20",
    "Europe/Malta||-W.4 -10 -20",
    "Europe/Minsk||-1O.g -1O -20 -30 -20 -10 -40 -30 -30",
    "Europe/Monaco||-t.w -9.l 0 -10 -20 -10 -20",
    "Europe/Moscow||-2u.h -2u.h -2v.j -3v.j -4v.j -40 -30 -50 -20 -30 -40",
    "Europe/Paris||-9.l -9.l 0 -10 -20 -10 -20",
    "Europe/Riga||-1A.y -1A.y -2A.y -20 -30 -20 -10 -40 -30",
    "Europe/Rome||-N.U -N.U -10 -20",
    "Europe/Samara||-3k.k -30 -40 -50",
    "Europe/Saratov||-34.i -30 -40 -50",
    "Europe/Simferopol||-2g.o -2g -20 -30 -20 -10 -40 -30 -40",
    "Europe/Sofia||-1x.g -1U.U -20 -10 -20 -30",
    "Europe/Stockholm||-1c.c -10.e -10 -20",
    "Europe/Tallinn||-1D -1D -10 -20 -20 -30 -40 -30",
    "Europe/Tirane||-1j.k -10 -20",
    "Europe/Ulyanovsk||-3d.A -30 -40 -50 -20",
    "Europe/Uzhgorod||-1t.c -10 -20 -30 -40 -20 -30",
    "Europe/Vienna||-15.l -10 -20",
    "Europe/Vilnius||-1F.g -1o -1z.A -10 -20 -30 -20 -40 -30",
    "Europe/Volgograd||-2V.E -30 -40 -50",
    "Europe/Warsaw||-1o -1o -10 -20 -20 -30",
    "Europe/Zaporozhye||-2k.E -2k -20 -30 -20 -10 -40 -30",
    "HST||a0",
    "Indian/Chagos||-4N.E -50 -60",
    "Indian/Christmas||-72.Q -70",
    "Indian/Cocos||-6r.E -6u",
    "Indian/Kerguelen||0 -50",
    "Indian/Mahe||-3F.M -40",
    "Indian/Maldives||-4S -4S -50",
    "Indian/Mauritius||-3O -40 -50",
    "Indian/Reunion||-3F.Q -40",
    "Pacific/Kwajalein||-b9.k -b0 c0 -c0",
    "MET||-10 -20",
    "MST||70",
    "MST7MDT||70 60 60 60",
    "Pacific/Chatham||-cd.M -cf -cJ -dJ",
    "Pacific/Apia||-cx.4 bq.U bu b0 a0 -e0 -d0",
    "Pacific/Bougainville||-am.g -9M.w -a0 -90 -b0",
    "Pacific/Chuuk||-a7.8 -a0",
    "Pacific/Efate||-bd.g -b0 -c0",
    "Pacific/Enderbury||bo.k c0 b0 -d0",
    "Pacific/Fakaofo||bo.U b0 -d0",
    "Pacific/Fiji||-bT.I -c0 -d0",
    "Pacific/Funafuti||-bU.Q -c0",
    "Pacific/Galapagos||5W.o 50 60",
    "Pacific/Gambier||8X.M 90",
    "Pacific/Guadalcanal||-aD.M -b0",
    "Pacific/Guam||el -9D -a0 -a0",
    "Pacific/Honolulu||av.q au 9u 9u 9u a0",
    "Pacific/Kiritimati||at.k aE a0 -e0",
    "Pacific/Kosrae||-aP.U -b0 -c0",
    "Pacific/Majuro||-bo.M -b0 -c0",
    "Pacific/Marquesas||9i 9u",
    "Pacific/Pago_Pago||-cB.c bm.M b0",
    "Pacific/Nauru||-b7.E -bu -90 -c0",
    "Pacific/Niue||bj.E bk bu b0",
    "Pacific/Norfolk||-bb.Q -bc -bu -cu -b0",
    "Pacific/Noumea||-b5.M -b0 -c0",
    "Pacific/Palau||-8V.U -90",
    "Pacific/Pitcairn||8E.k 8u 80",
    "Pacific/Pohnpei||-aw.Q -b0",
    "Pacific/Port_Moresby||-9M.E -9M.w -a0",
    "Pacific/Rarotonga||aD.4 au 9u a0",
    "Pacific/Tahiti||9W.g a0",
    "Pacific/Tarawa||-bw.4 -c0",
    "Pacific/Tongatapu||-cj.k -ck -d0 -e0",
    "Pacific/Wake||-b6.s -c0",
    "Pacific/Wallis||-cf.k -c0",
    "PST8PDT||80 70 70 70",
    "WET||0 -10",
  ],
  links: [
    "Africa/Abidjan|Africa/Bamako",
    "Africa/Abidjan|Africa/Banjul",
    "Africa/Abidjan|Africa/Conakry",
    "Africa/Abidjan|Africa/Dakar",
    "Africa/Abidjan|Africa/Freetown",
    "Africa/Abidjan|Africa/Lome",
    "Africa/Abidjan|Africa/Nouakchott",
    "Africa/Abidjan|Africa/Ouagadougou",
    "Africa/Abidjan|Africa/Timbuktu",
    "Africa/Abidjan|Atlantic/St_Helena",
    "Africa/Cairo|Egypt",
    "Africa/Johannesburg|Africa/Maseru",
    "Africa/Johannesburg|Africa/Mbabane",
    "Africa/Lagos|Africa/Bangui",
    "Africa/Lagos|Africa/Brazzaville",
    "Africa/Lagos|Africa/Douala",
    "Africa/Lagos|Africa/Kinshasa",
    "Africa/Lagos|Africa/Libreville",
    "Africa/Lagos|Africa/Luanda",
    "Africa/Lagos|Africa/Malabo",
    "Africa/Lagos|Africa/Niamey",
    "Africa/Lagos|Africa/Porto-Novo",
    "Africa/Maputo|Africa/Blantyre",
    "Africa/Maputo|Africa/Bujumbura",
    "Africa/Maputo|Africa/Gaborone",
    "Africa/Maputo|Africa/Harare",
    "Africa/Maputo|Africa/Kigali",
    "Africa/Maputo|Africa/Lubumbashi",
    "Africa/Maputo|Africa/Lusaka",
    "Africa/Nairobi|Africa/Addis_Ababa",
    "Africa/Nairobi|Africa/Asmara",
    "Africa/Nairobi|Africa/Asmera",
    "Africa/Nairobi|Africa/Dar_es_Salaam",
    "Africa/Nairobi|Africa/Djibouti",
    "Africa/Nairobi|Africa/Kampala",
    "Africa/Nairobi|Africa/Mogadishu",
    "Africa/Nairobi|Indian/Antananarivo",
    "Africa/Nairobi|Indian/Comoro",
    "Africa/Nairobi|Indian/Mayotte",
    "Africa/Tripoli|Libya",
    "America/Adak|America/Atka",
    "America/Adak|US/Aleutian",
    "America/Anchorage|US/Alaska",
    "America/Argentina/Buenos_Aires|America/Buenos_Aires",
    "America/Argentina/Catamarca|America/Argentina/ComodRivadavia",
    "America/Argentina/Catamarca|America/Catamarca",
    "America/Argentina/Cordoba|America/Cordoba",
    "America/Argentina/Cordoba|America/Rosario",
    "America/Argentina/Jujuy|America/Jujuy",
    "America/Argentina/Mendoza|America/Mendoza",
    "America/Atikokan|America/Coral_Harbour",
    "America/Chicago|US/Central",
    "America/Curacao|America/Aruba",
    "America/Curacao|America/Kralendijk",
    "America/Curacao|America/Lower_Princes",
    "America/Denver|America/Shiprock",
    "America/Denver|Navajo",
    "America/Denver|US/Mountain",
    "America/Detroit|US/Michigan",
    "America/Edmonton|Canada/Mountain",
    "America/Fort_Wayne|America/Indiana/Indianapolis",
    "America/Fort_Wayne|America/Indianapolis",
    "America/Fort_Wayne|US/East-Indiana",
    "America/Halifax|Canada/Atlantic",
    "America/Havana|Cuba",
    "America/Indiana/Knox|America/Knox_IN",
    "America/Indiana/Knox|US/Indiana-Starke",
    "America/Jamaica|Jamaica",
    "America/Kentucky/Louisville|America/Louisville",
    "America/Los_Angeles|US/Pacific",
    "America/Los_Angeles|US/Pacific-New",
    "America/Manaus|Brazil/West",
    "America/Mazatlan|Mexico/BajaSur",
    "America/Mexico_City|Mexico/General",
    "America/New_York|US/Eastern",
    "America/Noronha|Brazil/DeNoronha",
    "America/Panama|America/Cayman",
    "America/Phoenix|US/Arizona",
    "America/Port_of_Spain|America/Anguilla",
    "America/Port_of_Spain|America/Antigua",
    "America/Port_of_Spain|America/Dominica",
    "America/Port_of_Spain|America/Grenada",
    "America/Port_of_Spain|America/Guadeloupe",
    "America/Port_of_Spain|America/Marigot",
    "America/Port_of_Spain|America/Montserrat",
    "America/Port_of_Spain|America/St_Barthelemy",
    "America/Port_of_Spain|America/St_Kitts",
    "America/Port_of_Spain|America/St_Lucia",
    "America/Port_of_Spain|America/St_Thomas",
    "America/Port_of_Spain|America/St_Vincent",
    "America/Port_of_Spain|America/Tortola",
    "America/Port_of_Spain|America/Virgin",
    "America/Regina|Canada/Saskatchewan",
    "America/Rio_Branco|America/Porto_Acre",
    "America/Rio_Branco|Brazil/Acre",
    "America/Santiago|Chile/Continental",
    "America/Sao_Paulo|Brazil/East",
    "America/St_Johns|Canada/Newfoundland",
    "America/Tijuana|America/Ensenada",
    "America/Tijuana|America/Santa_Isabel",
    "America/Tijuana|Mexico/BajaNorte",
    "America/Toronto|America/Montreal",
    "America/Toronto|Canada/Eastern",
    "America/Vancouver|Canada/Pacific",
    "America/Whitehorse|Canada/Yukon",
    "America/Winnipeg|Canada/Central",
    "Asia/Ashgabat|Asia/Ashkhabad",
    "Asia/Bangkok|Asia/Phnom_Penh",
    "Asia/Bangkok|Asia/Vientiane",
    "Asia/Dhaka|Asia/Dacca",
    "Asia/Dubai|Asia/Muscat",
    "Asia/Ho_Chi_Minh|Asia/Saigon",
    "Asia/Hong_Kong|Hongkong",
    "Asia/Jerusalem|Asia/Tel_Aviv",
    "Asia/Jerusalem|Israel",
    "Asia/Kathmandu|Asia/Katmandu",
    "Asia/Kolkata|Asia/Calcutta",
    "Asia/Macau|Asia/Macao",
    "Asia/Makassar|Asia/Ujung_Pandang",
    "Asia/Nicosia|Europe/Nicosia",
    "Asia/Qatar|Asia/Bahrain",
    "Asia/Rangoon|Asia/Yangon",
    "Asia/Riyadh|Asia/Aden",
    "Asia/Riyadh|Asia/Kuwait",
    "Asia/Seoul|ROK",
    "Asia/Shanghai|Asia/Chongqing",
    "Asia/Shanghai|Asia/Chungking",
    "Asia/Shanghai|Asia/Harbin",
    "Asia/Shanghai|PRC",
    "Asia/Singapore|Singapore",
    "Asia/Taipei|ROC",
    "Asia/Tehran|Iran",
    "Asia/Thimphu|Asia/Thimbu",
    "Asia/Tokyo|Japan",
    "Asia/Ulaanbaatar|Asia/Ulan_Bator",
    "Asia/Urumqi|Asia/Kashgar",
    "Atlantic/Faroe|Atlantic/Faeroe",
    "Atlantic/Reykjavik|Iceland",
    "Australia/Adelaide|Australia/South",
    "Australia/Brisbane|Australia/Queensland",
    "Australia/Broken_Hill|Australia/Yancowinna",
    "Australia/Darwin|Australia/North",
    "Australia/Hobart|Australia/Tasmania",
    "Australia/Lord_Howe|Australia/LHI",
    "Australia/Melbourne|Australia/Victoria",
    "Australia/Perth|Australia/West",
    "Australia/Sydney|Australia/ACT",
    "Australia/Sydney|Australia/Canberra",
    "Australia/Sydney|Australia/NSW",
    "Etc/GMT-0|Etc/GMT",
    "Etc/GMT-0|Etc/GMT+0",
    "Etc/GMT-0|Etc/GMT0",
    "Etc/GMT-0|Etc/Greenwich",
    "Etc/GMT-0|GMT",
    "Etc/GMT-0|GMT+0",
    "Etc/GMT-0|GMT-0",
    "Etc/GMT-0|GMT0",
    "Etc/GMT-0|Greenwich",
    "Etc/UCT|UCT",
    "Etc/UTC|Etc/Universal",
    "Etc/UTC|Etc/Zulu",
    "Etc/UTC|UTC",
    "Etc/UTC|Universal",
    "Etc/UTC|Zulu",
    "Europe/Belgrade|Europe/Ljubljana",
    "Europe/Belgrade|Europe/Podgorica",
    "Europe/Belgrade|Europe/Sarajevo",
    "Europe/Belgrade|Europe/Skopje",
    "Europe/Belgrade|Europe/Zagreb",
    "Europe/Chisinau|Europe/Tiraspol",
    "Europe/Dublin|Eire",
    "Europe/Helsinki|Europe/Mariehamn",
    "Europe/Istanbul|Asia/Istanbul",
    "Europe/Istanbul|Turkey",
    "Europe/Lisbon|Portugal",
    "Europe/London|Europe/Belfast",
    "Europe/London|Europe/Guernsey",
    "Europe/London|Europe/Isle_of_Man",
    "Europe/London|Europe/Jersey",
    "Europe/London|GB",
    "Europe/London|GB-Eire",
    "Europe/Moscow|W-SU",
    "Europe/Oslo|Arctic/Longyearbyen",
    "Europe/Oslo|Atlantic/Jan_Mayen",
    "Europe/Prague|Europe/Bratislava",
    "Europe/Rome|Europe/San_Marino",
    "Europe/Rome|Europe/Vatican",
    "Europe/Warsaw|Poland",
    "Europe/Zurich|Europe/Busingen",
    "Europe/Zurich|Europe/Vaduz",
    "Pacific/Auckland|Antarctica/McMurdo",
    "Pacific/Auckland|Antarctica/South_Pole",
    "Pacific/Auckland|NZ",
    "Pacific/Chatham|NZ-CHAT",
    "Pacific/Chuuk|Pacific/Truk",
    "Pacific/Chuuk|Pacific/Yap",
    "Pacific/Easter|Chile/EasterIsland",
    "Pacific/Guam|Pacific/Saipan",
    "Pacific/Honolulu|Pacific/Johnston",
    "Pacific/Honolulu|US/Hawaii",
    "Pacific/Kwajalein|Kwajalein",
    "Pacific/Pago_Pago|Pacific/Midway",
    "Pacific/Pago_Pago|Pacific/Samoa",
    "Pacific/Pago_Pago|US/Samoa",
    "Pacific/Pohnpei|Pacific/Ponape",
  ],
});

export default moment;

function humanTimezone() {
  try {
    return moment.tz.guess();
  } catch (error) {
    return null;
  }
}

export { humanTimezone };

export const DefaultFormat = "MMM D, YYYY h:mm a";
