import moment from "modules/moment";
// Utility functions that convert dates to the strings that can be used in
// routing.

export function toDateString(date: Date): string {
  return date.toISOString().split("T")[0];
}

export function toDateTimeString(date: Date): string {
  return date.toISOString().split(".")[0];
}

export function dateFromString(date: string): Date {
  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").toDate();
}
