import React from "react";
import { Router } from "react-router-dom";

import history from "components/browserHistory";
import GlobalStyle from "components/Modules/GlobalStyle";
import { Menu } from "app/components/Navigation";

import RootContext from "app/providers/RootContext";
import { ContextType } from "modules/rootContext/types";

export default function MenuMount(props: ContextType) {
  return (
    <RootContext.Provider value={props}>
      <GlobalStyle />
      <Router history={history}>
        <Menu />
      </Router>
    </RootContext.Provider>
  );
}
