import React from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";

import URI from "utils/urijs";
import { isHistoryApiSupported } from "utils/routes";

type Props = (LinkProps | React.AnchorHTMLAttributes<HTMLAnchorElement>) & {
  useHistory?: boolean;
  to: string;
  replace?: boolean;
};

/**
 * Handles redirecting properties to either react-router's link or a native
 * anchor element based on the useHistory prop.
 */
function Link({ useHistory, to, replace, children, ...props }: Props) {
  const currentUrl = window.location.pathname + window.location.search;

  const useHistoryLink =
    useHistory !== undefined
      ? useHistory
      : isHistoryApiSupported(URI().path(), {
          currentUrl,
        }) && isHistoryApiSupported(to, { currentUrl });

  if (useHistoryLink) {
    return (
      <RouterLink
        to={to}
        replace={replace}
        {...(props as Omit<LinkProps, "to">)}
      >
        {children}
      </RouterLink>
    );
  }

  // We pass children explicitly (instead of relying on the expanded props
  // object) so that eslint will know that this link has content (otherwise an
  // accessibility linter check fails).
  return (
    <a href={to} {...props}>
      {children}
    </a>
  );
}

export default Link;
