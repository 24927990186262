import React, { PropsWithChildren } from "react";
import { Badge as BadgeChakra, Text } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./badge.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Props } from "./Badge.types";

export const Badge: React.FC<PropsWithChildren<Props>> = ({
  colorScheme = "green",
  children,
  width,
  showOverflowText = false,
  variant,
}) => {
  if (variant === "beta") {
    return <BetaBadge>{children}</BetaBadge>;
  }

  if (variant === "new") {
    return <NewBadge>{children}</NewBadge>;
  }

  if (variant === "trial") {
    return <TrialBadge>{children}</TrialBadge>;
  }

  return (
    <BadgeChakra
      {...styles.Badge(colorScheme, showOverflowText).Badge}
      position="relative"
      width={width}
    >
      {/* TODO: add a tooltip/popover if text is too long. */}
      <Text {...styles.Badge(colorScheme, showOverflowText).Text}>
        {children}
      </Text>
      <ChakraIcon
        data-testid="badge-info-icon"
        icon="info-outline-10"
        marginLeft="4px"
        position="absolute"
        right="8px"
      />
    </BadgeChakra>
  );
};

const BetaBadge: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <BadgeChakra {...styles.BetaBadge.Badge} position="relative">
      <Text {...styles.BetaBadge.Text}>{children}</Text>
    </BadgeChakra>
  );
};

const NewBadge: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <BadgeChakra {...styles.NewBadge.Badge} position="relative">
      <Text {...styles.NewBadge.Text}>{children}</Text>
    </BadgeChakra>
  );
};

const TrialBadge: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <BadgeChakra {...styles.TrialBadge.Badge} position="relative">
      <Text {...styles.TrialBadge.Text}>{children}</Text>
    </BadgeChakra>
  );
};
