import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";

export const Box = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Content = styled(Box)`
  width: 300px;
  font-size: 13px;
  padding: 16px;
`;

export const Title = styled.div`
  font-size: 13px;
  font-weight: bold;
  color: var(--neutral-grey);
`;

export const Text = styled.div`
  font-size: 13px;
  line-height: 1.54;
  color: var(--neutral-lightgrey);

  & + ${Title} {
    margin-top: 24px;
  }
`;

export const TextDark = styled(Text)`
  color: var(--neutral-grey);
`;

export const Container = styled.div`
  background: white;
  border-radius: 4px;
  z-index: 1;
  overflow: auto;
  box-shadow: 0px 4px 16px 0px rgba(0, 36, 73, 0.1),
    0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  :focus {
    outline: none;
  }
`;

export const SecondaryContainer = styled.div`
  background: white;
  border-radius: 4px;
  z-index: 1;
  box-shadow: 0px 4px 16px 0px rgba(0, 36, 73, 0.1),
    0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  :focus {
    outline: none;
  }
  background-color: var(--neutral-grey);
  color: white;
  padding: 8px;
  box-shadow: none;
`;

// In order to use tippyjs native arrow functionality we need to import tippy.js/dist/tippy.css
// However, it would break all tooltips across the project
// Here, we have this file being copied with one small addition - .hint-tooltip class so its applied only for Hint.tsx
export const HintGlobalStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        .hint-tooltip.tippy-box[data-animation="fade"][data-state="hidden"] {
          opacity: 0;
        }
        [data-tippy-root] {
          max-width: calc(100vw - 10px);
        }
        .hint-tooltip.tippy-box[data-placement^="top"] > .tippy-arrow {
          bottom: 0;
        }
        .hint-tooltip.tippy-box {
          position: relative;
          background-color: ${theme.colors["grey-dark"]};
          color: #fff;
          border-radius: 4px;
          font-size: 14px;
          line-height: 1.4;
          outline: 0;
          transition-property: transform, visibility, opacity;
          padding: 10px;
          box-shadow: 0px 4px 16px 0px rgba(0, 36, 73, 0.1),
            0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        }
        .hint-tooltip.tippy-box[data-placement^="top"] > .tippy-arrow:before {
          bottom: -7px;
          left: 0;
          border-width: 8px 8px 0;
          border-top-color: initial;
          transform-origin: center top;
        }
        .hint-tooltip.tippy-box[data-placement^="bottom"] > .tippy-arrow {
          top: 0;
        }
        .hint-tooltip.tippy-box[data-placement^="bottom"]
          > .tippy-arrow:before {
          top: -7px;
          left: 0;
          border-width: 0 8px 8px;
          border-bottom-color: initial;
          transform-origin: center bottom;
        }
        .hint-tooltip.tippy-box[data-placement^="left"] > .tippy-arrow {
          right: 0;
        }
        .hint-tooltip.tippy-box[data-placement^="left"] > .tippy-arrow:before {
          border-width: 8px 0 8px 8px;
          border-left-color: initial;
          right: -7px;
          transform-origin: center left;
        }
        .hint-tooltip.tippy-box[data-placement^="right"] > .tippy-arrow {
          left: 0;
        }
        .hint-tooltip.tippy-box[data-placement^="right"] > .tippy-arrow:before {
          left: -7px;
          border-width: 8px 8px 8px 0;
          border-right-color: initial;
          transform-origin: center right;
        }
        .hint-tooltip.tippy-box[data-inertia][data-state="visible"] {
          transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
        }
        .hint-tooltip .tippy-arrow {
          width: 16px;
          height: 16px;
          color: ${theme.colors["grey-dark"]};
        }
        .hint-tooltip .tippy-arrow:before {
          content: "";
          position: absolute;
          border-color: transparent;
          border-style: solid;
          transform: scale(1.5);
        }
      `}
    />
  );
};
