// eslint-disable-next-line no-restricted-imports -- This is a very specific wrapper around react-query so it needs to import it
import { UseQueryOptions, QueryKey, useQuery } from "@tanstack/react-query";

/**
 * A helper function to be able to generate succinct react-query hooks for a given
 * GET query. To create a hook, call this function with a cache key (usually a URL
 * or module path) and an action, and export the result. Example action to wrap:
 *
 * ```typescript
 * export async function get({
 *   arg1,
 *   arg2,
 * }: Args): Promise<Result> {
 *   ...
 * }
 * ```
 *
 * Example hook creation (in `hooks/queries/`)
 *
 * ```typescript
 * export const useGet = getQueryHook(
 *   "/url/of/query",
 *   () => get
 * );
 * ```
 *
 * Then, instead of the user importing and calling useQuery and manually specifying
 * cache key and args, this hook handles this automatically. Resulting code is more
 * simple:
 *
 * ```typescript
 * const { data, isLoading, error } = useGet([
 *   {
 *     arg1,
 *     arg2,
 *   },
 * ]);
 * ```
 *
 * An optional argument is available after the action arguments to provide config data
 * to react-query, for refined behavior.
 */
export default function getQueryHook<
  Args extends unknown[],
  Return extends NonUndefined
>(
  key: string,
  action: () => (...args: Args) => Promise<Return>,
  initialConfig: Omit<
    UseQueryOptions<Return, unknown>,
    "queryKey" | "queryFn"
  > = {}
) {
  return (
    args: Args,
    config: Omit<UseQueryOptions<Return, unknown>, "queryKey" | "queryFn"> & {
      useArgsAsDeps?: boolean;
    } = {},
    depsList: QueryKey = []
  ) => {
    const action_ = action();
    const { useArgsAsDeps = true, ...queryConfig } = config;
    const dependencies = useArgsAsDeps ? args : depsList;

    return useQuery<Return, unknown>(
      Array.isArray(dependencies)
        ? [key, ...dependencies]
        : [key, dependencies],
      () => {
        return action_(...args);
      },
      {
        ...initialConfig,
        ...queryConfig,
      }
    );
  };
}

// react-query v4 doesn't accept undefined return values from query functions.
// This type accepts any value (numbers, booleans, floats, objects, etc) except undefined
export type NonUndefined = {} | null;
