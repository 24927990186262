const getVariant = (color: string, hoverColor: string) => {
  return {
    track: {
      bg: "white",
      transition: "border-color 0.2s ease-in, background 0.2s ease-in",
      border: "2px solid",
      borderColor: "grey-light-green",
      position: "relative",
      padding: 0,
      _focus: {
        boxShadow: "0 0 0 2px rgba(66, 153, 225, 0.3)",
      },
      _before: {
        position: "absolute",
        borderRadius: "50%",
        content: '""',
        left: "1px",
        top: "1px",
        width: "10px",
        height: "10px",
        bg: "grey-dark",
        transition: "transform 0.25s ease-out, background 0.25s ease-out",
      },
      _checked: {
        bg: color,
        borderColor: color,
        _before: {
          transform: "translateX(17px)",
          bg: "white",
        },
        _hover: {
          borderColor: hoverColor,
          bg: hoverColor,
          _before: {
            bg: "white",
          },
        },
        _disabled: {
          borderColor: "grey-disabled",
          bg: "grey-disabled",
          _hover: {
            borderColor: "grey-disabled",
            bg: "grey-disabled",
          },
          _before: {
            bg: "white-disabled",
          },
        },
      },
      _hover: {
        borderColor: "grey-dark",
        _before: {
          bg: "grey-dark",
        },
      },
      _disabled: {
        borderColor: "grey-disabled",
        bg: "white-disabled",
        _hover: {
          borderColor: "grey-disabled",
          bg: "white-disabled",
        },
        _before: {
          bg: "grey-dark",
        },
      },
    },
    thumb: {
      display: "none",
    },
  };
};

export default {
  parts: ["label"],
  baseStyle: {
    label: {
      cursor: "pointer",
      _disabled: { cursor: "not-allowed", color: "grey-disabled" },
    },
    ...getVariant("inherit", "inherit"),
  },
  sizes: {
    md: {
      track: {
        w: "28px",
        h: "12px",
      },
    },
  },
  variants: {
    primary: getVariant("green", "green-dark"),
    secondary: getVariant("blue", "blue-dark"),
  },
  defaultProps: {
    size: "md",
    variant: "primary",
  },
};
