export const Tag = (
  isDisabled: boolean,
  colorScheme: string | undefined,
  size: "md" | "lg",
  hasIcon: boolean,
  showClose: boolean,
  onClick: boolean,
  alignItems?: "center" | "baseline"
) => {
  let bgColor = "white";
  let borderColor = "grey-light-green";
  let hoverBgColor = "grey-light-green";
  let color = "grey-dark";
  switch (colorScheme) {
    case "yellow":
      color = "yellow-dark";
      bgColor = "yellow-very-light";
      borderColor = "yellow-very-light";
      hoverBgColor = "yellow-very-light";
      break;
    case "purple":
      color = "purple-light";
      bgColor = "purple-light-2";
      borderColor = "purple-light-2";
      hoverBgColor = "purple-light-2";
      break;
    case "grey":
      bgColor = "grey-light-green";
      break;
  }

  let paddingLeft = size === "md" ? 12 : 16;
  let paddingRight = size === "md" ? 12 : 16;
  if (hasIcon) {
    paddingLeft -= 4;
  }
  if (showClose) {
    paddingRight -= 4;
  }

  return {
    lineHeight: 1.6,
    display: "inline-flex",
    flexFlow: "row nowrap",
    alignItems: alignItems ?? "center",
    backgroundColor: isDisabled ? "grey-disabled" : bgColor,
    paddingY: size === "md" ? "4px" : "6px",
    paddingLeft: `${paddingLeft}px`,
    paddingRight: `${paddingRight}px`,
    border: "2px solid",
    borderColor: isDisabled ? "grey-disabled" : borderColor,
    borderRadius: "4px",
    boxShadow: "none",
    color: isDisabled ? "grey" : color,
    cursor: isDisabled ? "not-allowed" : onClick ? "pointer" : "default",
    width: "fit-content",
    _hover: {
      backgroundColor: isDisabled ? "grey-disabled" : hoverBgColor,
    },
  };
};

export const TagContainer = {
  alignItems: "center",
  justifyContent: "center",
};

export const TagIcon = (isDisabled: boolean) => {
  return {
    color: isDisabled ? "grey" : "currentcolor",
    mr: "2px",
  };
};

export const TagLabel = (
  isDisabled: boolean,
  hasIcon: boolean,
  showClose: boolean,
  onClick: boolean
) => {
  let marginRight = "0px";
  if (hasIcon && showClose) {
    marginRight = "2px";
  } else if (showClose) {
    marginRight = "4px";
  }

  return {
    marginRight: marginRight,
    textStyle: "body2",
    cursor: isDisabled ? "not-allowed" : onClick ? "pointer" : "default",
  };
};

export const TagCloseButton = (isDisabled: boolean) => {
  return {
    height: "16px",
    width: "16px",
    border: "none",
    backgroundColor: "transparent",
    color: "grey-dark",
    cursor: isDisabled ? "not-allowed" : "pointer",
    _hover: {
      color: "grey-dark",
    },
    alignSelf: "center",
  };
};
