import { authServiceUrl } from "services/AuthApi";

export const switchCompany = ({
  email,
  companyId,
}: {
  email?: string | null;
  companyId: string;
}) => {
  const redirectUrl = new URL(authServiceUrl);
  redirectUrl.pathname = "/v2/ui/sign_in";
  email && redirectUrl.searchParams.set("email", email);
  redirectUrl.searchParams.set("company_id", companyId);

  const oauthInitiateRedirectUri = new URL(window.location.origin);
  oauthInitiateRedirectUri.pathname = "/authentication/initiate";
  redirectUrl.searchParams.set(
    "oauth_initiate_redirect_uri",
    oauthInitiateRedirectUri.toString()
  );
  window.location.href = redirectUrl.toString();
};
