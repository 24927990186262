import Cookies from "js-cookie";

export function set(value: string) {
  const element = getElement();

  if (element) {
    element.setAttribute("content", value);
  }

  dumpToCookies();
}

export function get() {
  const element = getElement();

  if (element) {
    return element.getAttribute("content");
  }
}

export function dumpToCookies() {
  const id = get();

  if (id) {
    Cookies.set(COOKIE_KEY, id);
  } else {
    Cookies.remove(COOKIE_KEY);
  }
}

const COOKIE_KEY = "dacid"; // ScopedByIndexKey::COOKIE_KEY

function getElement() {
  return document.querySelector('meta[name="autocomplete_company_id"]');
}
