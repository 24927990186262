const disabledCheckboxStyle = {
  bg: "white-disabled",
  borderColor: "grey-disabled",
  color: "grey-disabled",
  icon: { color: "grey-disabled" },
};

const getNewVariant = (color: string, hoverColor: string) => {
  return {
    control: {
      borderColor: "grey-light-green",
      _hover: {
        borderColor: "grey-dark",
      },
      _checked: {
        bg: "inherit",
        color: color,
        borderColor: color,
        _hover: {
          bg: "inherit",
          color: hoverColor,
          borderColor: hoverColor,
        },
      },
    },
  };
};

export default {
  baseStyle: {
    control: {
      bg: "white",
      border: "2px solid",
      borderRadius: "3px",
      transition: "color 0.2s ease-in, border-color 0.2s ease-in",
      _disabled: disabledCheckboxStyle,
      _hover: { _disabled: disabledCheckboxStyle },
      _checked: { _disabled: disabledCheckboxStyle },
    },
  },
  sizes: {
    default: {
      control: {
        w: "16px",
        h: "16px",
      },
    },
  },
  variants: {
    primary: getNewVariant("green", "green-dark"),
    secondary: getNewVariant("blue", "blue-dark"),
  },
  defaultProps: {
    size: "default",
    variant: "primary",
  },
};
