import React from "react";
import PropTypes from "prop-types";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Gate } from "./Modal";

class ModalToggler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: props.show };
  }

  show = () => {
    this.setState({ show: true });
  };

  hide = () => {
    this.setState({ show: false });
  };

  onSuccess = () => {
    if (this.props.onSuccess && this.props.onSuccess() === false) {
      return;
    }

    this.hide();
  };

  onCancel = (event) => {
    const { closeOnEscape } = this.props;
    if (!closeOnEscape && event.key === "Escape") {
      return;
    }

    if (this.props.onCancel && this.props.onCancel() === false) {
      return;
    }

    this.hide();
  };

  render() {
    const { children, modal, className, ...props } = this.props;
    const Content = modal;

    return (
      <>
        {children({ show: this.show })}

        {this.state.show && (
          <Gate isOpen onRequestClose={this.onCancel} className={className}>
            <Content
              {...props}
              onSuccess={this.onSuccess}
              onCancel={this.onCancel}
            />
          </Gate>
        )}
      </>
    );
  }
}

ModalToggler.defaultProps = {
  closeOnEscape: true,
};

ModalToggler.propTypes = {
  children: PropTypes.func.isRequired,
  modal: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  closeOnEscape: PropTypes.bool,
  show: PropTypes.bool,
  className: PropTypes.string,
};

export default ModalToggler;
