import React from "react";

import BaseModal, {
  Props as BaseModalProps,
} from "app/designSystem/components/Modal/BaseModal/BaseModal";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Footer, { Props as FooterProps } from "./Footer";

export default function ConfirmationModal({
  confirmText,
  goBackText,
  closeText,
  onConfirm,
  onClose,
  onBack,
  variant,
  isConfirming = false,
  canConfirm = true,
  ...props
}: BaseProps) {
  return (
    <BaseModal
      onClose={onClose}
      isCloseable={false}
      rightFooter={
        <Footer
          confirmText={confirmText}
          goBackText={goBackText}
          closeText={closeText}
          variant={variant}
          onConfirm={onConfirm}
          onClose={onClose}
          onBack={onBack}
          isConfirming={isConfirming}
          canConfirm={canConfirm}
        />
      }
      {...props}
    />
  );
}

export type BaseProps = BaseModalProps &
  FooterProps & {
    variant: "confirm" | "unsaved";
    body?: string | React.ReactNode;
    title: string;
    isConfirming?: boolean;
    canConfirm?: boolean;
  };
