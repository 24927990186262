import React from "react";
import BroadcastChannel from "broadcast-channel";

import reloadWithoutCompanyContext from "app/utils/reloadWithoutCompanyContext";

const CHANNEL_NAME = "COMPANY_CHANGE_NOTIFICATIONS";
const channel = new BroadcastChannel<string>(CHANNEL_NAME);

export const listenAndAlertUserOnCompanyChange = (companyId: string) => {
  // We need to require this after DOMContentLoaded, otherwise Modal tries to
  // reference document.body before it's there
  const confirm = require("components/Modules/Confirmation").default;

  channel.onmessage = (newCompanyId) => {
    if (companyId !== newCompanyId) {
      confirm({
        title: "Changed companies",
        message: (
          <>
            You’ve changed companies on another screen. Please refresh the page
            to continue working.
          </>
        ),
        actionTitle: "Refresh to continue",
        actionClassName: "cio-btn cio-btn--primary",
        options: { confirmOnly: true },
        action: () => {
          reloadWithoutCompanyContext();
          // We intentionally return a promise that never resolves, so that the
          // confirmation dialog won't disappear while the page reloads.
          return new Promise(() => {});
        },
      });
    }
  };
};

export const notifyOtherTabsOfLoadedCompany = (companyId: string) => {
  channel.postMessage(companyId);
};
