import React from "react";
/* eslint no-restricted-imports: ["warn", "@tanstack/react-query"] */
import { useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";

import * as indexes from "modules/indexes";
import { FormBody, changeIndex } from "components/Modules/IndexKeySelector";
import * as Modal from "components/Modules/Modal";
import RequestBox from "components/Modules/RequestBox";

export default function DefaultIndexChanger({ onCancel, onSuccess }: Props) {
  const history = useHistory();

  const onSelect = async (index: indexes.Index) => {
    changeIndex(index, { history });
    onSuccess();
  };

  const {
    error,
    isFetching,
    data: list,
  } = useQuery([indexes.listKey], async () => await indexes.list());

  return (
    <Modal.ModalElement style={{ overflow: "visible" }}>
      <Modal.Header title="Select Index" onClose={onCancel} />

      <RequestBox
        spinnerContainerProps={{ minHeight: 256 }}
        fetching={isFetching}
        error={error}
      >
        <FormBody
          isDefaultValue
          indexes={list || []}
          onCancel={onCancel}
          onSelect={onSelect}
        />
      </RequestBox>
    </Modal.ModalElement>
  );
}

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
};
