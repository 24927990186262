import React, { PropsWithChildren, ComponentProps } from "react";
import AnnounceKit from "announcekit-react";
import { Flex, Box, Text } from "@chakra-ui/react";

import URI from "utils/urijs";
import * as routes from "utils/routes";

import { Interact } from "components/Modules/Button";
import Link from "components/Modules/Link";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

import UserMenu from "./Navigation/UserMenu";

type Props = {
  user?: { firstName: string; email: string } | null;
  company?: { id: number; name: string } | null;
  companies: { id: number; name: string }[];
  displayLinks?: boolean;
  currentPath: string;
};

function Navigation({
  user,
  company,
  companies,
  displayLinks = true,
  currentPath,
}: Props) {
  // We do not show navigation links for non-authenticated users.
  if (!user || !company) {
    return null;
  }

  return (
    <>
      <Flex height="60px" alignItems="center">
        {displayLinks && (
          <Flex alignItems="center">
            <NavigationLink
              to="/dashboard"
              currentPath={currentPath}
              icon="dashboard-16"
              isActive={isActiveDashboardTab(currentPath)}
            >
              Dashboard
            </NavigationLink>

            <NavigationLink
              currentPath={currentPath}
              to="/dashboard/analytics/opportunities"
              icon="analytics-16"
              isActive={currentPath.startsWith("/dashboard/analytics")}
            >
              Analytics
            </NavigationLink>

            <NavigationLink
              currentPath={currentPath}
              to={routes.integrationIngestions().toString()}
              icon="integration-16"
              isActive={currentPath.startsWith("/dashboard/integration")}
            >
              Integration
            </NavigationLink>

            <NavigationLink
              target="_blank"
              currentPath={currentPath}
              to="http://docs.constructor.io"
              icon="docs-16"
            >
              API Docs
            </NavigationLink>

            <NavigationLink
              currentPath={currentPath}
              target="_blank"
              to="https://docs-beta.constructor.io/"
              icon="help-16"
            >
              Knowledgebase
            </NavigationLink>

            <Box width="22px" margin="0 12px 0 24px">
              <AnnounceKit
                widget="https://announcekit.app/widgets/v2/24lZny"
                widgetStyle={{
                  marginLeft: "-22px",
                  marginTop: "-13px",
                  transform: "scale(0.7)",
                }}
              >
                <ChakraIcon icon="notifications-16" />
              </AnnounceKit>
            </Box>

            <Interact
              to={URI("/dashboard/interact")
                .setSearch("back", URI().resource())
                .resource()}
            >
              <ChakraIcon icon="preview-16" />
              <Box marginLeft="5px">Interact</Box>
            </Interact>
          </Flex>
        )}

        <UserMenu user={user} currentCompany={company} companies={companies} />
      </Flex>
    </>
  );
}

const NavigationLink = ({
  to,
  children,
  target = "_self",
  icon,
  isActive,
}: PropsWithChildren<{
  to: string;
  currentPath: string;
  target?: string;
  icon: ComponentProps<typeof ChakraIcon>["icon"];
  isActive?: boolean;
}>) => {
  return (
    <Box
      padding="0 12px"
      color={isActive ? "success" : "grey-dark"}
      _hover={{
        color: "success",
      }}
    >
      <Link
        to={to}
        target={target}
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <Flex alignItems="center">
          <ChakraIcon icon={icon} />
          <Text
            margin="0"
            marginLeft="8px"
            textStyle="body1"
            fontWeight={isActive ? "bold" : undefined}
            textTransform="none"
          >
            {children}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

export const isActiveDashboardTab = (currentPath: string) =>
  currentPath === "/dashboard" ||
  currentPath === "/dashboard/" ||
  currentPath.startsWith("/dashboard/indexes") ||
  currentPath.startsWith("/dashboard/data_set_items") ||
  currentPath.startsWith("/dashboard/searchandizing") ||
  currentPath.startsWith("/dashboard/synonyms") ||
  currentPath.startsWith("/dashboard/redirect-rules") ||
  currentPath.startsWith("/dashboard/recommendations") ||
  currentPath.startsWith("/dashboard/collections") ||
  currentPath.startsWith("/dashboard/modify-facets") ||
  currentPath.startsWith("/dashboard/quizzes") ||
  currentPath.startsWith("/dashboard/attribute_enrichment");

export default Navigation;

export { default as Bar } from "./Navigation/Bar";
export { default as Menu } from "./Navigation/Menu";
export { default as AnalyticsMenu } from "./Navigation/AnalyticsMenu";
export { default as IntegrationMenu } from "./Navigation/IntegrationMenu";
