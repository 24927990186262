import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Text } from "@chakra-ui/react";

import * as Table from "components/Modules/Table";
import Icon from "components/Modules/Icon";

import { CompanyInfo, Product } from "../types";

import Platforms from "./SubscriptionRow/Platforms";
import TrialBadge from "./SubscriptionRow/TrialBadge";

export default function SubscriptionRow({
  productName,
  productKey,
  iconName,
  learnMore,
  companyInfo,
}: Product & { companyInfo: CompanyInfo }) {
  const isSubscribed = productKey in companyInfo && !!companyInfo[productKey];

  return (
    <Row>
      <Table.Td
        css={css`
          width: calc(100% * 2 / 3);
        `}
      >
        <ProductContainer>
          <Icon icon={iconName} />
          <Text textStyle="productNames" textColor="black" m={0}>
            {productName}
          </Text>
          {!isSubscribed && <TrialBadge />}
        </ProductContainer>
      </Table.Td>
      <Table.Td>
        {isSubscribed && (
          <Platforms productKey={productKey} companyInfo={companyInfo} />
        )}
      </Table.Td>
      {learnMore && (
        <LearnMoreTd>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={learnMore}
            css={css`
              font-size: 14px;
            `}
          >
            Learn More
          </a>
        </LearnMoreTd>
      )}
    </Row>
  );
}

const Row = styled(Table.Tr)`
  height: 64px;
`;

const ProductContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
`;

// shrinks the column to the width of the "Learn More" content
// https://stackoverflow.com/questions/11413365/how-to-make-table-cell-shrink-according-to-the-content
const LearnMoreTd = styled(Table.Td)`
  width: 1px;
  white-space: nowrap;
`;
