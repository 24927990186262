import React, { ComponentProps } from "react";
import {
  Popover as ChakraPopover,
  PopoverTrigger as ChakraPopoverTrigger,
  PopoverContent as ChakraPopoverContent,
  PopoverHeader as ChakraPopoverHeader,
  PopoverBody as ChakraPopoverBody,
  PopoverFooter as ChakraPopoverFooter,
  PopoverProps as ChakraPopoverProps,
  PlacementWithLogical,
  Link,
  SystemProps,
} from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { ChakraIcon } from "../ChakraIcon/ChakraIcon";

export type PopoverProps = {
  /**
   * Header content for `popover`.
   */
  popoverHeader?: string;

  /**
   * Body content for `popover`.
   */
  popoverBody?: React.ReactNode | string;

  /**
   * Footer content for `popover`. Footer is mainly used for connecting to guides and walkthroughs.
   */
  popoverLinkText?: string;

  /**
   * Link for Footer content, users who click on the `popover` footer link would be navigated there
   */
  popoverLinkHref?: string;

  /**
   * Download attribute for the Footer content link
   */
  popoverLinkDownload?: ComponentProps<typeof Link>["download"];

  /**
   * Interaction that triggers the `popover`. `hover` means the `popover` will open when you hover the trigger. `click` will open the `popover` when you mouseclick, press Enter or Space on the trigger.
   */
  trigger?: "click" | "hover";

  /**
   * Placement of the `popover` relative to its reference
   */
  placement?: PlacementWithLogical;

  /**
   * Delay (in ms) before showing the `popover`
   * @default 300ms
   */
  openDelay?: number;

  /**
   * Delay (in ms) before hiding the `popover`
   * @default 300ms
   */
  closeDelay?: number;
  /**
   * Callback to run when the `popover` shows
   */
  onOpen?(): void;

  /**
   * Callback to run when the `popover` hides
   */
  onClose?(): void;

  /**
   * The main and cross-axis offset to displace `popover` from its reference element.
   */
  offset?: [number, number];

  /**
   * If `true`, the `popover` will be shown (in controlled mode).
   * Note: This does not trigger onOpen and onClose.
   */
  isOpen?: boolean;

  /**
   * If `true`, the `popover` will be initially shown
   * @default false
   */
  defaultIsOpen?: boolean;

  /**
   * If `true`, `popover` will close when clicking outside
   */
  closeOnBlur?: boolean;

  /**
   * If `true`, `popover` will close when you hit `esc` key
   */
  closeOnEsc?: boolean;

  /**
   * Set custom width of `popover` content.
   */
  width?: SystemProps["width"];

  /**
   * Custom modifiers for the underlying Popper instance. See Chakra's and
   * Popper's documentation for how to use them.
   */
  modifiers?: ChakraPopoverProps["modifiers"];

  /**
   * If `true`, focus will be transferred to the first interactive element
   * when the popover opens
   */
  autoFocus?: boolean;
};

export const Popover = ({
  children,
  trigger = "hover",
  placement = "top-start",
  openDelay = 300,
  closeDelay = 300,
  offset,
  isOpen,
  defaultIsOpen = false,
  closeOnBlur = true,
  closeOnEsc = true,
  popoverHeader,
  popoverBody,
  popoverLinkText = "Learn more",
  popoverLinkHref,
  popoverLinkDownload,
  onClose,
  onOpen,
  width = "186px",
  modifiers,
  autoFocus = true,
}: React.PropsWithChildren<PopoverProps>) => {
  return (
    <ChakraPopover
      trigger={trigger}
      placement={placement}
      openDelay={openDelay}
      offset={offset}
      onClose={onClose}
      onOpen={onOpen}
      isOpen={isOpen}
      defaultIsOpen={defaultIsOpen}
      closeOnBlur={closeOnBlur}
      closeOnEsc={closeOnEsc}
      closeDelay={closeDelay}
      variant="designSystem"
      modifiers={modifiers}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
    >
      <ChakraPopoverTrigger>{children}</ChakraPopoverTrigger>
      <ChakraPopoverContent width={width}>
        {popoverHeader && (
          <ChakraPopoverHeader>{popoverHeader}</ChakraPopoverHeader>
        )}
        <ChakraPopoverBody>{popoverBody}</ChakraPopoverBody>
        {popoverLinkText && popoverLinkHref && (
          <ChakraPopoverFooter>
            <Link
              href={popoverLinkHref}
              textStyle="body3"
              color="black"
              target="_blank"
              display="inline-flex"
              alignItems="center"
              download={popoverLinkDownload}
            >
              {popoverLinkText}
              <ChakraIcon icon="links-open-in-raw-16" ml="4px" />
            </Link>
          </ChakraPopoverFooter>
        )}
      </ChakraPopoverContent>
    </ChakraPopover>
  );
};
