import { ResponsiveValue, SystemProps } from "@chakra-ui/react";

export const Table = {
  Table: {},
};

export const THeadStyles = ({
  position = "sticky",
  zIndex = "inherit",
}: {
  position?: SystemProps["position"];
  zIndex?: SystemProps["zIndex"];
}) => {
  return {
    bg: "grey-verylight-green",
    top: "0",
    position,
    zIndex,
  };
};

export const TBodyStyles = ({
  position,
  height,
  width,
}: {
  position?: ResponsiveValue<
    "static" | "absolute" | "fixed" | "relative" | "sticky"
  >;
  height?: string;
  width?: string;
}) => {
  return {
    bg: "white",
    position,
    height,
    width,
  };
};

export const ThStyles = ({
  width,
  padding = "14px 24px",
}: {
  width?: string;
  padding?: string;
}) => {
  return {
    bg: "grey-verylight-green",
    color: "grey-dark",
    fontWeight: "normal",
    fontFamily: "body",
    letterSpacing: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    padding,
    width,
  };
};

export const TdStyles = ({
  width,
  height,
}: {
  width?: string;
  height?: string;
}) => {
  return {
    padding: "24px",
    width,
    height,
  };
};

export const TrStyles = ({
  isSelected,
  position,
  top,
  left,
  height,
  width,
  transform,
  cursor,
}: {
  isSelected: boolean;
  position?: ResponsiveValue<
    "static" | "absolute" | "fixed" | "relative" | "sticky"
  >;
  top?: string;
  left?: string;
  height?: string;
  width?: string;
  transform?: string;
  cursor?: string;
}) => {
  return {
    bg: isSelected ? "green-light" : "white",
    position,
    top,
    left,
    height,
    width,
    transform,
    cursor,
  };
};

export const TableHeaderTextStyles = {
  textStyle: "caption",
  color: "grey-dark",
  m: "0 !important",
};
