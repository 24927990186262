// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as types from "./MultiSelect.types";

export const LABEL_MAX_CHAR = 26;

const multiSelectElementSizes = {
  labelTagMargin: 4,
  editButtonWidth: 42,
  hiddenItemsLabelWidth: 50,
  maxWidthToShowVisibleItems: 150,
};

export const filterItems = (
  items: types.MultiSelectItem[],
  keyword: types.keyword
) =>
  items.filter((item) => {
    return (
      item.label.toLocaleLowerCase().includes(keyword.toLowerCase()) ||
      item.value.toLocaleLowerCase().includes(keyword.toLowerCase())
    );
  });

export const toggleSelectedItems = (
  selectedItemId: types.MultiSelectItemId,
  items: types.MultiSelectItem[]
): types.MultiSelectItem[] => {
  return items.map((item) =>
    item.id === selectedItemId ? { ...item, selected: !item.selected } : item
  );
};

/**
 * Function that selects elements from a given array(items) which are matching
 * a list of ids passed in selectedItemIds and setting their selected value to isSelected.
 * A list of ids is required as multiSelect component is able to filter the options by search query
 * so the items that are going to be changed are limited.
 * @param selectedItemIds A list of entities ids which that have to be set to isSelected value
 * @param items An array of items to iterate over when searching for elements matching selectedItemIds
 * @param isSelected
 * @returns An array of updated items
 */
export const selectAll = (
  selectedItemIds: types.MultiSelectItemId[],
  items: types.MultiSelectItem[],
  isSelected: boolean
): types.MultiSelectItem[] => {
  return items.map((item) =>
    selectedItemIds.includes(item.id)
      ? {
          ...item,
          selected: isSelected,
        }
      : item
  );
};

export const formatLabel = (label: string): types.FormatLabelResult => {
  const isShortened = label.length > LABEL_MAX_CHAR;

  return {
    label: isShortened ? `${label.slice(0, LABEL_MAX_CHAR - 3)}...` : label,
    isShortened,
  };
};

/**
 * Calculates the width of the multi select masked box element, removing padding and borders.
 * @param maskedBox The masked box element.
 * @returns The real width.
 */
function getMaskedBoxWidth(maskedBox: HTMLDivElement | null): number {
  if (!maskedBox) {
    return 0;
  }

  const styles = getComputedStyle(maskedBox);
  const widthWithPadding = maskedBox.offsetWidth;

  const paddingLeft = parseFloat(styles.paddingLeft);
  const paddingRight = parseFloat(styles.paddingRight);

  return widthWithPadding - paddingLeft - paddingRight;
}

/**
 * Given the current masked box element and a list of selected items ordered by their length,
 * calculates which items can fit into the masked box and returns a list of visible items.
 *
 * @param maskedBox The masked box element.
 * @param orderedItems The selected items, ordered by length.
 * @returns An array of visible items that fit the masked box width.
 */
export function calculateVisibleLabels({
  isShowingEditLink,
  orderedItems,
  maskedBox,
}: types.CalculateVisibleLabelsArgs): types.MaskedSelectBoxItem[] {
  const visibleItems: types.MaskedSelectBoxItem[] = [];

  const maskedBoxWidth = getMaskedBoxWidth(maskedBox);

  const allLabelTags =
    maskedBox?.querySelectorAll("span[role='listitem']") || [];

  const allSeparatorTags =
    maskedBox?.querySelectorAll("span:not([role='listitem'])") || [];

  const separatorWidth = Array.from(allSeparatorTags).reduce((acc, tag) => {
    const element = tag as HTMLSpanElement;

    return acc + element.offsetWidth;
  }, 0);

  const editButtonWidth = isShowingEditLink
    ? multiSelectElementSizes.editButtonWidth
    : 0;

  let totalWidth = editButtonWidth + separatorWidth;
  let usedWidth = 0;

  /**
   * Here, we iterate over all ordered items and sum the total used width to display each one.
   * If there's enough width in the input, they will be added to the visible items array.
   */
  Array.from(allLabelTags).forEach((label) => {
    const element = label as HTMLSpanElement;

    const item = orderedItems.find((item) => {
      const { label } = formatLabel(item.label);
      return label === element.innerText;
    });

    totalWidth += element.offsetWidth + multiSelectElementSizes.labelTagMargin;

    if (item && maskedBoxWidth > totalWidth) {
      usedWidth = totalWidth;
      visibleItems.push(item);
    }
  });

  const widthLeft = maskedBoxWidth - usedWidth;

  const hasSpaceForVisibleItems =
    maskedBoxWidth >= multiSelectElementSizes.maxWidthToShowVisibleItems;
  const hasWidthLeft =
    widthLeft >= multiSelectElementSizes.hiddenItemsLabelWidth;
  const hasHiddenItems = visibleItems.length !== orderedItems.length;
  const hasVisibleItemsLeft = visibleItems.length > 1;

  const shouldPopLastItem =
    hasHiddenItems &&
    !hasWidthLeft &&
    (hasVisibleItemsLeft || !hasSpaceForVisibleItems);

  /**
   * If we will render hidden items, we need to render the hidden items label.
   * If we don't have enough width left to render it with all items, we remove the
   * last item so that it won't overflow the label.
   */
  if (shouldPopLastItem) {
    visibleItems.pop();
  }

  return visibleItems;
}
