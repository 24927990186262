import Api from "services/Api";

import * as types from "./types";

export function get(): types.CurrentUser | null {
  const element = getElement();

  const currentUserData: types.CurrentUserData =
    element?.content && JSON.parse(element.content);

  return currentUserData && types.CurrentUser.fromData(currentUserData);
}

export function require(): types.CurrentUser {
  const user = get();

  if (!user) {
    throw new Error("Current user is not found in DOM but expected");
  }

  return user;
}

export async function fetchCurrentUser(): Promise<types.CurrentUser> {
  const result = await Api.get<types.CurrentUserData>(["api", "current_user"]);

  return types.CurrentUser.fromData(result);
}

export const fetchCurrentUserKey =
  "modules/currentUser/actions/fetchCurrentUser";

export async function can(action: string, subject: string): Promise<boolean> {
  return await Api.get<boolean>([
    "api",
    "current_user",
    "can",
    action,
    subject,
  ]);
}

export async function update(
  properties: types.CurrentUserUpdate
): Promise<types.CurrentUser> {
  const data = types.CurrentUserUpdate.toData(properties);

  const result = await Api.patch<types.CurrentUserData>(
    ["api", "current_user"],
    {},
    data
  );

  const element = getElement();

  if (element) {
    element.content = JSON.stringify({
      ...types.CurrentUser.toData(get() || {}),
      ...result,
    });
  }

  return types.CurrentUser.fromData(result);
}

function getElement(): HTMLMetaElement | null {
  return document.querySelector<HTMLMetaElement>('meta[name="current_user"]');
}
