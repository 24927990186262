export default {
  baseStyle: {
    track: {
      borderWidth: "1px",
      borderStyle: "solid",
      bg: "transparent",
    },
  },
  variants: {
    light: {
      filledTrack: {
        bg: "white",
      },
      track: {
        borderColor: "white",
      },
    },
    dark: {
      filledTrack: {
        bg: "grey-dark",
      },
      track: {
        borderColor: "grey-dark",
      },
    },
  },
  defaultProps: {
    size: "md",
    variant: "dark",
  },
};
