import React, { Suspense } from "react";

import BaseRoute from "components/Modules/Route";

export default function Route({
  loading,
  ...props
}: { loading: React.ReactNode } & React.ComponentProps<typeof BaseRoute>) {
  return (
    <Suspense fallback={loading}>
      <BaseRoute {...props} />
    </Suspense>
  );
}
