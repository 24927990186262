import React from "react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as UI from "./Button/shared/UI";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as utils from "./Button/shared/utils";

/**
 * @deprecated Use the design system `Button` component instead.
 */
const Button = React.forwardRef(function ButtonComponent(
  { loading, children, ...props },
  ref
) {
  return (
    <UI.ButtonElement disabled={loading} {...props} ref={ref}>
      <UI.Content loading={loading}>{children}</UI.Content>
      {loading ? <UI.SpinnerElement /> : null}
    </UI.ButtonElement>
  );
});

Button.propTypes = utils.propTypes;
Button.defaultProps = utils.defaultProps;
Button.displayName = "Button";

export default Button;

export { Content, ButtonElement } from "./Button/shared/UI";
export { default as Link } from "./Button/Link";
export { default as Action } from "./Button/Action";
export { default as Interact } from "./Button/Interact";
