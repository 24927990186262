import { BoxProps, TagProps, TextProps, ButtonProps } from "@chakra-ui/react";

import { IconType } from "app/designSystem/components/ChakraIcon/ChakraIcon";

export const LABEL_TAG_WIDTH = 96;
export const CONTAINER_HEIGHT = 242;

export const checkboxIcon = (selected: boolean) => {
  const icon = selected ? "checkbox-selected-16" : "checkbox-16";
  const color = selected ? "green" : "grey-light-green";
  return {
    icon,
    color,
    pt: "2px",
    mr: "8px",
    boxSize: "16px",
  } as const;
};

export const closeIcon: IconType = {
  color: "grey-dark",
  cursor: "pointer",
  icon: "close-16",
  ml: "2px",
  pt: "2px",
};

export const listItemText: Partial<TextProps> = {
  m: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const listItemTextInfo: Partial<TextProps> = {
  as: "span",
  color: "grey",
  fontStyle: "italic",
  overflow: "hidden",
  pointerEvents: "none",
  pr: "1px",
  textOverflow: "ellipsis",
  textStyle: "body2",
  whiteSpace: "nowrap",
};

export const maskedBoxContainer: Partial<BoxProps> = {
  background: "white",
  border: "none",
  borderRadius: "var(--cio-radii-md)",
  bottom: "2px",
  left: "2px",
  p: "6px",
  position: "absolute",
  textAlign: "left",
  top: "2px",
  w: "calc(100% - 36px)",
  whiteSpace: "nowrap",
  overflow: "hidden",
};

export const labelTag: Partial<TagProps> = {
  bgColor: "white",
  borderColor: "grey-light-green",
  borderStyle: "solid",
  borderWidth: "2px",
  color: "grey-dark",
  fontSize: "14px",
  lineHeight: "14px",
  mr: "4px",
  overflow: "hidden",
  pl: "12px",
  pr: "6px",
  py: "4px",
  whiteSpace: "nowrap",
};

export const labelTagAlternative: Partial<TagProps> = {
  background: "white",
  color: "grey-dark",
  lineHeight: "14px",
  pl: "3px",
  pr: 0,
  py: "7px",
};

export const wordSeparatorLabel = {
  ...labelTagAlternative,
  flexGrow: 0,
  flexShrink: 0,
  mr: "4px",
  pl: 0,
  minWidth: 0,
  fontWeight: "bold",
};

export const containerLoading = (height: number) => ({
  alignItems: "center",
  bgColor: "white",
  display: "flex",
  flex: 1,
  height: `${height}px`,
  justifyContent: "center",
});

export const containerEmptyStateText: Partial<TextProps> = {
  color: "grey",
  fontStyle: "italic",
  h: "100%",
  pl: "20px",
  textAlign: "left",
};

export const buttonAction: Partial<ButtonProps> = {
  borderBottomRadius: "6px",
  borderTopRadius: 0,
  fontSize: "12px",
  textTransform: "uppercase",
  variant: "primary",
  h: "44px",
  w: "100%",
  _disabled: {
    opacity: 1,
    backgroundColor: "grey-disabled",
  },
};

export const listItemEmptyContainer = (height: number) => ({
  borderRadius: 0,
  overflow: "inherit",
  h: `${height}px`,
  w: "100%",
});
