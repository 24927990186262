import styled from "@emotion/styled";

import theme from "components/theme";

const Container = styled.div`
  background-color: ${theme.colors.backgroundDefault};
  border: 1px solid #e3e3e3;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  padding: 40px 30px;
  overflow: auto;
`;

export default Container;
