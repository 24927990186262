import Api from "services/Api";

import useRestRequest, * as rest from "./useRequest";

const apiRequest = (method, url, apiOptions) => {
  const { query, data, ...options } = apiOptions;

  return new Promise((resolve, reject) =>
    Api.request(method, url, query, data, options)
      .then((result) => resolve([result, { status: null }]))
      .catch(reject)
  );
};

export default function useRequest(
  method,
  url,
  { javascriptizeData = true, ...options } = {}
) {
  return useRestRequest(method, url, {
    javascriptizeData,
    request: apiRequest,
    ...options,
  });
}

export function useGet(url, { javascriptizeData = true, ...options } = {}) {
  return rest.useGet(url, {
    javascriptizeData,
    request: apiRequest,
    ...options,
  });
}

export function usePost(url, { javascriptizeData = true, ...options } = {}) {
  return rest.usePost(url, {
    javascriptizeData,
    request: apiRequest,
    ...options,
  });
}

export function usePut(url, { javascriptizeData = true, ...options } = {}) {
  return rest.usePut(url, {
    javascriptizeData,
    request: apiRequest,
    ...options,
  });
}

export function usePatch(url, { javascriptizeData = true, ...options } = {}) {
  return rest.usePatch(url, {
    javascriptizeData,
    request: apiRequest,
    ...options,
  });
}

export function useDelete(url, { javascriptizeData = true, ...options } = {}) {
  return rest.useDelete(url, {
    javascriptizeData,
    request: apiRequest,
    ...options,
  });
}
