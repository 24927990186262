import * as actions from "modules/authentication/actions";

import getQueryHook from "app/hooks/getQueryHook";

const useTokenValidate = getQueryHook(
  actions.tokenValidate.key,
  () => actions.tokenValidate,
  {
    refetchOnMount: true,
  }
);

export default useTokenValidate;
