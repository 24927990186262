import AuthApi from "services/AuthApi";

import { GetUserResponse, GetUserResponseData } from "./get";

export default async function update(
  userId: string,
  payload: UpdateUserPayload
): Promise<UpdateUserResponse> {
  const result = await AuthApi.put<UpdateUserResponse>(
    ["v1", "users", userId],
    {},
    {
      first_name: payload.firstName,
      last_name: payload.lastName,
    }
  );

  return result;
}

export type UpdateUserPayload = { firstName: string; lastName: string };

export type UpdateUserResponseData = Omit<
  GetUserResponseData,
  "last_login_at_in_company"
>;

export type UpdateUserResponse = Omit<GetUserResponse, "lastLoginsAtInCompany">;
