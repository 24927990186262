import moment from "modules/moment";

export function convertToApiFormat(date: Date): string {
  // Dates in the API don't include subseconds nor the timezone indicator, and
  // they're also always UTC, so we replicate that format here.
  return date.toISOString().split(".")[0];
}

export function convertFromCubeAnalyticsFormat(date: string): Date {
  // Dates coming from the Analytics Service are UTC and in ISO 8601 format, but
  // they don't currently include the timezone indicator.
  const utcDate = date + "Z";
  return convertFromAnalyticsFormat(utcDate);
}

export function convertFromApiFormat(date: string): Date {
  // Dates coming from the API are assumed to be UTC and in ISO 8601 format, but
  // they don't currently include the timezone indicator.
  return moment.utc(date, "YYYY-MM-DDTHH:mm:ss").toDate();
}

export function convertFromAnalyticsFormat(date: string): Date {
  return new Date(date);
}

/**
 * Takes a date range and calculates an equally long range ending one second
 * before the provided start date
 */
export function getPreviousDateRange({
  startDate,
  endDate,
}: DateRange): DateRange {
  const distance = moment(endDate).diff(moment(startDate), "s");

  const previousEndDate = moment(startDate).subtract(1, "second").toDate();
  const previousStartDate = moment(previousEndDate)
    .subtract(distance, "s")
    .toDate();

  return { startDate: previousStartDate, endDate: previousEndDate };
}

type DateRange = {
  startDate: Date;
  endDate: Date;
};
