import React, { useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

import Footer from "components/Modules/Footer";
import Flash from "components/Modules/Flash";
import { setCurrentIndex as setIndexInSentry } from "utils/sentry";
import { useApi } from "app/hooks/useCurrentIndex";
import useCurrentUser from "app/hooks/useCurrentUser";

import { HeaderProps } from "./shared/actionGroupProps";
import usePageLayout from "./shared/usePageLayout";
import useSidebar from "./shared/useSidebar";
import Header from "./PageLayout/Header";
import NavigationSidebar from "./PageLayout/NavigationSidebar";

const ANIMATION_DURATION = 0.3;

type Props = {
  children: React.ReactNode;
  /**
   * Is true if the component is used as part of a loading state.
   * eg: Used in layout for loader in Suspense
   */
  isLoading?: boolean;
} & HeaderProps;

export default function PageLayout({
  children,
  isLoading,
  breadcrumbs,
  ...headerProps
}: Props) {
  const { currentPath, currentPathData, animationHelper } = usePageLayout();
  const { isDesktopView, isSidebarOpen, onSidebarToggle, onSidebarClose } =
    useSidebar();

  const [animated, setAnimated] = animationHelper;

  const [index, { fetching }] = useApi();

  useEffect(() => {
    setIndexInSentry(index?.indexKey || null);
    return () => setIndexInSentry(null);
  }, [index?.indexKey]);

  const { email: userEmail } = useCurrentUser();

  const sidebar = (
    <NavigationSidebar
      currentPath={currentPath}
      isSidebarExpanded={isSidebarOpen}
      onToggleSidebar={onSidebarToggle}
      currentAccordionIndex={currentPathData.index}
      index={index}
      {...headerProps}
    />
  );

  const breadcrumbToRender = breadcrumbs || currentPathData.breadcrumbs;

  return (
    <AnimatePresence initial={!(animated || isLoading)}>
      <Grid
        height="100%"
        className="ia_layout_container"
        gridTemplateRows="60px calc(100% - 60px)"
        gridTemplateColumns={isSidebarOpen ? "236px auto" : "76px auto"}
        transition="grid-template-columns 300ms ease-in-out"
        gridTemplateAreas={{
          sm: `"header header" "content content"`,
          // If there is no user email(unauthorized) then the sidebar should be hidden
          // hence no space for it shall be reserved.
          md: userEmail
            ? `"header header" "sidebar content"`
            : `"header header" "content content"`,
        }}
      >
        {!isDesktopView && userEmail && (
          <Drawer
            placement="left"
            onClose={onSidebarClose}
            isOpen={isSidebarOpen}
          >
            <DrawerOverlay
              background="rgba(0, 0, 0, 0.08)"
              height="unset"
              top="60px"
              bottom={0}
            />
            <DrawerContent mt="60px" maxW="236px">
              <DrawerBody boxShadow="feedback" p="0">
                {sidebar}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}

        <motion.div
          style={{ gridArea: "header" }}
          initial={{ y: -60 }}
          animate={{ y: !animated && isLoading ? -60 : 0 }}
          transition={{ duration: ANIMATION_DURATION, ease: "easeInOut" }}
        >
          <Header
            isOpen={isSidebarOpen}
            onToggle={onSidebarToggle}
            isSidebarOpen={isSidebarOpen}
            currentPath={currentPath}
            index={index}
            isIndexFetching={fetching}
            breadcrumbs={breadcrumbToRender}
            {...headerProps}
          />
        </motion.div>

        {userEmail && (
          <motion.div
            style={{
              height: "100%",
              display: isDesktopView ? "block" : "none",
              gridArea: "sidebar",
            }}
            initial={{ x: -250 }}
            animate={{ x: !animated && isLoading ? -250 : 0 }}
            transition={{
              duration: ANIMATION_DURATION,
              ease: "easeInOut",
              delay: ANIMATION_DURATION,
            }}
          >
            {sidebar}
          </motion.div>
        )}

        <motion.div
          style={{
            width: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gridArea: "content",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 2 * ANIMATION_DURATION,
            ease: "easeInOut",
            delay: (isDesktopView ? 1 : 0.5) * ANIMATION_DURATION,
          }}
          onAnimationComplete={() => setAnimated(true)}
        >
          <Flex
            direction="column"
            gap="16px"
            mx="auto"
            minW="1070px"
            maxW="1240px"
            w="full"
            p={{ sm: "24px 24px 0", lg: "32px 32px 0" }}
          >
            {!isLoading && <Flash />}
            <Grid gridTemplateColumns="100%">{children}</Grid>
          </Flex>
          <Box mx="auto" minW="1070px" maxW="1240px" w="full">
            <Footer />
          </Box>
        </motion.div>
      </Grid>
    </AnimatePresence>
  );
}
