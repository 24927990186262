import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import URI from "utils/urijs";
import moment from "modules/moment";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

import useAnalyticsReferenceDate from "./useAnalyticsReferenceDate";

export default function useDates({
  keepQueryParameters = [],
  defaultRangeInDays,
}: Args = {}): [DateRange, SetDates] {
  const { moment: referenceMomentInclusive } = useAnalyticsReferenceDate();
  // when the analytics reference date is set to some day, we want the period to be inclusive of that day
  const referenceMoment = referenceMomentInclusive?.clone().add(1, "day");

  const referenceDateAllowed = !isFlagEnabled(FeatureFlags.EnableDemoStubs);

  // current date should not change over midnight
  const [currentDate] = useState(
    (referenceDateAllowed && referenceMoment) || moment.utc()
  );

  const history = useHistory();
  const location = useLocation();

  const uri = useMemo<uri.URI>(
    () => URI(location.pathname + location.search),
    [location.pathname, location.search]
  );

  // safely pass as dependency to effect
  const keepQueryParametersString = keepQueryParameters.join(",");

  const startDate = parseDate(
    uri.search(true).start_date,
    currentDate.clone().subtract(defaultRangeInDays ?? DEFAULT_RANGE, "days")
  ).set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

  const endDate = parseDate(
    uri.search(true).end_date,
    currentDate.clone().subtract(1, "days")
  ).set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 0 });

  const setDates: SetDates = useCallback(
    ({ startDate, endDate }) => {
      // rely on inner URI rather than dependency in order to avoid excessive re-renders
      const uri = URI(history.location.pathname + history.location.search);
      const params = uri.search(true);
      const result = uri.search("");

      ["index_key", ...keepQueryParametersString.split(",")].forEach(
        (parameter) => {
          if (Object.prototype.hasOwnProperty.call(params, parameter)) {
            result.setSearch(parameter, params[parameter]);
          }
        }
      );

      history.push(
        result
          .setSearch("start_date", startDate.format("YYYY-MM-DD"))
          .setSearch("end_date", endDate.format("YYYY-MM-DD"))
          .toString()
      );
    },
    [history, keepQueryParametersString]
  );

  return [{ startDate, endDate }, setDates];
}

type Args = {
  defaultRangeInDays?: number;
  keepQueryParameters?: string[];
};

export type DateRange = {
  startDate: moment.Moment;
  endDate: moment.Moment;
};

export type SetDates = (dates: DateRange) => void;

function parseDate(value: string, defaultValue: moment.Moment) {
  const result = value && moment.utc(value);
  if (!result || !result.isValid()) {
    return defaultValue;
  }

  return result;
}

// default range in days
const DEFAULT_RANGE = 7;
