import React, { ReactNode } from "react";
import { ChakraProvider } from "@chakra-ui/react";

import theme from "../designSystem/theme";

export default function ThemeProvider({ children, resetCSS = false }: Props) {
  return (
    <ChakraProvider theme={theme} resetCSS={resetCSS}>
      {children}
    </ChakraProvider>
  );
}

type Props = {
  resetCSS?: boolean;
  children: ReactNode;
};
