import React from "react";

import { Configuration } from "app/App";

import Navigation from "app/components/Navigation";

export default function WithConfiguration(
  props: React.ComponentProps<typeof Navigation>
) {
  return (
    <Configuration>
      <Navigation {...props} />
    </Configuration>
  );
}
