import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { list, listKey, Index } from "modules/indexes";

type Args = {
  originalIndexes?: Index[] | undefined;
  onlyActive?: boolean;
  options?: Omit<UseQueryOptions<Index[], Error>, "queryKey" | "queryFn">;
};

export default function useListIndexes({
  originalIndexes,
  onlyActive = false,
  options,
}: Args = {}) {
  return useQuery<Index[], Error>(
    [listKey, originalIndexes, onlyActive],
    async () => {
      if (originalIndexes) {
        return originalIndexes;
      }

      const indexes = await list();

      if (onlyActive) {
        return indexes.filter((index) => index.active);
      }

      return indexes;
    },
    options
  );
}
