import React, { useEffect, useState, useMemo } from "react";

import { useToast } from "app/designSystem/components";
import Api from "services/Api";
import moment from "modules/moment";

import Notification from "./NewVersionNotification/Notification";

export default function NewVersionNotification() {
  const currentVersion = useMemo(() => {
    const metaElement = document.querySelector<HTMLMetaElement>(
      'meta[name="frontend_version"]'
    );

    if (!metaElement) {
      return null;
    }

    return metaElement.content;
  }, []);

  const [doItLater, setDoItLater] = useState(false);

  const toast = ({ closeToast }: { closeToast: () => void }) => (
    <Notification
      onDoItLater={() => {
        setDoItLater(true);
        closeToast();
      }}
    />
  );

  const showToast = useToast();

  const showNewVersionToast = () => {
    showToast.custom(toast, {
      autoDismiss: false,
      customToastId: "new-version-toast",
    });
  };

  // Check new version on each response received from server
  useEffect(() => {
    const onRequestCompleted = ({ headers }: { headers: Headers }) => {
      const newVersion = headers.get("X-Frontend-Version");

      if (
        currentVersion &&
        newVersion &&
        isVersionMoreRecent(currentVersion, newVersion)
      ) {
        if (!doItLater) {
          showNewVersionToast();
        }
      }
    };

    Api.events.on("request-completed", onRequestCompleted);

    return () => {
      Api.events.removeListener("request-completed", onRequestCompleted);
    };
  });

  return null;
}

function isVersionMoreRecent(
  currentVersion: string,
  newVersion: string
): boolean {
  const currentDateParsed = moment(currentVersion, moment.ISO_8601, true);
  const newDateParsed = moment(newVersion, moment.ISO_8601, true);

  if (!currentDateParsed.isValid() || !newDateParsed.isValid()) {
    return currentVersion !== newVersion;
  }

  return currentDateParsed.isBefore(newDateParsed);
}
