import React, {
  PropsWithChildren,
  useRef,
  useEffect,
  KeyboardEvent,
} from "react";
import { isEmpty } from "remeda";
import {
  Box,
  Flex,
  Text,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Select as ChakraSelect,
  LayoutProps,
  FlexProps,
  VStack,
  useControllableState,
  useOutsideClick,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectProps as ChakraSelectProps,
  Portal,
} from "@chakra-ui/react";

import * as TippyTooltip from "components/Modules/TippyTooltip";
import { CHAKRA_ICONS_16_TYPE } from "app/designSystem/components/ChakraIcon/iconTypes";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

import colors from "app/designSystem/theme/foundations/colors";

import {
  selectInfoStyle,
  selectEditTextStyle,
  selectStackStyle,
  selectLabelStyle,
  selectStyle,
  selectHelperStyle,
  // @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
  // eslint-disable-next-line local-rules/enforce-fractal-pattern,
} from "./Select.styles";

type SelectProps = Pick<LayoutProps, "w" | "width"> &
  Pick<FlexProps, "shrink" | "flexShrink" | "basis" | "flexBasis"> &
  Pick<ChakraSelectProps, "onBlur" | "name"> & {
    placeholder?: string;
    label?: string;
    tooltipText?: React.ReactNode;
    helperText?: React.ReactNode;
    infoText?: React.ReactNode;
    errorMessage?: React.ReactNode;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isShowingInfoText?: boolean;
    isRounded?: boolean;
    editText?: string;
    isShowingEditLink?: boolean;
    icon?: CHAKRA_ICONS_16_TYPE;
    iconColor?: keyof typeof colors;
    // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    maskedSelectBox?: React.ReactNode;
    isMultiSelect?: boolean;
    onClickEditLink?: (event: React.MouseEvent<HTMLElement>) => void;
    /**
     * Allows setting the open state of this component in controlled mode - if
     * true, the select will be open, otherwise closed. Leave it undefined for
     * uncontrolled mode.
     */
    isOpen?: boolean;
    /**
     * When in controlled mode, useful to know when to open/close the select.
     */
    onChangeOpen?: (newValue: boolean) => void;
    /**
     * Set to true if you want to render the dropdown inside a portal. Useful when
     * Select is used inside overflow hidden components for the actual dropdown component
     * to render over the component and not get cut off.
     */
    renderInPortal?: boolean;
    ariaLabel?: string;
  };

export const Select = ({
  children,
  name,
  isDisabled = false,
  isInvalid,
  placeholder,
  label,
  tooltipText,
  helperText,
  errorMessage,
  isShowingInfoText = false,
  infoText = "info",
  editText = "edit",
  isShowingEditLink = false,
  onClickEditLink,
  icon,
  iconColor,
  width = "100%",
  w,
  value,
  maskedSelectBox,
  isMultiSelect = false,
  shrink,
  flexShrink,
  basis,
  flexBasis,
  isOpen,
  isRounded = false,
  renderInPortal = false,
  onChangeOpen,
  onBlur,
  ariaLabel,
}: // cognitive-complexity rule is temporarily disabled for this function, please refactor this function
// whenever possible.
// eslint-disable-next-line sonarjs/cognitive-complexity
PropsWithChildren<SelectProps>) => {
  const selectWidth = width || w;
  const [isShowingOptions, setIsShowingOptions] = useControllableState({
    value: isOpen,
    onChange: onChangeOpen,
    defaultValue: false,
  });
  const isSelectInvalid: boolean = isInvalid || !!errorMessage;
  const selectValue = Array.isArray(value) ? value.join(", ") : value;
  const selectPlaceholder =
    !value || (Array.isArray(value) && value.length === 0)
      ? placeholder
      : selectValue;

  const setIsShowingOptionsRef = useRef(setIsShowingOptions);
  const selectItemsRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  setIsShowingOptionsRef.current = setIsShowingOptions;

  useOutsideClick({
    ref: selectRef,
    handler: (event: Event) => {
      const targetNode = event.target as HTMLElement;

      const isOutsideClick =
        selectItemsRef.current && !selectItemsRef.current.contains(targetNode);

      const targetIsMultiSelectActionButton = targetNode?.classList.contains(
        "multi-select-action-button"
      );

      const shouldClose =
        isShowingOptions && (isOutsideClick || targetIsMultiSelectActionButton);

      if (shouldClose) {
        setIsShowingOptions(false);
      }
    },
  });

  const handleKeyDownEvent = (e: KeyboardEvent) => {
    switch (e.key) {
      case " ":
      case "Enter":
        if (!isShowingOptions) {
          e.stopPropagation();
          e.preventDefault();
          setIsShowingOptions(true);
        }
        break;

      case "Escape":
        isShowingOptions ? setIsShowingOptions(false) : null;
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  };

  /**
   * Close the single-option select when the user selects an option.
   */
  useEffect(() => {
    if (!isMultiSelect) {
      setIsShowingOptionsRef.current?.(false);
    }
  }, [isMultiSelect, selectValue]);

  const selectElementWidth = selectRef?.current?.clientWidth;

  const PopOverContentWrapper = renderInPortal ? Portal : React.Fragment;

  return (
    <FormControl
      isInvalid={isInvalid}
      w={selectWidth}
      flexShrink={shrink ?? flexShrink}
      flexBasis={basis ?? flexBasis}
      onKeyDown={handleKeyDownEvent}
    >
      <VStack {...selectStackStyle}>
        {(label || tooltipText) && (
          <Flex {...selectLabelStyle.container}>
            {label && (
              <FormLabel htmlFor={label} {...selectLabelStyle.label}>
                {label}
              </FormLabel>
            )}

            {tooltipText && (
              <TippyTooltip.Info
                placement="top-start"
                tooltip={
                  <TippyTooltip.Content>
                    <TippyTooltip.Text>{tooltipText}</TippyTooltip.Text>
                  </TippyTooltip.Content>
                }
              ></TippyTooltip.Info>
            )}
          </Flex>
        )}
        <Box>
          <Popover
            isOpen={isShowingOptions}
            placement="bottom-start"
            gutter={0}
            isLazy
          >
            <PopoverTrigger>
              <Box
                ref={selectRef}
                tabIndex={0}
                _focus={{
                  outline: isShowingOptions
                    ? "none"
                    : "2px solid var(--primary-green)",
                }}
              >
                {maskedSelectBox ? (
                  <Box
                    position="relative"
                    onClick={() => setIsShowingOptions(!isShowingOptions)}
                  >
                    <ChakraSelectBox
                      name={name}
                      isSelectInvalid={isSelectInvalid}
                      isDisabled={isDisabled}
                      isRounded={isRounded}
                      selectPlaceholder={selectPlaceholder}
                      isShowingOptions={isShowingOptions}
                      onBlur={onBlur}
                      icon={icon}
                      isShowingEditLink={isShowingEditLink}
                      isShowingInfoText={isShowingInfoText}
                      ariaLabel={ariaLabel}
                      hasPlaceholder={isEmpty(value)}
                    />
                    {maskedSelectBox}
                  </Box>
                ) : (
                  <ChakraSelectBox
                    name={name}
                    isSelectInvalid={isSelectInvalid}
                    isDisabled={isDisabled}
                    isRounded={isRounded}
                    selectPlaceholder={selectPlaceholder}
                    setIsShowingOptions={setIsShowingOptions}
                    isShowingOptions={isShowingOptions}
                    onBlur={onBlur}
                    icon={icon}
                    isShowingEditLink={isShowingEditLink}
                    isShowingInfoText={isShowingInfoText}
                    ariaLabel={ariaLabel}
                    hasPlaceholder={isEmpty(value)}
                  />
                )}
              </Box>
            </PopoverTrigger>

            <PopOverContentWrapper>
              <PopoverContent
                backgroundColor="transparent"
                minWidth={selectElementWidth}
                alignItems="flex-start"
                width={selectWidth}
              >
                <Box ref={selectItemsRef} minWidth={selectWidth}>
                  {/* We may want to put <ListMenu> here in the future that is able to handle single selection or multi selection. */}
                  {children}
                </Box>
              </PopoverContent>
            </PopOverContentWrapper>
          </Popover>

          {/* TODO: combine info and edit into one */}
          <Box {...selectInfoStyle(!!label, !!tooltipText).box}>
            {icon && (
              <ChakraIcon
                icon={icon}
                color={iconColor}
                {...selectInfoStyle().icon}
              />
            )}
            <Box {...selectInfoStyle().spacer} />
            {isShowingInfoText && !isShowingEditLink && !isDisabled && (
              <Box
                {...selectInfoStyle().infoText}
                whiteSpace="nowrap"
                userSelect="none"
              >
                {infoText}
              </Box>
            )}
            <ChakraIcon
              icon={isDisabled ? "empty-16" : "link-specific-chevron-down-16"}
              {...selectInfoStyle().dropdownIcon}
            />
          </Box>

          {isShowingEditLink && !isDisabled && (
            <Box
              onClick={onClickEditLink}
              {...selectEditTextStyle(!!label, !!tooltipText).box}
            >
              <Text {...selectEditTextStyle().editLink} userSelect="none">
                {editText}
              </Text>
            </Box>
          )}
        </Box>
        {!(errorMessage && isSelectInvalid) && helperText && (
          <FormHelperText {...selectHelperStyle.formHelper}>
            {helperText}
          </FormHelperText>
        )}
        {errorMessage && (
          <FormErrorMessage {...selectHelperStyle.errorMessage}>
            {errorMessage}
          </FormErrorMessage>
        )}
      </VStack>
    </FormControl>
  );
};

/**
 * This is a component that was previously a function defined inside the render
 * function of `Select`. Defining it there meant it was a different function
 * each render, so a) React would destroy the whole DOM each time and recreate
 * it and b) it caused issues with keyboard navigation and focus.
 * It was hastily extracted to this component by just making all of its
 * dependencies props.
 * TODO: Rethink this subcomponent and see whether it makes sense to refactor
 * it to make it more understandable standalone.
 */
function ChakraSelectBox({
  name,
  isSelectInvalid,
  isDisabled,
  selectPlaceholder,
  setIsShowingOptions = () => {},
  isShowingOptions,
  onBlur,
  icon,
  isShowingEditLink,
  isShowingInfoText,
  isRounded = false,
  ariaLabel,
  hasPlaceholder,
}: ChakraSelectBoxProps) {
  const styles = selectStyle(
    !!icon,
    isShowingEditLink || isShowingInfoText,
    isShowingOptions,
    isRounded,
    hasPlaceholder
  ).select;
  return (
    <ChakraSelect
      {...styles}
      onChange={(e) => e.preventDefault()}
      name={name}
      tabIndex={-1}
      variant="outline"
      isInvalid={isSelectInvalid}
      isDisabled={isDisabled}
      placeholder={selectPlaceholder}
      title={selectPlaceholder}
      onMouseDown={(e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setIsShowingOptions(!isShowingOptions);
      }}
      icon={<ChakraIcon icon="empty-16" />}
      onBlur={onBlur}
      aria-label={ariaLabel}
    />
  );
}

type ChakraSelectBoxProps = {
  name?: string;
  isSelectInvalid: boolean;
  isDisabled: boolean;
  selectPlaceholder: string;
  isShowingOptions: boolean;
  isRounded: boolean;
  setIsShowingOptions?: React.Dispatch<React.SetStateAction<boolean>>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  icon?: string;
  isShowingEditLink: boolean;
  isShowingInfoText: boolean;
  ariaLabel?: string;
  hasPlaceholder: boolean;
};
