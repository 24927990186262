import styled from "@emotion/styled";
import { css } from "@emotion/react";

import A from "components/Modules/Link";
import colors from "app/designSystem/theme/foundations/colors";

export const Links = styled.div`
  box-sizing: border-box;
  color: rgb(113, 122, 132);
  display: block;
  float: left;
  font-size: 15px;
  height: auto;
  text-align: left;
`;

export const Link = styled(A, {
  shouldForwardProp: (prop) =>
    prop !== "isActive" && prop !== "isSubscribed" && prop !== "isVisited",
})<{ isActive: boolean; isSubscribed?: boolean; isVisited?: boolean }>`
  display: inline-block;
  color: var(--neutral-grey);
  line-height: 16px;
  padding: 21px 16px 20px 16px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-color: transparent;
  position: relative;
  transition: all 0.25s ease-in-out;

  :focus,
  :hover,
  :focus:hover {
    text-decoration: none;
    border-color: ${colors["grey-dark"]};
    outline: none;
    background: var(--background-grey);
  }

  :after {
    content: "";
    position: absolute;
    height: 8px;
    width: 8px;
    right: 6px;
    top: 13px;
    background: linear-gradient(180deg, #1f89fb 0%, #00b8d9 100%);
    border-radius: 50%;
    display: none;
  }

  ${({ isSubscribed = true, isActive, isVisited = true }) => {
    if (isActive) {
      return css`
        font-weight: bold;
        color: ${colors["grey-dark"]};
        border-color: ${colors["grey-dark"]};
      `;
    }
    if (!isSubscribed) {
      if (!isVisited) {
        return css`
          color: var(--cio-colors-grey);
          :after {
            display: block;
          }
        `;
      }
      return css`
        color: var(--cio-colors-grey);
      `;
    }
    return;
  }}
`;
