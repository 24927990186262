import React from "react";
import styled from "@emotion/styled";
import Dropdown from "react-bootstrap/Dropdown";

import { Box } from "@chakra-ui/react";

import { useInView } from "react-intersection-observer";

import { OptionButton } from "components/Modules/OptionsMenu";
import * as types from "modules/indexes";

export default function AutocompleteCompany({
  index,
  onSelect,
  onView,
  totalItemsCount,
}: Props) {
  const { ref } = useInView({
    onChange(isInView) {
      onView(index.indexKey, isInView);
    },
  });

  return (
    <Item ref={ref} onSelect={onSelect}>
      <div>{index.name}</div>
      <Box textStyle="body2-italic" textColor="grey-dark">
        {totalItemsCount ?? "..."} items
      </Box>
    </Item>
  );
}

type Props = {
  index: types.Index;
  onSelect: () => void;
  totalItemsCount: number | undefined;
  onView: (indexKey: string, isInView: boolean) => void;
};

const Item = styled(OptionButton)``.withComponent(Dropdown.Item);
