import { extendTheme } from "@chakra-ui/react";

// Structure based on "Scaling out your project", as described in Chakra's
// documentation
// https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import styles from "./styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import colors from "./foundations/colors";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { breakpoints } from "./foundations/breakpoints";
import {
  fonts,
  lineHeights,
  fontWeights,
  textStyles,
  // @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
  // eslint-disable-next-line local-rules/enforce-fractal-pattern,
} from "./foundations/typography";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { shadows } from "./foundations/shadows";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { sizes } from "./foundations/sizes";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Radio from "./components/radio";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Checkbox from "./components/checkbox";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Switch from "./components/switch";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Button from "./components/button";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import List from "./components/list-menu";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Popover from "./components/popover";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Progress from "./components/Progress";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Tooltip from "./components/tooltip";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Toast from "./components/toast";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import FilterDropdownChip from "./components/filter-dropdown-chip";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Menu, MenuItem } from "./components/internal/menu";

// We extend Chakra's default theme, so everything not overridden is available.
// Check the default theme values (and where each token can be used) at
// https://chakra-ui.com/docs/theming/theme
const theme = extendTheme({
  styles,
  colors,
  fonts,
  lineHeights,
  fontWeights,
  textStyles,
  shadows,
  sizes,
  breakpoints,
  config: {
    cssVarPrefix: "cio",
  },
  components: {
    Radio,
    Checkbox,
    Switch,
    Button,
    List,
    Popover,
    Progress,
    Tooltip,
    Toast,
    FilterDropdownChip,
    internal: {
      Menu,
      MenuItem,
    },
  },
});

// Remove the theme styles for FormLabel so we can properly style it with
// `textStyles`.
// See https://github.com/chakra-ui/chakra-ui/issues/3501#issuecomment-803610656
// for a similar issue with `Heading`.
theme.components.FormLabel = {};

export default theme;
