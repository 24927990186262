import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Link from "components/Modules/Link";
import IARefreshBanner from "app/components/IARefreshBanner";

export default function Bar({ children }: Props) {
  return (
    <>
      <IARefreshBanner />
      <Container>
        <Header>
          <Logo to="/dashboard">
            <img
              css={css`
                height: 30px;
              `}
              alt="Logo constructor io"
              src={require("./logo.png")}
            />
          </Logo>
        </Header>
        <Body>{children}</Body>
      </Container>
    </>
  );
}

type Props = {
  children: React.ReactNode;
};

const Container = styled.div`
  background-color: white;
  padding: 0 15px;
  border-radius: 0px;
  position: relative;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
`;

const Header = styled.div``;

const Logo = styled(Link)`
  display: block;
  padding: 5px 15px 15px 35px;
  margin-top: 11px;
  height: 50px;
  font-size: 18px;
  line-height: 20px;
`;

const Body = styled.div``;
