import { History } from "history";

import * as IndexKeyId from "utils/IndexKeyId";
import * as IndexKeyValue from "utils/IndexKeyValue";
import redirect from "utils/redirect";
import { Index } from "modules/indexes";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

export default function changeIndex(
  index: Index,
  {
    history,
    preserveQueryParams = false,
  }: { history: History; preserveQueryParams?: boolean }
) {
  const shouldUseNewId = isFlagEnabled(FeatureFlags.EnableAcidDeprecate);

  if (shouldUseNewId) {
    IndexKeyValue.set(index.indexKey);
  } else {
    IndexKeyId.set(index.id.toString());
  }

  let mergeQueryParams = "";

  if (preserveQueryParams) {
    const params = new URLSearchParams(history.location.search);
    params.delete("index_key");
    mergeQueryParams = params.toString() ? "&" + params.toString() : "";
  }

  redirect(history, "?index_key=" + index.indexKey + mergeQueryParams);
}
