import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Tooltip } from "app/designSystem/components";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Icon from "./Icon";

const InfoIcon = styled(Icon)`
  margin-left: 6px;
  width: 16px;
  height: 16px;
  top: -1px;
`;

function InfoTooltip({ tooltip }) {
  return (
    <Tooltip label={tooltip}>
      <InfoIcon icon="info" />
    </Tooltip>
  );
}

InfoTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
};

export default InfoTooltip;
