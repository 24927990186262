import React, { useState } from "react";
import { DayPickerProps, DateUtils } from "react-day-picker";
import { ClassNames } from "@emotion/react";

import theme from "components/theme";

import DatePicker from "components/Modules/DatePicker";

import Navbar from "./DateRangePicker/Navbar";

// DateUtils is value, not a type, it seems types are broken
// https://react-day-picker-v7.netlify.app/api/DateUtils
// @ts-ignore
const { addDayToRange } = DateUtils;

export default function DateRangePicker({
  startDate,
  endDate,
  numberOfMonths,
  onSelectionChanged = () => {},
  navbarElement,
  captionElement,
  disabledDays,
  fromMonth,
  toMonth,
  fluid = false,
  isSingleSelection = false,
  handleDayRender,
}: Props) {
  const [inProgress, setInProgress] = useState<boolean>(false);

  const handleDayClick = (
    ...params: Parameters<NonNullable<DayPickerProps["onDayClick"]>>
  ): void => {
    const [day, , event] = params;
    event.stopPropagation();

    if (isSingleSelection) {
      onSelectionChanged({ from: day, to: day });
      return;
    }

    if (inProgress) {
      const selection = addDayToRange(day, {
        from: startDate,
        to: endDate,
      });

      // DateUtils.addDayToRange sets everything to null if day === from === to
      if (selection.from === null && selection.to === null) {
        selection.from = selection.to = day;
      }

      onSelectionChanged(selection);
      setInProgress(false);
    } else {
      onSelectionChanged({ from: day, to: day });
      setInProgress(true);
    }
  };

  return (
    <ClassNames>
      {({ css }) => {
        const selectedModifier = css`
          label: selected;
          background-color: ${theme.colors.lightHighlight};
        `;

        const rangeLimitModifier = css`
          label: rangeLimitModifier;
          ${selectedModifier};
          color: #fff;
          background-color: ${theme.colors.darkHighlight};
        `;

        const generalStyles = css`
          ${fluid ? `width: 100%;` : ""}

          .DayPicker-wrapper {
            padding-bottom: 0;
          }

          .DayPicker-Month {
            ${isSingleSelection
              ? `
            margin-left: 0;
            margin-right: 0;
          `
              : `
              &:first-of-type {
                margin-left: 0;
              }

              &:last-of-type {
                margin-right: 0;
              }
            `}

            margin-top: 0;

            ${fluid ? `width: 100%;` : ""}
          }
        `;

        return (
          <DatePicker
            modifiers={{
              [rangeLimitModifier]: isSingleSelection
                ? [startDate]
                : [startDate, endDate],
              [selectedModifier]: isSingleSelection
                ? { from: startDate }
                : { from: startDate, to: endDate },
            }}
            initialMonth={startDate}
            fromMonth={fromMonth}
            toMonth={toMonth}
            disabledDays={disabledDays}
            onDayClick={handleDayClick}
            navbarElement={navbarElement ?? Navbar}
            captionElement={captionElement}
            numberOfMonths={numberOfMonths}
            className={generalStyles}
            renderDay={handleDayRender}
          />
        );
      }}
    </ClassNames>
  );
}

type Props = {
  startDate: Date;
  endDate: Date;
  onSelectionChanged(selection: { from: Date; to: Date }): void;
  numberOfMonths?: number;
  navbarElement?: DayPickerProps["navbarElement"];
  captionElement?: DayPickerProps["captionElement"];
  disabledDays?: DayPickerProps["disabledDays"];
  fromMonth?: DayPickerProps["fromMonth"];
  toMonth?: DayPickerProps["toMonth"];
  fluid?: boolean;
  handleDayRender?: DayPickerProps["renderDay"];
  isSingleSelection?: boolean;
};
