// This is copy-paste of the source code of the npm package
// `use-persisted-state` (https://github.com/donavon/use-persisted-state)
// The copy-paste was done because this package is not maintained anymore and
// was blocking the React upgrade to 18th major version.

const createStorage = (provider) => ({
  get(key, defaultValue) {
    const json = provider.getItem(key);
    // eslint-disable-next-line no-nested-ternary
    return json === null || typeof json === 'undefined'
      ? typeof defaultValue === 'function'
        ? defaultValue()
        : defaultValue
      : JSON.parse(json);
  },
  set(key, value) {
    provider.setItem(key, JSON.stringify(value));
  },
});

export default createStorage;
