import React from "react";

export const PageLayoutWrapperContext = React.createContext<{
  isLayoutUpdating: boolean;
  setIsLayoutUpdating: (value: boolean) => void;
}>({
  isLayoutUpdating: false,
  setIsLayoutUpdating: () => {},
});

export const usePageLayoutWrapperContext = () => {
  const context = React.useContext(PageLayoutWrapperContext);

  return context;
};
