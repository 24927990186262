import React from "react";
import { Box } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import FeatureFlags from "utils/featureFlags";
import useFeatureToggle from "app/hooks/useFeatureToggle";
import { Alert } from "app/designSystem/components";

export default function ProspectWarningMessage() {
  const showWarning = useFeatureToggle(FeatureFlags.ProspectTrackingMessage);

  if (!showWarning) {
    return null;
  }

  return (
    <Box marginBottom="40px">
      <Alert type="warning" noClose>
        <FormattedMessage id="prospect_warning_message" />
      </Alert>
    </Box>
  );
}
