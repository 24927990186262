import {
  Drawer,
  DrawerBody,
  DrawerContent,
  PopoverContent,
} from "@chakra-ui/react";
import React from "react";

const DatePickerWrapper = ({
  isDesktopView,
  children,
  onClose,
  isOpen,
  singleSelection,
}: {
  isDesktopView: boolean;
  children: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
  singleSelection?: boolean;
}) => {
  return isDesktopView ? (
    <PopoverContent w={singleSelection ? "590px" : "900px"}>
      {children}
    </PopoverContent>
  ) : (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
      <DrawerContent mt="60px" maxW="440px" background="transparent">
        <DrawerBody boxShadow="feedback" p="0" w="100%" maxW="440px" mx="auto">
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DatePickerWrapper;
