import React from "react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import TippyTooltip from "./TippyTooltip";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { SecondaryContainer } from "./UI";

export default function Secondary({
  tooltip,
  children,
  ...props
}: Props & React.ComponentProps<typeof TippyTooltip>) {
  return (
    <TippyTooltip
      followCursor
      placement="bottom-start"
      tooltip={<SecondaryContainer>{tooltip}</SecondaryContainer>}
      {...props}
    >
      {children}
    </TippyTooltip>
  );
}

type Props = {
  tooltip: React.ReactElement;
};
