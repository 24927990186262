import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

import history from "components/browserHistory";

const SENTRY_DSN = document.querySelector<HTMLMetaElement>(
  'meta[name="sentry_dsn"]'
)?.content;

const SENTRY_ENVIRONMENT = document.querySelector<HTMLMetaElement>(
  'meta[name="sentry_environment"]'
)?.content;

const SENTRY_SAMPLING_RATE = Number(
  document.querySelector<HTMLMetaElement>(
    'meta[name="sentry_traces_sample_rate"]'
  )?.content
);

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    ignoreErrors: [
      // This is used by us to stop the default autocomplete behavior and hook into
      // it to do other things after an item gets selected (e.g. searchandizing)
      "this error intentionally left uncaught",
      // Per this answer and related reading https://stackoverflow.com/a/50387233
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications",
      // There's a crawler within Outlook that checks for URLs on emails
      // received. It tries to access our site and throws an error like this,
      // which we don't really care about.
      // Related thread: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/8
      "Object Not Found Matching Id",
      "Unexpected end of script",
    ],
    denyUrls: [
      // We sometimes receive errors from the following 3rd party URLs that
      // don't affect the app's behavior and can be safely ignored.
      "googleads.g.doubleclick.net",
    ],
    beforeSend(event) {
      // We ignore search UI bundle loading error that comes from the hook
      // useSearchUi.tsx on the interact page at the moment of this commit
      // because the integrations team often uses browser extensions to redefine
      // the search UI bundle URL content and this causes a false positive for
      // this notification.
      if (
        isConstructorUser() &&
        event.exception?.values?.at(0)?.value ===
          "Failed to load search ui bundle: error 404"
      ) {
        return null;
      }

      if (!event.extra) {
        event.extra = {};
      }

      if (typeof window.FS !== "undefined" && window.FS.getCurrentSessionURL) {
        try {
          event.extra["fullstory_session_url"] =
            window.FS.getCurrentSessionURL(true);
        } catch (fullstorySessionUrlError) {
          /* eslint-disable-next-line no-console */
          console.error(fullstorySessionUrlError);
        }
      }

      try {
        const logRocketSession = LogRocket.sessionURL;
        if (logRocketSession !== null) {
          event.extra["logrocket_session_url"] = logRocketSession;
        }
      } catch (logrocketSessionUrlError) {
        /* eslint-disable-next-line no-console */
        console.error(logrocketSessionUrlError);
      }

      return event;
    },
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: SENTRY_SAMPLING_RATE,
    maxBreadcrumbs: 80,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb) {
      if (
        breadcrumb.category === "xhr" &&
        breadcrumb.data?.url?.includes("rs.fullstory.com")
      ) {
        // Ignore breadcrumbs about requests to FullStory - they don't add value
        // and they add noise to the UI when debugging (plus we have 80
        // breadcrumbs per issue so they limit the amount of useful information
        // we get).
        return null;
      }

      return breadcrumb;
    },
  });
}

// cognitive-complexity rule is temporarily disabled for this function, please refactor this function
// whenever possible.
// eslint-disable-next-line sonarjs/cognitive-complexity
document.addEventListener("DOMContentLoaded", function () {
  if (!SENTRY_DSN) {
    return;
  }

  Sentry.configureScope(function (scope) {
    const currentUser = getCurrentUser();
    if (currentUser && currentUser.email) {
      scope.setUser({ email: currentUser.email });
    }

    const frontendVersion: string =
      document.querySelector<HTMLMetaElement>('meta[name="frontend_version"]')
        ?.content || "";

    if (frontendVersion) {
      scope.setTag("frontend_version", frontendVersion);
    }

    const currentAdminUserEmail = getCurrentAdminEmail();
    if (currentAdminUserEmail) {
      scope.setTag("current_admin_user_email", currentAdminUserEmail);
    }

    const currentCompanyIdElement = document.querySelector<HTMLMetaElement>(
      'meta[name="current_company_id"]'
    );

    if (currentCompanyIdElement?.content) {
      scope.setTag("current_company_id", currentCompanyIdElement.content);
    }

    const currentCompanyName = document.querySelector<HTMLMetaElement>(
      'meta[name="current_company_name"]'
    );

    if (currentCompanyName?.content) {
      scope.setTag("current_company_name", currentCompanyName.content);
    }

    // Check that FS.getCurrentSessionURL because adblockers can mess it up
    if (typeof window.FS !== "undefined" && window.FS.getCurrentSessionURL) {
      try {
        scope.setExtra(
          "fullstory_session_url",
          window.FS.getCurrentSessionURL(true)
        );
      } catch (fullstorySessionUrlError) {
        Sentry.captureException(fullstorySessionUrlError);
      }
    }

    try {
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        scope.setExtra("logrocket_session_url", logRocketSession);
      }
    } catch (logrocketSessionUrlError) {
      Sentry.captureException(logrocketSessionUrlError);
    }
  });
});

export function setCurrentIndex(indexKey: string | null) {
  Sentry.configureScope((scope) => scope.setTag("index_key", indexKey));
}

type User = {
  email: string;
};

function isConstructorUser(): boolean {
  return (
    getCurrentAdminEmail() !== undefined ||
    getCurrentUser()?.email?.endsWith("@constructor.io")
  );
}

function getCurrentUser(): User {
  const currentUserElement = document.querySelector<HTMLMetaElement>(
    'meta[name="current_user"]'
  );

  const currentUser =
    currentUserElement &&
    currentUserElement.content &&
    JSON.parse(currentUserElement.content);

  return currentUser;
}

function getCurrentAdminEmail(): string | undefined {
  const currentAdminUserEmail = document.querySelector<HTMLMetaElement>(
    'meta[name="current_admin_user_email"]'
  );

  if (currentAdminUserEmail?.content === "") {
    return undefined;
  }

  return currentAdminUserEmail?.content;
}
