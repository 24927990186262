import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Link as RouterLink } from "react-router-dom";

import * as UI from "./shared/UI";
import * as utils from "./shared/utils";

function Link({ loading, children, ...props }) {
  const LinkComponent = props.to ? RouterLinkElement : LinkElement;
  const tabIndex = props.disabled ? -1 : props.tabIndex;

  return (
    <LinkComponent
      disabled={loading}
      {...props}
      tabIndex={tabIndex}
      onClick={(event) => {
        if (props.disabled) {
          event.stopPropagation();
          event.preventDefault();
        } else if (props.onClick) {
          props.onClick(event);
        }
      }}
    >
      <UI.Content loading={loading}>{children}</UI.Content>
      {loading && <UI.SpinnerElement />}
    </LinkComponent>
  );
}

Link.propTypes = utils.propTypes;
Link.defaultProps = { ...utils.defaultProps, kind: "text" };

export default Link;

const disabledStyle = css`
  background-color: var(--primary-disabled);
  color: var(--neutral-disabledgrey);
  cursor: not-allowed;
  box-shadow: none;

  ${"" /* not(:disabled) is required to overwrite previous definitions */}

  :hover:not(:disabled),
  :focus:not(:disabled),
  :active:not(:disabled) {
    cursor: not-allowed;
    background-color: var(--primary-disabled);
    color: var(--neutral-disabledgrey);
    box-shadow: none;
  }
`;

const LinkElement = styled(UI.ButtonElement)`
  :hover,
  :focus {
    text-decoration: ${(props) => (props.underline ? "underline" : "none")};
  }

  /* disabled state is duplicated to correctly disable links */
  ${({ disabled }) => disabled && disabledStyle}
`.withComponent("a");

const RouterLinkElement = styled(UI.ButtonElement)`
  :hover,
  :focus {
    text-decoration: none;
  }

  /* disabled state is duplicated to correctly disable links */
  ${({ disabled }) => disabled && disabledStyle}
`.withComponent(RouterLink);
