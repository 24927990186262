import AuthApi from "services/AuthApi";

export type DeleteUserRolesInCompanyParams = {
  companyId: string;
  userId: string;
};

/**
 * @description Delete all role associations from a user for the specified company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-delete-assigned-company-role-to-user
 * @returns {void}
 */
export default async function deleteUserRolesInCompany({
  companyId,
  userId,
}: DeleteUserRolesInCompanyParams): Promise<void> {
  const result = await AuthApi.delete<void>([
    "v1",
    "companies",
    companyId,
    "users",
    userId,
    "roles",
  ]);

  return result;
}

deleteUserRolesInCompany.key =
  "modules/authentication/actions/users/deleteUserRolesInCompany";
