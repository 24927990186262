import React, { PropsWithChildren } from "react";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";

export default function TippyTooltip({
  tooltip,
  children,
  ...props
}: Props & React.ComponentProps<typeof Tippy>) {
  return (
    <Tippy content={tooltip} plugins={[followCursor]} {...props}>
      {children}
    </Tippy>
  );
}

type Props = PropsWithChildren<{
  tooltip: React.ReactNode;
}>;
