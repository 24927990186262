import React, { createContext, useContext, useState, useEffect } from "react";
import { useQueryParam, StringParam } from "use-query-params";

import { setCurrentIndex as setCurrentIndexInSentry } from "utils/sentry";
import { Index } from "types/index";
import { Result } from "types/request";
import { useGet } from "app/hooks/useApi";
import * as IndexKeyId from "utils/IndexKeyId";
import * as IndexKeyValue from "utils/IndexKeyValue";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";

const Context = createContext<Index | null>(null);

export function CurrentIndexProvider({
  value,
  ...props
}: React.ComponentProps<typeof Context.Provider>) {
  useEffect(() => {
    setCurrentIndexInSentry(value?.indexKey || null);

    return () => {
      setCurrentIndexInSentry(null);
    };
  }, [value]);

  return <Context.Provider value={value} {...props} />;
}

export default function useCurrentIndex(): Index {
  const index = useCurrentIndexOrNull();

  if (!index) {
    throw new Error(
      "Index was not found in context: make sure the index was defined and " +
        "the current component is wrapped in the context."
    );
  }

  return index;
}

export function useCurrentIndexOrNull(): Index | null {
  return useContext(Context);
}

const CacheContext = createContext<{
  indexes: Index[];
  setIndexes: React.Dispatch<React.SetStateAction<Index[]>>;
} | null>(null);

export function Cache({ children }: CacheProps) {
  const [indexes, setIndexes] = useState<Index[]>([]);

  return (
    <CacheContext.Provider value={{ indexes, setIndexes }}>
      {children}
    </CacheContext.Provider>
  );
}

type CacheProps = {
  children: React.ReactNode;
};

// cognitive-complexity rule is temporarily disabled for this function, please refactor this function
// whenever possible.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useApi(): Result<Index | null> {
  const { indexes, setIndexes } = useContext(CacheContext) || {};
  const query: { indexKey?: string; autocompleteCompanyId?: number } = {};

  const [indexKey] = useQueryParam("index_key", StringParam);

  const shouldUseNewCookie = isFlagEnabled(FeatureFlags.EnableAcidDeprecate);

  const keyId = IndexKeyId.get();
  const keyValue = IndexKeyValue.get();

  if (indexKey !== "") {
    if (indexKey) {
      query.indexKey = indexKey;
    } else if (keyId && !shouldUseNewCookie) {
      query.autocompleteCompanyId = parseInt(keyId, 10);
    } else if (keyValue && shouldUseNewCookie) {
      query.indexKey = keyValue;
    }
  }

  const cachedIndex =
    indexes &&
    indexes.find(
      (index) =>
        (query.indexKey && query.indexKey === index.indexKey) ||
        (query.autocompleteCompanyId &&
          query.autocompleteCompanyId === index.id)
    );

  const [apiIndex, other] = useGet<Index>(
    "/api/autocomplete_companies/current",
    {
      query: query,
      run: !cachedIndex && indexKey !== "",
      onSuccess: (index: Index) =>
        setIndexes && index && setIndexes((indexes) => [...indexes, index]),
    }
  );

  const index = cachedIndex || apiIndex;

  const [itemImageBaseUrl, setItemImageBaseUrl] = useState(
    index && index.itemImageBaseUrl
  );

  useEffect(() => {
    if (index) {
      setItemImageBaseUrl(index.itemImageBaseUrl);
    }
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index && index.itemImageBaseUrl]);

  // index key was intentionally reset
  if (indexKey === "") {
    return [null, { fetching: false, error: null }];
  }

  return [index && { ...index, itemImageBaseUrl, setItemImageBaseUrl }, other];
}

export { default as Selector } from "components/Modules/IndexKeySelector";

export * from "components/Modules/IndexKeySelector";
