import { useCallback, useEffect, useMemo, useState } from "react";

import { useDebounce } from "use-debounce";

import * as types from "modules/indexes/types";
import useIndexesItemCounts from "app/queries/indexes/useIndexesItemCounts";

import useCancelRequest from "./useCancelRequest";

/**
 * Loads item count information for a set of indexes.
 * Supports:
 * - cancellation
 * - sequential load (load one after another)
 * - no duplicate API calls (keep internal state of already loaded indexes)
 * - delays
 * @param args Arguments hash.
 * @param args.indexes List of indexes to load.
 * @param {boolean} [args.enabled=true] Whether can make API call.
 */
export default function useLoadIndexesItemCounts({
  indexes,
  enabled = true,
}: Args): Result {
  const [indexesInView, setIndexesInView] = useState<Record<string, boolean>>(
    {}
  );
  const [loadedIndexes, setLoadedIndexes] = useState<Record<string, boolean>>(
    {}
  );

  const { abort, getSignal } = useCancelRequest();

  const indexToLoad = useMemo(() => {
    return indexes?.find(
      (index) => indexesInView[index.indexKey] && !loadedIndexes[index.indexKey]
    );
  }, [indexes, indexesInView, loadedIndexes]);

  const [debouncedIndex, debounceFn] = useDebounce(indexToLoad, 1000);

  const indexesItemCounts = useIndexesItemCounts({
    index: debouncedIndex,
    signal: getSignal(),
    enabled: !!debouncedIndex && enabled,
  });

  const onView = useCallback((indexKey: string, isInView: boolean) => {
    setIndexesInView((prevState) => ({
      ...prevState,
      [indexKey]: isInView,
    }));
  }, []);

  const onRefresh = useCallback(
    (shouldAbort: boolean) => {
      debounceFn.flush();

      if (shouldAbort) {
        abort();
      }
    },
    [abort, debounceFn]
  );

  useEffect(() => {
    Object.entries(indexesItemCounts).forEach(([key, data]) => {
      setLoadedIndexes((prevState) => ({
        ...prevState,
        [key]: !data.isLoading,
      }));
    });
  }, [indexesItemCounts]);

  return { onView, onRefresh, indexesItemCounts };
}

type Args = {
  indexes: types.Index[] | undefined;
  enabled?: boolean;
};

type Result = {
  onView: (indexKey: string, isInView: boolean) => void;
  onRefresh: (shouldAbort: boolean) => void;
  indexesItemCounts: ReturnType<typeof useIndexesItemCounts>;
};
