import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Text } from "@chakra-ui/react";

import theme from "components/theme";

export default function Empty({
  title,
  children,
  image = require("./Empty/empty.svg"),
  imageWidth = "94px",
  imageHeight = "94px",
  ...props
}: Props & React.ComponentProps<typeof Box>) {
  return (
    <Box {...props}>
      <Container>
        {image && (
          <div
            css={css`
              width: ${imageWidth};
              height: ${imageHeight};
              background: url(${image}) no-repeat;
              margin: 0 auto;
            `}
          />
        )}

        {title && (
          <Text
            mb="0"
            mt="40px"
            as="h2"
            textStyle="heading-3"
            color="dark-grey"
          >
            {title}
          </Text>
        )}
        <Text
          as="div"
          mb="0"
          mt="8px"
          textStyle="body-3"
          color="grey"
          maxWidth="368px"
        >
          {children}
        </Text>
      </Container>
    </Box>
  );
}

type Props = {
  title?: string;
  children?: React.ReactNode;
  image?: string;
  imageWidth?: string;
  imageHeight?: string;
};

const Box = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const Container = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const Comment = styled.div`
  font-size: 13px;
  color: ${theme.colors.headerGray};
`;

export { Comment };
