import reportError from "services/ReportError";

// Imports feature flags automatically generated
import { FeatureFlags } from "./generatedFeatures";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    ENABLED_FEATURES?: FeatureFlags[];
  }
}

export default FeatureFlags;

export function isFlagEnabled(flagName: FeatureFlags) {
  let features = window.ENABLED_FEATURES;

  if (!features) {
    reportError("ENABLED_FEATURES not found");
    features = [];
  }

  return features.includes(flagName);
}
