import { init } from "@amplitude/analytics-browser";

import * as currentUserData from "modules/currentUser/actions";

import {
  sendAmplitudeIdentifyEvent,
  AmplitudeIdentifyProperties,
  AmplitudeIdentifyEvent,
} from "./amplitude/events";
import { getAmplitudeId } from "./amplitude/getAmplitudeId";

const amplitudeId = getAmplitudeId();

if (amplitudeId) {
  const currentAdminUserEmail: string =
    document.querySelector<HTMLMetaElement>(
      'meta[name="current_admin_user_email"]'
    )?.content || "";

  const currentUser = currentUserData.get();

  const currentCompanyId = document.querySelector<HTMLMetaElement>(
    'meta[name="current_company_id"]'
  )?.content;

  const currentCompanyName = document.querySelector<HTMLMetaElement>(
    'meta[name="current_company_name"]'
  )?.content;

  init(amplitudeId);

  if (currentUser) {
    const vWidth = window.innerWidth;
    const vHeight = window.innerHeight;

    const identifyProperties: AmplitudeIdentifyEvent = {
      [AmplitudeIdentifyProperties.ViewportWidth]: vWidth,
      [AmplitudeIdentifyProperties.ViewportHeight]: vHeight,
      [AmplitudeIdentifyProperties.UserId]: currentUser.id,
    };

    currentUser?.firstName &&
      (identifyProperties[AmplitudeIdentifyProperties.FirstName] =
        currentUser.firstName);
    currentUser?.lastName &&
      (identifyProperties[AmplitudeIdentifyProperties.LastName] =
        currentUser.lastName);
    currentUser?.email &&
      (identifyProperties[AmplitudeIdentifyProperties.Email] =
        currentUser.email);
    identifyProperties[
      AmplitudeIdentifyProperties.IsIaResponsivePageLayoutActive
    ] = !!currentUser.isIaResponsivePageLayoutActive;
    currentCompanyName &&
      (identifyProperties[AmplitudeIdentifyProperties.CompanyName] =
        currentCompanyName);
    currentCompanyId &&
      (identifyProperties[AmplitudeIdentifyProperties.CompanyId] =
        currentCompanyId);
    currentAdminUserEmail &&
      (identifyProperties[AmplitudeIdentifyProperties.AdminEmail] =
        currentAdminUserEmail);

    sendAmplitudeIdentifyEvent(identifyProperties);
  }
}
