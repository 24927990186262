import { Permission } from "modules/authentication/actions/shared/types";
import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

/**
 * @description ValidateToken validates bearer token and returns its properties. Bearer token can be both user and pregenerated token.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Tokens/operation/v1-auth-validate-token
 * @returns {TokenValidateResponse}
 */
export default async function tokenValidate(): Promise<TokenValidateResponse> {
  const result = await AuthApi.get<TokenValidateResponseData>([
    "tokens",
    "validate",
  ]);

  return GetTokenValidateResponse.fromGetTokenValidateResponseData(result);
}

tokenValidate.key = "modules/authentication/actions/tokens/validate";

export type TokenValidateResponseData = {
  company_id: string;
  user_id: string;
  created_by_user_id: string | null;
  created_at: string;
  description: string;
  email: string;
  expires_at: string;
  permissions: Permission[];
  pregenerated: boolean;
  source_cidrs: string[] | null;
  token_hash: string;
  token_id: string;
  created_by_token_id: string | null;
  is_valid_for_all_companies: boolean;
};

export type TokenValidateResponse = {
  companyId: string;
  userId: string;
  createdByUserId: string | null;
  createdAt: Date | null;
  description: string;
  email: string;
  expiresAt: Date | null;
  permissions: Permission[];
  pregenerated: boolean;
  sourceCidrs: string[] | null;
  tokenHash: string;
  tokenId: string;
  createdByTokenId: string | null;
  isValidForAllCompanies: boolean;
};

export const GetTokenValidateResponse = {
  fromGetTokenValidateResponseData: (
    data: TokenValidateResponseData
  ): TokenValidateResponse => {
    const getTokenResponse: TokenValidateResponse = {
      companyId: data.company_id,
      userId: data.user_id,
      createdByUserId: data.created_by_user_id,
      createdAt: data.created_at ? convertFromApiFormat(data.created_at) : null,
      description: data.description,
      email: data.email,
      expiresAt: data.expires_at ? convertFromApiFormat(data.expires_at) : null,
      pregenerated: data.pregenerated,
      tokenHash: data.token_hash,
      tokenId: data.token_id,
      createdByTokenId: data.created_by_token_id,
      isValidForAllCompanies: data.is_valid_for_all_companies,
      permissions: data.permissions,
      sourceCidrs: data.source_cidrs || [],
    };

    return getTokenResponse;
  },
};
