import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input as ChakraInput,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
} from "@chakra-ui/react";
import { css } from "@emotion/react";

import {
  ChakraIcon,
  IconType,
} from "app/designSystem/components/ChakraIcon/ChakraIcon";
import * as TippyTooltip from "components/Modules/TippyTooltip";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./Input.styles";

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      isInvalid,
      isDisabled,
      id,
      errorMessage,
      helperText,
      icon,
      rightIcon,
      label,
      name,
      step,
      min,
      max,
      onChange,
      onBlur,
      placeholder,
      tooltipText,
      value,
      preserveSpaceForMessage = true,
      ariaLabel,
      autoFocus,
      type,
      truncatePlaceholder,
      onFocus,
      autoComplete,
      maxLength,
      onRightIconClick,
    }: Props,
    ref
  ) => {
    const invalid = isInvalid || !!errorMessage;

    return (
      <FormControl
        isInvalid={invalid}
        {...styles.getFormControlStyle(!!errorMessage)}
      >
        <VStack spacing="8px" alignItems="start">
          {label || tooltipText ? (
            <Flex {...styles.labelContainer}>
              {label && (
                <FormLabel htmlFor={id} {...styles.formLabel}>
                  {label}
                </FormLabel>
              )}

              {tooltipText && (
                <TippyTooltip.Info
                  placement="top-start"
                  tooltip={
                    <TippyTooltip.Content>
                      <TippyTooltip.Text>{tooltipText}</TippyTooltip.Text>
                    </TippyTooltip.Content>
                  }
                />
              )}
            </Flex>
          ) : null}

          <InputGroup>
            {icon && (
              <InputLeftElement pointerEvents="none" {...styles.leftAddon}>
                <ChakraIcon icon={icon} {...styles.icon} color="grey" />
              </InputLeftElement>
            )}

            <ChakraInput
              id={id}
              isInvalid={invalid}
              isDisabled={isDisabled}
              placeholder={placeholder}
              name={name}
              min={min}
              max={max}
              step={step}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              aria-label={ariaLabel}
              maxLength={maxLength}
              type={type}
              // It's up to consumers of this component to decide whether this
              // rule applies to them or not, so disabling it here.
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              css={css`
                ${truncatePlaceholder
                  ? `&:placeholder-shown {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }`
                  : ""}
              `}
              ref={ref}
              {...styles.getInputStyle(!!icon, !!rightIcon)}
            />

            {rightIcon && (
              <InputRightElement
                pointerEvents={onRightIconClick ? "all" : "none"}
                cursor={onRightIconClick ? "pointer" : "initial"}
                onClick={onRightIconClick}
              >
                <ChakraIcon
                  icon={rightIcon}
                  {...styles.rightIcon}
                  color="grey-dark"
                />
              </InputRightElement>
            )}
          </InputGroup>

          {errorMessage ? (
            <FormErrorMessage {...styles.text}>{errorMessage}</FormErrorMessage>
          ) : helperText || preserveSpaceForMessage ? (
            <FormHelperText {...styles.text}>{helperText}</FormHelperText>
          ) : null}
        </VStack>
      </FormControl>
    );
  }
);

Input.displayName = "Input";

type Props = {
  isInvalid?: boolean;
  isDisabled?: boolean;
  id?: string;
  errorMessage?: React.ReactNode;
  helperText?: React.ReactNode;
  icon?: IconType["icon"];
  rightIcon?: IconType["icon"];
  label?: string;
  name?: string;
  step?: number | string | undefined;
  min?: number | string | undefined;
  max?: number | string | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  tooltipText?: React.ReactNode;
  value?: string;
  ariaLabel?: string;
  autoFocus?: boolean;
  type?: "text" | "url" | "password" | "email" | "number";
  truncatePlaceholder?: boolean;
  autoComplete?: "off" | "on";
  maxLength?: number;
  onRightIconClick?: () => void;

  /**
   * If `true`, it will save some space under the input so that showing or
   * hiding helper text and error messages won't shift the layout (assuming
   * those messages are a single line tall).
   */
  preserveSpaceForMessage?: boolean;
};
