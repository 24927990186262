import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  forwardRef,
  List as ChakraList,
  ListItem as ChakraListItem,
  useMultiStyleConfig,
  StylesProvider,
  ButtonProps,
  LayoutProps,
  CSSObject,
  Box,
  useMergeRefs,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

// can not rely on "Chakra"'s icon because it has unchangeable "svg" node
// need to make it possible to pass any component there (f.e. checkbox)
const ScIconWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  pointer-events: none;
`;

const ScLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }

  &:last-of-type {
    li::before {
      visibility: hidden;
    }
  }
`;

type ListMenuItemCommonProps = PropsWithChildren<{
  href?: string;
  tabIndex?: number;
  icon?: React.ReactNode;
  additionalContent?: React.ReactNode;
  selected?: boolean;
  variant?: ListMenuProps["variant"];
  "data-key"?: number | string;
  title?: string;
  onClick?(): void;
  sx?: CSSObject;
}>;

const ListMenuItemCommon = forwardRef<ListMenuItemCommonProps, "li">(
  (
    {
      variant,
      additionalContent,
      selected,
      "data-key": dataKey,
      icon,
      children,
      sx = {},
      ...rest
    }: PropsWithChildren<ListMenuItemCommonProps>,
    ref
  ) => {
    const styles = useMultiStyleConfig("List", { variant });

    return (
      <StylesProvider value={styles}>
        <ChakraListItem
          ref={ref}
          aria-selected={selected}
          role="option"
          // @ts-ignore: Deal with this during refactor
          variant={variant}
          data-key={dataKey ? String(dataKey) : null}
          {...rest}
          sx={{ ...styles.item, ...sx }}
        >
          {icon && <ScIconWrapper role="presentation">{icon}</ScIconWrapper>}
          {children}
          {additionalContent && (
            <Box
              textStyle="body2"
              fontStyle="italic"
              display="inline-block"
              marginLeft="auto"
              my="0"
              color="grey"
              pointerEvents="none"
            >
              {additionalContent}
            </Box>
          )}
        </ChakraListItem>
      </StylesProvider>
    );
  }
);

export { ListMenuItemCommon };

type ListMenuHeaderProps = Omit<
  ListMenuItemCommonProps,
  | "variant"
  | "href"
  | "icon"
  | "additionalContent"
  | "selected"
  | "tabIndex"
  | "data-key"
>;

export const ListMenuHeader: FC<ListMenuHeaderProps> = (props) => {
  return <ListMenuItemCommon {...props} variant="with-header" />;
};

type ListMenuItemProps = Omit<
  ListMenuItemCommonProps,
  "variant" | "tabIndex"
> & {
  href?: string;
  to?: string;
};

export const ListMenuItem: FC<ListMenuItemProps> = ({ to, ...props }) => {
  if (!to) {
    return <ListMenuItemContent {...props} />;
  }

  return (
    <ScLink to={to} data-testid="list-menu-item-anchor">
      <ListMenuItemContent {...props} />
    </ScLink>
  );
};

const ListMenuItemContent: FC<Omit<ListMenuItemProps, "to">> = (props) => {
  const ref = useRef<HTMLLIElement | null>(null);

  return (
    <ListMenuItemCommon
      variant="default"
      ref={ref}
      tabIndex={ref.current?.matches(":focus") ? 1 : -1}
      {...props}
    />
  );
};

type ListMenuButtonItemProps = Omit<
  ListMenuItemCommonProps,
  "variant" | "tabIndex"
> &
  ButtonProps & {
    isDisabled?: boolean;
  };

/**
 * Item for a `ListMenu` that behaves like a button. Some **caveats**:
 * * At this moment, keyboard navigation within the ListMenu won't work when
 *   using these.
 * * Right now the styles assume all elements are either buttons or links, so
 *   it's recommended not to use both combined (plus links support keyboard
 *   navigation but it will break when reaching buttons).
 */
export const ListMenuButtonItem: FC<ListMenuButtonItemProps> = ({
  isDisabled,
  onClick,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement | null>(null);

  return (
    <ListMenuItemCommon
      type="button"
      {...props}
      as="button"
      // According to these docs, using Chakra's `forwardRef` should be enough
      // for the `as` prop to work properly, and the assumption is that it
      // should correctly type `ref` as well.
      // https://chakra-ui.com/community/recipes/as-prop#option-1-using-forwardref-from-chakra-uireact
      // That is not the case for us though - `ListMenuItemCommon` only accepts
      // a `ref` to a `li` element even if we have `as="button"` above. This
      // might be some issue with Chakra, but we also have plenty of custom
      // code that could be confusing the type signatures. We might refactor
      // this component to avoid having non-li children of a list parent, and
      // at that point this issue will become irrelevant, so for now we ignore
      // it in TS.
      // @ts-expect-error
      ref={ref}
      variant="default"
      tabIndex={ref.current?.matches(":focus") ? 1 : -1}
      _last={{ _before: { visibility: "hidden" } }}
      onClick={() => !isDisabled && onClick?.()}
      sx={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
    />
  );
};

type ListMenuProps = Pick<LayoutProps, "w" | "width"> & {
  variant?: "default" | "with-header";
  onClick?(event: React.SyntheticEvent<HTMLUListElement>, key?: string): void;
  // both render props and default render available
  children?:
    | PropsWithChildren<{}>["children"]
    | ((props: {
        Item: React.ComponentType<React.ComponentProps<typeof ListMenuItem>>;
        Header: React.ComponentType<
          React.ComponentProps<typeof ListMenuHeader>
        >;
      }) => JSX.Element);
  focusOnLoad?: boolean;
  sx?: CSSObject;
};

export const ListMenu: FC<ListMenuProps> = React.forwardRef(
  (
    {
      variant = "default",
      children,
      onClick,
      focusOnLoad = false,
      width = "100%",
      w,
      sx = {},
    },
    ref
    // Adding a ref to this triggered cognitive complexity. It's unclear if it's
    // worth refactoring this component or just throwing it away given that it
    // has caused us issues in the past. This is happening as part of working on
    // retail media where we are on a huge crunch, so skipping the check for
    // now.
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    const listMenuWidth = width || w;
    const styles = useMultiStyleConfig("List", { variant });
    const innerRef = useRef<HTMLUListElement | null>(null);
    const mergedRef = useMergeRefs(ref, innerRef);

    // Add custom styles to listmenu container
    const listMenuStyle = sx
      ? Object.assign(styles.container ?? {}, sx)
      : styles.container;

    const [maxHeight, setMaxHeight] = useState<string>("1px");

    /**
     * Click handler for list element that catches
     * all click events and determines whether the
     * event should be handled. In addition to that
     * applies client's passed click handler
     */
    const handleClick = (
      event: Parameters<NonNullable<ListMenuProps["onClick"]>>[0]
    ) => {
      const eventTarget = event.target as HTMLElement;
      if (
        eventTarget instanceof HTMLLIElement &&
        eventTarget.getAttribute("variant") !== "with-header"
      ) {
        const key = eventTarget.dataset.key;
        onClick?.(event, key);

        // external link
        const href = eventTarget.getAttribute("href");
        if (href) {
          window.open(href);
        }
      }
    };

    /**
     * Handling focus change on list element and determining
     * triggered list item that should receive focus
     */
    const handleFocus = (event: React.FocusEvent) => {
      const listElement = event.target as HTMLLIElement;
      listElement.focus();
    };

    /**
     * Handling hover on list element and determining
     * triggered list item that should receive focus
     */
    const handleMouseOver = (event: React.MouseEvent) => {
      const listElement = event.target as HTMLLIElement;
      listElement.focus();
    };

    /**
     * Handling key down event on list element and determining
     * next sibling list element that should receive focus
     *
     * In addition to that handling "Enter" press that should
     * trigger click event on list menu item currently being focused
     */
    const handleKeyDown = (event: React.KeyboardEvent) => {
      event.preventDefault();

      const listElement = event.target as HTMLLIElement;

      if (
        event.key === "ArrowDown" ||
        (event.key === "Tab" && !event.shiftKey)
      ) {
        let nextListElement: HTMLElement = listElement.parentElement
          ?.nextElementSibling?.firstElementChild as HTMLLinkElement;
        if (!nextListElement) {
          nextListElement = listElement.nextElementSibling as HTMLLIElement;
        }
        nextListElement?.focus();
      }

      if (event.key === "Enter") {
        listElement.click();
      }
    };

    /**
     * Handling key up event on list element and determining
     * next sibling list element that should receive focus.
     */
    const handleKeyUp = (event: React.KeyboardEvent) => {
      event.preventDefault();

      const listElement = event.target as HTMLLIElement;
      if (event.key === "ArrowUp" || (event.key === "Tab" && event.shiftKey)) {
        let prevListElement: HTMLElement = listElement.parentElement
          ?.previousElementSibling?.firstElementChild as HTMLLinkElement;
        if (!prevListElement) {
          prevListElement = listElement.previousElementSibling as HTMLLIElement;
        }
        prevListElement?.focus();
      }
    };

    useEffect(() => {
      /**
       * Control scroll behavior
       * <= 6 items - without scroll
       * > 6 items - scrollable container
       */
      const height = Array.from(innerRef.current?.children ?? [])
        .slice(0, 6)
        .reduce<number>((maxHeight, node) => {
          maxHeight += (node as HTMLElement).offsetHeight;
          return maxHeight;
        }, 0);
      setMaxHeight(height + "px");
    }, []);

    /**
     * On first component load the first eligible
     * list menu item should receive focus.
     */
    useEffect(() => {
      if (focusOnLoad) {
        const firstFocusableElement = innerRef.current?.querySelector(
          'li[variant="default"]'
        ) as HTMLLIElement | undefined;
        firstFocusableElement?.focus();
      }
    }, [focusOnLoad]);

    return (
      <StylesProvider value={styles}>
        <ChakraList
          ref={mergedRef}
          role="listbox"
          onClick={handleClick}
          onFocus={handleFocus}
          onMouseOver={handleMouseOver}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          sx={listMenuStyle}
          maxHeight={maxHeight}
          w={listMenuWidth}
        >
          {typeof children === "function"
            ? children({
                Item: ListMenuItem,
                Header: ListMenuHeader,
              })
            : children}
        </ChakraList>
      </StylesProvider>
    );
  }
);

ListMenu.displayName = "ListMenu";
