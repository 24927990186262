import React from "react";
import { Link } from "react-router-dom";
import {
  AccordionButton,
  Box,
  Button,
  ButtonProps,
  keyframes,
} from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";
import * as amplitude from "utils/amplitude/events";

const ripple = keyframes({
  "0%": { transform: "scale(1)", opacity: 0.65 },
  "100%": { transform: "scale(2.5)", opacity: 0.02 },
});

const getButtonProps = (
  isActive: boolean,
  isSidebarExpanded: boolean
): ButtonProps => ({
  py: "12px",
  height: "44px",
  minHeight: "44px",
  borderRadius: "4px",
  textTransform: "capitalize",
  justifyContent: "flex-start",
  transitionTimingFunction: "ease-in-out",
  transition: "background-color 150ms, color 150ms, width 300ms, padding 300ms",
  paddingInline: isSidebarExpanded ? "12px 8px" : "12px",
  width: isSidebarExpanded ? "200px" : "44px",
  color: isActive ? "sidebar-blue" : "grey-dark",
  backgroundColor: isActive ? "grey-pale" : "inherit",
  _focus: { _hover: { textDecoration: "none" } },
  _hover: {
    backgroundColor: "grey-pale",
    textDecoration: "none",
    color: isActive ? "sidebar-blue" : "grey-dark",
  },
});

const RightIcon = ({ isExpanded }: { isExpanded: boolean }) => {
  return (
    <ChakraIcon
      color="grey-dark"
      icon="link-specific-chevron-down-16"
      opacity={isExpanded ? 1 : 0}
      transition="opacity 150ms, transform 300ms"
      transitionTimingFunction="ease-in-out"
      transformOrigin="center"
      _groupHover={{
        opacity: 1,
        transform: isExpanded ? "rotate(-180deg)" : "rotate(0deg)",
      }}
    />
  );
};

export default function MenuItem({
  icon,
  isSidebarExpanded,
  label,
  isActive,
  onClick,
  as,
  href,
  isExpanded,
  showIndicator,
}: Props) {
  const props =
    as === Link ? { to: href, as } : as === "a" ? { href, as } : { as };

  const isButton = as === AccordionButton;

  const indicator = {
    content: '""',
    position: "absolute",
    left: "6px",
    top: "6px",
    height: "8px",
    aspectRatio: 1,
    borderRadius: "50%",
    backgroundColor: "success",
  };

  return (
    <Button
      {...props}
      {...getButtonProps(!!isActive, isSidebarExpanded)}
      {...(showIndicator
        ? {
            position: "relative",
            _before: indicator,
            _after: {
              ...indicator,
              animation: `${ripple} 1200ms infinite ease-in`,
            },
          }
        : {})}
      onClick={() => {
        onClick && onClick();
        amplitude.sendAmplitudeEvent({
          eventName: amplitude.AmplitudeEvents.MenuItemClicked,
          eventProperties: { label },
        });
      }}
      data-group
      leftIcon={
        <ChakraIcon
          color={isActive ? "sidebar-blue" : "grey-dark"}
          icon={icon}
        />
      }
      rightIcon={
        isSidebarExpanded && isButton ? (
          <RightIcon isExpanded={!!isExpanded} />
        ) : undefined
      }
      aria-label={`${label} ${
        isSidebarExpanded ? "expanded" : "collapsed"
      } menu item`}
    >
      <Box
        w={isSidebarExpanded ? "full" : "0px"}
        opacity={isSidebarExpanded ? 1 : 0}
        overflow="hidden"
        flexGrow={1}
        aria-label="Menu label"
        textStyle="body1"
        whiteSpace="nowrap"
        textAlign="left"
        transition="300ms ease-in-out"
        transitionProperty="width, opacity"
      >
        {label}
      </Box>
    </Button>
  );
}

type DefaultProps = {
  icon: React.ComponentProps<typeof ChakraIcon>["icon"];
  isSidebarExpanded: boolean;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  isExpanded?: boolean;
  href?: string;
  showIndicator?: boolean;
};

type AnchorProps = DefaultProps & {
  as: "a";
  href: string;
};

type LinkProps = DefaultProps & {
  as: typeof Link;
  href: string;
};

type AccordionButtonProps = DefaultProps & {
  as: typeof AccordionButton;
};

type Props = AnchorProps | AccordionButtonProps | LinkProps;
