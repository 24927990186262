import addRetryToFetch from "fetch-retry";
import snakeize from "snakeize";
import camelize from "camelize";
// @refactoring Forbid usage of `ramda` (https://constructor.slab.com/posts/deprecating-ramda-and-adopting-remeda-wlks8rn7)
// eslint-disable-next-line local-rules/no-ramda
import { forEachObjIndexed } from "ramda";

import URI from "utils/urijs";
import reportError from "services/ReportError";

import { ApiError } from "./ApiErrors";

// Previous version:
// const fetchWithRetry = addRetryToFetch(fetch);
// To allow proper mocking in storybook:
export const fetchWithRetry = (fetchFn: typeof window.fetch) =>
  addRetryToFetch(fetchFn);

const defaultFetchOptions: RequestInit = {
  headers: {
    Accept: "application/json",
  },
};

export type Url = Array<string | undefined | null | number> | string;
export type HttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
type Result<T> = [T, { status: number; headers: Headers }];

export async function get<T>(url: Url, options: RequestOptions) {
  return request<T>("GET", url, options);
}

export async function post<T>(url: Url, options: RequestOptions) {
  return request<T>("POST", url, options);
}

export async function put<T>(url: Url, options: RequestOptions) {
  return request<T>("PUT", url, options);
}

export async function patch<T>(url: Url, options: RequestOptions) {
  return request<T>("PATCH", url, options);
}

export async function destroy<T>(url: Url, options: RequestOptions) {
  return request<T>("DELETE", url, options);
}

export default async function request<T>(
  method: HttpMethod,
  url: Url,
  {
    query = {},
    data,
    baseUrl,
    fetchOptions = {},
    snakeizeQuery = true,
    snakeizeData = true,
    camelizeResponse = true,
    encodeBodyToBase64 = false,
    arrayBrackets,
    retryDelay,
  }: RequestOptions = {}
) {
  const fetchUrl = getUrl(url, {
    baseUrl,
    query,
    snakeizeQuery,
    arrayBrackets,
  });
  const { headers, ...extraFetchOptions } = fetchOptions;

  let response;

  const requestHeaders = new Headers({
    ...defaultFetchOptions.headers,
    ...headers,
  });

  if (data) {
    requestHeaders.append(
      "Content-Type",
      `application/${encodeBodyToBase64 ? "base64-" : ""}json; charset=utf-8`
    );
  }

  try {
    response = await fetchWithRetry(fetch)(fetchUrl, {
      retryDelay: retryDelay ?? exponentialBackoffDelay,
      retryOn: (attempt, _error, response) => {
        // This high number of retries might only be necessary in certain cases
        return attempt < 10 && response?.status === 429;
      },
      method,
      body: getBody(data, { snakeizeData, encodeBodyToBase64 }),
      headers: requestHeaders,
      ...extraFetchOptions,
    });
  } catch (rawError: unknown) {
    const error = rawError as Error;
    // The AbortError is a part of the custom logic provided as "fetch" options
    // and it is expected for consumer to take care of it (most probably, catch
    // this error and ignore it)
    if (error.name === "AbortError") {
      throw error;
    }

    // Fetch would throw error if there will be network issues (DNS resolve
    // error, non reachable host and similar). We report this errors to Sentry
    // and let user know that there was a error sending the HTTP request.
    const requestError = new RequestError({
      method,
      url: fetchUrl,
      status: error.message,
      displayErrorMessage: "HTTP request failed",
    });
    reportError(requestError);

    throw requestError;
  }

  const result = await getResult<T>(response, {
    method,
    url: fetchUrl,
    camelizeResponse,
  });

  return result;
}

export type RequestOptions = {
  // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query?: Record<string, any>;
  // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: Record<string, any>;
  baseUrl?: string;
  fetchOptions?: RequestInit;
  snakeizeQuery?: boolean;
  snakeizeData?: boolean;
  camelizeResponse?: boolean;
  arrayBrackets?: boolean;
  retryDelay?: number;

  // Sometimes AWS WAF blocks valid requests that might contain HTML which is
  // often used in the "content rules". To work this around, we encode the
  // request body into base64 to hide the request content from WAF. Each rails
  // API endpoint should support accepting base64-encoded requests individually.
  //
  // More on this here:
  //   * https://constructor.slack.com/archives/C03FZHH1SBB/p1660864038819419
  //   * https://constructor.slack.com/archives/C8EGZRPC6/p1661158744057459
  encodeBodyToBase64?: boolean;
};

export function getUrl(
  url: Url,
  {
    baseUrl,
    query,
    snakeizeQuery,
    arrayBrackets,
  }: {
    baseUrl?: string;
    // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    query: Record<string, any>;
    snakeizeQuery: boolean;
    arrayBrackets?: boolean;
  }
) {
  if (snakeizeQuery) {
    query = snakeize(query);
  }

  url = convertUrlToString(url);

  const urlObject = new URL(url, baseUrl || window.location.href);
  addQueryToUrlObject(urlObject, query, arrayBrackets);
  return urlObject.toString();
}

function getBody(
  data: Record<string, string> | undefined,
  {
    snakeizeData,
    encodeBodyToBase64,
  }: { snakeizeData: boolean; encodeBodyToBase64: boolean }
): string | undefined {
  if (data === undefined) {
    return undefined;
  }

  if (snakeizeData) {
    data = snakeize(data);
  }

  let result = JSON.stringify(data);
  if (encodeBodyToBase64) {
    result = Buffer.from(result).toString("base64");
  }

  return result;
}

async function getResult<T>(
  response: Response,
  {
    camelizeResponse,
    method,
    url,
  }: { method: string; url: string; camelizeResponse: boolean }
): Promise<Result<T>> {
  const status = response.status;
  const headers = response.headers;
  const text = await response.text();

  let json;

  try {
    json = text ? JSON.parse(text) : null;

    if (camelizeResponse && json instanceof Object) {
      json = camelize(json);
    }
  } catch (error) {
    let requestError = null;
    const requestErrorParams = {
      method,
      url,
      status,
      displayErrorMessage: "Internal error",
    };
    if (status === 200) {
      // This is a json parse error. The response status is 200, but response is non-parseable
      requestError = new RequestError({
        ...requestErrorParams,
        errorMessage: `Failed to parse json: ${error} - ${text}`,
      });
    } else {
      // This is a special case for the non-JSON response, which application
      // can not understand and parse correctly. Most probably, there was a
      // "Bad Gateway" error or similar, so we report this case to sentry and
      // raise "Internal error" later to user.
      requestError = new RequestError({
        ...requestErrorParams,
        response: text,
      });
    }

    reportError(requestError);
    throw requestError;
  }

  if (status === 470) {
    const requestError = handleESTimeOutError({
      method,
      url,
      status,
      error: String(json?.message),
    });

    reportError(requestError);

    throw requestError;
  }

  if (status >= 400 && !json) {
    json = {
      error: "Server returned empty response",
      message: "Server returned empty response",
    } as ApiError;
  }

  return [json, { status, headers }];
}

/**
 * Handler for Elastic Search Timeout (response status code: 470)
 */
export const handleESTimeOutError = ({
  method,
  url,
  status,
  error,
}: {
  method: string;
  url: string;
  status: number;
  error: string;
}) => {
  return new RequestError({
    method,
    url,
    status,
    errorMessage: String(error),
    displayErrorMessage: "The API server is temporarily unavailable",
  });
};

function convertUrlToString(url: Url) {
  if (url instanceof Array) {
    url =
      "/" +
      url
        .map((part) => part && encodeURIComponent(part))
        .filter((part) => part !== undefined && part !== null)
        .join("/");
  }

  return url;
}

function addQueryToUrlObject(
  urlObject: URL,
  // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: Record<string, any>,
  arrayBrackets: boolean = true,
  parentKey: string | null = null
) {
  forEachObjIndexed((value, key) => {
    if (value === undefined || value === null) {
      return;
    }

    const currentKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof Array) {
      for (const arrayValue of value) {
        ensurePrimitive(arrayValue);
        if (arrayBrackets) {
          urlObject.searchParams.append(`${currentKey}[]`, arrayValue);
        } else {
          urlObject.searchParams.append(currentKey, arrayValue);
        }
      }

      return;
    }

    if (value instanceof Object) {
      addQueryToUrlObject(urlObject, value, arrayBrackets, currentKey);
      return;
    }

    urlObject.searchParams.set(currentKey, value);
  }, query);
}

// TODO: Make this a type guard instead of throwing an error
// @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ensurePrimitive(value: any) {
  if (value instanceof Object) {
    throw new Error(
      "Can't encode a nested non-primitive value in the query string"
    );
  }
}

const MAX_BACKOFF_TIME = 20000;

function exponentialBackoffDelay(attempt: number): number {
  // For attempt N, define 2^N seconds as the maximum number of ms to wait for
  // (capped at MAX_BACKOFF_TIME).
  const maxDelay = Math.min(Math.pow(2, attempt) * 1000, MAX_BACKOFF_TIME);

  // Then wait a random time between 0 and that maximum. This will make wait
  // times statistically longer and longer, without having all requests
  // repeating at the same time.
  return Math.random() * maxDelay;
}

/**
 * Request error is designed to be used when the server didn't respond or the
 * response was not been able to be parsed.
 */
export class RequestError extends Error {
  method: string;
  target: string;
  query: string;
  response?: string;
  status?: number | string;
  displayErrorMessage?: string; // Error message to be displayed on Alert
  errorMessage?: string; // Explicitly specifying the Error message

  constructor({
    method,
    url,
    status,
    displayErrorMessage,
    errorMessage,
    response,
  }: RequestErrorParams) {
    const uri = URI(url);
    const target = uri.origin() + uri.path();

    super(getRequestErrorMessage({ method, target, status, errorMessage }));

    this.method = method;
    this.target = target;
    this.query = uri.query();

    this.status = status;
    this.response = response;
    this.errorMessage = errorMessage;
    this.displayErrorMessage = displayErrorMessage;

    Object.setPrototypeOf(this, RequestError.prototype);
  }

  get message() {
    return getRequestErrorMessage({
      method: this.method,
      target: this.target,
      status: this.status,
      errorMessage: this.errorMessage,
    });
  }

  get payload() {
    return {
      method: this.method,
      target: this.target,
      query: this.query,
      status: this.status,
      displayErrorMessage: this.displayErrorMessage,
      // cut response - otherwise, sentry reports "413 Payload Too Large"
      response: this.response && this.response.substring(0, 256),
    };
  }
}

function getRequestErrorMessage({
  method,
  target,
  status,
  errorMessage,
}: {
  method: string;
  target: string;
  status?: number | string;
  errorMessage?: string;
}): string {
  return errorMessage
    ? errorMessage
    : // eslint-disable-next-line sonarjs/no-nested-template-literals
      `HTTP request failed: ${method} ${target} ${status ? `(${status})` : ""}`;
}

type RequestErrorParams = {
  method: string;
  url: string;
  status?: number | string;
  errorMessage?: string;
  displayErrorMessage?: string;
  response?: string;
};
