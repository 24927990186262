import { ChakraComponent, Icon, IconProps } from "@chakra-ui/react";
import React from "react";

import colors from "app/designSystem/theme/foundations/colors";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { CHAKRA_ICONS } from "./IconDetails";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { CHAKRA_ICONS_KEYS } from "./iconTypes";

export type IconType = {
  icon: CHAKRA_ICONS_KEYS | ChakraComponent<typeof Icon>;
  color?: string;
  boxSize?: string;
  isDisabled?: boolean;
} & Omit<IconProps, "css">;

// TODO: move this component into the /Chakra folder and rename to Icon
export const ChakraIcon = React.forwardRef(
  (
    { icon, color, boxSize, isDisabled, onClick, ...props }: IconType,
    ref: React.Ref<SVGSVGElement>
  ) => {
    const iconColor = isDisabled ? colors["grey-disabled"] : color;
    const onClickAction = isDisabled ? undefined : onClick;
    let iconSize = boxSize;
    let iconToRender =
      typeof icon === "string" ? CHAKRA_ICONS[icon].icon : icon;

    if (typeof icon === "string") {
      iconSize = boxSize || CHAKRA_ICONS[icon].size;
      iconToRender = CHAKRA_ICONS[icon].icon;
    }

    return (
      <Icon
        ref={ref}
        as={iconToRender}
        color={iconColor}
        boxSize={iconSize}
        onClick={onClickAction}
        {...props}
      />
    );
  }
);

ChakraIcon.displayName = "ChakraIcon";
