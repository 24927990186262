import React from "react";
import { Location } from "history";
import { useLocation } from "react-router-dom";

import FeatureFlags from "utils/featureFlags";
import * as routes from "utils/routes";
import useFeatureToggle from "app/hooks/useFeatureToggle";

import {
  GEO_PERSONALIZATION_PATH,
  RULE_PERFORMANCE_PATH,
  AB_TESTING_PATH,
  QUIZZES_ANALYTICS_PATH,
} from "utils/routes";

import { Links, Link } from "./shared/MenuUI";

export default function AnalyticsMenu() {
  const browseAnalyticsEnabled = useFeatureToggle(FeatureFlags.BrowseAnalytics);

  const recommendationsEnabled = useFeatureToggle(FeatureFlags.Recommendations);

  const geoPersonalizationEnabled = useFeatureToggle(
    FeatureFlags.GeoPersonalization
  );

  const quizzesEnabled = useFeatureToggle(FeatureFlags.Quizzes);
  const abTestingEnabled = useFeatureToggle(FeatureFlags.AbTestingRoute);

  const location = useLocation();

  return (
    <Links>
      <Link
        to={"/dashboard/analytics/opportunities" + getSearch(location)}
        isActive={location.pathname.startsWith(
          "/dashboard/analytics/opportunities"
        )}
      >
        Opportunities
      </Link>

      <Link
        to={"/dashboard/analytics/search" + getSearch(location)}
        isActive={location.pathname.startsWith("/dashboard/analytics/search")}
      >
        Search
      </Link>

      {browseAnalyticsEnabled && (
        <Link
          to={"/dashboard/analytics/browse" + getSearch(location)}
          isActive={location.pathname.startsWith("/dashboard/analytics/browse")}
        >
          Browse
        </Link>
      )}

      <Link
        to={"/dashboard/analytics/conversions" + getSearch(location)}
        isActive={location.pathname.startsWith(
          "/dashboard/analytics/conversions"
        )}
      >
        Conversions
      </Link>
      {recommendationsEnabled && (
        <Link
          to={routes.analyticsRecommendations().toString()}
          isActive={location.pathname.startsWith(
            "/dashboard/analytics/recommendations"
          )}
        >
          Recommendations
        </Link>
      )}

      {quizzesEnabled && (
        <Link
          to={routes.quizzes().toString() + getSearch(location)}
          isActive={location.pathname.startsWith(QUIZZES_ANALYTICS_PATH)}
        >
          Quizzes
        </Link>
      )}

      {geoPersonalizationEnabled && (
        <Link
          to={routes.geoPersonalization().toString()}
          isActive={location.pathname.startsWith(GEO_PERSONALIZATION_PATH)}
        >
          Geo Personalization
        </Link>
      )}

      <Link
        to={routes.rulePerformance().toString()}
        isActive={location.pathname.startsWith(RULE_PERFORMANCE_PATH)}
      >
        Rule Performance
      </Link>

      {abTestingEnabled && (
        <Link
          to={routes.abTesting().toString()}
          isActive={location.pathname.startsWith(AB_TESTING_PATH)}
        >
          A/B Testing
        </Link>
      )}
    </Links>
  );
}

function getSearch(location: Location): string {
  const params = new URLSearchParams(location.search);
  const newParams = new URLSearchParams();

  const startDate = params.get("start_date");
  if (startDate) {
    newParams.set("start_date", startDate);
  }

  const endDate = params.get("end_date");
  if (endDate) {
    newParams.set("end_date", endDate);
  }

  const indexKey = params.get("index_key");
  if (indexKey !== null) {
    newParams.set("index_key", indexKey);
  }

  const result = newParams.toString();

  if (result) {
    return "?" + result;
  }

  return "";
}
