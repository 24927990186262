import { useEffect, useRef } from "react";

/**
 * The hook abstracts away the complexity of abort controllers.
 *
 * Example usage:
 * const { abort, getSignal } = useCancelRequest();
 * ...
 * const onClick = () => abort();
 * ...
 * <Component signal={getSignal()} />
 * <AnotherComponent onClick={onClick} />
 */
export default function useCancelRequest(): Result {
  const controllerRef = useRef<AbortController>(new AbortController());

  const abort: AbortController["abort"] = () => {
    controllerRef.current?.abort();
    controllerRef.current = new AbortController();
  };

  // make sure to return correct reference
  const getSignal = () => controllerRef.current.signal;

  useEffect(() => {
    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  return {
    abort,
    getSignal,
  };
}

type Result = {
  abort: AbortController["abort"];
  getSignal: () => AbortController["signal"];
};
