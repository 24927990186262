import { Platform, Product } from "./types";

export const PRODUCT_INFO: Array<Product> = [
  {
    productName: "Search",
    productKey: "productSubscriptionSearch",
    iconName: "product-search-small",
    learnMore: "https://constructor.io/products/search/",
  },
  {
    productName: "Autosuggest",
    productKey: "productSubscriptionAutosuggest",
    iconName: "product-autosuggest-small",
    learnMore: "https://constructor.io/products/autosuggest/",
  },
  {
    productName: "Browse",
    productKey: "productSubscriptionBrowse",
    iconName: "product-browse-small",
    learnMore: "https://constructor.io/products/browse/",
  },
  {
    productName: "Recommendations",
    productKey: "productSubscriptionRecommendations",
    iconName: "product-recommendations-small",
    learnMore: "https://constructor.io/products/recommendations/",
  },
  {
    productName: "Collections",
    productKey: "productSubscriptionCollections",
    iconName: "product-collections-small",
    learnMore: "https://constructor.io/products/collections/",
  },
  {
    productName: "Quizzes",
    productKey: "productSubscriptionQuizzes",
    iconName: "product-quizzes-small",
    learnMore: "https://constructor.io/products/quizzes/",
  },
  {
    productName: "Content Search",
    productKey: "productSubscriptionContentSearch",
    iconName: "product-content-search-small",
  },
];

export const PLATFORMS: Array<Platform> = [
  {
    platformKey: "web",
    platformOptions: ["FrontendAPI", "FrontendUi", "Backend"],
    iconName: "platform-web",
  },
  {
    platformKey: "android",
    platformOptions: ["Android"],
    iconName: "platform-android",
  },
  {
    platformKey: "ios",
    platformOptions: ["Ios"],
    iconName: "platform-apple-ios",
  },
];
