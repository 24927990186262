import React from "react";
import { useLocation } from "react-router-dom";

import useFeatureToggle from "app/hooks/useFeatureToggle";
import FeatureFlags from "utils/featureFlags";
import { Link } from "app/components/Navigation/shared/MenuUI";

export default function RetailMedia() {
  const location = useLocation();
  const isRetailMediaPageEnabled = useFeatureToggle(
    FeatureFlags.RetailMediaPage
  );

  if (!isRetailMediaPageEnabled) {
    return null;
  }

  return (
    <Link
      to="/dashboard/retail-media/campaigns"
      isActive={location.pathname.startsWith("/dashboard/retail-media")}
    >
      Retail Media
    </Link>
  );
}
