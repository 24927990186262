export const Menu = {
  baseStyle: {
    boxShadow: "feedback",
    backgroundColor: "white",
    borderRadius: "6px",
    overflow: "hidden",
    alignItems: "stretch",
    overflowWrap: "break-word",
    _focusVisible: {
      outline: "none",
    },
  },
};

const selectedItemStyle = {
  backgroundColor: "grey-verylight-green",
};

const activeItemStyle = {
  backgroundColor: "grey-pale",
  // For the time being, the selected styles take priority over the active item
  // styles (meaning that if the currently selected item becomes "active", it
  // won't be noticeable).
  // https://constructor.slack.com/archives/C03UR4WR89X/p1695290435995869?thread_ts=1695214817.656019&cid=C03UR4WR89X
  _selected: selectedItemStyle,
};

export const MenuItem = {
  baseStyle: {
    backgroundColor: "transparent",
    display: "flex",
    flexDirection: "column",
    border: "none",
    textAlign: "start",
    padding: 0,
    color: "grey-dark",
    cursor: "pointer",
    apply: "textStyles.body1",
    _selected: selectedItemStyle,
    _hover: {
      ...activeItemStyle,
      color: "grey-dark",
      textDecoration: "none",
    },
    _active: activeItemStyle,
    // There is no aria attribute for indicating the active element within a
    // list of elements, so we use the specific selector from headless-ui.
    // This is necessary for keyboard navigation, where the item won't be
    // hovered.
    '&[data-headlessui-state="active"]': activeItemStyle,
    _notFirst: {
      _before: {
        content: '""',
        m: 0,
        backgroundColor: "grey-light-green",
        width: "calc(100% - 40px)",
        height: "1px",
        alignSelf: "center",
      },
    },
  },
};
