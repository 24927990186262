import React, { FocusEvent } from "react";
import { Switch as ChakraSwitch } from "@chakra-ui/react";

type SwitchProps = {
  /**
   * Used to specify the variant of the Switch component
   */
  variant?: (string & {}) | "primary" | "secondary";
  /**
   * If `true`, the switch will be checked
   */
  isChecked?: boolean;
  /**
   * If `true`, the switch will be disabled
   */
  isDisabled?: boolean;
  /**
   * If `true`, the switch will be readonly
   */
  isReadOnly?: boolean;
  /**
   * Sets the aria-label for the Switch component
   */
  ariaLabel?: string;
  /**
   * Sets the default state of the Switch (true = checked, false = unchecked)
   */
  defaultChecked?: boolean;
  /**
   * Sets the value for the Switch
   */
  value?: string | number;
  /**
   * Sets the Id for the Switch
   */
  id?: string;
  /**
   * Callback function when the Switch is updated/changed
   */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Callback function when the Switch is focused
   */
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /**
   * Callback function when the Switch is blurred
   */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
};

export const Switch = ({
  isChecked,
  isDisabled,
  isReadOnly,
  ariaLabel,
  value,
  defaultChecked,
  variant,
  onChange,
  onFocus,
  onBlur,
  id,
}: SwitchProps) => {
  return (
    <ChakraSwitch
      id={id}
      isChecked={isChecked}
      isDisabled={isDisabled}
      isReadOnly={isReadOnly}
      aria-label={ariaLabel}
      defaultChecked={defaultChecked}
      value={value}
      variant={variant}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
