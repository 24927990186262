import LogRocket from "logrocket";

import * as currentUserData from "modules/currentUser/actions";

import { getLogRocketAppID } from "./logrocket/getLogRocketAppID";

const logRocketAppID = getLogRocketAppID();

if (logRocketAppID) {
  const currentAdminUserEmail: string =
    document.querySelector<HTMLMetaElement>(
      'meta[name="current_admin_user_email"]'
    )?.content || "";

  const currentUser = currentUserData.get();

  const currentCompanyId: string =
    document.querySelector<HTMLMetaElement>('meta[name="current_company_id"]')
      ?.content || "";

  const currentCompanyName: string =
    document.querySelector<HTMLMetaElement>('meta[name="current_company_name"]')
      ?.content || "";

  LogRocket.init(logRocketAppID);

  const name =
    (currentAdminUserEmail ? `${currentAdminUserEmail} as ` : "") +
    `${currentUser?.firstName} ${currentUser?.lastName}`;

  const email =
    (currentAdminUserEmail ? `${currentAdminUserEmail} as ` : "") +
    currentUser?.email;

  if (currentUser) {
    LogRocket.identify(currentUser.id, {
      name,
      email,
      is_admin: !!currentAdminUserEmail,
      company_name: currentCompanyName,
      company_id: currentCompanyId,
    });
  }
}
