import { get } from "services/request";
import { HttpStatus } from "types/http";

export async function update() {
  const [response, { status }] = await get<CsrfToken>(
    ["api", "users", "authenticity_token"],
    {
      snakeizeQuery: false,
      camelizeResponse: false,
    }
  );

  const csrfTokenElement = document.querySelector<HTMLMetaElement>(
    '[name="csrf-token"]'
  );

  if (csrfTokenElement && status === HttpStatus.Ok) {
    csrfTokenElement.setAttribute("content", response.payload);
  }
}

type CsrfToken = {
  payload: string;
};
