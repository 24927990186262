import React from "react";
import { css } from "@emotion/react";
import { useHistory } from "react-router-dom";

import useModal from "app/hooks/useModal";
import Button from "components/Modules/Button";
import Empty from "components/Modules/Empty";
import RequestBox from "components/Modules/RequestBox";
import { Index } from "modules/indexes";

import useListIndexes from "app/queries/indexes/useListIndexes";

import Form, { Body } from "./IndexKeySelector/Form";
import changeIndex from "./IndexKeySelector/changeIndex";

export default function IndexKeySelector({
  title = "No index selected",
  comment = "Select an index to view and add merchant rules.",
  autoselectSingleKey = true,
  indexes: defaultIndexes,
  allowChangingDefaultIndex = true,
  onSelect: onSelectOriginal,
  onSelectAll,
  onModalOpen = () => {},
  onModalClose = () => {},
  preserveQueryParams = false,
}: Props) {
  const history = useHistory();

  const onSelect = async (autocompleteCompany: Index) =>
    onSelectOriginal
      ? onSelectOriginal(autocompleteCompany)
      : changeIndex(autocompleteCompany, { history, preserveQueryParams });

  const [ModalGate, toggleModal] = useModal();

  const {
    error,
    isFetching,
    data: indexes,
  } = useListIndexes({
    originalIndexes: defaultIndexes,
    onlyActive: true,
    options: {
      onSuccess: (data) => {
        if (autoselectSingleKey && data.length === 1) {
          onSelect(data[0]);
        }
      },
    },
  });

  if (autoselectSingleKey && indexes?.length === 1) {
    return null;
  }

  return (
    <>
      {indexes && (
        <ModalGate onAfterOpen={onModalOpen} onAfterClose={onModalClose}>
          <Form
            onCancel={() => toggleModal(false)}
            onSelect={onSelect}
            onSelectAll={onSelectAll}
            indexes={indexes}
            allowChangingDefaultIndex={allowChangingDefaultIndex}
          />
        </ModalGate>
      )}

      <RequestBox
        spinnerContainerProps={{ minHeight: 256 }}
        fetching={isFetching}
        error={error}
      >
        <Empty
          title={title}
          image={require("./IndexKeySelector/no-index.svg")}
          imageWidth="89px"
          imageHeight="77px"
          style={{ marginTop: "96px" }}
        >
          <div>{comment}</div>

          <Button
            type="button"
            kind="primary"
            onClick={() => toggleModal(true)}
            css={css`
              margin-top: 30px;
            `}
          >
            Select index
          </Button>
        </Empty>
      </RequestBox>
    </>
  );
}

type Props = {
  title?: string;
  comment?: string;
  autoselectSingleKey?: boolean;
  indexes?: Index[];
  allowChangingDefaultIndex?: boolean;
  onSelect?: (index: Index) => void;
  onSelectAll?: () => void;
  onModalOpen?: () => void;
  onModalClose?: () => void;
  preserveQueryParams?: boolean;
};

export { Form, Body as FormBody, changeIndex };
