import React, { FC } from "react";
import { Text, Box as ChakraBox, TextProps } from "@chakra-ui/react";
import styled from "@emotion/styled";

import theme from "components/theme";
import Date from "components/Modules/Date";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import LinkWithChevron from "./LinkWithChevron";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Box from "./Box";

import * as List from "./Table/List";
export { List };

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  ${Box} &:last-child {
    margin-top: -26px;
    margin-bottom: -30px;
  }
`;

export { Table };

const THead: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <ChakraBox as="thead">{children}</ChakraBox>;
};

export { THead };

const TBody = styled.tbody``;

export { TBody };

const TFoot = styled.tfoot``;

export { TFoot };

const Tr = styled.tr`
  & + & {
    border-top: 1px solid ${theme.colors.borderGrayLight};
  }
`;

export { Tr };

type ThProps = React.PropsWithChildren<
  Pick<
    TextProps,
    "textAlign" | "padding" | "whiteSpace" | "width" | "height" | "lineHeight"
  >
>;

const Th: FC<ThProps> = ({
  children,
  textAlign,
  padding = "16px 8px",
  whiteSpace,
  width,
  height,
  lineHeight,
}) => {
  return (
    <Text
      padding={padding}
      color="grey-dark"
      textStyle="caption"
      as="th"
      textAlign={textAlign}
      whiteSpace={whiteSpace}
      width={width}
      height={height}
      lineHeight={lineHeight}
      textTransform="capitalize"
    >
      {children}
    </Text>
  );
};

export { Th };

const NumberHeader: FC<ThProps> = ({ children, ...rest }) => {
  return (
    <Th textAlign="right" {...rest}>
      {children}
    </Th>
  );
};

export { NumberHeader };

const Td = styled.td`
  padding: 16px 8px;
  color: ${theme.colors.textGrayBlue};
  font-size: 14px;

  ${TBody} & {
    border-bottom: 1px solid ${theme.colors.borderGrayLight};
  }

  ${TBody} ${Tr}:last-of-type & {
    border-bottom: 0px solid transparent;
  }

  ${TFoot} & {
    border-bottom: 1px solid transparent;
  }

  ${TFoot} ${Tr}:first-of-type & {
    border-top: 1px solid ${theme.colors.borderGrayLight};
  }

  & a {
    color: ${theme.colors.headerBlack};
  }

  & ${LinkWithChevron} {
    color: ${theme.colors.headerBlack};
  }
`;

export { Td };

const Index = styled(Td)`
  border-bottom: 1px solid transparent;
  text-transform: uppercase;
  color: ${theme.colors.headerGray};
  font-size: 12px;
  margin-top: 2px;
  width: 32px;
  word-break: normal;
`;

export { Index };

const Number = styled(Td)`
  text-align: right;
  color: ${theme.colors.headerGray};
  vertical-align: middle;
  white-space: nowrap;
  width: 1px;
`;

export { Number };

const Actions = styled(Td)`
  vertical-align: middle;
  white-space: nowrap;
  width: 1px;
  padding-left: 32px;
`;

const DateContainer = styled(Td)`
  width: 88px;
  color: var(--cio-colors-grey-dark);
`;

export { Actions };

function DateColumn({
  date,
  label,
  Component = Date,
}: {
  date?: Date | null;
  label: React.ReactNode;
  Component?: React.ComponentType<{ date: Date; label: React.ReactNode }>;
}) {
  if (!date) {
    return <>&ndash;</>;
  }

  return (
    <DateContainer>
      <Component date={date} label={label} />
    </DateContainer>
  );
}

export { DateColumn };
