import AuthApi from "services/AuthApi";

export type DeleteUserParams = {
  companyId: string;
  userId: string;
};

/**
 * @description Remove a specific user from a company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-remove-user-from-company
 * @returns {void}
 */
export default async function deleteUser({
  companyId,
  userId,
}: DeleteUserParams): Promise<void> {
  const result = await AuthApi.delete<void>([
    "v1",
    "companies",
    companyId,
    "users",
    userId,
  ]);

  return result;
}

deleteUser.key = "modules/authentication/actions/users/delete";
