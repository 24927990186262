import TippyTooltip from "./TippyTooltip/TippyTooltip";
import Info from "./TippyTooltip/Info";
import Default from "./TippyTooltip/Default";
import Secondary from "./TippyTooltip/Secondary";
import { Hint } from "./TippyTooltip/Hint";
import MainContainer from "./TippyTooltip/Container";

export default TippyTooltip;
export { Info, Default, Secondary, Hint, MainContainer };
export {
  Content,
  Title,
  Text,
  TextDark,
  Container,
  SecondaryContainer,
} from "./TippyTooltip/UI";
