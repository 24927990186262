import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@chakra-ui/react";

export default function SubItem({
  isActive,
  label,
  href,
  isExternal,
  isHistorySupported,
  isSubscribed,
  hasVisited,
  onClick,
}: Props) {
  const linkProps: Partial<React.ComponentProps<typeof Button>> = isExternal
    ? { href, as: "a", target: "_blank" }
    : isHistorySupported
    ? { to: href, as: Link }
    : { href, as: "a" };

  return (
    <Button
      {...linkProps}
      borderRadius="4px"
      backgroundColor={isActive ? "grey-pale" : "inherit"}
      justifyContent="flex-start"
      textStyle="body3"
      fontWeight="regular"
      height="40px"
      minHeight="40px"
      width="200px"
      paddingLeft="36px"
      textTransform="capitalize"
      transition="150ms ease-in-out"
      transitionProperty="background-color, color"
      _focus={{ _hover: { textDecoration: "none" } }}
      position="relative"
      onClick={onClick}
      {...(isSubscribed
        ? {
            color: isActive ? "sidebar-blue" : "grey-dark",
            _hover: {
              color: isActive ? "sidebar-blue" : "grey-dark",
              backgroundColor: "grey-pale",
              textDecoration: "none",
            },
          }
        : {
            color: isActive ? "blue-dark" : "grey",
            _hover: {
              color: isActive ? "blue-dark" : "grey",
              backgroundColor: "grey-pale",
              textDecoration: "none",
            },
            ...(hasVisited
              ? {}
              : {
                  _before: {
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    right: "8px",
                    height: "8px",
                    width: "8px",
                    borderRadius: "50%",
                    transform: "translateY(-50%)",
                    background:
                      "linear-gradient(180deg, #0B63C2 0%, #00B8D9 100%)",
                  },
                }),
          })}
    >
      {label}
    </Button>
  );
}

type Props = React.ComponentProps<typeof Button> & {
  isActive?: boolean;
  label: string;
  href: string;
  isExternal?: boolean;
  isHistorySupported?: boolean;
  isSubscribed: boolean;
  hasVisited: boolean;
  onClick: () => void;
};
