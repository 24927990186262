import { useCallback, useEffect, useState } from "react";

function useSessionStorage<T>(keyName: string, defaultValue: T | null) {
  const [value, setValue] = useState<T | null>(() => {
    try {
      const value = sessionStorage.getItem(keyName);
      return value ? JSON.parse(value) : defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });

  useEffect(() => {
    sessionStorage.setItem(keyName, JSON.stringify(value));
  }, [keyName, value]);

  const remove = useCallback(() => {
    setValue(null);
    sessionStorage.removeItem(keyName);
  }, [keyName]);

  return [value, setValue, remove] as const;
}

export default useSessionStorage;
