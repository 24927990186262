import { AllOrNone } from "utils/types";

export type CurrentUser = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string;
  defaultIndexKey: string | null;
  isIaResponsivePageLayoutActive: boolean;
};

export const CurrentUser = {
  toData(user: Partial<CurrentUser>): Partial<CurrentUserData> {
    return {
      id: user.id,
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
      default_index_key: user.defaultIndexKey,
      is_ia_responsive_page_layout_active: user.isIaResponsivePageLayoutActive,
    };
  },
  fromData(data: CurrentUserData): CurrentUser {
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      defaultIndexKey: data.default_index_key,
      isIaResponsivePageLayoutActive: data.is_ia_responsive_page_layout_active,
    };
  },
};

export type CurrentUserData = {
  id: string;
  first_name: string | null;
  last_name: string | null;
  email: string;
  default_index_key: string | null;
  is_ia_responsive_page_layout_active: boolean;
};

// Add extra parameters here and to the controller when needed
export type CurrentUserUpdate = {
  defaultIndexKey?: string | null;
  isIaResponsivePageLayoutActive?: boolean | null;
} & AllOrNone<{
  ftpPassword: string;
  ftpPasswordConfirmation: string;
}>;

export type CurrentUserUpdateData = {
  default_index_key?: string | null;
  is_ia_responsive_page_layout_active?: boolean | null;
} & AllOrNone<{
  ftp_password: string;
  ftp_password_confirmation: string;
}>;

export const CurrentUserUpdate = {
  toData(properties: CurrentUserUpdate): CurrentUserUpdateData {
    let data: CurrentUserUpdateData = {
      default_index_key: properties.defaultIndexKey,
      is_ia_responsive_page_layout_active:
        properties.isIaResponsivePageLayoutActive,
    };

    if (properties.ftpPassword) {
      data = {
        ...data,
        ftp_password: properties.ftpPassword,
        ftp_password_confirmation: properties.ftpPasswordConfirmation,
      };
    }

    return data;
  },
};
