import URI from "utils/urijs";
import { FilterByConditionType } from "modules/refinedObjects";
import { RuleType } from "modules/refinedQueries";

export * from "./routes/auth";
export * from "./routes/dates";
export * from "./routes/analytics";
export * from "./routes/recommendations";

export function catalogUploads({
  back,
  indexKey,
  task_index_key,
  task_id,
  status,
}: {
  back?: string;
  indexKey?: string;
  task_index_key?: string;
  status?: string;
  task_id?: string;
} = {}): uri.URI {
  const query: Record<string, string | undefined> = {
    index_key: indexKey,
    back,
    task_index_key,
    task_id,
    status,
  };

  return URI("/dashboard/integration/catalog_uploads").search(query);
}

export function globalBrowseSearchandizing(): uri.URI {
  return URI("/dashboard/searchandizing/global/browse");
}

export function globalSearchSearchandizing(): uri.URI {
  return URI("/dashboard/searchandizing/global/dynamic");
}

export function searchSearchandizing(
  args: {
    indexKey?: string;
    condition?: FilterByConditionType;
    ruleType?: RuleType[];
    excludeEmpty?: "True";
  } = {}
): uri.URI {
  return URI("/dashboard/searchandizing/dynamic").search({
    index_key: args.indexKey,
    condition: args.condition,
    ruleType: args.ruleType,
    excludeEmpty: args.excludeEmpty,
  });
}

export function searchandizingCampaigns(): uri.URI {
  return URI("/dashboard/searchandizing/campaigns");
}

export function newCampaign(args: {
  query?: string[];
  filters?: { filterName: string; filterValue: string }[];
  back?: string;
}): uri.URI {
  const filters: Record<string, string[]> = {};

  if (args.filters) {
    Object.values(args.filters).forEach((filter) => {
      const key = `filter[${filter.filterName}]`;

      if (!filters[key]) {
        filters[key] = [filter.filterValue];
        return;
      }

      filters[key].push(filter.filterValue);
    });
  }

  return URI("/dashboard/searchandizing/campaigns/new").search({
    back: args.back,
    query: args.query,
    ...filters,
  });
}

export function campaignsEdit(args: {
  id: string;
  indexKey?: string;
  back?: string;
  preview?: string;
}): uri.URI {
  const { id } = args;
  return URI.expand("/dashboard/searchandizing/campaigns/edit/{id}", {
    id,
  }).search({
    index_key: args.indexKey,
    back: args.back,
    preview: args.preview,
  });
}

export function searchRedirectTerm(
  indexKey: string,
  searchRedirectId: number
): uri.URI {
  const uri = URI("/dashboard/redirect-rules")
    .setSearch("index_key", indexKey)
    .setSearch("editId", searchRedirectId.toString());
  return uri;
}

export function facetCategorySearchandizing({
  back,
  groupId,
  indexKey,
}: {
  groupId: string;
  back?: string;
  indexKey?: string;
}): uri.URI {
  return URI.expand("/dashboard/modify-facets/browse/group_id/{groupId}", {
    groupId,
  }).search({ back, index_key: indexKey });
}

export function facetCollectionSearchandizing({
  back,
  collectionId,
  indexKey,
}: {
  collectionId: string;
  back?: string;
  indexKey?: string;
}): uri.URI {
  return URI.expand(
    "/dashboard/modify-facets/browse/collection_id/{collectionId}",
    {
      collectionId,
    }
  ).search({ back, index_key: indexKey });
}

export function categoryFacetSearchandizingEditOptions(
  groupId: string,
  facetGroupName: string,
  { indexKey, back }: { indexKey?: string; back?: string } = {}
): uri.URI {
  return URI.expand(
    "/dashboard/modify-facets/browse/group_id/{groupId}/{facetGroupName}",
    { groupId, facetGroupName }
  ).search({
    index_key: indexKey,
    back,
  });
}

export function collectionFacetSearchandizingEditOptions(
  collectionId: string,
  facetGroupName: string,
  { indexKey, back }: { indexKey?: string; back?: string } = {}
): uri.URI {
  return URI.expand(
    "/dashboard/modify-facets/browse/collection_id/{collectionId}/{facetGroupName}",
    { collectionId, facetGroupName }
  ).search({
    index_key: indexKey,
    back,
  });
}

export function facets({
  indexKey,
  back,
}: {
  indexKey?: string;
  back?: string;
}): uri.URI {
  return URI("/dashboard/facets").search({ index_key: indexKey, back });
}

export function editFacet({
  facetName,
  indexKey,
}: {
  facetName: string;
  indexKey?: string;
}): uri.URI {
  return URI.expand("/dashboard/facets/{facetName}/edit", {
    facetName,
  }).search({ index_key: indexKey });
}

export function editFacetOption({
  facetName,
  optionValue,
  indexKey,
}: {
  facetName: string;
  optionValue: string;
  indexKey?: string;
}): uri.URI {
  return URI.expand("/dashboard/facets/{facetName}/options/{optionValue}", {
    facetName,
    optionValue,
  }).search({ index_key: indexKey });
}

export function searchabilities({
  indexKey,
}: { indexKey?: string } = {}): uri.URI {
  return URI("/dashboard/searchabilities").search({ index_key: indexKey });
}

export function editSearchability({
  name,
  indexKey,
}: {
  name: string;
  indexKey?: string;
}): uri.URI {
  return URI.expand("/dashboard/searchabilities/edit/{name}", { name }).search({
    index_key: indexKey,
  });
}

export function dataSetItems(actPartId: number): uri.URI {
  return URI("/dashboard/data_set_items").search({ ac_part: actPartId });
}

export function collections({ indexKey }: { indexKey?: string } = {}): uri.URI {
  return URI("/dashboard/collections").search({ indexKey });
}

type CollectionArgs = {
  id: string;
  back?: string;
  indexKey?: string;
};

export function collectionEdit(args: CollectionArgs): uri.URI {
  const { id } = args;
  return URI.expand("/dashboard/collections/edit/{id}", { id }).search({
    index_key: args.indexKey,
    back: args.back,
  });
}

export function collectionsSearchandizing({
  collectionId,
  back,
  indexKey,
}: {
  collectionId: string;
  back?: string;
  indexKey?: string;
}): uri.URI {
  return URI.expand("/dashboard/searchandizing/collections/{id}", {
    id: collectionId,
  }).search({
    back,
    index_key: indexKey,
  });
}

export function browseSearchandizing({
  filterName,
  filterValue,
  back,
  tab,
  indexKey,
  condition,
  ruleType,
  excludeEmpty,
}: {
  filterName?: string;
  filterValue?: string;
  back?: string;
  tab?: string;
  indexKey?: string;
  condition?: FilterByConditionType;
  ruleType?: RuleType[];
  excludeEmpty?: "True";
} = {}): uri.URI {
  const uri = URI.expand(
    "/dashboard/searchandizing/browse{/filterName}{/filterValue}",
    {
      filterName,
      filterValue,
    }
  ).setSearch({
    back,
    tab,
    index_key: indexKey,
    condition,
    ruleType,
    excludeEmpty,
  });

  return uri;
}

export function searchQuerySearchandizing({
  query,
  back,
  tab,
  section,
}: {
  query: string;
  back?: string;
  // in search, we should have section name instead of just true/false
  tab?: string;
  section?: string;
}): uri.URI {
  const uri = URI.expand("/dashboard/searchandizing/dynamic/{query}", {
    query,
  }).setSearch({ back, tab, section });

  return uri;
}

export function interact(args: InteractArgs): uri.URI {
  const query: Record<string, string | undefined> = {
    index_key: args.indexKey,
    back: args.back,
    q: args.query,
    p: args.page ? args.page.toString() : "1",
    campaign: args.campaignId,
  };

  if (args.filter) {
    const { name, value } = args.filter;

    if (name === "group_id") {
      query.category = value;
    } else if (name === "collection_id") {
      query.collection = value;
    } else {
      query.facet = [name, value].join(":");
    }
  }

  return URI("/dashboard/interact").search(query);
}

type InteractArgs = {
  indexKey?: string;
  back?: string;
  page?: number;
  query?: string;
  filter?: { name: string; value: string };
  campaignId?: string;
};

type QuizArgs = {
  id: string;
  back?: string;
  indexKey?: string;
};

export function quizEdit(args: QuizArgs): uri.URI {
  const { id } = args;
  return URI.expand("/dashboard/quizzes/edit/{id}", { id }).search({
    index_key: args.indexKey,
    back: args.back,
  });
}

export function quizList(): uri.URI {
  return URI("/dashboard/quizzes");
}

export function quizCreate(args: {
  displayName: string;
  id: string;
  back: string;
}): uri.URI {
  return URI("/dashboard/quizzes/new").search(args);
}

export function quizAnalyticsRoute({
  quizId,
  back,
  indexKey,
  startDate,
  endDate,
}: {
  quizId: string;
  back?: string;
  indexKey?: string;
  startDate?: string;
  endDate?: string;
}): uri.URI {
  return URI.expand("/dashboard/analytics/quizzes/{quizId}", {
    quizId,
  }).search({
    back: back,
    index_key: indexKey,
    start_date: startDate,
    end_date: endDate,
  });
}

export function itemAnalyticsRoute({
  itemId,
  back,
  indexKey,
  startDate,
  endDate,
}: {
  itemId: string;
  back?: string;
  indexKey?: string;
  startDate?: string;
  endDate?: string;
}): uri.URI {
  return URI.expand("/dashboard/analytics/items/{itemId}", {
    itemId,
  }).search({
    back: back,
    index_key: indexKey,
    start_date: startDate,
    end_date: endDate,
  });
}

export function integrationIngestions({
  indexKey,
  back,
}: Partial<{
  indexKey: string;
  back: string;
}> = {}): uri.URI {
  const query: Record<string, string | undefined> = {};

  if (indexKey) {
    query.index_key = indexKey;
  }

  if (back) {
    query.back = back;
  }

  return URI("/dashboard/integration/ingestions").search(query);
}

export function integrationIngestionsDownloads(
  id: string,
  {
    indexKey,
  }: Partial<{
    indexKey: string;
  }> = {}
): uri.URI {
  const query: Record<string, string | undefined> = {};

  if (indexKey) {
    query.index_key = indexKey;
  }

  return URI.expand("/dashboard/integration/ingestions/{taskId}/download", {
    taskId: id,
  }).search(query);
}

export function items({
  indexKey,
  section,
}: {
  indexKey: string;
  section: string;
}): uri.URI {
  return URI("/dashboard/indexes/items").search({
    index_key: indexKey,
    section,
  });
}

export function oneWaySynonyms({
  indexKey,
  phrase,
}: {
  indexKey: string;
  phrase?: string;
}): uri.URI {
  return URI("/dashboard/synonyms/one").search({ index_key: indexKey, phrase });
}

export function twoWaySynonyms({
  indexKey,
  phrase,
}: {
  indexKey: string;
  phrase?: string;
}): uri.URI {
  return URI("/dashboard/synonyms/two").search({ index_key: indexKey, phrase });
}

const urlsWithHistoryApiSupport = [
  /^\/dashboard\/?$/,
  /^\/dashboard\/indexes/,
  /^\/dashboard\/synonyms\/one$/,
  /^\/dashboard\/synonyms\/one\//,
  /^\/dashboard\/synonyms\/two$/,
  /^\/dashboard\/synonyms\/two\//,
  /^\/dashboard\/facets/,
  /^\/dashboard\/searchabilities/,
  /^\/dashboard\/collections/,
  /^\/dashboard\/indexes\/items/,

  // recommendations
  /^\/dashboard\/recommendations/,

  // refined queries
  /^\/dashboard\/searchandizing\/dynamic/,
  /^\/dashboard\/searchandizing\/dynamic\/.*/,

  // refined filters
  /^\/dashboard\/searchandizing\/browse/,
  /^\/dashboard\/searchandizing\/browse\/.*/,

  // refined collections
  /^\/dashboard\/searchandizing\/collections\/.*/,

  // campaigns
  /^\/dashboard\/searchandizing\/campaigns\/?.*/,

  // global searchandizing
  /^\/dashboard\/searchandizing\/global/,

  // interact
  /^\/dashboard\/interact/,

  // integration
  /^\/dashboard\/integration\/performance/,
  /^\/dashboard\/integration\/catalog_uploads/,
  /^\/dashboard\/integration\/api_tokens/,
  /^\/dashboard\/integration\/catalog_uploads/,

  // analytics
  /^\/dashboard\/analytics\/search/,
  /^\/dashboard\/analytics\/browse/,
  /^\/dashboard\/analytics\/terms/,
  /^\/dashboard\/analytics\/opportunities/,
  /^\/dashboard\/analytics\/conversions/,
  /^\/dashboard\/analytics\/recommendations/,
  /^\/dashboard\/analytics\/geo_personalization/,
  /^\/dashboard\/analytics\/quizzes*/,
  /^\/dashboard\/analytics\/rule_performance/,
  /^\/dashboard\/analytics\/rule_performance\/browse\/.*/,
  /^\/dashboard\/analytics\/rule_performance\/search\/.*/,

  // analytics ab-testing
  /^\/dashboard\/analytics\/ab_testing/,
  /^\/dashboard\/analytics\/ab_testing\/.*/,

  // modify facets - categories
  /^\/dashboard\/modify-facets\/browse/,
  /^\/dashboard\/modify-facets\/browse\/.*/,

  // modify facets - collections
  /^\/dashboard\/modify-facets\/collections/,
  /^\/dashboard\/modify-facets\/collections\/.*/,

  // quizzes
  /^\/dashboard\/quizzes/,
  /^\/dashboard\/quizzes\/.*/,

  // attribute enrichments
  /^\/dashboard\/attribute_enrichment/,

  // auth service account management
  /^\/dashboard\/accounts_v2*/,
];

export function isHistoryApiSupported(
  url: string,
  { currentUrl }: { currentUrl: string }
): boolean {
  if (url.startsWith("?") && currentUrl !== undefined) {
    url = URI(currentUrl).pathname() + url;
  }

  const path = URI(url).path();
  return urlsWithHistoryApiSupport.some((expr) => !!path.match(expr));
}
