import React, { lazy } from "react";

import { AnalyticsMenu as Menu } from "app/components/Navigation";

import BaseRoute from "./shared/Route";
import Loading from "./shared/Loading";

const ABTesting = lazy(() => import("./analytics/ab_testing"));

const ABTestingSearchandizingReport = lazy(
  () => import("app/pages/analytics/ab_testing/view/searchandizing/[testId]")
);

const ABTestingRecommendationsReport = lazy(
  () => import("app/pages/analytics/ab_testing/view/recommendations/[testId]")
);

const ABTestingNewSearchandising = lazy(
  () => import("./analytics/ab_testing/new/[testType]")
);

const ABTestingNewRecommendations = lazy(
  () => import("./analytics/ab_testing/new/recommendations")
);

const RulePerformance = lazy(() => import("./analytics/rule_performance"));

const SearchRuleDetails = lazy(
  () =>
    import("./analytics/rule_performance/search/[query]/[position]/[itemId]")
);

const BrowseRuleDetails = lazy(
  () =>
    import(
      "./analytics/rule_performance/browse/[filterName]/[filterValue]/[position]/[itemId]"
    )
);

const Opportunities = lazy(() => import("./analytics/opportunities"));

const TermOpportunities = lazy(
  () => import("./analytics/opportunities/[term]")
);

const Search = lazy(() => import("./analytics/search"));
const Term = lazy(() => import("./analytics/search/term/[term]"));
const Browse = lazy(() => import("./analytics/browse"));

const BrowseCategory = lazy(
  () => import("./analytics/browse/group_id/[categoryId]")
);

const BrowseCollection = lazy(
  () => import("./analytics/browse/collection_id/[collectionId]")
);

const Facet = lazy(
  () => import("./analytics/browse/facet/[filter_name]/[filter_value]")
);

const Conversions = lazy(() => import("./analytics/conversions"));
const Recommendations = lazy(() => import("./analytics/recommendations"));

const RecommendationsPod = lazy(
  () => import("./analytics/recommendations/pods/[pod_id]")
);

const GeoPersonalization = lazy(
  () => import("./analytics/geo_personalization")
);

const Quizzes = lazy(() => import("./analytics/quizzes"));
const QuizAnalytics = lazy(() => import("./analytics/quizzes/[quizId]"));

const ItemAnalytics = lazy(() => import("./analytics/items/[itemId]"));

function Route(props: Omit<React.ComponentProps<typeof BaseRoute>, "loading">) {
  return <BaseRoute {...props} loading={<Loading menu={<Menu />} />} />;
}

export default function analyticsRoutes() {
  return (
    <>
      <Route
        exact
        path="/dashboard/analytics/opportunities"
        component={Opportunities}
      />
      <Route exact path="/dashboard/analytics/search" component={Search} />
      <Route exact path="/dashboard/analytics/terms/:term" component={Term} />
      <Route
        exact
        path="/dashboard/analytics/opportunities/:term"
        component={TermOpportunities}
      />
      <Route exact path="/dashboard/analytics/browse" component={Browse} />
      <Route
        exact
        path="/dashboard/analytics/browse/group_id/:categoryId"
        component={BrowseCategory}
      />
      <Route
        exact
        path="/dashboard/analytics/browse/collection_id/:collectionId"
        component={BrowseCollection}
      />
      <Route
        exact
        path="/dashboard/analytics/browse/facet/:filterName/:filterValue"
        component={Facet}
      />
      <Route
        exact
        path="/dashboard/analytics/conversions"
        component={Conversions}
      />
      <Route
        exact
        path="/dashboard/analytics/recommendations"
        component={Recommendations}
      />
      <Route
        exact
        path="/dashboard/analytics/recommendations/pods/:podId"
        component={RecommendationsPod}
      />
      <Route
        exact
        path="/dashboard/analytics/geo_personalization"
        component={GeoPersonalization}
      />
      <Route
        exact
        path="/dashboard/analytics/rule_performance"
        component={RulePerformance}
      />
      <Route
        exact
        path="/dashboard/analytics/rule_performance/search/:query/:position/:itemId"
        component={SearchRuleDetails}
      />
      <Route
        exact
        path="/dashboard/analytics/rule_performance/browse/:filterName/:filterValue/:position/:itemId"
        component={BrowseRuleDetails}
      />
      <Route
        exact
        path="/dashboard/analytics/ab_testing"
        component={ABTesting}
      />
      <Route
        exact
        path="/dashboard/analytics/ab_testing/view/searchandizing/:testId"
        component={ABTestingSearchandizingReport}
      />
      <Route
        exact
        path="/dashboard/analytics/ab_testing/view/recommendations/:testId"
        component={ABTestingRecommendationsReport}
      />
      <Route
        exact
        path="/dashboard/analytics/ab_testing/new/search"
        render={() => <ABTestingNewSearchandising testType="search" />}
      />
      <Route
        exact
        path="/dashboard/analytics/ab_testing/new/browse"
        render={() => <ABTestingNewSearchandising testType="browse" />}
      />
      <Route
        exact
        path="/dashboard/analytics/ab_testing/new/recommendations"
        component={ABTestingNewRecommendations}
      />
      <Route exact path="/dashboard/analytics/quizzes" component={Quizzes} />
      <Route
        exact
        path="/dashboard/analytics/quizzes/:quizId"
        component={QuizAnalytics}
      />
      <Route
        exact
        path="/dashboard/analytics/items/:itemId"
        component={ItemAnalytics}
      />
    </>
  );
}
