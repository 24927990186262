import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import isPropValid from "@emotion/is-prop-valid";
import Tippy from "@tippyjs/react";

import Icon from "components/Modules/Icon";
import theme from "components/theme";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Input from "./Input";

export default function Dropdown({
  hasValue = true,
  disabled,
  children,
  dropdown,
  className,
}: Props) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Tippy
      interactive
      duration={0}
      content={
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => setIsExpanded(false)}
        >
          {dropdown}
        </div>
      }
      visible={isExpanded}
      onClickOutside={() => setIsExpanded(false)}
      placement="bottom"
    >
      <Container
        type="button"
        disabled={disabled}
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        className={className}
      >
        <Title hasValue={hasValue}>{children}</Title>

        <Icon
          icon="chevron-down"
          css={css`
            flex: 0 0 24px;
            ${disabled && "color: var(--neutral-disabledgrey);"}
          `}
        />
      </Container>
    </Tippy>
  );
}

type Props = {
  hasValue?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  dropdown: React.ReactNode;
  className?: string;
};

const Container = styled(Input)`
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: auto;

  :disabled {
    cursor: default;
    color: var(--neutral-greenishligthgrey);
    border-color: var(--neutral-blueishgrey);
  }
`.withComponent("button");

const Title = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "hasValue",
})<{ hasValue: boolean }>`
  flex: 1;
  color: ${({ hasValue }) =>
    hasValue ? theme.colors.inputColor : "var(--neutral-disabledgrey);"};
`;
