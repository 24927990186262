import en from "./locale/en.json";

const messages = new Map();

messages.set("en", en);

export const i18nConfig = {
  defaultLocale: "en",
  messages,
};
