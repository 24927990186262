import React, { useEffect, useState, ReactNode } from "react";
import {
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
  Icon,
  CheckboxGroup,
  useCheckbox,
} from "@chakra-ui/react";

type CheckboxProps = Omit<
  ChakraCheckboxProps,
  "size" | "isDisabled" | "isChecked" | "isReadOnly" | "isRequired" | "variant"
> & {
  variant?: (string & {}) | "primary" | "secondary";
  checked?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  children?: ReactNode;
};

function CustomIcon({
  // `isChecked` and `isIndeterminate`are props meant for the specific case of
  // a checkbox icon, and should not be passed down to the `Icon` component.
  // https://chakra-ui.com/docs/components/checkbox#icon
  isChecked: _isChecked,
  isIndeterminate: _isIndeterminate,
  ...props
}: // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const d =
    "M7.39441 0.986649C7.78494 0.596125 8.4181 0.596125 8.80863 0.986649C9.19915 1.37717 9.19915 2.01034 8.80863 2.40086L4.49809 6.7114C4.10756 7.10192 3.4744 7.10192 3.08388 6.7114L0.845139 4.47266C0.454615 4.08214 0.454615 3.44897 0.845139 3.05845C1.23566 2.66792 1.86883 2.66792 2.25935 3.05845L3.79098 4.59008L7.39441 0.986649Z";

  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        transform="translate(2,3)"
        strokeWidth={2}
        fill="currentColor"
        d={d}
      />
    </Icon>
  );
}

export const Checkbox = ({
  children,
  checked,
  disabled = false,
  readOnly = false,
  required = false,
  isInvalid,
  isFocusable,
  spacing = "12px",
  onChange,
  onBlur,
  onFocus,
  id,
  name,
  value,
  variant,
  title,
  ...args
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  const onToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(() => {
      return !isChecked;
    });

    onChange?.(e);
  };

  useEffect(() => {
    if (typeof checked !== "undefined" || onChange) {
      setIsChecked(checked);
    }
  }, [checked, isChecked, setIsChecked, onChange]);

  return (
    <ChakraCheckbox
      variant={variant}
      icon={<CustomIcon />}
      onChange={onToggle}
      onBlur={onBlur}
      onFocus={onFocus}
      spacing={spacing}
      isRequired={required}
      isChecked={isChecked}
      isReadOnly={readOnly}
      isDisabled={disabled}
      isFocusable={isFocusable}
      isInvalid={isInvalid}
      id={id}
      name={name}
      value={value}
      title={title}
      {...args}
    >
      {children}
    </ChakraCheckbox>
  );
};

export { CheckboxGroup, useCheckbox };
