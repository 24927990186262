import styled from "@emotion/styled";

const Container = styled.div`
  border-radius: 6px;
  border: 2px solid var(--neutral-blueishgrey);
  box-shadow: none;
  color: var(--neutral-black);
  display: block;
  font-size: 14px;
  height: 42px;
  line-height: 1.42857143;
  outline: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: 100%;

  :disabled {
    background: var(--neutral-greenishligthgrey);
  }

  :hover:not(:disabled):not(:focus):not(:focus-within) {
    border: 2px solid var(--neutral-lightgrey);
  }

  :focus,
  :focus-within {
    border: 2px solid var(--primary-green);
  }
`;

export default Container;
