import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";

const favicon = require("./favicon.png");
export const Favicon = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={favicon}
      alt="Favicon"
      htmlWidth={80}
      htmlHeight={80}
      {...props}
    />
  );
};

const logoHorizontal = require("./logo-horizontal.svg");
export const LogoHorizontal = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={logoHorizontal}
      alt="Logo Horizontal"
      htmlWidth={686}
      htmlHeight={151}
      {...props}
    />
  );
};

const logoHorizontalNew = require("./logo-horizontal-new.svg");
export const LogoHorizontalNew = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={logoHorizontalNew}
      alt="Logo Horizontal"
      htmlWidth={685}
      htmlHeight={150}
      {...props}
    />
  );
};

const logoVertical = require("./logo-vertical.png");
export const LogoVertical = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={logoVertical}
      alt="Logo Vertical"
      htmlWidth={300}
      htmlHeight={150}
      {...props}
    />
  );
};

const symbol = require("./symbol.png");
export const Symbol = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={symbol}
      sizes="256px 256px"
      alt="Symbol"
      htmlWidth={256}
      htmlHeight={256}
      {...props}
    />
  );
};
