/**
 * Scrolls to an element if id provided in location.
 */
export function scrollToHash(location: HashState): void {
  if (location.hash) {
    const element = document.getElementById(location.hash.substring(1));
    if (element) {
      element.scrollIntoView();
    }
  }
}

export type HashState = {
  hash?: string;
};
