// From https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging

export default function getDisplayName(WrappedComponent: Displayable) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

type Displayable = {
  displayName?: string;
  name?: string;
};
