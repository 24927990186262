import React from "react";
import { Box } from "@chakra-ui/react";

export const Bullet: React.FC<Props> = ({ active = false, index, onClick }) => {
  return (
    <Box
      onClick={() => onClick(index)}
      as="button"
      width="6px"
      height="6px"
      borderRadius="50%"
      padding={0}
      border="1px solid"
      cursor="pointer"
      borderColor={active ? "grey-dark" : "grey"}
      backgroundColor={active ? "grey-dark" : "white"}
    />
  );
};

type Props = {
  active?: boolean;
  index: number;
  onClick: (index: number) => void;
};
