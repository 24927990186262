import React from "react";
import styled from "@emotion/styled";
import { Field } from "formik";

import theme from "components/theme";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Label from "./Forms/Label";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as Forms from "./Forms";

const FormGroupElement = styled.div<{ hasError: boolean }>`
  margin-bottom: 24px;

  :last-child {
    margin-bottom: 0;
  }

  ${({ hasError }) =>
    hasError
      ? `
    & ${Input},
    & ${Forms.Input},
    & ${Forms.Textarea},
    & ${Forms.Container}:not(:disabled):not(:focus):not(:focus-within),
    & .error-field:not(:disabled):not(:focus):not(:focus-within),
      & ${Input}:focus,
      & .cio-form__control,
      & .cio-form__control:focus {
        border-color: ${theme.colors.danger};
        outline: none;
        box-shadow: none;
      }

    & .cio-form__message {
      color: ${theme.colors.danger};
      font-size: 13px;
      margin-top: 6px;
    }
  `
      : null}
`;

const ErrorMessage = styled.div`
  color: ${theme.colors.danger};
  font-size: 13px;
  margin-top: 6px;
`;

function getErrorMessage(errors: string | string[] | React.ReactElement) {
  if (Array.isArray(errors)) {
    return errors.join(", ");
  }

  if (React.isValidElement(errors)) {
    return errors;
  }

  // This should always be a string at this point, but this component is used
  // in very old pages that are still in JS so for the sake of safety (i.e. if
  // something not matching the type definitions makes its way here) we turn it
  // into a string.
  return errors.toString();
}

function FormGroup({
  errors,
  touched,
  ignoreTouch = false,
  children,
  label,
  id,
  help,
  className,
  hideErrors = false,
}: Props) {
  const hasError =
    (!!touched || !!ignoreTouch) &&
    !!errors &&
    (React.isValidElement(errors) || errors.length > 0);

  return (
    <FormGroupElement hasError={hasError} className={className}>
      {label ? (
        typeof label === "string" ? (
          <Label htmlFor={id} help={help}>
            {label}
          </Label>
        ) : (
          label
        )
      ) : null}

      {children}

      {hasError && !hideErrors && (
        <ErrorMessage>{getErrorMessage(errors)}</ErrorMessage>
      )}
    </FormGroupElement>
  );
}

type Props = {
  errors?: string | string[] | React.ReactElement;

  /**
   * For certian fields we want to show the error even if the field is not touched
   */
  ignoreTouch?: boolean;
  touched?: boolean;
  children?: React.ReactNode;
  label?: string | React.ReactNode;
  id?: string;
  /**
   * @deprecated
   * This prop shows an info tooltip next to the label, but depends on jQuery
   * and bootstrap under the hood. Until we migrate that, prefer to build the
   * tooltip manually and pass it as part of the `label` prop.
   */
  help?: string;
  className?: string;
  /**
   * If true, the presence of errors will be used to style components but the
   * actual messages won't be shown.
   */
  hideErrors?: boolean;
};

export default FormGroup;

export const Input = styled(Forms.Input)``.withComponent(Field);
