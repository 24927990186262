import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import URI from "utils/urijs";
import Box from "components/Modules/Box";
import { H2, Body2CopySecondary } from "components/Modules/Typography";
import FormGroup from "components/Modules/FormGroup";
import Input from "components/Modules/Forms/Input";
import Button from "components/Modules/Button";
import { Checkbox } from "app/designSystem/components";
import AuthToken from "components/forms/AuthToken";
import GlobalStyle from "components/Modules/GlobalStyle";
import ThemeProvider from "app/providers/ThemeProvider";

const Label = styled(Body2CopySecondary)`
  margin-bottom: 40px;
`.withComponent("label");

function FinishSignup() {
  const confirmationToken = new URI().search(true).confirmation_token;

  return (
    <ThemeProvider>
      <GlobalStyle />
      <Box
        css={css`
          width: 570px;
          padding: 80px 100px;
          box-shadow: var(--light-shadow);
          border-radius: 8px;
        `}
      >
        <H2
          css={css`
            text-align: center;
            margin-bottom: 40px;
            color: var(--neutral-black);
            font-weight: 500;
            font-size: 24px;
          `}
        >
          Set a password to log in
        </H2>

        <form method="POST" action="/users/confirmation">
          <input type="hidden" name="_method" value="PATCH" />
          <input
            type="hidden"
            name="confirmation_token"
            value={confirmationToken}
          />
          <AuthToken />

          <FormGroup
            label="Password"
            css={css`
              margin-bottom: 15px;

              label {
                margin-bottom: 8px;
              }
              span {
                color: var(--neutral-grey);
                font-size: 15px !important;
                font-weight: 700 !important;
              }
            `}
          >
            <Input
              type="password"
              name="user[password]"
              required
              placeholder="********"
            />
          </FormGroup>

          <Label>
            <Checkbox
              name="terms_of_service_accepted"
              required
              data-testid="terms-of-service"
              css={css`
                margin-right: 10px;
              `}
            />
            I agree to the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://constructor.io/terms-of-service-agreement/"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://constructor.io/privacy-policy/"
            >
              Privacy Policy
            </a>
          </Label>

          <div
            css={css`
              text-align: center;
            `}
          >
            <Button kind="primary">Set password</Button>
          </div>
        </form>
      </Box>
    </ThemeProvider>
  );
}

export default FinishSignup;
