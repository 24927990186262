// This is copy-paste of the source code of the npm package
// `use-persisted-state` (https://github.com/donavon/use-persisted-state)
// The copy-paste was done because this package is not maintained anymore and
// was blocking the React upgrade to 18th major version.

import { useState } from "react";

import usePersistedState from "./usePersistedState";
import createStorage from "./createStorage";

const getProvider = () => {
  if (typeof global !== "undefined" && global.localStorage) {
    return global.localStorage;
  }
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== "undefined" && globalThis.localStorage) {
    // eslint-disable-next-line no-undef
    return globalThis.localStorage;
  }
  if (typeof window !== "undefined" && window.localStorage) {
    return window.localStorage;
  }
  if (typeof localStorage !== "undefined") {
    return localStorage;
  }
  return null;
};

const createPersistedState = (key, provider = getProvider()) => {
  if (provider) {
    const storage = createStorage(provider);
    return (initialState) => usePersistedState(initialState, key, storage);
  }
  return useState;
};

export default createPersistedState;
