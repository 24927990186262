import React, { Fragment } from "react";
import { Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import {
  getSearch,
  getIsHistorySupported,
} from "app/components/shared/shared/utils";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

import type { Breadcrumbs } from "app/components/shared/actionGroupProps";

type Props = Breadcrumbs & {
  index: number;
  currentPath: string;
};

export default function Breadcrumbs({
  label,
  href,
  index,
  currentPath,
}: Props) {
  const props = href
    ? getIsHistorySupported(currentPath, href)
      ? ({ as: Link, to: href + getSearch([]) } as LinkProps)
      : ({ as: "a", href: href + getSearch([]) } as AnchorProps)
    : {};

  return (
    <Fragment key={label}>
      {!!index && <ChakraIcon icon="links-chevron-right-24" />}
      <Box
        {...props}
        textStyle="caption"
        maxW="24ch"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        color="grey-dark"
        title={
          label.length > 24
            ? label
            : undefined /* only show title if label is truncated */
        }
        _hover={{ color: "grey-dark" }}
        _focus={{ _hover: { color: "grey-dark" } }}
      >
        {label}
      </Box>
    </Fragment>
  );
}

type AnchorProps = { as: "a"; href: string };

type LinkProps = { as: typeof Link; to: string };
