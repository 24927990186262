import AuthApi from "services/AuthApi";

/**
 * @description Deletes role association from user.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-delete-assigned-company-role-to-user
 * @returns {void}
 */
export default async function deleteRoleAssociation({
  companyId,
  userId,
  roleId,
}: Props): Promise<DeleteRoleAssociationResponse> {
  const result = await AuthApi.delete<DeleteRoleAssociationResponse>([
    "v1",
    "companies",
    companyId,
    "users",
    userId,
    "roles",
    roleId,
  ]);

  return result;
}

export type DeleteRoleAssociationResponseData = {
  companyId: string;
  userId: string;
  roleId: string;
  deletedAt: string;
};

export type DeleteRoleAssociationResponse = {
  data: DeleteRoleAssociationResponseData;
  status: string;
};

export type Props = { companyId: string; userId: string; roleId: string };
