import React from "react";
import { css } from "@emotion/react";

import { DateInput as DateInputElement } from "components/Modules/Forms";
import Icon from "components/Modules/Icon";

const DateInput = React.forwardRef(
  (
    { onClear, clearable, isError, className, disabled, ...props }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <div
      className={className}
      css={css`
        position: relative;
      `}
    >
      <DateInputElement
        {...props}
        ref={ref}
        disabled={disabled}
        css={
          isError &&
          css`
            border: 2px solid var(--accent-danger);
          `
        }
      />

      {clearable && !disabled && (
        <button
          onClick={onClear}
          css={css`
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translate(0, -50%);
            cursor: pointer;
            background: none;
            border-radius: 0;
            padding: 0;
            margin: 0;
            border: none;
          `}
        >
          <Icon icon="close" />
        </button>
      )}
    </div>
  )
);

DateInput.displayName = "DateInput";

type Props = {
  onClear: () => void;
  clearable: boolean;
  isError: boolean;
  disabled?: boolean;
} & React.ComponentProps<typeof DateInputElement>;

export default DateInput;
