import EventEmitter from "events";

import LogRocket from "logrocket";

import {
  UserLoggedOutError,
  NotFoundError,
  ConflictError,
  CompanyMismatchError,
  DisplayError,
  SignOutOnTooManyPasswordAttemptsError,
} from "modules/appError";
import * as csrfToken from "modules/csrfToken/actions";
import { HttpStatus } from "types/http";

import reloadWithoutCompanyContext from "app/utils/reloadWithoutCompanyContext";
// The import is done from subdirectory "utils/logrocket" to resolve circular
// dependency
import { getLogRocketAppID } from "utils/logrocket/getLogRocketAppID";

import request, { Url, HttpMethod, RequestOptions } from "./request";
import ApiErrors, { IApiError, ApiError, isErrorResponse } from "./ApiErrors";

class Api {
  getFetchOptions: (
    options: FetchOptions
  ) => Pick<RequestInit, "credentials" | "headers" | "signal">;
  baseUrl?: string;

  constructor({
    getFetchOptions,
    baseUrl,
  }: {
    getFetchOptions: (
      options: FetchOptions
    ) => Pick<RequestInit, "credentials" | "headers" | "signal">;
    baseUrl?: string;
  }) {
    this.baseUrl = baseUrl;
    this.getFetchOptions = getFetchOptions;
  }

  // export error classes on instance for convenience
  Errors = ApiErrors;
  events = new EventEmitter();

  get<Resource, Err extends ApiError = ApiError>(
    url: Url,
    query: RequestOptions["query"] = {},
    options: RequestConfig<Err> = {}
  ) {
    return this.request<Resource, Err>("GET", url, query, undefined, options);
  }

  put<Resource, Err extends ApiError = ApiError>(
    url: Url,
    query: RequestOptions["query"] = {},
    data: RequestOptions["data"] = {},
    options: RequestConfig<Err> = {}
  ) {
    return this.request<Resource, Err>("PUT", url, query, data, options);
  }

  post<Resource, Err extends ApiError = ApiError>(
    url: Url,
    query: RequestOptions["query"] = {},
    data: RequestOptions["data"] = {},
    options: RequestConfig<Err> = {}
  ) {
    return this.request<Resource, Err>("POST", url, query, data, options);
  }

  patch<Resource, Err extends ApiError = ApiError>(
    url: Url,
    query: RequestOptions["query"] = {},
    data: RequestOptions["data"] = {},
    options: RequestConfig<Err> = {}
  ) {
    return this.request<Resource, Err>("PATCH", url, query, data, options);
  }

  delete<Resource, Err extends ApiError = ApiError>(
    url: Url,
    query: RequestOptions["query"] = {},
    // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any = undefined,
    options: RequestConfig<Err> = {}
  ) {
    return this.request<Resource, Err>("DELETE", url, query, data, options);
  }

  async request<T, Err extends ApiError = ApiError>(
    method: HttpMethod,
    url: Url,
    query: RequestOptions["query"],
    data: RequestOptions["data"],
    {
      javascriptizeData = false,
      snakeizeQuery = true,
      formatError,
      signal,
      encodeBodyToBase64 = false,
      arrayBrackets,
    }: RequestConfig<Err> = {},
    retries: number = 0
  ): Promise<T> {
    try {
      const [result, { status, headers }] = await request<T | Err>(
        method,
        url,
        {
          query,
          data,
          baseUrl: this.baseUrl,
          fetchOptions: this.getFetchOptions({ signal }),
          snakeizeQuery,
          snakeizeData: javascriptizeData,
          camelizeResponse: javascriptizeData,
          encodeBodyToBase64,
          arrayBrackets,
        }
      );

      if (headers?.has("X-Session-Expires-At")) {
        // convert to ms
        const sessionExpiresAt =
          Number(headers.get("X-Session-Expires-At")) * 1000;

        this.events.emit("session-watch", new Date(sessionExpiresAt));
      }

      this.events.emit("request-completed", { result, status, headers });

      if (isErrorResponse<T, Err>(result, status)) {
        throw this.getError<Err>(status, result, formatError);
      }

      return result;
    } catch (unknownError: unknown) {
      const error = unknownError as { status: number };

      if (
        error.status === HttpStatus.UnprocessableEntity &&
        retries < MAX_FORM_SUBMIT_RETRIES
      ) {
        await csrfToken.update();

        return this.request(
          method,
          url,
          query,
          data,
          {
            javascriptizeData,
            snakeizeQuery,
            formatError,
            signal,
          },
          retries + 1
        );
      }

      throw error;
    }
  }

  private getError<Err extends ApiError = ApiError>(
    status: HttpStatus,
    result: Err,
    formatError?: (err: Err) => IApiError
  ) {
    if (isCompanyMismatchError(result)) {
      // Every page in the dashboard is scoped to a single company. When the
      // user switches, we show a modal in other open tabs to prevent them
      // from doing anything until they reload, in order to get the right
      // company. It is still possible for users to get rid of that modal
      // under the right circumstances - for example, when browser
      // navigation loads a page from the cache instead of making a new
      // request. When the backend sees a mismatch between the company
      // expected by the frontend and the one currently selected in the
      // session, it will return a COMPANY_MISMATCH error, which we use here
      // to force a reload of the page.
      reloadWithoutCompanyContext();

      return new CompanyMismatchError();
    }

    if (isSignOutOnTooManyPasswordAttemptsError(result)) {
      window.location.href = "/users/sign_in";

      return new SignOutOnTooManyPasswordAttemptsError();
    }

    if (isIndexNotAvailableInAPIError(result)) {
      return new DisplayError(result.message);
    }

    if (status === HttpStatus.Unauthorized) {
      return new UserLoggedOutError(result?.message, result);
    }

    if (status === HttpStatus.NotFound) {
      return new NotFoundError(result?.message, result);
    }

    if (status === HttpStatus.Conflict) {
      return new ConflictError(result?.message, result);
    }

    return new ApiErrors(result, status, formatError && formatError(result));
  }
}

type RequestConfig<Err> = {
  javascriptizeData?: boolean;
  snakeizeQuery?: boolean;
  formatError?: (err: Err) => IApiError;
  signal?: AbortSignal | null;
  encodeBodyToBase64?: boolean;
  arrayBrackets?: boolean;
};

const ApiInstance = new Api({ getFetchOptions });

export { ApiInstance as default };
export { Api as ApiClass };

type FetchOptions = {
  signal?: AbortSignal | null;
};

function getFetchOptions(
  options: FetchOptions = {}
): Pick<RequestInit, "credentials" | "headers" | "signal"> {
  const headers: Record<string, string> = {};
  const { signal } = options;

  // Add CSRF token
  const csrfTokenElement = document.querySelector<HTMLMetaElement>(
    '[name="csrf-token"]'
  );

  if (csrfTokenElement) {
    headers["X-CSRF-Token"] = csrfTokenElement.content;
  }

  // Add current company id for validation on the backend
  const companyIdElement = document.querySelector<HTMLMetaElement>(
    '[name="current_company_id"]'
  );

  if (companyIdElement) {
    headers["X-Company-Id"] = companyIdElement.content;
  }

  // Add fullstory session url
  if (window.FS && window.FS.getCurrentSessionURL) {
    try {
      const fsURL = window.FS.getCurrentSessionURL(true);
      if (fsURL !== null) {
        headers["X-FullStory-Session-Url"] = fsURL;
      }
    } catch (fullstorySessionUrlError) {
      /* eslint-disable-next-line no-console */
      console.error(fullstorySessionUrlError);
    }
  }

  if (getLogRocketAppID()) {
    // Add logrocket session url
    try {
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        headers["X-LogRocket-Session-Url"] = logRocketSession;
      }
    } catch (logrocketSessionUrlError) {
      /* eslint-disable-next-line no-console */
      console.error(logrocketSessionUrlError);
    }
  }

  // Add Frontend version
  const frontendVersion: string =
    document.querySelector<HTMLMetaElement>('meta[name="frontend_version"]')
      ?.content || "";
  if (frontendVersion) {
    headers["X-Frontend-Version"] = frontendVersion;
  }

  return {
    credentials: "same-origin",
    headers,
    signal,
  };
}

/**
 * Maximum number of retries for non-GET requests
 * failing with: 422 Unprocessable Entity.
 */
const MAX_FORM_SUBMIT_RETRIES = 1;

function isCompanyMismatchError(result: unknown): boolean {
  return isErrorCode(result, "COMPANY_MISMATCH");
}

function isIndexNotAvailableInAPIError(result: unknown): boolean {
  return isErrorCode(result, "INDEX_NOT_AVAILABLE");
}

function isSignOutOnTooManyPasswordAttemptsError(result: unknown): boolean {
  return isErrorCode(result, "SIGN_OUT_ON_TOO_MANY_PASSWORD_ATTEMPTS");
}

function isErrorCode(result: unknown, code: string): boolean {
  // We expect a result of `unknown` because the API typing response is dynamic
  // and based on whatever the consumer provided. If the API fails, it might
  // return something unexpected.
  // The checks below ensure that either `errorCode` or `error_code` are a
  // property of `result`. As of TS 4.9, this information will be used and TS
  // will know that the property exists
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-9.html#unlisted-property-narrowing-with-the-in-operator.
  // Until we upgrade, we do a type cast to `any`.
  return (
    typeof result === "object" &&
    result !== null &&
    // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (("errorCode" in result && (result as any).errorCode) ||
      // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ("error_code" in result && (result as any).error_code)) === code
  );
}
