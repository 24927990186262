import React from "react";

import { Checkbox as ModulesCheckbox } from "app/designSystem/components";
import ThemeProvider from "app/providers/ThemeProvider";

export default function Checkbox(
  props: React.ComponentProps<typeof ModulesCheckbox>
) {
  return (
    <ThemeProvider>
      <ModulesCheckbox {...props} />
    </ThemeProvider>
  );
}
