import AuthApi from "services/AuthApi";

export type DeleteRoleParams = {
  companyId: string;
  roleId: string;
};

/**
 * @description Delete a specific role within a company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-delete-role
 * @returns {void}
 */
export default async function deleteRole({
  companyId,
  roleId,
}: DeleteRoleParams): Promise<void> {
  const result = await AuthApi.delete<void>([
    "v1",
    "companies",
    companyId,
    "roles",
    roleId,
  ]);

  return result;
}

deleteRole.key = "modules/authentication/actions/roles/delete";
