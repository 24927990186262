import React, { PropsWithChildren } from "react";
import { Box } from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Alert } from "./Alert";

export const SUPPORT_EMAIL = "support@constructor.io";

export const AlertSupport = ({
  children,
}: // @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
PropsWithChildren<Record<never, any>>) => {
  return (
    <Alert type="warning" noClose>
      {children}
      <Box mt="4px">
        <Contact />
      </Box>
    </Alert>
  );
};

export const Contact = () => (
  <>
    Please contact <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> to
    receive help.
  </>
);
