import React from "react";
// eslint-disable-next-line no-restricted-imports -- This file configures react-query so it needs to import it
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { UserLoggedOutError } from "modules/appError";

const options = {
  queries: {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: (failureCount: number, err: unknown) => {
      if (err instanceof UserLoggedOutError) {
        return false;
      }
      return failureCount < 3;
    },
  },
};

const reactQueryClient = new QueryClient({
  defaultOptions: options,
});

const ReactQueryProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <QueryClientProvider client={reactQueryClient}>
    {children}
  </QueryClientProvider>
);

export default ReactQueryProvider;
