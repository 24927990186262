import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";
import {
  RoleData,
  Role,
  NextPageCursor,
  NextPageCursorData,
} from "modules/authentication/actions/shared/types";

export type GetCompanyRolesParams = {
  companyId: string;
  nextPageCursor?: string;
};

/**
 * @description Get all roles associated with a specified company.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-get-roles
 * @returns {GetCompanyRolesResponse}
 */
export default async function getRolesByCompany({
  companyId,
  nextPageCursor = "",
}: GetCompanyRolesParams): Promise<GetCompanyRolesResponse> {
  const queryParams = nextPageCursor
    ? { next_page_cursor: nextPageCursor }
    : {};

  const result = await AuthApi.get<GetCompanyRolesResponseData>(
    ["v1", "companies", companyId, "roles"],
    queryParams
  );

  return GetCompanyRolesResponse.fromGetCompanyRolesResponseData(result);
}

getRolesByCompany.key =
  "modules/authentication/actions/companies/getUserRolesByCompany";

export type GetCompanyRolesResponseData = {
  roles: RoleData[];
} & NextPageCursorData;

export type GetCompanyRolesResponse = {
  roles: Role[];
} & NextPageCursor;

export const GetCompanyRolesResponse = {
  fromGetCompanyRolesResponseData: (
    data: GetCompanyRolesResponseData
  ): GetCompanyRolesResponse => {
    const getCompanyRolesResponse: GetCompanyRolesResponse = {
      roles: data.roles.map((role) => ({
        createdAt: role.created_at
          ? convertFromApiFormat(role.created_at)
          : null,
        defaultAdminRole: role.default_admin_role,
        defaultBasicRole: role.default_basic_role,
        description: role.description,
        displayName: role.display_name,
        id: role.id,
        modifiedAt: role.modified_at
          ? convertFromApiFormat(role.modified_at)
          : null,
        modifiedBy: role.modified_by,
        permissions: role.permissions,
      })),
      nextPageCursor: data.next_page_cursor,
    };

    return getCompanyRolesResponse;
  },
};
