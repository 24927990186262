import Cookies from "js-cookie";

export function set(value: string) {
  const element = getElement();

  if (element) {
    element.setAttribute("content", value);
  }

  dumpToCookies();
}

export function get() {
  const element = getElement();

  if (element) {
    return element.getAttribute("content");
  }
}

export function dumpToCookies() {
  const value = get();

  if (value) {
    Cookies.set(COOKIE_INDEX_KEY, value);
  } else {
    Cookies.remove(COOKIE_INDEX_KEY);
  }
}

// ScopedByIndexKey::COOKIE_INDEX_KEY
const COOKIE_INDEX_KEY = "current_index_key";

function getElement() {
  return document.querySelector('meta[name="index_key"]');
}
