import React from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Textarea as ChakraTextArea,
  VStack,
  Text,
  Box,
  useControllableState,
} from "@chakra-ui/react";

import * as TippyTooltip from "components/Modules/TippyTooltip";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./TextArea.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { TextAreaProps } from "./TextArea.types";

export const TextArea = ({
  isInvalid,
  isDisabled,
  id,
  errorMessage,
  helperText,
  label,
  name,
  onChange,
  onBlur,
  placeholder,
  tooltip,
  value,
  preserveSpaceForMessage = true,
  ariaLabel,
  autoFocus,
  onFocus,
  maxLength,
  maxLengthMessage = "",
  height,
  minHeight,
  resize = "none",
}: TextAreaProps) => {
  const [count, setCount] = useControllableState({
    defaultValue: 0,
    value: value?.length,
  });

  const hasMaxLength = Boolean(maxLength && count > maxLength);

  const invalid = isInvalid || !!errorMessage || hasMaxLength;

  const activeErrorMessage =
    hasMaxLength && maxLengthMessage ? maxLengthMessage : errorMessage;

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCount(event.target.value.length);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <FormControl
      isInvalid={invalid}
      {...styles.getFormControlStyle(resize)}
      height="100%"
    >
      <VStack spacing="8px" alignItems="start" height="100%">
        {label || tooltip ? (
          <Flex {...styles.labelContainer}>
            {label && (
              <FormLabel htmlFor={id} {...styles.formLabel}>
                {label}
              </FormLabel>
            )}

            {tooltip && (
              <TippyTooltip.Info
                placement="top-start"
                tooltip={
                  <TippyTooltip.Content>
                    <TippyTooltip.Text>{tooltip}</TippyTooltip.Text>
                  </TippyTooltip.Content>
                }
              />
            )}
          </Flex>
        ) : null}

        <ChakraTextArea
          id={id}
          isInvalid={invalid}
          isDisabled={isDisabled}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleOnChange}
          onBlur={onBlur}
          onFocus={onFocus}
          aria-label={ariaLabel}
          resize={resize}
          // It's up to consumers of this component to decide whether this
          // rule applies to them or not, so disabling it here.
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          {...styles.textAreaStyle}
          height={height}
          minHeight={minHeight}
        />

        <Box {...styles.helperText.container}>
          {activeErrorMessage ? (
            <FormErrorMessage {...styles.helperText.error}>
              {activeErrorMessage}
            </FormErrorMessage>
          ) : (
            helperText ||
            (preserveSpaceForMessage && (
              <FormHelperText {...styles.helperText.helper}>
                {helperText}
              </FormHelperText>
            ))
          )}
          {maxLength && (
            <Text {...styles.maxLength(invalid)}>
              {count}/{maxLength}
            </Text>
          )}
        </Box>
      </VStack>
    </FormControl>
  );
};
