export const searchBarinputGroup = ({
  isFocused,
  isAlwaysExpanded,
  isSizeLg,
  isFilled,
}: {
  isFocused: boolean;
  isAlwaysExpanded: boolean;
  isSizeLg: boolean;
  isFilled: boolean;
}) => {
  const searchBarSize = isSizeLg ? "46px" : "32px";
  return {
    w: isFocused || isAlwaysExpanded || isFilled ? "100%" : searchBarSize,
    h: searchBarSize,
    pos: "absolute",
    top: 0,
    right: 0,
    transition: "width 0.5s ",
  } as const;
};

export const searchBarContainer = (isSizeLg: boolean) => {
  return {
    float: "right",
    position: "relative",
    w: "100%",
    h: isSizeLg ? "46px" : "32px",
  } as const;
};

export const searchBarLeft = (isSizeLg: boolean) => {
  const searchBarSize = isSizeLg ? "46px" : "32px";
  return {
    container: {
      w: searchBarSize,
      h: searchBarSize,
      pointerEvents: "none",
    },
    largeIcon: {
      boxSize: "18px",
    },
    smallIcon: {
      boxSize: "12px",
    },
  } as const;
};

export const searchBarCloseIcon = (isSizeLg: boolean) => {
  return {
    w: "16px",
    h: "16px",
    mr: isSizeLg ? "20px" : "12px",
    cursor: "pointer",
  };
};

export const searchBarInput = ({
  isHover,
  isSizeLg,
  isAlwaysExpanded,
  isRoundEdged,
  isFilled,
}: {
  isHover: boolean;
  isSizeLg: boolean;
  isAlwaysExpanded: boolean;
  isRoundEdged: boolean;
  isFilled: boolean;
}) => {
  const searchBarSize = isSizeLg ? "46px" : "32px";
  const searchBarPaddingLeft = isSizeLg ? "28px" : "14px";
  return {
    borderRadius: isRoundEdged ? "120px" : "4px",
    border: "2px solid",
    borderColor: isHover ? "grey-dark" : "grey-light-green",
    bgColor: isFilled ? "white" : "grey-light-green",
    color: "grey-dark",
    textStyle: isSizeLg ? "body2" : "body3",
    fontSize: isSizeLg ? "14px" : "13px",
    w: isAlwaysExpanded || isFilled ? "100%" : searchBarSize,
    h: searchBarSize,
    cursor: "pointer",
    paddingLeft:
      isAlwaysExpanded || isFilled ? searchBarSize : searchBarPaddingLeft,
    transition: "padding-left 0.5s",
    _placeholder: {
      color: "grey",
    },
    _hover: {
      borderColor: "grey-dark",
    },
    _focus: {
      borderColor: "grey-dark",
      w: "100%",
      paddingLeft: searchBarSize,
      bgColor: "white",
      cursor: "text",
    },
  } as const;
};
