const sharedStyles = {
  width: "100%",
};

export const containerBoxStyles = {
  ...sharedStyles,
  maxHeight: "100%",
  bgColor: "var(--cio-colors-white)",
  padding: "12px",
  gap: 0,
  boxShadow: "base",
  borderRadius: 8,
};

export const contentBoxStyles = {
  ...sharedStyles,
  minHeight: "140px",
  alignItems: "center",
  justifyContent: "center",
  pb: "12px",
  overflow: "hidden",
};

export const buttonBoxStyles = {
  ...sharedStyles,
  pt: "12px",
  borderTop: "1px solid var(--cio-colors-grey-light-green)",
  justifyContent: "space-between",
  mt: "0px !important",
};
