import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import theme from "components/theme";

function OptionsList({ children, className }) {
  return <Container className={className}>{children}</Container>;
}

OptionsList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

const Container = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const OptionButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  padding: 13px 17px;
  text-align: left;
`;

const OptionListItem = styled.li`
  padding: 0;
  background-color: transparent;
  min-width: 200px;

  &:hover {
    background-color: ${theme.colors.lightHighlight};
  }

  & + & {
    border-top: 2px solid #eff1f9;
  }
`;

OptionsList.Option = function Option(props) {
  return (
    <OptionListItem>
      <OptionButton type="button" {...props} />
    </OptionListItem>
  );
};

OptionsList.propTypes = {
  children: PropTypes.oneOfType([
    OptionsList.Option,
    PropTypes.arrayOf(OptionsList.Option),
  ]),
};

export default OptionsList;
