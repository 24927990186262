import PropTypes from "prop-types";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { sizes, kinds } from "./UI";

export const propTypes = {
  kind: PropTypes.oneOf(Object.keys(kinds)),
  size: PropTypes.oneOf(Object.keys(sizes)),
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export const defaultProps = {
  kind: "default",
  size: "default",
  loading: false,
};
