import React, { useEffect, useState } from "react";

import reportError from "services/ReportError";
import { Alert } from "app/designSystem/components";

// renders rails flash messages passed in header as:
// <meta name="flash" content="JSON" />
export default function Flash() {
  const [messages, setMessages] = useState<["notice" | "alert", string][]>([]);

  useEffect(() => {
    const element =
      document.querySelector<HTMLMetaElement>('meta[name="flash"]');

    if (!element || !element.content) {
      return;
    }

    try {
      setMessages(JSON.parse(element.content));
    } catch (rawError: unknown) {
      reportError(rawError as Error);
    }

    // remove element from DOM to prevent showing messages again on transitions
    if (element.parentElement) {
      element.parentElement.removeChild(element);
    }
  }, []);

  return (
    <>
      {messages
        .filter(([, message]) => message)
        .map(([severity, message], index) => (
          <Alert key={index} type={severity === "alert" ? "danger" : "success"}>
            {message}
          </Alert>
        ))}
    </>
  );
}
