import React, { createContext } from "react";

export default function LoadingStateConfigProvider({
  children,
  minimumSpinnerDelay,
}: React.PropsWithChildren<Props>) {
  return (
    <LoadingStateConfigContext.Provider value={{ minimumSpinnerDelay }}>
      {children}
    </LoadingStateConfigContext.Provider>
  );
}

export const DEFAULT_SPINNER_DELAY = 1000;

type Props = {
  /**
   * The minimum amount of time to show the spinner.
   * *If* the spinner is shown, then it will be shown for *at least* this long.
   * This avoids the spinner flashing on the screen for a very short amount of
   * time.
   */
  minimumSpinnerDelay: number;
};

export const LoadingStateConfigContext = createContext<Props>({
  minimumSpinnerDelay: 0,
});
