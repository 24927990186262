import React from "react";
import { Box, Tag, Tooltip } from "@chakra-ui/react";

import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as helpers from "../MultiSelect.helpers";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "../MultiSelect.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as types from "../MultiSelect.types";

export default function LabelTag({ label, onRemove }: types.LabelTag) {
  const formattedLabel = helpers.formatLabel(label);

  const handleOnRemove: React.MouseEventHandler<SVGElement> = (event) => {
    event.stopPropagation();
    onRemove();
  };

  return (
    <Tag role="listitem" {...styles.labelTag}>
      {formattedLabel.isShortened ? (
        /**
         * HACK: Here, we're rendering the tooltip text inside a Box. We do not want the tooltip
         * to wrap the children in <span />, because that messes up with the label tag width
         * calculation (since we query for all spans to calculate the used width).
         *
         * Note that we're passsing shouldWrapChildren=false.
         * But Chakra UI always wraps if the children is a string.
         *
         * Check it out on the source code:
         *
         * `const shouldWrap = isString(children) || shouldWrapChildren`:
         *
         * v1.8.8: https://github.com/chakra-ui/chakra-ui/blob/bab4f2f2cddc5d4ff073b71ef72537fb754d3643/packages/tooltip/src/tooltip.tsx
         * latest: https://github.com/chakra-ui/chakra-ui/blob/main/packages/tooltip/src/tooltip.tsx
         *
         * FIXME: Remove this when Chakra UI fixes this and properly avoids wrapping children
         * if shouldWrapChildren is false, regardless of the children type.
         */
        <Tooltip label={label} shouldWrapChildren={false}>
          <Box>{formattedLabel.label}</Box>
        </Tooltip>
      ) : (
        formattedLabel.label
      )}
      <ChakraIcon {...styles.closeIcon} onClick={handleOnRemove} />
    </Tag>
  );
}
