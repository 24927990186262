import AuthApi from "services/AuthApi";

/**
 * @description Assigns company role to user.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Companies/operation/v1-auth-companies-assign-company-role-to-user
 * @returns {void}
 */
export default async function assignRole({
  companyId,
  userId,
  roleId,
}: Props): Promise<AssignRoleResponse> {
  const result = await AuthApi.post<AssignRoleResponse>(
    ["v1", "companies", companyId, "users", userId, "roles"],
    {},
    {
      role_id: roleId,
    }
  );

  return result;
}

export type AssignRoleResponseData = {
  companyId: string;
  userId: string;
  role: string;
  assignedAt: string;
};

export type AssignRoleResponse = {
  data: AssignRoleResponseData;
  status: string;
};

export type Props = { companyId: string; userId: string; roleId: string };
