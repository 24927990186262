import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { HStack, VStack, Collapse, Box } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { IconButton } from "../IconButton";

import {
  buttonBoxStyles,
  containerBoxStyles,
  contentBoxStyles,
  // @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
  // eslint-disable-next-line local-rules/enforce-fractal-pattern,
} from "./Carousel.styles";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { Bullet } from "./Bullet";

export const Carousel: React.FC<Props> = ({
  children,
  cycle = true,
  defaultSlide = 0,
  afterChange,
  testId,
}) => {
  const items: React.ReactNode[] = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );

  const LAST_INDEX = items.length - 1;
  const FIRST_INDEX = 0;

  const isValidIndex = useCallback(
    (index: number): boolean => {
      return index < items.length;
    },
    [items]
  );

  const [index, setIndex] = useState(
    isValidIndex(defaultSlide) ? defaultSlide : 0
  );

  useEffect(() => {
    if (!isValidIndex(index)) {
      setIndex(items.length > 0 ? LAST_INDEX : 0);
    }
  }, [index, items, isValidIndex, LAST_INDEX]);

  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (afterChange) {
      afterChange(index);
    }

    // Ensures to clear active/focus button state after slide change
    if (prevButtonRef?.current) {
      prevButtonRef?.current.blur();
    }

    // Ensures to clear active/focus button state after slide change
    if (nextButtonRef?.current) {
      nextButtonRef?.current.blur();
    }
  }, [index, afterChange, prevButtonRef, nextButtonRef]);

  const hasNext = index < LAST_INDEX;
  const hasPrev = index > FIRST_INDEX;

  const itemToDisplay = items[index];

  // Carousel is not available with no items
  if (items.length === 0) {
    return null;
  }

  return (
    <VStack {...containerBoxStyles} data-testid={testId}>
      <VStack {...contentBoxStyles}>
        <AnimatePresence exitBeforeEnter>
          <motion.div
            style={{ width: "100%" }}
            key={
              React.isValidElement(itemToDisplay)
                ? itemToDisplay.key
                : String(index)
            }
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: "spring",
              stiffness: 100,
              mass: 1,
              damping: 15,
            }}
          >
            {itemToDisplay}
          </motion.div>
        </AnimatePresence>
      </VStack>

      <Collapse in={items.length > 1} animateOpacity css={{ width: "100%" }}>
        <HStack {...buttonBoxStyles}>
          <IconButton
            ref={prevButtonRef}
            icon="link-specific-chevron-left-16"
            ariaLabel="Previous"
            variant="ghost"
            isDisabled={!cycle && !hasPrev}
            onClick={() => {
              const prevIndex = hasPrev ? index - 1 : LAST_INDEX;
              setIndex(prevIndex);
            }}
          />

          <Box m={0} as="div" gap="4px" display="flex" flexDirection="row">
            {items.map((_elem, idx) => (
              <Bullet
                key={idx}
                index={idx}
                active={idx === index}
                onClick={setIndex}
              />
            ))}
          </Box>

          <IconButton
            ref={nextButtonRef}
            icon="link-specific-chevron-right-16"
            ariaLabel="Next"
            variant="ghost"
            isDisabled={!cycle && !hasNext}
            onClick={() => {
              const nextIndex = hasNext ? index + 1 : FIRST_INDEX;
              setIndex(nextIndex);
            }}
          />
        </HStack>
      </Collapse>
    </VStack>
  );
};

type Props = {
  /**
   * Children are required.
   */
  children: React.ReactNode[] | React.ReactNode;
  /**
   * If false, disables navigation when reaches the end. Defaults to true.
   */
  cycle?: boolean;
  /**
   * Callback function called after the current index changes
   */
  afterChange?: (currentIndex: number) => void;
  /**
   * Sets the initial slide to show.
   */
  defaultSlide?: number;
  /**
   * Forward prop to `data-testid`
   */
  testId?: string;
};
