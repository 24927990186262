import { useEffect, useState } from "react";
/* eslint no-restricted-imports: ["warn", "@tanstack/react-query"] */
import { useQueryClient, QueryClient } from "@tanstack/react-query";

import reportError from "services/ReportError";
import * as types from "modules/indexes/types";
import * as items from "modules/items";

/**
 * Hook that requests for the total count of items for each index section,
 * and returns an object with keys and correspondind total count and all section counts.
 *
 * @param args Arguments hash.
 * @param args.index The index to fetch data for.
 * @param args.signal The abort controller signal for canceling requests.
 * @param args.enabled Whether API call can be made.
 */
export default function useIndexesItemCounts({
  index,
  signal,
  enabled = true,
}: Args): Result {
  const [result, setResult] = useState<Result>(
    index
      ? {
          [index.indexKey]: {
            isLoading: true,
            sectionItemsCount: undefined,
            totalItemsCount: undefined,
          },
        }
      : {}
  );

  const cache = useQueryClient();

  const isEnabled = Boolean(
    enabled &&
      index?.indexKey &&
      (result[index.indexKey]?.totalItemsCount === undefined ||
        !result[index.indexKey].isLoading)
  );

  useEffect(() => {
    // enabled state may not check presense of index
    // so we should double-check
    if (!isEnabled) {
      setResult((prevState) =>
        index
          ? {
              ...prevState,
              [index.indexKey]: {
                isLoading: false,
                sectionItemsCount: undefined,
                totalItemsCount: undefined,
              },
            }
          : prevState
      );

      return;
    }

    setResult((prevState) =>
      index
        ? {
            ...prevState,
            [index.indexKey]: {
              isLoading: true,
              sectionItemsCount: undefined,
              totalItemsCount: undefined,
            },
          }
        : prevState
    );

    fetchAllSectionsData({ index: index!, signal }, cache).then((newResult) =>
      setResult((prevState) => ({ ...prevState, ...newResult }))
    );
  }, [index, cache, signal, isEnabled]);

  return result;
}

/**
 * Fetches data from all sections for a given index, and returns the result.
 *
 * @param index The index to fetch data for.
 * @param cache The query cache to use.
 */
const fetchAllSectionsData = async (
  { index, signal }: Args & { index: types.Index },
  cache: QueryClient
): Promise<Result> =>
  await new Promise((resolve) => {
    const queryPromises = index.sections.map((section) =>
      fetchSectionData(section, { index, signal }, cache)
    );

    Promise.all(queryPromises).then((responses) => {
      const sectionItemsCount = responses.reduce(
        (acc, data) => ({
          ...acc,
          [data.section.name]: data?.response?.totalCount ?? 0,
        }),
        {}
      );

      const totalItemsCount = responses.reduce((acc, data) => {
        return acc + (data?.response?.totalCount ?? 0);
      }, 0);

      resolve(
        signal.aborted
          ? {}
          : {
              [index.indexKey]: {
                sectionItemsCount,
                isLoading: false,
                totalItemsCount,
              },
            }
      );
    });
  });

/**
 * Fetches data from one sections for a given index.
 * Returns a promise that resolves even with failures, since some requests are expected
 * to fail when either the index key or section are invalid.
 *
 * @param section The section to fetch data for.
 * @param index The index to fetch data for.
 * @param cache The query cache to use.
 */
const fetchSectionData = (
  section: types.Section,
  {
    index,
    signal,
  }: {
    signal: AbortSignal;
    index: types.Index;
  },
  cache: QueryClient
): Promise<SectionDataResponse> =>
  new Promise<SectionDataResponse>((resolve) => {
    return cache.fetchQuery(
      [items.listKey, index.indexKey, section.name],
      async () => {
        try {
          const response = await items.list({
            indexKey: index.indexKey,
            section: section.name,
            numResultsPerPage: 1,
            signal,
          });

          resolve({
            response,
            section,
          });

          return null;
        } catch (err) {
          // do not report canceled request
          if (!signal.aborted) {
            reportError(err);
          }
          resolve({
            response: undefined,
            section,
          });
          return null;
        }
      },
      { retry: false }
    );
  });

type Args = {
  signal: AbortSignal;
  index: types.Index | null | undefined;
  enabled?: boolean;
};

type SectionDataResponse = {
  response: items.ListResponse | undefined;
  section: types.Section;
};

type Result = Record<
  string,
  {
    isLoading: boolean;
    totalItemsCount: undefined | number;
    sectionItemsCount:
      | undefined
      | {
          [sectionName: string]: number;
        };
  }
>;
