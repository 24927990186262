import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import Box from "components/Modules/Box";
import Button, { Action } from "components/Modules/Button";

export default function Notification({ onDoItLater }: Props) {
  return (
    <Container>
      <Title>A new version of Constructor is available</Title>

      <Text>
        Please save any changes you&apos;re making, and then refresh the page to
        get the latest version.
      </Text>

      <Buttons>
        <Button
          onClick={() => window.location.reload()}
          type="button"
          kind="primary"
          size="small"
          css={css`
            padding: 8px 22px;
          `}
        >
          Refresh
        </Button>

        <Action
          kind="link"
          size="small"
          onClick={onDoItLater}
          css={css`
            margin-left: 16px;
            color: var(--black);
          `}
        >
          I&apos;ll do it later
        </Action>
      </Buttons>
    </Container>
  );
}

type Props = {
  onDoItLater: () => void;
};

const Container = styled(Box)`
  width: 336px;
  border-top: 6px solid var(--primary-green);
  padding: 18px 24px 24px 24px;
  box-shadow: var(--heavy-shadow);
  text-align: left;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 15px;
  color: var(--neutral-grey);
`;

const Text = styled.div`
  font-size: 14px;
  color: var(--neutral-grey);
  margin-top: 6px;
`;

const Buttons = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
`;
