import React from "react";

import { Menu } from "app/components/Navigation";

import BaseRoute from "./shared/Route";
import Loading from "./shared/Loading";

type RouteProps = { blank?: boolean } & Omit<
  React.ComponentProps<typeof BaseRoute>,
  "loading"
>;

export default function Route({ blank = false, ...props }: RouteProps) {
  return (
    <BaseRoute
      {...props}
      loading={<Loading menu={blank ? undefined : <Menu />} />}
    />
  );
}
