import * as ChakraIcons from "app/designSystem/components/ChakraIcon/Icon";
import { sizes } from "app/designSystem/theme/foundations/sizes";

export type CHAKRA_ICONS_KEYS = keyof typeof CHAKRA_ICONS;

/**
 * IMPORTANT READ FIRST:
 * To add in custom icons please follow this guide on how to add custom Chakra Icons: https://constructor.slab.com/posts/adding-custom-chakra-icons-gegt6sa0
 */
export const CHAKRA_ICONS = {
  "close-16": {
    icon: ChakraIcons.Close16,
    size: sizes["icon-sm"],
  },
  "search-big-16": {
    icon: ChakraIcons.SearchBig16,
    size: sizes["icon-sm"],
  },
  "add-16": {
    icon: ChakraIcons.Add16,
    size: sizes["icon-sm"],
  },
  "analytics-16": {
    icon: ChakraIcons.Analytics16,
    size: sizes["icon-sm"],
  },
  "arrow-drop-down-16": {
    icon: ChakraIcons.ArrowDropDown16,
    size: sizes["icon-sm"],
  },
  "arrow-drop-left-16": {
    icon: ChakraIcons.ArrowDropLeft16,
    size: sizes["icon-sm"],
  },
  "arrow-drop-up-16": {
    icon: ChakraIcons.ArrowDropUp16,
    size: sizes["icon-sm"],
  },
  "automatic-16": {
    icon: ChakraIcons.Automatic16,
    size: sizes["icon-sm"],
  },
  "calendar-16": {
    icon: ChakraIcons.Calendar16,
    size: sizes["icon-sm"],
  },
  "checkbox-16": {
    icon: ChakraIcons.Checkbox16,
    size: sizes["icon-sm"],
  },
  "checkbox-indeterminate-16": {
    icon: ChakraIcons.CheckboxIndeterminate16,
    size: sizes["icon-sm"],
  },
  "checkbox-selected-16": {
    icon: ChakraIcons.CheckboxSelected16,
    size: sizes["icon-sm"],
  },
  "close-circle-24": {
    icon: ChakraIcons.CloseCircle24,
    size: sizes["icon-sm"],
  },
  "cloud-upload-24": {
    icon: ChakraIcons.CloudUpload24,
    size: sizes["icon-sm"],
  },
  "danger-16": {
    icon: ChakraIcons.Danger16,
    size: sizes["icon-sm"],
  },
  "dashboard-16": {
    icon: ChakraIcons.Dashboard16,
    size: sizes["icon-sm"],
  },
  "delete-16": {
    icon: ChakraIcons.Delete16,
    size: sizes["icon-sm"],
  },
  "docs-16": {
    icon: ChakraIcons.Docs16,
    size: sizes["icon-sm"],
  },
  "edit-16": {
    icon: ChakraIcons.Edit16,
    size: sizes["icon-sm"],
  },
  "export-16": {
    icon: ChakraIcons.Export16,
    size: sizes["icon-sm"],
  },
  "filter-16": {
    icon: ChakraIcons.Filter16,
    size: sizes["icon-sm"],
  },
  "global-16": {
    icon: ChakraIcons.Global16,
    size: sizes["icon-sm"],
  },
  "grid-16": {
    icon: ChakraIcons.Grid16,
    size: sizes["icon-sm"],
  },
  "hide-16": {
    icon: ChakraIcons.Hide16,
    size: sizes["icon-sm"],
  },
  "info-filled-light-16": {
    icon: ChakraIcons.InfoFilledLight16,
    size: sizes["icon-sm"],
  },
  "info-filled-dark-16": {
    icon: ChakraIcons.InfoFilledDark16,
    size: sizes["icon-sm"],
  },
  "info-outline-16": {
    icon: ChakraIcons.InfoOutline16,
    size: sizes["icon-sm"],
  },
  "info-outline-10": {
    icon: ChakraIcons.InfoOutline10,
    size: sizes["icon-xs"],
  },
  "lock-16": {
    icon: ChakraIcons.Lock16,
    size: sizes["icon-sm"],
  },
  "move-16": {
    icon: ChakraIcons.Move16,
    size: sizes["icon-sm"],
  },
  "new-16": {
    icon: ChakraIcons.New16,
    size: sizes["icon-sm"],
  },
  "notifications-16": {
    icon: ChakraIcons.Notifications16,
    size: sizes["icon-sm"],
  },
  "preview-16": {
    icon: ChakraIcons.Preview16,
    size: sizes["icon-sm"],
  },
  "radio-16": {
    icon: ChakraIcons.Radio16,
    size: sizes["icon-sm"],
  },
  "radio-selected-16": {
    icon: ChakraIcons.RadioSelected16,
    size: sizes["icon-sm"],
  },
  "refresh-16": {
    icon: ChakraIcons.Refresh16,
    size: sizes["icon-sm"],
  },
  "search-small-16": {
    icon: ChakraIcons.SearchSmall16,
    size: sizes["icon-sm"],
  },
  "searchandize-filled-16": {
    icon: ChakraIcons.SearchandizeFilled16,
    size: sizes["icon-sm"],
  },
  "searchandize-outline-16": {
    icon: ChakraIcons.SearchandizeOutline16,
    size: sizes["icon-sm"],
  },
  "show-16": {
    icon: ChakraIcons.Show16,
    size: sizes["icon-sm"],
  },
  "success-16": {
    icon: ChakraIcons.Success16,
    size: sizes["icon-sm"],
  },
  "upload-16": {
    icon: ChakraIcons.Upload16,
    size: sizes["icon-sm"],
  },
  "warning-16": {
    icon: ChakraIcons.Warning16,
    size: sizes["icon-sm"],
  },
  "warning-alt-16": {
    icon: ChakraIcons.WarningAlt16,
    size: sizes["icon-sm"],
  },
  "warning-red-16": {
    icon: ChakraIcons.WarningRed16,
    size: sizes["icon-sm"],
  },
  "opportunities-failed-search-24": {
    icon: ChakraIcons.OpportunitiesFailedSearch24,
    size: sizes["icon-md"],
  },
  "opportunities-failed-search-disabled-24": {
    icon: ChakraIcons.OpportunitiesFailedSearchDisabled24,
    size: sizes["icon-md"],
  },
  "opportunities-reformulated-search-24": {
    icon: ChakraIcons.OpportunitiesReformulatedSearch24,
    size: sizes["icon-md"],
  },
  "opportunities-reformulated-search-disabled-24": {
    icon: ChakraIcons.OpportunitiesReformulatedSearchDisabled24,
    size: sizes["icon-md"],
  },
  "opportunities-successful-search-24": {
    icon: ChakraIcons.OpportunitiesSuccessfulSearch24,
    size: sizes["icon-md"],
  },
  "opportunities-successful-search-disabled-24": {
    icon: ChakraIcons.OpportunitiesSuccessfulSearchDisabled24,
    size: sizes["icon-md"],
  },
  "link-specific-alert-delay-16": {
    icon: ChakraIcons.LinkSpecificAlertDelay16,
    size: sizes["icon-sm"],
  },
  "link-specific-base-url-16": {
    icon: ChakraIcons.LinkSpecificBaseUrl16,
    size: sizes["icon-sm"],
  },
  "link-specific-browse-16": {
    icon: ChakraIcons.LinkSpecificBrowse16,
    size: sizes["icon-sm"],
  },
  "link-specific-chevron-down-16": {
    icon: ChakraIcons.LinkSpecificChevronDown16,
    size: sizes["icon-sm"],
  },
  "link-specific-chevron-left-16": {
    icon: ChakraIcons.LinkSpecificChevronLeft16,
    size: sizes["icon-sm"],
  },
  "link-specific-chevron-right-16": {
    icon: ChakraIcons.LinkSpecificChevronRight16,
    size: sizes["icon-sm"],
  },
  "link-specific-chevron-up-16": {
    icon: ChakraIcons.LinkSpecificChevronUp16,
    size: sizes["icon-sm"],
  },
  "link-specific-categories-16": {
    icon: ChakraIcons.LinkSpecificCategories16,
    size: sizes["icon-sm"],
  },
  "link-specific-code-16": {
    icon: ChakraIcons.LinkSpecificCode16,
    size: sizes["icon-sm"],
  },
  "link-specific-delete-16": {
    icon: ChakraIcons.LinkSpecificDelete16,
    size: sizes["icon-sm"],
  },
  "link-specific-docs-16": {
    icon: ChakraIcons.LinkSpecificDocs16,
    size: sizes["icon-sm"],
  },
  "link-specific-drag-16": {
    icon: ChakraIcons.LinkSpecificDrag16,
    size: sizes["icon-sm"],
  },
  "link-specific-edit-16": {
    icon: ChakraIcons.LinkSpecificEdit16,
    size: sizes["icon-sm"],
  },
  "link-specific-facet-index-16": {
    icon: ChakraIcons.LinkSpecificFacetIndex16,
    size: sizes["icon-sm"],
  },
  "link-specific-group-attractiveness-16": {
    icon: ChakraIcons.LinkSpecificGroupAttractiveness16,
    size: sizes["icon-sm"],
  },
  "link-specific-item-attractiveness-16": {
    icon: ChakraIcons.LinkSpecificItemAttractiveness16,
    size: sizes["icon-sm"],
  },
  "link-specific-send-16": {
    icon: ChakraIcons.LinkSpecificSend16,
    size: sizes["icon-sm"],
  },
  "link-specific-searchandizing-16": {
    icon: ChakraIcons.LinkSpecificSearchandizing16,
    size: sizes["icon-sm"],
  },
  "link-specific-upload-16": {
    icon: ChakraIcons.LinkSpecificUpload16,
    size: sizes["icon-sm"],
  },
  "link-specific-user-attractiveness-16": {
    icon: ChakraIcons.LinkSpecificUserAttractiveness16,
    size: sizes["icon-sm"],
  },
  "link-specific-view-autosuggest-16": {
    icon: ChakraIcons.LinkSpecificViewAutosuggest16,
    size: sizes["icon-sm"],
  },
  "link-analytics-16": {
    icon: ChakraIcons.LinkAnalytics16,
    size: sizes["icon-sm"],
  },
  "metrics-autossuggest-sessions-16": {
    icon: ChakraIcons.MetricsAutossuggestSessions16,
    size: sizes["icon-sm"],
  },
  "metrics-autosuggest-selects-16": {
    icon: ChakraIcons.MetricsAutosuggestSelects16,
    size: sizes["icon-sm"],
  },
  "metrics-category-pages-16": {
    icon: ChakraIcons.MetricsCategoryPages16,
    size: sizes["icon-sm"],
  },
  "metrics-click-16": {
    icon: ChakraIcons.MetricsClick16,
    size: sizes["icon-sm"],
  },
  "metrics-clickthrough-rate-16": {
    icon: ChakraIcons.MetricsClickthroughRate16,
    size: sizes["icon-sm"],
  },
  "metrics-conversions-16": {
    icon: ChakraIcons.MetricsConversions16,
    size: sizes["icon-sm"],
  },
  "metrics-geopersonalization-16": {
    icon: ChakraIcons.MetricsGeopersonalization16,
    size: sizes["icon-sm"],
  },
  "metrics-impressions-16": {
    icon: ChakraIcons.MetricsImpressions16,
    size: sizes["icon-sm"],
  },
  "metrics-lost-session-16": {
    icon: ChakraIcons.MetricsLostSession16,
    size: sizes["icon-sm"],
  },
  "metrics-no-conversion-16": {
    icon: ChakraIcons.MetricsNoConversion16,
    size: sizes["icon-sm"],
  },
  "metrics-recommendation-sessions-16": {
    icon: ChakraIcons.MetricsRecommendationSessions16,
    size: sizes["icon-sm"],
  },
  "metrics-recommendation-views-16": {
    icon: ChakraIcons.MetricsRecommendationViews16,
    size: sizes["icon-sm"],
  },
  "metrics-recommendation-views-24": {
    icon: ChakraIcons.MetricsRecommendationViews24,
    size: sizes["icon-md"],
  },
  "metrics-reformulate-search-16": {
    icon: ChakraIcons.MetricsReformulateSearch16,
    size: sizes["icon-sm"],
  },
  "metrics-reformulated-conversions-16": {
    icon: ChakraIcons.MetricsReformulatedConversions16,
    size: sizes["icon-sm"],
  },
  "metrics-search-16": {
    icon: ChakraIcons.MetricsSearch16,
    size: sizes["icon-sm"],
  },
  "metrics-search-results-16": {
    icon: ChakraIcons.MetricsSearchResults16,
    size: sizes["icon-sm"],
  },
  "metrics-search-sessions-16": {
    icon: ChakraIcons.MetricsSearchSessions16,
    size: sizes["icon-sm"],
  },
  "metrics-user-16": {
    icon: ChakraIcons.MetricsUser16,
    size: sizes["icon-sm"],
  },
  "link-specific-lock-16": {
    icon: ChakraIcons.LinkSpecificLock16,
    size: sizes["icon-sm"],
  },
  "multi-lock-16": {
    icon: ChakraIcons.MultiLock16,
    size: sizes["icon-sm"],
  },
  "link-specific-check-multi-16": {
    icon: ChakraIcons.LinkSpecificCheckMulti16,
    size: sizes["icon-sm"],
  },
  "link-specific-check-single-16": {
    icon: ChakraIcons.LinkSpecificCheckSingle16,
    size: sizes["icon-sm"],
  },
  "cognitive-embedding-search-16": {
    icon: ChakraIcons.CognitiveEmbeddingSearch16,
    size: sizes["icon-sm"],
  },
  "sort-ascending-16": {
    icon: ChakraIcons.SortAscending16,
    size: sizes["icon-sm"],
  },
  "help-16": {
    icon: ChakraIcons.Help16,
    size: sizes["icon-sm"],
  },
  "integration-16": {
    icon: ChakraIcons.Integration16,
    size: sizes["icon-sm"],
  },
  "section-performance-16": {
    icon: ChakraIcons.SectionPerformance16,
    size: sizes["icon-sm"],
  },
  "calendar-dark-16": {
    icon: ChakraIcons.CalendarDark16,
    size: sizes["icon-sm"],
  },
  "calendar-warning-24": {
    icon: ChakraIcons.CalendarWarning24,
    size: sizes["icon-md"],
  },
  "section-integrations-24": {
    icon: ChakraIcons.SectionIntegrations24,
    size: sizes["icon-md"],
  },
  "section-performance-24": {
    icon: ChakraIcons.SectionPerformance24,
    size: sizes["icon-md"],
  },
  "section-opportunities-24": {
    icon: ChakraIcons.SectionOpportunities24,
    size: sizes["icon-md"],
  },
  "arrow-up-24": {
    icon: ChakraIcons.ArrowUp24,
    size: sizes["icon-md"],
  },
  "arrow-right-16": {
    icon: ChakraIcons.ArrowRight16,
    size: sizes["icon-sm"],
  },
  "arrow-right-24": {
    icon: ChakraIcons.ArrowRight24,
    size: sizes["icon-md"],
  },
  "arrow-down-24": {
    icon: ChakraIcons.ArrowDown24,
    size: sizes["icon-md"],
  },
  "arrow-left-24": {
    icon: ChakraIcons.ArrowLeft24,
    size: sizes["icon-md"],
  },
  "arrow-left-right-16": {
    icon: ChakraIcons.ArrowLeftRight16,
    size: sizes["icon-sm"],
  },
  "arrow-left-right-24": {
    icon: ChakraIcons.ArrowLeftRight24,
    size: sizes["icon-md"],
  },
  "blocklist-24": {
    icon: ChakraIcons.Blocklist24,
    size: sizes["icon-md"],
  },
  "lock-24": {
    icon: ChakraIcons.Lock24,
    size: sizes["icon-md"],
  },
  "user-attractiveness-24": {
    icon: ChakraIcons.UserAttractiveness24,
    size: sizes["icon-md"],
  },
  "item-attractiveness-inactive-24": {
    icon: ChakraIcons.ItemAttractivenessInactive24,
    size: sizes["icon-md"],
  },
  "searchandizing-down-24": {
    icon: ChakraIcons.SearchandizingDown24,
    size: sizes["icon-md"],
  },
  "group-attractiveness-24": {
    icon: ChakraIcons.GroupAttractiveness24,
    size: sizes["icon-md"],
  },
  "cognitive-embedding-search-24": {
    icon: ChakraIcons.CognitiveEmbeddingSearch16,
    size: sizes["icon-md"],
  },
  "arrow-drop-up-24": {
    icon: ChakraIcons.ArrowDropUp24,
    size: sizes["icon-md"],
  },
  "chevron-left-24": {
    icon: ChakraIcons.ChevronLeft24,
    size: sizes["icon-md"],
  },
  "add-circle-24": {
    icon: ChakraIcons.AddCircle24,
    size: sizes["icon-md"],
  },
  "arrow-drop-right-24": {
    icon: ChakraIcons.ArrowDropRight24,
    size: sizes["icon-md"],
  },
  "calendar-24": {
    icon: ChakraIcons.Calendar24,
    size: sizes["icon-md"],
  },
  "checkmark-circle-24": {
    icon: ChakraIcons.CheckmarkCircle24,
    size: sizes["icon-md"],
  },
  "chevron-right-24": {
    icon: ChakraIcons.ChevronRight24,
    size: sizes["icon-md"],
  },
  "close-24": {
    icon: ChakraIcons.Close24,
    size: sizes["icon-md"],
  },
  "copy-24": {
    icon: ChakraIcons.Copy24,
    size: sizes["icon-md"],
  },
  "delete-24": {
    icon: ChakraIcons.Delete24,
    size: sizes["icon-md"],
  },
  "desktop-24": {
    icon: ChakraIcons.Desktop24,
    size: sizes["icon-md"],
  },
  "edit-24": {
    icon: ChakraIcons.Edit24,
    size: sizes["icon-md"],
  },
  "export-24": {
    icon: ChakraIcons.Export24,
    size: sizes["icon-md"],
  },
  "hide-24": {
    icon: ChakraIcons.Hide24,
    size: sizes["icon-md"],
  },
  "info-filled-24": {
    icon: ChakraIcons.InfoFilled24,
    size: sizes["icon-md"],
  },
  "lock-alt-24": {
    icon: ChakraIcons.LockAlt24,
    size: sizes["icon-md"],
  },
  "mobile-24": {
    icon: ChakraIcons.Mobile24,
    size: sizes["icon-md"],
  },
  "more-options-24": {
    icon: ChakraIcons.MoreOptions24,
    size: sizes["icon-md"],
  },
  "play-24": {
    icon: ChakraIcons.Play24,
    size: sizes["icon-md"],
  },
  "restart-24": {
    icon: ChakraIcons.Restart24,
    size: sizes["icon-md"],
  },
  "search-24": {
    icon: ChakraIcons.Search24,
    size: sizes["icon-md"],
  },
  "show-24": {
    icon: ChakraIcons.Show24,
    size: sizes["icon-md"],
  },
  "global-24": {
    icon: ChakraIcons.Global24,
    size: sizes["icon-md"],
  },
  "scheduled-slotted-24": {
    icon: ChakraIcons.ScheduledSlotted24,
    size: sizes["icon-md"],
  },
  "insight-24": {
    icon: ChakraIcons.Insight24,
    size: sizes["icon-md"],
  },
  "platform-android-24": {
    icon: ChakraIcons.PlatformAndroid24,
    size: sizes["icon-md"],
  },
  "platform-apple-ios-24": {
    icon: ChakraIcons.PlatformAppleIos24,
    size: sizes["icon-md"],
  },
  "platform-web-24": {
    icon: ChakraIcons.PlatformWeb24,
    size: sizes["icon-md"],
  },
  "search-education-24": {
    icon: ChakraIcons.SearchEducation24,
    size: sizes["icon-md"],
  },
  "searchandize-24": {
    icon: ChakraIcons.Searchandize24,
    size: sizes["icon-md"],
  },
  "statistics-24": {
    icon: ChakraIcons.Statistics24,
    size: sizes["icon-md"],
  },
  "links-chevron-down-24": {
    icon: ChakraIcons.LinksChevronDown24,
    size: sizes["icon-md"],
  },
  "links-chevron-right-24": {
    icon: ChakraIcons.LinksChevronRight24,
    size: sizes["icon-md"],
  },
  "links-chevron-up-24": {
    icon: ChakraIcons.LinksChevronUp24,
    size: sizes["icon-md"],
  },
  "links-uploading-24": {
    icon: ChakraIcons.LinksUploading24,
    size: sizes["icon-md"],
  },
  "decrease-24": {
    icon: ChakraIcons.Decrease24,
    size: sizes["icon-md"],
  },
  "increase-24": {
    icon: ChakraIcons.Increase24,
    size: sizes["icon-md"],
  },
  "account-16": {
    icon: ChakraIcons.Account16,
    size: sizes["icon-sm"],
  },
  "arrow-drop-right-16": {
    icon: ChakraIcons.ArrowDropRight16,
    size: sizes["icon-sm"],
  },
  "help-circle-16": {
    icon: ChakraIcons.HelpCircle16,
    size: sizes["icon-sm"],
  },
  "copy-16": {
    icon: ChakraIcons.Copy16,
    size: sizes["icon-sm"],
  },
  "insight-stale-24": {
    icon: ChakraIcons.insightStale24,
    size: sizes["icon-md"],
  },
  "empty-16": {
    icon: ChakraIcons.Empty16,
    size: sizes["icon-sm"],
  },
  "quizzes-checkbox-checked-16": {
    icon: ChakraIcons.QuizzesCheckboxChecked16,
    size: sizes["icon-sm"],
  },
  "quizzes-radio-checked-16": {
    icon: ChakraIcons.QuizzesRadioChecked16,
    size: sizes["icon-sm"],
  },
  "product-browse-16": {
    icon: ChakraIcons.productBrowse16,
    size: sizes["icon-sm"],
  },
  "product-facets-16": {
    icon: ChakraIcons.productFacets16,
    size: sizes["icon-sm"],
  },
  "product-collections-16": {
    icon: ChakraIcons.productCollections16,
    size: sizes["icon-sm"],
  },
  "arrow-drop-down-24": {
    icon: ChakraIcons.arrowDropDown24,
    size: sizes["icon-md"],
  },
  "badge-rule-lock-24": {
    icon: ChakraIcons.badgeRuleLock24,
    size: sizes["icon-md"],
  },
  "quizzes-cover-page-16": {
    icon: ChakraIcons.QuizzesCoverPage16,
    size: sizes["icon-sm"],
  },
  "quizzes-open-text-16": {
    icon: ChakraIcons.QuizzesOpenText16,
    size: sizes["icon-sm"],
  },
  "git-merge-16": {
    icon: ChakraIcons.GitMerge16,
    size: sizes["icon-sm"],
  },
  "image-24": {
    icon: ChakraIcons.Image24,
    size: sizes["icon-md"],
  },
  "image-16": {
    icon: ChakraIcons.Image16,
    size: sizes["icon-sm"],
  },
  "picture-16": {
    icon: ChakraIcons.Picture16,
    size: sizes["icon-sm"],
  },
  "cancel-16": {
    icon: ChakraIcons.Cancel16,
    size: sizes["icon-sm"],
  },
  "link-close-16": {
    icon: ChakraIcons.linkClose16,
    size: sizes["icon-sm"],
  },
  "git-merge-24": {
    icon: ChakraIcons.GitMerge24,
    size: sizes["icon-md"],
  },
  "image-skinny-16": {
    icon: ChakraIcons.ImageSkinny16,
    size: sizes["icon-sm"],
  },
  "play-16": {
    icon: ChakraIcons.Play16,
    size: sizes["icon-sm"],
  },
  "star-16": {
    icon: ChakraIcons.Star16,
    size: sizes["icon-sm"],
  },
  "chevron-down-16": {
    icon: ChakraIcons.ChevronDown16,
    size: sizes["icon-sm"],
  },
  "chevron-left-16": {
    icon: ChakraIcons.ChevronLeft16,
    size: sizes["icon-sm"],
  },
  "chevron-right-16": {
    icon: ChakraIcons.ChevronRight16,
    size: sizes["icon-sm"],
  },
  "chevron-up-16": {
    icon: ChakraIcons.ChevronUp16,
    size: sizes["icon-sm"],
  },
  "union-16": {
    icon: ChakraIcons.Union16,
    size: sizes["icon-sm"],
  },
  "restart-16": {
    icon: ChakraIcons.Restart16,
    size: sizes["icon-sm"],
  },
  "cloud-offline-24": {
    icon: ChakraIcons.CloudOffline24,
    size: sizes["icon-md"],
  },
  "cloud-upload-outline-24": {
    icon: ChakraIcons.CloudUploadOutline24,
    size: sizes["icon-md"],
  },
  "product-browse-24": {
    icon: ChakraIcons.ProductBrowse24,
    size: sizes["icon-md"],
  },
  "product-search-24": {
    icon: ChakraIcons.ProductSearch24,
    size: sizes["icon-md"],
  },
  "global-boost-24": {
    icon: ChakraIcons.GlobalBoost24,
    size: sizes["icon-md"],
  },
  "global-bury-24": {
    icon: ChakraIcons.GlobalBury24,
    size: sizes["icon-md"],
  },
  "global-blocklist-24": {
    icon: ChakraIcons.GlobalBlockList24,
    size: sizes["icon-md"],
  },
  "arrow-down-16": {
    icon: ChakraIcons.ArrowDown16,
    size: sizes["icon-sm"],
  },
  "arrow-top-16": {
    icon: ChakraIcons.ArrowTop16,
    size: sizes["icon-sm"],
  },
  "product-search-16": {
    icon: ChakraIcons.ProductSearch16,
    size: sizes["icon-sm"],
  },
  "status-16": {
    icon: ChakraIcons.Status16,
    size: sizes["icon-sm"],
  },
  "cloud-upload-outline-16": {
    icon: ChakraIcons.CloudUploadOutline16,
    size: sizes["icon-sm"],
  },
  "code-working-16": {
    icon: ChakraIcons.CodeWorking16,
    size: sizes["icon-sm"],
  },
  "folder-open-outline-16": {
    icon: ChakraIcons.FolderOpenOutline16,
    size: sizes["icon-sm"],
  },
  "links-open-in-raw-16": {
    icon: ChakraIcons.LinksOpenInRaw16,
    size: sizes["icon-sm"],
  },
  "more-options-16": {
    icon: ChakraIcons.MoreOptions16,
    size: sizes["icon-sm"],
  },
  "quizzes-results-page-16": {
    icon: ChakraIcons.QuizzesResultsPage16,
    size: sizes["icon-sm"],
  },
  "add-24": {
    icon: ChakraIcons.Add24,
    size: sizes["icon-md"],
  },
  "information-circle-outline-16": {
    icon: ChakraIcons.InformationCircleOutline16,
    size: sizes["icon-sm"],
  },
  "stop-circle-16": {
    icon: ChakraIcons.StopCircle16,
    size: sizes["icon-sm"],
  },
  "arrow-down-thick-16": {
    icon: ChakraIcons.ArrowDownThick16,
    size: sizes["icon-sm"],
  },
  "arrow-top-thick-16": {
    icon: ChakraIcons.ArrowTopThick16,
    size: sizes["icon-sm"],
  },
  "cart-16": {
    icon: ChakraIcons.Cart16,
    size: sizes["icon-sm"],
  },
  "sign-post-outline-16": {
    icon: ChakraIcons.SignPostOutline16,
    size: sizes["icon-sm"],
  },
  "link-specific-play-16": {
    icon: ChakraIcons.LinkSpecificPlay16,
    size: sizes["icon-sm"],
  },
  "move-24": {
    icon: ChakraIcons.Move24,
    size: sizes["icon-md"],
  },
  "redo-16": {
    icon: ChakraIcons.Redo16,
    size: sizes["icon-sm"],
  },
  "undo-16": {
    icon: ChakraIcons.Undo16,
    size: sizes["icon-sm"],
  },
  "searchandising-filled-24": {
    icon: ChakraIcons.SearchandisingFilled24,
    size: sizes["icon-md"],
  },
  "blacklist-16": {
    icon: ChakraIcons.Blacklist16,
    size: sizes["icon-sm"],
  },
  "boost-16": {
    icon: ChakraIcons.Boost16,
    size: sizes["icon-sm"],
  },
  "bury-16": {
    icon: ChakraIcons.Bury16,
    size: sizes["icon-sm"],
  },
  "slotting-16": {
    icon: ChakraIcons.Slotting16,
    size: sizes["icon-sm"],
  },
  "whitelist-16": {
    icon: ChakraIcons.Whitelist16,
    size: sizes["icon-sm"],
  },
  "image-content-16": {
    icon: ChakraIcons.ImageContent16,
    size: sizes["icon-sm"],
  },
  "product-recommendations-24": {
    icon: ChakraIcons.ProductRecommendations24,
    size: sizes["icon-md"],
  },
  "checkmark-16": {
    icon: ChakraIcons.Checkmark16,
    size: sizes["icon-sm"],
  },
  "checkmark-circle-16": {
    icon: ChakraIcons.CheckmarkCircle16,
    size: sizes["icon-sm"],
  },
  "danger-white-16": {
    icon: ChakraIcons.DangerWhite16,
    size: sizes["icon-sm"],
  },
  "info-white-16": {
    icon: ChakraIcons.InfoWhite16,
    size: sizes["icon-sm"],
  },
  "success-white-16": {
    icon: ChakraIcons.SuccessWhite16,
    size: sizes["icon-sm"],
  },
  "product-item-16": {
    icon: ChakraIcons.ProductItem16,
    size: sizes["icon-sm"],
  },
  "attribute-16": {
    icon: ChakraIcons.Attribute16,
    size: sizes["icon-sm"],
  },
  "apps-16": {
    icon: ChakraIcons.Apps16,
    size: sizes["icon-sm"],
  },
  "refresh-ccw-16": {
    icon: ChakraIcons.RefreshCCW16,
    size: sizes["icon-sm"],
  },
  "export-download-16": {
    icon: ChakraIcons.ExportDownload16,
    size: sizes["icon-sm"],
  },
  "grid-hollow-16": {
    icon: ChakraIcons.GridHollow16,
    size: sizes["icon-sm"],
  },
  "home-16": {
    icon: ChakraIcons.Home16,
    size: sizes["icon-sm"],
  },
  "megaphone-16": {
    icon: ChakraIcons.Megaphone16,
    size: sizes["icon-sm"],
  },
  "person-16": {
    icon: ChakraIcons.Person16,
    size: sizes["icon-sm"],
  },
  "podium-16": {
    icon: ChakraIcons.Podium16,
    size: sizes["icon-sm"],
  },
  "terminal-16": {
    icon: ChakraIcons.Terminal16,
    size: sizes["icon-sm"],
  },
  "help-circle-hollow-16": {
    icon: ChakraIcons.HelpCircleHollow16,
    size: sizes["icon-sm"],
  },
  "wand-16": {
    icon: ChakraIcons.Wand16,
    size: sizes["icon-sm"],
  },
  "eye-16": {
    icon: ChakraIcons.Eye16,
    size: sizes["icon-sm"],
  },
  "sponsored-16": {
    icon: ChakraIcons.Sponsored16,
    size: sizes["icon-sm"],
  },
  "download-16": {
    icon: ChakraIcons.Download16,
    size: sizes["icon-sm"],
  },
  "stop-16": {
    icon: ChakraIcons.Stop16,
    size: sizes["icon-sm"],
  },
  "equal-16": {
    icon: ChakraIcons.Equal16,
    size: sizes["icon-sm"],
  },
  "gavel-16": {
    icon: ChakraIcons.Gavel16,
    size: sizes["icon-sm"],
  },
  "insights-documents-16": {
    icon: ChakraIcons.InsightsDocuments16,
    size: sizes["icon-sm"],
  },
  "shuffle-16": {
    icon: ChakraIcons.Shuffle16,
    size: sizes["icon-sm"],
  },
  "card-chevron-down-16": {
    icon: ChakraIcons.CardChevronDown16,
    size: sizes["icon-sm"],
  },
  "card-chevron-up-16": {
    icon: ChakraIcons.CardChevronUp16,
    size: sizes["icon-sm"],
  },
  "bar-chart-16": {
    icon: ChakraIcons.BarChart16,
    size: sizes["icon-sm"],
  },
  "aged-16": {
    icon: ChakraIcons.Aged16,
    size: sizes["icon-sm"],
  },
  "settings-24": {
    icon: ChakraIcons.Settings24,
    size: sizes["icon-md"],
  },
  "enter-16": {
    icon: ChakraIcons.Enter16,
    size: sizes["icon-sm"],
  },
  "lightning-16": {
    icon: ChakraIcons.lightning16,
    size: sizes["icon-sm"],
  },
};
