import * as Sentry from "@sentry/react";
import { Route } from "react-router-dom";

// Wraps the default Route component with Sentry's instrumentation so we can
// identify different URLs as the same transaction (e.g. /users/1 and /users/2
// both belong to /users/:id)
// We keep the real Route for tests since we don't use Sentry there.
const WrappedRoute =
  process.env.NODE_ENV === "test" ? Route : Sentry.withSentryRouting(Route);

export default WrappedRoute;
