import { useState, useEffect } from "react";
// @refactoring Forbid usage of `ramda` (https://constructor.slab.com/posts/deprecating-ramda-and-adopting-remeda-wlks8rn7)
// eslint-disable-next-line local-rules/no-ramda
import { dissoc } from "ramda";

import doRequest from "services/request";

// cognitive-complexity rule is temporarily disabled for this function, please refactor this function
// whenever possible.
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useRequest(
  method,
  url,
  {
    run = true,
    keepResponse = false,
    request = doRequest,
    getCacheKey = getCacheKeyDefault,
    cache = null,
    ...options
  } = {}
) {
  const key = [method, JSON.stringify(url), JSON.stringify(options), run];
  const keyJson = JSON.stringify(key);

  const [response, setResponse] = useState(null);
  const [fetching, setFetching] = useState(run);
  const [currentKeyJson, setCurrentKeyJson] = useState(null);
  const [error, setError] = useState(null);
  const [abortController, setAbortController] = useState(new AbortController());

  const execute = (localOptions = {}) => {
    const resultOptions = {
      ...options,
      ...dissoc("url", localOptions),
      query:
        options.query || localOptions.query
          ? { ...options.query, ...localOptions.query }
          : undefined,
      data:
        options.data || localOptions.data
          ? { ...options.data, ...localOptions.data }
          : undefined,
    };

    const targetUrl = localOptions.url || url;

    if (!keepResponse) {
      setResponse(null);
    }

    setFetching(true);
    setError(null);

    const onSuccess = (result) => {
      setResponse(result);
      setCurrentKeyJson(keyJson);
      setFetching(false);

      if (resultOptions.onSuccess) {
        resultOptions.onSuccess(result);
      }
    };

    const onError = (error) => {
      setError(error);
      setCurrentKeyJson(keyJson);
      setFetching(false);

      if (resultOptions.onError) {
        resultOptions.onError(error);
      }
    };

    return withCache({
      cache: !!cache,
      onSuccess,
      onError,
      getCacheValue: () =>
        cache.get(getCacheKey(url, dissoc("fetchOptions", resultOptions))),
      setCacheValue: (value) =>
        cache.set(
          getCacheKey(url, dissoc("fetchOptions", resultOptions)),
          value
        ),
      callback: () => {
        const promise = request(method, targetUrl, resultOptions);

        promise
          .then(([result, { status }]) => {
            if (status >= 400) {
              throw new Error(
                (result && result.message) || "Error fetching data"
              );
            }

            onSuccess(result);
          })
          .catch((err) => {
            if (err.name !== "AbortError") {
              onError(err);
            }
          });

        return promise;
      },
    });
  };

  function abortPreviousRequestsAndExecute(options = {}) {
    abortController.abort();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    return execute({
      fetchOptions: {
        signal: newAbortController.signal,
        ...options.fetchOptions,
      },
      ...dissoc("fetchOptions", options),
    });
  }

  useEffect(() => {
    if (run) {
      abortPreviousRequestsAndExecute();
    }
    // @refactoring Forbid deactivation of hook dependencies (https://constructor.slab.com/posts/rfc-remove-deactivation-of-the-es-lint-rule-for-hook-dependenciesoli-vk98awgw)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, key);

  return [
    response,
    {
      execute: abortPreviousRequestsAndExecute,
      fetching: run ? fetching || keyJson !== currentKeyJson : fetching,
      error,
    },
  ];
}

export function useGet(url, options = {}) {
  const [response, helpers] = useRequest("GET", url, options);

  const get = (query = undefined, params = {}) =>
    helpers.execute(query === undefined ? params : { ...params, query });

  return [response, { ...helpers, get: get }];
}

export function usePost(url, options = {}) {
  const [response, helpers] = useRequest("POST", url, {
    run: false,
    ...options,
  });

  const post = (data = undefined, params = {}) =>
    helpers.execute(data === undefined ? params : { ...params, data });

  return [response, { ...helpers, post: post }];
}

export function usePut(url, options = {}) {
  const [response, helpers] = useRequest("PUT", url, {
    run: false,
    ...options,
  });

  const put = (data = undefined, params = {}) =>
    helpers.execute(data === undefined ? params : { ...params, data });

  return [response, { ...helpers, put: put }];
}

export function usePatch(url, options = {}) {
  const [response, helpers] = useRequest("PATCH", url, {
    run: false,
    ...options,
  });

  const patch = (data = undefined, params = {}) =>
    helpers.execute(data === undefined ? params : { ...params, data });

  return [response, { ...helpers, patch: patch }];
}

export function useDelete(url, options = {}) {
  const [response, helpers] = useRequest("DELETE", url, {
    run: false,
    ...options,
  });

  const destroy = (data = undefined, params = {}) =>
    helpers.execute(data === undefined ? params : { ...params, data });

  return [response, { ...helpers, destroy: destroy, delete: destroy }];
}

function withCache({
  cache,
  getCacheValue,
  setCacheValue,
  onSuccess,
  onError,
  callback,
}) {
  if (!cache) {
    return callback();
  }

  const cacheData = getCacheValue();

  if (cacheData !== undefined) {
    if (cacheData instanceof Promise) {
      cacheData.then(onSuccess).catch(onError);
      return;
    }

    onSuccess(cacheData);
    return;
  }

  setCacheValue(
    new Promise((resolve, reject) => {
      callback()
        .then(([result]) => {
          setCacheValue(result);
          resolve(result);
        })
        .catch((error) => {
          setCacheValue(null);
          reject(error);
        });
    })
  );
}

function getCacheKeyDefault(url, options) {
  return JSON.stringify({ ...options, url });
}
