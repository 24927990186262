import styled from "@emotion/styled";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Input from "./Input";

const DateInputElement = styled(Input)`
  background-image: url(${require("components/Modules/Icon/calendar.svg")});
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: 16px center;
  padding-left: 50px;
  height: 44px;

  :disabled {
    background: var(--neutral-greenishligthgrey)
      url(${require("components/Modules/Icon/calendar.svg")}) no-repeat 16px
      center;
  }
`;

export default DateInputElement;
