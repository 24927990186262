import React, { useContext } from "react";
import { Box } from "@chakra-ui/react";

import { Spinner } from "app/designSystem/components";
import { Main as MainPage, Root } from "components/Modules/Page";
import FeatureFlags, { isFlagEnabled } from "utils/featureFlags";
import PageLayout from "app/components/PageLayout";
import RootContext from "app/providers/RootContext";

type Props = { menu?: React.ReactNode };

export default function Loading({ menu }: Props) {
  const { isIaResponsivePageLayoutActive } = useContext(RootContext);

  const isIaLayoutEnabled =
    (isFlagEnabled(FeatureFlags.EnableIaResponsivePageLayout) &&
      isIaResponsivePageLayoutActive) ||
    isFlagEnabled(FeatureFlags.EnableIaResponsivePageLayoutByDefault);

  if (isIaLayoutEnabled && menu !== undefined) {
    return (
      <PageLayout isLoading>
        <Box width="max-content" margin="128px auto">
          <Spinner />
        </Box>
      </PageLayout>
    );
  }

  if (menu === undefined) {
    return (
      <Root>
        <Box width="max-content" margin="auto auto">
          <Spinner />
        </Box>
      </Root>
    );
  }

  return (
    <MainPage menu={menu} displayFlashMessages={false}>
      <Box width="max-content" margin="128px auto">
        <Spinner />
      </Box>
    </MainPage>
  );
}
