import URI from "utils/urijs";

export function recommendations({
  indexKey,
  tab,
}: Partial<Index> & { tab?: string } = {}): uri.URI {
  return URI("/dashboard/recommendations").search({ index_key: indexKey, tab });
}

export function recommendationsInteractPod({
  podId,
  indexKey,
}: PodId & Index): uri.URI {
  return URI.expand("/dashboard/recommendations/interact/pod/{podId}", {
    podId,
  }).search({ index_key: indexKey });
}

export function recommendationsInteractRule({
  ruleId,
  indexKey,
}: RuleId & Index): uri.URI {
  return URI.expand("/dashboard/recommendations/interact/rule/{ruleId}", {
    ruleId,
  }).search({ index_key: indexKey });
}

export function recommendationsEditPod({
  podId,
  indexKey,
  name,
  back,
}: PodId &
  Index & {
    name?: string;
    isNew?: boolean;
    back?: string;
  }): uri.URI {
  return URI.expand("/dashboard/recommendations/edit/pod/{podId}", {
    podId,
  }).search({
    index_key: indexKey,
    back,
    ...(name ? { name } : {}),
  });
}

export function recommendationsEditRule({
  ruleId,
  indexKey,
  name,
  back,
}: RuleId &
  Index & {
    name?: string;
    isNew?: boolean;
    back?: string;
  }): uri.URI {
  return URI.expand("/dashboard/recommendations/rules/edit/{ruleId}", {
    ruleId,
  }).search({
    index_key: indexKey,
    back,
    ...(name ? { name } : {}),
  });
}

export function recommendationsNewPod({
  indexKey,
  back,
}: Index & {
  back?: string;
}): uri.URI {
  return URI("/dashboard/recommendations/new/pod").search({
    index_key: indexKey,
    back,
  });
}

export function recommendationsNewRule({
  indexKey,
  back,
}: Index & {
  back?: string;
}): uri.URI {
  return URI("/dashboard/recommendations/rules/new").search({
    index_key: indexKey,
    back,
  });
}

export function searchandizeRefinedStrategy({
  indexKey,
  strategyId,
  back,
  preview,
  ...props
}: SearchandizeRefinedStrategyArgs): uri.URI {
  return URI.expand("/dashboard/recommendations/searchandizing/{strategyId}", {
    strategyId,
  }).search({
    index_key: indexKey,
    back,
    preview: preview ? "1" : undefined,
    ...props,
  });
}

type PodId = { podId: string };
type RuleId = { ruleId: number };
type StrategyId = { strategyId: string };
type Index = { indexKey: string };

type SearchandizeRefinedStrategyArgs = Index &
  StrategyId &
  RefinedStrategy & { back?: string; preview?: boolean };

type RefinedItemStrategy = {
  itemId: string;
};

type RefinedAttributeStrategy = {
  filterName: string;
  filterValue: string;
};

type RefinedStrategy = RefinedItemStrategy | RefinedAttributeStrategy | {};
