import AuthApi from "services/AuthApi";

export type SetUserPasswordParams = {
  userId: string;
  newPassword: string;
  oldPassword: string;
};

/**
 * @description Update current user's password.
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Users/operation/v1-auth-users-reset-password
 * @returns {SetUserPasswordResponse}
 */
export default async function updateUserPassword({
  userId,
  newPassword,
  oldPassword,
}: SetUserPasswordParams): Promise<void> {
  return await AuthApi.post(
    ["v1", "users", userId, "reset-password"],
    {},
    {
      new_password: newPassword,
      old_password: oldPassword,
    }
  );
}

updateUserPassword.key = "modules/authentication/actions/users/updatePassword";
