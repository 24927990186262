import { Box, VStack } from "@chakra-ui/react";
import React from "react";
import { useToaster } from "react-hot-toast";
import { motion, AnimatePresence } from "framer-motion";

const ToastProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      {children}
      <Toaster />
    </>
  );
};

export default ToastProvider;

const Toaster: React.FC = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <Box
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      position="fixed"
      bottom="0px"
      right="32px"
      maxWidth="calc(100% - 64px)"
      bg="transparent"
      zIndex="toast"
    >
      <VStack spacing="24px" alignItems="flex-end">
        {toasts.reverse().map((toast) => {
          return (
            <AnimatePresence key={toast.id}>
              {toast.visible ? (
                <motion.div
                  key={toast.id}
                  layout
                  {...toast.ariaProps}
                  initial={{ y: 0, opacity: 0 }}
                  animate={{
                    y: -32,
                    opacity: 1,
                  }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.25, ease: "linear" }}
                >
                  {typeof toast.message === "function"
                    ? toast.message(toast)
                    : toast.message}
                </motion.div>
              ) : null}
            </AnimatePresence>
          );
        })}
      </VStack>
    </Box>
  );
};
