import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";

const autoSynonym = require("./auto-synonym.png");
export const AutoSynonym = (props: Omit<ImageProps, "src">) => {
  return (
    <Image src={autoSynonym} htmlWidth={60} htmlHeight={61} alt="" {...props} />
  );
};

const autoSynonymsNotification = require("./auto-synonyms-notification.png");
export const AutoSynonymsNotification = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={autoSynonymsNotification}
      htmlWidth={60}
      htmlHeight={60}
      alt=""
      {...props}
    />
  );
};

const autosuggestCtrXsm = require("./autosuggest-ctr-xsm.png");
export const AutosuggestCtrXsm = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={autosuggestCtrXsm}
      htmlWidth={60}
      htmlHeight={60}
      alt=""
      {...props}
    />
  );
};

const cPosition = require("./c-position.png");
export const CPosition = (props: Omit<ImageProps, "src">) => {
  return (
    <Image src={cPosition} htmlWidth={60} htmlHeight={60} alt="" {...props} />
  );
};

const conversions = require("./conversions.png");
export const Conversions = (props: Omit<ImageProps, "src">) => {
  return (
    <Image src={conversions} htmlWidth={60} htmlHeight={60} alt="" {...props} />
  );
};

const ctr = require("./ctr.png");
export const Ctr = (props: Omit<ImageProps, "src">) => {
  return <Image src={ctr} htmlWidth={60} htmlHeight={60} alt="" {...props} />;
};

const collection = require("./collection.png");
export const Collection = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={collection}
      htmlWidth={100}
      htmlHeight={100}
      alt=""
      {...props}
    />
  );
};

const geopersonalization = require("./geopersonalization.png");
export const Geopersonalization = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={geopersonalization}
      htmlWidth={63}
      htmlHeight={79}
      alt=""
      {...props}
    />
  );
};

const guides = require("./guides.png");
export const Guides = (props: Omit<ImageProps, "src">) => {
  return (
    <Image src={guides} htmlWidth={40} htmlHeight={40} alt="" {...props} />
  );
};

const irecommendationsHome = require("./irecommendations-home.png");
export const IrecommendationsHome = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={irecommendationsHome}
      htmlWidth={100}
      htmlHeight={100}
      alt=""
      {...props}
    />
  );
};

const irecommendationsZeroResults = require("./irecommendations-zero-results.png");
export const IrecommendationsZeroResults = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={irecommendationsZeroResults}
      htmlWidth={100}
      htmlHeight={100}
      alt=""
      {...props}
    />
  );
};

const recommendationsCartPage = require("./recommendations-cart-page.png");
export const RecommendationsCartPage = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendationsCartPage}
      htmlWidth={100}
      htmlHeight={100}
      alt=""
      {...props}
    />
  );
};

const recommendationsItemPage = require("./recommendations-item-page.png");
export const RecommendationsItemPage = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendationsItemPage}
      htmlWidth={101}
      htmlHeight={100}
      alt=""
      {...props}
    />
  );
};

const searchandizeSlotting = require("./searchandize-slotting.png");
export const SearchandizeSlotting = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={searchandizeSlotting}
      htmlWidth={241}
      htmlHeight={157}
      alt=""
      {...props}
    />
  );
};

const recommendationsWalkthrough2 = require("./recommendations-walkthrough-2.png");
export const RecommendationsWalkthrough2 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendationsWalkthrough2}
      htmlWidth={158}
      htmlHeight={130}
      alt=""
      {...props}
    />
  );
};

const recommendationsWalkthrough1 = require("./recommendations-walkthrough-1.png");
export const RecommendationsWalkthrough1 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendationsWalkthrough1}
      htmlWidth={246}
      htmlHeight={114}
      alt=""
      {...props}
    />
  );
};

const recommendationsWalkthrough3 = require("./recommendations-walkthrough-3.png");
export const RecommendationsWalkthrough3 = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={recommendationsWalkthrough3}
      htmlWidth={172}
      htmlHeight={122}
      alt=""
      {...props}
    />
  );
};

const searchandize = require("./searchandize.png");
export const Searchandize = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={searchandize}
      htmlWidth={241}
      htmlHeight={157}
      alt=""
      {...props}
    />
  );
};

const slotting = require("./slotting.png");
export const Slotting = (props: Omit<ImageProps, "src">) => {
  return (
    <Image src={slotting} htmlWidth={60} htmlHeight={60} alt="" {...props} />
  );
};

const redirects = require("./redirects.png");
export const Redirects = (props: Omit<ImageProps, "src">) => {
  return (
    <Image src={redirects} htmlWidth={210} htmlHeight={180} alt="" {...props} />
  );
};

const manualFacetConfig = require("./manual-facet-config.png");
export const ManualFacetConfig = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={manualFacetConfig}
      htmlWidth={105}
      htmlHeight={71}
      alt=""
      {...props}
    />
  );
};

const quizzes = require("./quizzes.png");
export const Quizzes = (props: Omit<ImageProps, "src">) => {
  return (
    <Image src={quizzes} htmlWidth={206} htmlHeight={144} alt="" {...props} />
  );
};

const attributeEnrichment = require("./attribute-enrichment.png");
export const AttributeEnrichment = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={attributeEnrichment}
      htmlWidth={120}
      htmlHeight={120}
      alt=""
      {...props}
    />
  );
};
