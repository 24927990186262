import { Role, RoleData } from "modules/authentication/actions/shared/types";
import AuthApi from "services/AuthApi";
import { convertFromApiFormat } from "utils/datesApi";

export type GetUserRolesParams = {
  userId: string;
  currentCompanyId: string;
};

/**
 * @description Get roles assigned to user for the current company
 *
 * @see https://auth-dev-main.constructor.io/openapi/www/openapi.html#tag/Users/operation/v1-auth-users-get-roles-assigned-to-user
 * @returns {GetUserRolesResponse[]}
 */
export default async function getUserRoles({
  userId,
  currentCompanyId,
}: GetUserRolesParams): Promise<GetUserRolesResponse[]> {
  const result = await AuthApi.get<GetUserRolesResponseData>([
    "v1",
    "users",
    userId,
    "roles",
  ]);

  const roles = GetUserRolesResponse.fromGetUserRolesResponseData(result);
  return roles.filter((role) => role.companyId === currentCompanyId);
}

getUserRoles.key = "modules/authentication/actions/users/getUserRoles";

export type GetUserRolesResponseData = {
  next_page_cursor: string;
  roles: (RoleData & {
    company_id: string;
  })[];
};

export type GetUserRolesResponse = Role & {
  companyId: string;
};

export const GetUserRolesResponse = {
  fromGetUserRolesResponseData: (
    data: GetUserRolesResponseData
  ): GetUserRolesResponse[] => {
    const getUserRolesResponse: GetUserRolesResponse[] = data.roles.map(
      (role) => {
        return {
          companyId: role.company_id,
          createdAt: role.created_at
            ? convertFromApiFormat(role.created_at)
            : null,
          defaultAdminRole: role.default_admin_role,
          defaultBasicRole: role.default_basic_role,
          description: role.description,
          displayName: role.display_name,
          id: role.id,
          modifiedAt: role.modified_at
            ? convertFromApiFormat(role.modified_at)
            : null,
          modifiedBy: role.modified_by,
          permissions: role.permissions,
        };
      }
    );

    return getUserRolesResponse;
  },
};
