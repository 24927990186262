import React from "react";
import { Box, Text, Flex } from "@chakra-ui/react";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./MultiSelect.styles";

type Props = {
  placeholder?: string;
};

export default function EmptyMaskedSelectBox({ placeholder }: Props) {
  return (
    <Box {...styles.maskedBoxContainer}>
      <Flex dir="column" align="center" height="full">
        <Text m={0} marginLeft="2" opacity={0.6}>
          {placeholder}
        </Text>
      </Flex>
    </Box>
  );
}
