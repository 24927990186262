import React, { useContext } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi

import Navigation, { Bar, Menu } from "app/components/Navigation";
import RootContext from "app/providers/RootContext";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Footer from "./Footer";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import Flash from "./Flash";

const Page = styled.div`
  margin: 40px auto;
  min-height: 400px;
  width: 100%;
  max-width: 1234px;
  min-width: 300px;
  padding-left: 32px;
  padding-right: 32px;
  flex-grow: 1;
`;

export default Page;

export function Main({
  children,
  menu = <Menu />,
  filter,
  navigationBarProps,
  displayFlashMessages = true,
  className,
}: Props) {
  const location = useLocation();
  const { userFirstName, userEmail, currentCompany, companies } =
    useContext(RootContext);

  return (
    <Root>
      <Bar>
        <Navigation
          user={{ firstName: userFirstName, email: userEmail }}
          company={currentCompany}
          companies={companies}
          currentPath={location.pathname}
          {...navigationBarProps}
        />
      </Bar>

      {(menu || filter) && (
        <MenuContainer>
          {menu}
          {filter}
        </MenuContainer>
      )}

      <Page className={className}>
        {displayFlashMessages && <Flash />}
        {children}
      </Page>

      <Footer />
    </Root>
  );
}

type Props = {
  children: React.ReactNode;
  menu?: React.ReactNode;
  filter?: React.ReactNode;
  navigationBarProps?: Partial<React.ComponentProps<typeof Navigation>>;
  className?: string;
  displayFlashMessages?: boolean;
};

export const Root = styled.div`
  min-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Filter = styled.div`
  flex: 1;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div {
    align-self: stretch;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  background-color: white;
  margin: 0;
  padding: 0px 30px 0px 30px;
  border-top: 1px solid var(--neutral-greenishgrey);
  box-shadow: 0px 2px 20px 0px rgba(149, 151, 152, 0.08);
`;
