import { useContext } from "react";

import RootContext from "app/providers/RootContext";

export default function useCurrentUser(): User {
  const { userId, userEmail, userFirstName, hasSso } = useContext(RootContext);
  return { id: userId, email: userEmail, userFirstName, hasSso };
}

export type User = {
  id: number;
  email: string;
  userFirstName: string;
  hasSso: boolean;
};
