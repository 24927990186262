import URI from "utils/urijs";

import { toDateString } from "./dates";

export function analyticsOpportunitiesQuery({
  query,
  startDate,
  endDate,
  indexKey,
  back,
}: AnalyticsOpportunitiesQueryArgs): uri.URI {
  return URI.expand("/dashboard/analytics/opportunities/{query}", {
    query,
  }).search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
    back,
  });
}

type AnalyticsOpportunitiesQueryArgs = Args & {
  query: string;
  back?: string;
};

export function analyticsTerm({
  term,
  startDate,
  endDate,
  indexKey,
  back,
}: AnalyticsTermArgs): uri.URI {
  return URI.expand("/dashboard/analytics/terms/{term}", { term }).search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
    back,
  });
}

type AnalyticsTermArgs = Args & {
  term: string;
  back?: string;
};

export function analyticsSearch({
  startDate,
  endDate,
  indexKey,
}: Args): uri.URI {
  return URI("/dashboard/analytics/search").search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
  });
}

export function analyticsBrowse({
  startDate,
  endDate,
  indexKey,
}: Args): uri.URI {
  return URI("/dashboard/analytics/browse").search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
  });
}

type AnalyticsFilterArgs = Args & {
  filterName: string;
  filterValue: string;
  originalIndexKey?: string;
};

export function analyticsBrowseFilter({
  startDate,
  endDate,
  indexKey,
  filterName,
  filterValue,
  back,
  originalIndexKey,
}: AnalyticsFilterArgs): uri.URI {
  return URI.expand("/dashboard/analytics/browse/{filterName}/{filterValue}", {
    filterName,
    filterValue,
  }).search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
    back,
    original_index_key: originalIndexKey || indexKey,
  });
}

export function analyticsVisitorJourney({
  uuid,
  eventId,
  session,
  indexKey,
}: AnalyticsVisitorJourneyArgs): uri.URI {
  return URI.expand("/dashboard/visitors/{uuid}/journey", { uuid }).search({
    index_key: indexKey,
    event_id: eventId,
    session,
  });
}

type AnalyticsVisitorJourneyArgs = {
  uuid: string;
  eventId: string;
  session: number;
  indexKey?: string;
  back?: string;
};

export function analyticsRecommendations({
  startDate,
  endDate,
  indexKey,
  back,
}: Args = {}): uri.URI {
  return URI("/dashboard/analytics/recommendations").search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
    back,
  });
}

export function analyticsRecommendationPod({
  podId,
  startDate,
  endDate,
  indexKey,
  interact,
  back,
}: Args & { podId: string }): uri.URI {
  return URI.expand("/dashboard/analytics/recommendations/pods/{podId}", {
    podId,
  }).search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
    interact: interact ? 1 : undefined,
    back,
  });
}

type Args = {
  indexKey?: string;
  startDate?: Date;
  endDate?: Date;
  back?: string;
  interact?: boolean;
};

export const GEO_PERSONALIZATION_PATH =
  "/dashboard/analytics/geo_personalization";

export function geoPersonalization(): uri.URI {
  return URI(GEO_PERSONALIZATION_PATH);
}

export const RULE_PERFORMANCE_PATH = "/dashboard/analytics/rule_performance";

export function rulePerformance(): uri.URI {
  return URI(RULE_PERFORMANCE_PATH);
}

export const AB_TESTING_PATH = "/dashboard/analytics/ab_testing";

type ABTestingProps = {
  id: string;
  back?: string;
};

export function abTesting(test?: ABTestingProps): uri.URI {
  if (test) {
    return URI.expand(`${AB_TESTING_PATH}/view/searchandizing/{testId}`, {
      testId: test.id,
    }).search({ back: test.back });
  }
  return URI(AB_TESTING_PATH);
}

type ABTestingRecommendationsProps = {
  id: string;
  back?: string;
};

export function abTestingRecommendations(
  test?: ABTestingRecommendationsProps
): uri.URI {
  if (test) {
    return URI.expand(`${AB_TESTING_PATH}/view/recommendations/{testId}`, {
      testId: test.id,
    }).search({ back: test.back });
  }
  return URI(AB_TESTING_PATH);
}

export const QUIZZES_ANALYTICS_PATH = "/dashboard/analytics/quizzes";

export function quizzes(): uri.URI {
  return URI(QUIZZES_ANALYTICS_PATH);
}

export type ABTestingNewProps = {
  type: "search" | "browse" | "recommendations";
  back?: string;
};

export function abTestingNew({ type, back }: ABTestingNewProps): uri.URI {
  return URI(`${AB_TESTING_PATH}/new/${type}`).search({ back });
}

type AnalyticsBrowseGroupsFacetsArgs = {
  value: string;
  startDate: Date;
  endDate: Date;
  indexKey?: string;
  back?: string;
  originalIndexKey?: string;
};

export function analyticsBrowseGroupsFacets({
  value,
  indexKey,
  back,
  startDate,
  endDate,
  originalIndexKey,
}: AnalyticsBrowseGroupsFacetsArgs) {
  return analyticsBrowseFilter({
    filterName: "group_id",
    filterValue: value,
    indexKey,
    back,
    startDate,
    endDate,
    originalIndexKey,
  });
}

type AnalyticsCollectionPageArgs = {
  value: string;
  startDate: Date;
  endDate: Date;
  indexKey?: string;
  back?: string;
  originalIndexKey?: string;
};

export function analyticsCollectionPage({
  value,
  indexKey,
  back,
  startDate,
  endDate,
  originalIndexKey,
}: AnalyticsCollectionPageArgs) {
  return analyticsBrowseFilter({
    filterName: "collection_id",
    filterValue: value,
    indexKey,
    back,
    startDate,
    endDate,
    originalIndexKey,
  });
}

type AnalyticsFacetsPageArgs = {
  filterName: string;
  filterValue: string;
  startDate?: Date;
  endDate?: Date;
  indexKey?: string;
  back?: string;
};

export function analyticsFacetsPage({
  filterName,
  filterValue,
  indexKey,
  back,
  startDate,
  endDate,
}: AnalyticsFacetsPageArgs): uri.URI {
  return URI.expand(
    "/dashboard/analytics/browse/facet/{filterName}/{filterValue}",
    {
      filterName,
      filterValue,
    }
  ).search({
    index_key: indexKey,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
    back,
  });
}

type SearchRulePerformanceProps = {
  query: string;
  itemId: string;
  position: number;
  back?: string;
};

export function searchRulePerformanceHelper({
  query,
  back,
  position,
  itemId,
}: SearchRulePerformanceProps): uri.URI {
  const uri = URI.expand(
    "/dashboard/analytics/rule_performance/search/{query}/{position}/{itemId}",
    {
      query,
      position,
      itemId,
    }
  ).search({ back });

  return uri;
}

type BrowseRulePerformanceProps = {
  filterName: string;
  filterValue: string;
  position: number;
  itemId: string;
  back?: string;
};

export function browseRulePerformanceHelper({
  filterName,
  filterValue,
  back,
  position,
  itemId,
}: BrowseRulePerformanceProps): uri.URI {
  const uri = URI.expand(
    "/dashboard/analytics/rule_performance/browse/{filterName}/{filterValue}/{position}/{itemId}",
    {
      filterName,
      filterValue,
      position,
      itemId,
    }
  ).search({ back });

  return uri;
}

type SearchUnderperformingRulesHelperProps = {
  showUnderperforming?: boolean;
  tab?: string;
  indexKey?: string;
  startDate?: string;
  endDate?: string;
};

export function searchUnderperformingRulesHelper({
  indexKey,
  showUnderperforming,
  tab,
  startDate,
  endDate,
}: SearchUnderperformingRulesHelperProps) {
  const uri = URI.expand("/dashboard/analytics/search", {}).search({
    showUnderperforming,
    index_key: indexKey,
    tab,
    start_date: startDate,
    end_date: endDate,
  });

  return uri;
}

type BrowseUnderperformingRulesHelperProps = {
  showUnderperforming?: boolean;
  browseTab?: string;
  indexKey?: string;
  startDate?: string;
  endDate?: string;
};

export function browseUnderperformingRulesHelper({
  indexKey,
  showUnderperforming,
  browseTab,
  startDate,
  endDate,
}: BrowseUnderperformingRulesHelperProps) {
  const uri = URI.expand("/dashboard/analytics/browse", {}).search({
    showUnderperforming,
    index_key: indexKey,
    browseTab,
    start_date: startDate,
    end_date: endDate,
  });

  return uri;
}

export function itemAnalyticsPage({
  itemId,
  startDate,
  endDate,
  back,
}: {
  itemId: string;
  startDate?: Date;
  endDate?: Date;
  back?: string;
}) {
  return URI.expand("/dashboard/analytics/items/{itemId}", {
    itemId,
  }).search({
    back,
    start_date: startDate && toDateString(startDate),
    end_date: endDate && toDateString(endDate),
  });
}
