import { isHistoryApiSupported } from "utils/routes";
import { PageGroup } from "app/components/shared/shared/types";
import URI from "utils/urijs";

export const isPathInGroup = (path: string, pageGroup: PageGroup) =>
  !!pageGroup.items.find(({ activePaths }) =>
    activePaths.some((activePath) => path.startsWith(activePath))
  );

export const getPathDetails = (path: string, pageGroup?: PageGroup) => {
  if (!pageGroup) {
    return { index: -1, breadcrumbs: [] };
  }

  return {
    index: pageGroup.index,
    breadcrumbs: pageGroup.items.find(({ activePaths }) =>
      activePaths.some((activePath) => path.startsWith(activePath))
    )!.breadcrumbs,
  };
};

export const getSearch = (paramsToKeep: string[]): string => {
  const params = new URLSearchParams(URI().search());
  const newParams = new URLSearchParams();
  const paramsSet = new Set([
    "index_key",
    "start_date",
    "end_date",
    ...paramsToKeep,
  ]);

  Array.from(paramsSet).forEach((param) => {
    const value = params.get(param);
    value ? newParams.set(param, value) : undefined;
  });
  const result = newParams.toString();
  return result ? "?" + result : "";
};

export const getIsHistorySupported = (pathOne: string, pathTwo: string) => {
  return (
    isHistoryApiSupported(pathOne, {
      currentUrl: pathTwo,
    }) &&
    isHistoryApiSupported(pathTwo, {
      currentUrl: pathOne,
    })
  );
};
