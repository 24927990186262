/**
 * This module is a wrapper around day.js library.
 * The main goal of the wrapper is to protect from different behavior
 * of string parsing algorithm in different browsers
 * by forcing user to specify format string in case of string input.
 *
 * When string is passed as an argument to dayjs constructor without specifying parse format string
 * it usually cause the string to be passed to native Date constructor to be parsed into a Date instance.
 * Different browsers implement this parsing algorithm differently
 * so there could be an issue when the same input parses to a Date in one browser and fails in another.
 * One example of such behavior would be string `2023-03-20 06:00 AM UTC` in Chrome and Firefox
 * when passed to Date constructor. Chrome will parse it, and Firefox will produce `InvalidDate`.
 *
 * Suggested solution is to use wrappers from utils/dayjs.
 * These wrappers just pass arguments to the original functions
 * but also ensure a format string to be passed in case of first argument being string.
 *
 * Also check `disallow-using-dayjs-directly` eslint rule
 * which ensures usage of this module instead of day.js directly
 *
 * For more details check the post mortem here:
 * @see https://linear.app/constructor/issue/MT-760/pm-add-wrapper-on-top-of-dayjs-with-format-validation
 */
// eslint-disable-next-line local-rules/disallow-using-dayjs-directly
import originalDayjs from "dayjs";
import utcPlugin from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezonePlugin from "dayjs/plugin/timezone";
import customParseFormatPlugin from "dayjs/plugin/customParseFormat";

export type { ConfigType } from "dayjs";

export type { FormatObject } from "dayjs";

export type { OptionType } from "dayjs";

export type { UnitTypeShort } from "dayjs";

export type { UnitTypeLong } from "dayjs";

export type { UnitTypeLongPlural } from "dayjs";

export type { UnitType } from "dayjs";

export type { OpUnitType } from "dayjs";

export type { QUnitType } from "dayjs";

export type { ManipulateType } from "dayjs";

type StringConfigNotAllowed = Exclude<originalDayjs.ConfigType, string>;

/**
 * @param config a string `date` is only allowed when explicit `format` is specified.
 */
function dayjs(date?: StringConfigNotAllowed): originalDayjs.Dayjs;

function dayjs(
  date?: StringConfigNotAllowed,
  format?: originalDayjs.OptionType,
  strict?: boolean
): originalDayjs.Dayjs;

function dayjs(
  date?: StringConfigNotAllowed,
  format?: originalDayjs.OptionType,
  locale?: string,
  strict?: boolean
): originalDayjs.Dayjs;

function dayjs(
  date: string,
  format: originalDayjs.OptionType,
  strict?: boolean
): originalDayjs.Dayjs;

function dayjs(
  date: string,
  format: originalDayjs.OptionType,
  locale?: string,
  strict?: boolean
): originalDayjs.Dayjs;

// @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function dayjs(...args: any[]) {
  return originalDayjs(...args);
}

dayjs.locale = originalDayjs.locale;

dayjs.isDayjs = originalDayjs.isDayjs;

dayjs.unix = originalDayjs.unix;

originalDayjs.extend(utcPlugin);
originalDayjs.extend(advancedFormat);

/**
 * @param config a string `date` is only allowed when explicit `format` is specified.
 * @param format format string
 * @param strict strict parsing requires that the format and input match exactly, including delimiters.
 */
function utc(
  config?: StringConfigNotAllowed,
  format?: string,
  strict?: boolean
): originalDayjs.Dayjs;

function utc(
  config: string,
  format: string,
  strict?: boolean
): originalDayjs.Dayjs;

// @refactoring TS no-explicit-any linting rule (https://constructor.slab.com/posts/refactor-rfc-ts-no-explicit-any-linting-rule-h66tj51a)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function utc(...args: any[]) {
  return originalDayjs.utc(...args);
}

dayjs.utc = utc;

originalDayjs.extend(timezonePlugin);

originalDayjs.extend(customParseFormatPlugin);

export default dayjs;

export { Dayjs } from "dayjs";

export type { PluginFunc } from "dayjs";
