import React, { useEffect } from "react";
import { Box, Text, ListItem } from "@chakra-ui/react";

import { Tooltip } from "app/designSystem/components/Tooltip";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as types from "./MultiSelect.types";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./MultiSelect.styles";

export default function OptionListItem({
  data: { items, onSelectHandler, activeIndex, handleItemOnKeyDownEvent },
  index,
  style,
}: types.OptionListItemProps) {
  const { id, label, labelTooltip, info, selected } = items[index];
  const itemRef = React.useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (activeIndex === index) {
      requestAnimationFrame(() => {
        itemRef?.current?.focus();
      });
    }
  }, [activeIndex, index]);

  return (
    <ListItem
      ref={itemRef}
      tabIndex={0}
      style={style}
      onClick={() => onSelectHandler(id, index)}
      onKeyDown={(e) => {
        e.preventDefault();
        handleItemOnKeyDownEvent(e.key, id, index, e.shiftKey);
      }}
      key={id}
      role="option"
      justifyContent="space-between"
    >
      <Box display="flex" flexGrow={1} maxW="80%" alignItems="center">
        <ChakraIcon {...styles.checkboxIcon(selected)} />
        <Tooltip label={labelTooltip || ""} shouldWrapChildren>
          <Text {...styles.listItemText}>{label}</Text>
        </Tooltip>
      </Box>

      {info && <Text {...styles.listItemTextInfo}>{info}</Text>}
    </ListItem>
  );
}
