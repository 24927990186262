// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { IconButtonProps } from "./IconButton.types";

const getDisabledColor = (
  variant: IconButtonProps["variant"],
  isLoading: boolean
) => {
  if (variant === "ghost") {
    return "transparent";
  }

  if (variant === "danger" && !isLoading) {
    return "danger";
  }

  return "grey-disabled";
};

export const IconButton = (
  variant: IconButtonProps["variant"],
  isLoading: boolean
) => {
  const borderColor = variant === "ghost" ? "white" : "grey-light-blue";
  const hoverBgColor = variant === "danger" ? "danger" : "grey-light-blue";
  const hoverColor = variant === "danger" ? "white" : "grey-dark";
  const disabledBgColor = getDisabledColor(variant, isLoading);

  return {
    p: "6px",
    h: "32px",
    w: "32px",
    minW: "32px",
    color: "grey-dark",
    border: "2px solid",
    borderRadius: "4px",
    borderColor: borderColor,
    bgColor: "white",
    cursor: isLoading ? "not-allowed" : "pointer",
    _hover: {
      bgColor: hoverBgColor,
      color: hoverColor,
      borderColor: hoverBgColor,
      _active: {
        borderColor: "grey-dark",
        bgColor: hoverBgColor,
        color: hoverColor,
      },
      _focus: {
        borderColor: "grey-dark",
        bgColor: hoverBgColor,
        color: hoverColor,
      },
      _disabled: {
        borderColor: disabledBgColor,
        bgColor: disabledBgColor,
        cursor: "not-allowed",
      },
    },
    _disabled: {
      borderColor: disabledBgColor,
      bgColor: disabledBgColor,
      cursor: "not-allowed",
    },
    _active: {
      borderColor: "grey-dark",
      bgColor: hoverBgColor,
      color: hoverColor,
    },
    _focus: {
      borderColor: "grey-dark",
      bgColor: hoverBgColor,
      color: hoverColor,
    },
  };
};

export const Icon = (
  variant: IconButtonProps["variant"],
  isDisabled: boolean
) => {
  if (isDisabled && variant === "ghost") {
    return { color: "grey-disabled" };
  }

  return {
    color: isDisabled ? "grey" : "currentColor",
  };
};
