import Highcharts from "highcharts";
import { useEffect } from "react";

import useSidebar from "app/components/shared/useSidebar";

const useHighchartsReflow = () => {
  const { isSidebarOpen } = useSidebar();

  useEffect(() => {
    setTimeout(() => {
      Highcharts.charts.forEach((chart) => {
        chart?.reflow();
      });
      // Duration of the sidebar transition
    }, 300);
  }, [isSidebarOpen]);
};

export default useHighchartsReflow;
