import React from "react";
import { css } from "@emotion/react";

import URI from "utils/urijs";
import { OptionLink } from "components/Modules/OptionsMenu";

export const Company = ({ company, selected = false }: Props) => {
  return (
    <OptionLink
      key={company.id}
      href={URI("/dashboard")
        .setSearch("company_id", String(company.id))
        .resource()}
      css={css`
        text-transform: uppercase;
        font-weight: bold;
        white-space: nowrap;
        letter-spacing: 1px;

        ${selected &&
        css`
          color: #181c25;
        `}
      `}
    >
      <div
        css={css`
          position: relative;

          ${selected &&
          css`
            &:before {
              content: "";
              position: absolute;
              background-color: #181c25;
              height: 100%;
              width: 3px;
              top: 0;

              /* This value is tied to the padding of OptionLink */
              left: -25px;
            }
          `}
        `}
      >
        {company.name}
      </div>
    </OptionLink>
  );
};

type Props = {
  company: { id: number; name: string };
  selected?: boolean;
};
