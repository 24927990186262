import React from "react";
import { createConfirmation, confirmable } from "react-confirm";

import { Gate as Modal } from "components/Modules/Modal";

import ThemeProvider from "app/providers/ThemeProvider";

import Dialog, { Props as DialogProps } from "./Confirmation/Dialog";

export { default as useConfirmation } from "./Confirmation/useConfirmation";

// this file exports only final usable "confirm" function; if you need
// Confirmation or ConfirmationConfirmable classes to be used somewhere,
// add them to export

function Confirmation<FooterProps = {}>({
  show = true,
  onDismiss = () => {},
  dismiss = () => {},
  ...props
}: Props<FooterProps>) {
  return (
    // This component gets mounted outside of the regular app tree, which means
    // that any context provider defined in `App.tsx` won't be available. We
    // need to add the ones that are necessary here.
    // https://github.com/haradakunihiko/react-confirm/issues/33#issuecomment-852671015
    <ThemeProvider>
      <Modal
        isOpen={show}
        onRequestClose={() => {
          if (!props?.options?.confirmOnly) {
            dismiss();
            onDismiss();
          }
        }}
        closeTimeoutMS={0}
        className="ReactModal__Content cio-modal cio-confirmation"
      >
        <Dialog onDismiss={onDismiss} dismiss={dismiss} {...props} />
      </Modal>
    </ThemeProvider>
  );
}

type Props<FooterProps> = DialogProps<FooterProps> & {
  show?: boolean;
};

const ConfirmationConfirmable = confirmable(Confirmation);
const confirm = createConfirmation(ConfirmationConfirmable);

export default confirm;
