import React from "react";
import styled from "@emotion/styled";

import getCopyrightYear from "utils/copyrightYear";
import theme from "components/theme";

const Container = styled.div`
  border-top: 1px solid #ddd;
  margin: 50px auto 0;
  max-width: 1170px;
  overflow: hidden;
  padding: 30px 0 30px;
  text-align: center;
  width: 100%;
`;

const Link = styled.a`
  display: inline-block;
  color: ${theme.colors.headerGray};
  font-size: 14px;
  text-decoration: none;
  margin: 0 16px;

  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div`
  display: inline-block;
  margin: 0 16px;
  font-size: 14px;
  color: ${theme.colors.headerGray};
`;

function Footer() {
  return (
    <Container>
      <Link href="https://constructor.io/terms-of-service-agreement/">
        Terms
      </Link>

      <Link href="https://constructor.io/privacy-policy/">Privacy Policy</Link>

      <Copyright>
        Copyright &copy; Constructor.io {getCopyrightYear()}. All Rights
        Reserved.
      </Copyright>
    </Container>
  );
}

export default Footer;
