export default {
  black: "#181C25",
  "grey-dark": "#505A75",
  "grey-dark-2": "#737D91",
  "grey-dark-disabled": "#B4B9C3",
  "grey-2": "#BEC1CB",
  "grey-light-3": "#d3d3d3",
  grey: "#8F97A7",
  "grey-shadow": "#8F97A775",
  "grey-disabled": "#CED1D8",
  "grey-light-blue": "#E1E5F1",
  "grey-light-green": "#E4EBEF",
  "grey-light-green-2": "#E9F0F3",
  "grey-light-disabled": "#F5F6F7",
  "grey-verylight-green": "#EEF2F5",
  "grey-light-green-transparent": "#E4EBEFA3",
  "grey-pale": "#F7F9FA",
  "grey-pale-2": "#EFF4F6",
  white: "#FFFFFF",
  "white-disabled": "#F5F6F7",
  success: "#4ABB8B",
  "success-2": "#568500",
  "success-shadow": "#4ABB8B75",
  "success-light": "#EBF3DC",
  warning: "#FFC44D",
  "warning-shadow": "#FFC44D75",
  danger: "#E0182D",
  "danger-shadow": "#E0182D75",
  "danger-light": "#fad1d5",
  "danger-dark": "#BF1728",
  "danger-dark-2": "#4C1E27",
  "green-light": "#F1FAF6",
  "green-light-2": "#daf1e7",
  green: "#4ABB8B",
  "green-dark": "#2B8F65",
  "blue-light": "#BCDCFE",
  "blue-light-1": "#EBF2FF",
  "blue-light-2": "#CCE2FA",
  blue: "#1F89FB",
  "blue-dark": "#196EC9",
  "blue-dark-shadow": "#196EC975",
  "yellow-light": "#FFC44D",
  "yellow-very-light": "#FFF3DB",
  yellow: "#FFAB00",
  "yellow-dark": "#E69A00",
  "turquoise-light-2": "#79E2F2",
  "turquoise-light-1": "#bdf1f9",
  turquoise: "#00B8D9",
  "turquoise-dark": "#008DA6",
  "purple-light": "#998DD9",
  "purple-light-2": "#E8E5F6",
  "purple-light-3": "#7F71CB",
  purple: "#6554C0",
  "purple-dark": "#403294",
  "magenta-light": "#F55F89",
  "magenta-light-2": "#FDF7F8",
  magenta: "#F3376B",
  "magenta-dark": "#C22C56",

  // Brands
  "brands-violet": "#CBB9EB",
  "brands-pink": "#E5BEC3",
  "brands-lightblue": "#A3D3FF",
  "brands-beige": "#F4D592",
  "brands-green": "#6D8C9F",

  //overlay
  "overlay-white": "#E2E8F0",

  // Information architecture
  "sidebar-blue": "#085DB8",

  //Skeleton
  "grey-light-disabled-2": "#EAEBED",

  gray: {
    25: "#FCFCFC",
    50: "#F7F7F9",
    100: "#EFEFF4",
    200: "#D9D9E0",
    300: "#BEBEC6",
    400: "#A0A0A8",
    500: "#7F7F8D",
    600: "#5E5E6F",
    700: "#424252",
    800: "#2B2B39",
    900: "#1B1B26",
    1000: "#0F0F17",
  },
};
