import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  VStack,
  HStack,
  Popover,
  useDisclosure,
  PopoverTrigger,
  Flex,
} from "@chakra-ui/react";

import moment from "modules/moment";
import URI from "utils/urijs";

import DateRangePicker from "components/Modules/DateRangePicker";
import DateInput from "components/Modules/DateRangeFilter/DateInput";

import useSidebar from "app/components/shared/useSidebar";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

import Option from "./DateRangeFilter/Option";
import DatePickerWrapper from "./DateRangeFilter/DatePickerWrapper";

const DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_HEADLINE = "Date";
const DEFAULT_NUMBER_OF_MONTHS = 2;

export default function DateRangeFilter({
  startDate: startDateString,
  endDate: endDateString,
  onChange = ({ startDate, endDate }) => {
    window.location.href = URI()
      .setSearch("start_date", startDate.format(DATE_FORMAT))
      .setSearch("end_date", endDate.format(DATE_FORMAT))
      .toString();
  },
  headline = DEFAULT_HEADLINE,
  singleSelection = false,
}: Props) {
  const initialStartDate = moment(startDateString, DATE_FORMAT);
  const initialEndDate = moment(endDateString, DATE_FORMAT);

  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    setStartDate(moment(startDateString, DATE_FORMAT));
    setEndDate(moment(endDateString, DATE_FORMAT));
  }, [startDateString, endDateString]);

  const validDateRange = moment(endDate).isSameOrAfter(startDate);

  let displayDate = initialStartDate.format("MMM D, YYYY");
  if (!initialStartDate.isSame(initialEndDate, "day")) {
    displayDate += ` - ${initialEndDate.format("MMM D, YYYY")}`;
  }

  const onSelectionChange = (newSelection: {
    from: moment.Moment;
    to: moment.Moment;
  }) => {
    setStartDate(newSelection.from);
    setEndDate(newSelection.to);
  };

  const selectRange = (from: moment.Moment, to?: moment.Moment) => {
    onSelectionChange({ from: from, to: to || from });
  };

  const onApply = () => {
    onChange({ startDate, endDate });
  };

  const optionList = [
    {
      startDate: moment(),
      label: "Today",
    },
    {
      startDate: moment().subtract(1, "day"),
      label: "Yesterday",
    },
    {
      startDate: moment().subtract(7, "days"),
      endDate: moment().subtract(1, "day"),
      label: "Last 7 days",
    },
    {
      startDate: moment().subtract(30, "days"),
      endDate: moment().subtract(1, "day"),
      label: "Last 30 days",
    },
    {
      startDate: moment().startOf("month"),
      endDate: moment().isSame(moment().startOf("month"), "day")
        ? moment()
        : moment().subtract(1, "day"),
      label: "This month",
    },
    {
      startDate: moment().subtract(1, "month").startOf("month"),
      endDate: moment().subtract(1, "month").endOf("month"),
      label: "Last month",
    },
  ];

  const options = (
    <>
      {optionList.map((option) => (
        <Option
          key={option.label}
          singleSelection={singleSelection}
          selectRange={selectRange}
          {...option}
        />
      ))}
      {singleSelection && (
        <Option
          singleSelection={singleSelection}
          selectRange={selectRange}
          startDate={initialStartDate}
          label="Custom range"
        />
      )}
    </>
  );

  const { isOpen, onClose, onToggle } = useDisclosure();
  const { isDesktopView } = useSidebar();

  return (
    <Popover placement="top-end" onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          h="59px"
          minH="59px"
          borderRadius="0"
          bg="white"
          onClick={onToggle}
          paddingInline="0"
        >
          <VStack
            h="full"
            justifyContent="center"
            alignItems="flex-start"
            gap="0"
            spacing="0"
            px="8px"
            color="grey-dark"
          >
            <Box textStyle="buttonLabel">{headline}</Box>
            <HStack alignItems="center" gap="8px">
              <Box
                textStyle="body2"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
              >
                {displayDate}
              </Box>
              {isDesktopView ? (
                <ChakraIcon icon="arrow-drop-down-16" />
              ) : (
                <ChakraIcon icon="arrow-drop-left-16" />
              )}
            </HStack>
          </VStack>
        </Button>
      </PopoverTrigger>
      <DatePickerWrapper
        isDesktopView={isDesktopView}
        isOpen={isOpen}
        onClose={onClose}
        singleSelection={singleSelection}
      >
        <Box bg="white" borderRadius="8px" boxShadow="menu">
          <Flex p="30px" justifyContent="center" gap="16px" w="full">
            <Flex flexDirection="column" alignItems="center" w="full">
              <Flex
                flexWrap="nowrap"
                display={{ sm: "flex", md: "none" }}
                w="80%"
                minW="324px"
                overflowX="auto"
                gap="4px"
                css={{
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                  "&::-webkit-scrollbar": { display: "none" },
                }}
                pb="16px"
              >
                {options}
              </Flex>
              <Flex justifyContent="space-between">
                <Flex
                  gap="28px"
                  alignItems="flex-start"
                  direction={{ sm: "column", md: "row" }}
                >
                  <DateInput
                    className="form-control cio-form__control"
                    value={startDate}
                    onChange={(newDate) => setStartDate(newDate)}
                  />
                  {!singleSelection && (
                    <VStack align="stretch">
                      <DateInput
                        className="form-control cio-form__control"
                        value={endDate}
                        onChange={(newDate) => setEndDate(newDate)}
                        isError={!validDateRange}
                      />
                      {!validDateRange && (
                        <Box color="danger" fontSize="12px">
                          End date cannot be earlier than start date
                        </Box>
                      )}
                    </VStack>
                  )}
                </Flex>
              </Flex>
              <Box
                marginBottom="14px"
                marginTop="16px"
                height={{ sm: "290px", md: "auto" }}
              >
                <DateRangePicker
                  numberOfMonths={
                    singleSelection || !isDesktopView
                      ? 1
                      : DEFAULT_NUMBER_OF_MONTHS
                  }
                  isSingleSelection={singleSelection}
                  startDate={startDate.toDate()}
                  endDate={endDate.toDate()}
                  toMonth={new Date()}
                  disabledDays={{ after: new Date() }}
                  onSelectionChanged={(newSelection: {
                    from: Date;
                    to: Date;
                  }) => {
                    if (
                      moment(newSelection.from).isAfter(moment(), "day") ||
                      moment(newSelection.to).isAfter(moment(), "day")
                    ) {
                      return;
                    }

                    onSelectionChange({
                      from: moment(newSelection.from),
                      to: singleSelection
                        ? moment(newSelection.from)
                        : moment(newSelection.to),
                    });
                  }}
                />
              </Box>
            </Flex>
            <Box flexGrow={1} display={{ sm: "none", md: "block" }}>
              {options}
            </Box>
          </Flex>
          <Flex
            w="full"
            bg="grey-light-green"
            p="22px 30px"
            justifyContent={{ sm: "center", md: "flex-end" }}
            gap="16px"
          >
            <Button size="sm" p="4px 32px" variant="ghost" onClick={onClose}>
              Discard
            </Button>

            <Button
              p="4px 32px"
              disabled={!validDateRange}
              variant="primary"
              size="sm"
              title={validDateRange ? "" : "Invalid date range"}
              onClick={() => {
                onApply();
                onClose();
              }}
            >
              Apply
            </Button>
          </Flex>
        </Box>
      </DatePickerWrapper>
    </Popover>
  );
}

type Props = {
  singleSelection?: boolean;
  headline?: string;
  startDate: string;
  endDate: string;
  onChange?: ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  }) => void;
};
