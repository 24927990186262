import React from "react";
import { Image, ImageProps } from "@chakra-ui/react";

const unknownItemBig = require("./unknown-item-big.png");
export const UnknownItemBig = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={unknownItemBig}
      htmlWidth={200}
      htmlHeight={200}
      alt="Unknown Item"
      {...props}
    />
  );
};

const unknownItemSmall = require("./unknown-item-small.png");
export const UnknownItemSmall = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={unknownItemSmall}
      htmlWidth={48}
      htmlHeight={48}
      alt="Unknown Item"
      {...props}
    />
  );
};

const unknownCategory = require("./category.png");
export const UnknownCategory = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={unknownCategory}
      htmlWidth={40}
      htmlHeight={40}
      alt="Unknown Item"
      {...props}
    />
  );
};

const noSearches = require("./no-searches.png");
export const NoSearches = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={noSearches}
      htmlWidth={75}
      htmlHeight={90}
      alt="No Searches"
      {...props}
    />
  );
};

const notFoundGreen = require("./not-found-green.svg");
export const NotFoundGreen = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={notFoundGreen}
      htmlWidth={408}
      htmlHeight={232}
      alt="No Searches"
      {...props}
    />
  );
};

const notFoundBlue = require("./not-found-blue.svg");
export const NotFoundBlue = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={notFoundBlue}
      htmlWidth={408}
      htmlHeight={232}
      alt="No Searches"
      {...props}
    />
  );
};

const notFound2Green = require("./not-found-2-green.svg");
export const NotFound2Green = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={notFound2Green}
      htmlWidth={408}
      htmlHeight={232}
      alt="No Searches"
      {...props}
    />
  );
};

const notFound2Blue = require("./not-found-2-blue.svg");
export const NotFound2Blue = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={notFound2Blue}
      htmlWidth={408}
      htmlHeight={232}
      alt="No Searches"
      {...props}
    />
  );
};

const noAutomaticRules = require("./no-automatic-rules.png");
export const NoAutomaticRules = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={noAutomaticRules}
      alt="No Automatic Rules"
      htmlWidth={240}
      htmlHeight={160}
      {...props}
    />
  );
};

const zeroresultsWashed = require("./zeroresults-washed.png");
export const ZeroresultsWashed = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={zeroresultsWashed}
      htmlWidth={196}
      htmlHeight={196}
      alt="Zero Results Washed"
      {...props}
    />
  );
};

const emptyListingGreen = require("./empty-listing-green.png");
export const EmptyListingGreen = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={emptyListingGreen}
      htmlWidth={196}
      htmlHeight={196}
      alt="Zero Pods On Recommendation Analytics"
      {...props}
    />
  );
};

const emptySearchBlue = require("./empty-search-blue.png");

export const EmptySearchBlue = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={emptySearchBlue}
      htmlWidth={196}
      htmlHeight={196}
      alt="No conditions found"
      {...props}
    />
  );
};

const emptySearchGreen = require("./empty-search-green.png");

export const EmptySearchGreen = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={emptySearchGreen}
      htmlWidth={196}
      htmlHeight={196}
      alt="No recommendation pods found"
      {...props}
    />
  );
};

const emptyItemSearchandising = require("./searchandising-search-items.svg");
export const EmptyItemSearchandising = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={emptyItemSearchandising}
      htmlWidth={196}
      htmlHeight={196}
      alt="No item selected"
      {...props}
    />
  );
};

const emptySearchandisingPreview = require("./empty-searchandizing-preview.svg");
export const EmptySearchandisingPreview = (props: Omit<ImageProps, "src">) => {
  return (
    <Image
      src={emptySearchandisingPreview}
      htmlWidth={196}
      htmlHeight={196}
      alt="No preview available"
      {...props}
    />
  );
};
