import React, { lazy } from "react";

import Route from "./shared/MainRoute";

const QuizzesNew = lazy(() => import("./quizzes/new"));
const Quizzes = lazy(() => import("./quizzes/index"));
const QuizEditor = lazy(() => import("./quizzes/edit/[quizId]"));

export default function QuizRoutes() {
  return (
    <>
      <Route exact path="/dashboard/quizzes" component={Quizzes} />

      <Route
        blank
        exact
        path="/dashboard/quizzes/edit/:quizId"
        component={QuizEditor}
      />

      <Route blank exact path="/dashboard/quizzes/new" component={QuizzesNew} />
    </>
  );
}
