import React from "react";

import RegularButton from "app/designSystem/components/Button/RegularButton/RegularButton";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./Footer.styles";

export default function Footer({
  goBackText = "Go back",
  confirmText,
  closeText,
  variant,
  onConfirm,
  onClose,
  onBack,
  isConfirming = false,
  canConfirm = true,
}: Props) {
  return (
    <>
      <RegularButton
        {...styles.buildConfirmButtonStyle(variant)}
        onClick={onConfirm}
        isLoading={isConfirming}
        disabled={!canConfirm || isConfirming}
      >
        {confirmText}
      </RegularButton>

      {variant === "unsaved" && (
        <RegularButton
          {...styles.buttonStyle}
          disabled={isConfirming}
          onClick={onBack}
        >
          {goBackText}
        </RegularButton>
      )}

      <RegularButton
        {...styles.buttonStyle}
        disabled={isConfirming}
        onClick={onClose}
      >
        {closeText}
      </RegularButton>
    </>
  );
}

export type Props = {
  variant: "confirm" | "unsaved";
  confirmText: string;
  closeText: string;
  onConfirm: () => void;
  onClose?: () => void;
  onBack?: () => void;
  goBackText?: string;
  isConfirming?: boolean;
  canConfirm?: boolean;
};
