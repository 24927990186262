import React from "react";
import { IconButton as ChakraIconButton } from "@chakra-ui/react";

import { Spinner } from "app/designSystem/components/Spinner";
import { ChakraIcon } from "app/designSystem/components/ChakraIcon/ChakraIcon";

// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import * as styles from "./IconButton.styles";
// @refactoring Fractal Pattern Alignment https://constructor.slab.com/posts/fractal-pattern-alignment-codebase-structuring-project-s41p7oqi
// eslint-disable-next-line local-rules/enforce-fractal-pattern
import { IconButtonProps } from "./IconButton.types";

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      icon,
      ariaLabel,
      variant = "outline",
      isDisabled = false,
      isLoading = false,
      onClick,
      title,
      dataTestId,
      type,
      ...rest
    },
    ref
  ) => {
    return (
      <ChakraIconButton
        ref={ref}
        variant={variant}
        aria-label={ariaLabel}
        isDisabled={isDisabled}
        isLoading={isLoading}
        title={title}
        type={type}
        data-testid={dataTestId}
        icon={
          icon && (
            <ChakraIcon icon={icon} {...styles.Icon(variant, isDisabled)} />
          )
        }
        onClick={onClick}
        spinner={
          <Spinner
            size="sm"
            color={variant === "ghost" ? "grey-dark" : "white"}
          />
        }
        {...styles.IconButton(variant, isDisabled)}
        {...rest}
      />
    );
  }
);

IconButton.displayName = "IconButton";
